import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withParams, connectInternal } from '../../Helpers/SystemHelper';
import eventsService from '../../Services/EventsService';
import ElementBlock, { elementBlockTitleClass } from '../Elements/ElementBlock';
import ElementsBase from '../Elements/ElementsBase';
import ElementsList from '../Elements/ElementsList';
import InfoBlock from '../Elements/InfoBlock';

class EventTicketTemplateDetails extends Component {
    constructor(props){
        super(props);

        this.state = {
            templateData: ''
        };

        this.loadData = this.loadData.bind(this);
    }

    async loadData(){
        const actionResult = await this.props.processActionResponse(
            eventsService.getTicketTemplate(this.props.params.templateId, this.props.accountToken));

        if (!actionResult.isError){
            this.setState({
                ...this.state,
                templateData: actionResult.data
            });
        }
    }

    render(){
        if (this.state.templateData){
            return(
                <ElementsBase>
                    <ElementBlock>
                        <div className={elementBlockTitleClass}>
                            <h5>{this.state.templateData.name}</h5>
                        </div>
                        <ElementsList includeSeparator>
                            <InfoBlock title='События'>
                                {this.state.templateData.events.map(e => 
                                    <h6 key={`key-evt-${e.id}}`} className="rounded border p-1 d-inline me-1">
                                        {e.name} <Link to={`/events/${e.id}`} className='btn btn-primary p-0'><i className="bi bi-info-square"/></Link>
                                    </h6>)}
                            </InfoBlock>
                            <InfoBlock title='Цена' hidden={!this.state.templateData.price}>
                                <h6 className="rounded border p-1 d-inline">
                                    {this.state.templateData.price} р.
                                </h6>
                            </InfoBlock>
                        </ElementsList>
                    </ElementBlock>
                </ElementsBase>
            );
        }
        else{
            return (<></>);
        }
    }

    async componentDidMount(){
        await this.loadData();

        document.title = "Детали шаблона билета"; 
    }
}

const mapStateToProps = (state) => {
    return {
        accountToken: state.account.token
    }
}

export default connectInternal(mapStateToProps)(withParams(EventTicketTemplateDetails));