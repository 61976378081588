import React, { Component } from "react";
import eventsService from "../../Services/EventsService";
import { withParams, connectInternal } from '../../Helpers/SystemHelper';
import { Link } from "react-router-dom";
import PagingElement from "../FormComponents/PagingElement";
import ElementsBase, { elementsBaseCollapseBlockClass } from "../Elements/ElementsBase";
import ElementsList from "../Elements/ElementsList";
import Modal from "../Elements/Modal";
import EventTicketListElement from "../Elements/EventTicketListElement";
import { elementBlockMenuClass } from "../Elements/ElementBlock";

class EventTickets extends Component {
    pageEntriesCount = 8;

    constructor(props){
        super(props);

        this.state = {
            totalPagesCount: 0,
            ticketsCollection: [],
            accountNameFilter: '',
            commentFilter: '',
            isActiveOnlyFilter: true,
        };

        this.loadData = this.loadData.bind(this);
        this.handleRefreshList = this.handleRefreshList.bind(this);
        this.handleTextFilterChange = this.handleTextFilterChange.bind(this);
        this.handleDeactivatedFilterChange = this.handleDeactivatedFilterChange.bind(this);
        this.handleDeactivateModalOpen = this.handleDeactivateModalOpen.bind(this);
        this.handleDeactivateTicket = this.handleDeactivateTicket.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleActiveFilterChange = this.handleActiveFilterChange.bind(this);
    }

    handleDeactivateModalOpen(event){
        const currentTicket = this.state.ticketsCollection.find(s => s.id == event.currentTarget.value);

        this.setState({
            ...this.state,
            ticketToDeactivate: {
                id: currentTicket.id
            }
        });
    }

    async handleDeactivateTicket(event){
        const actionResult = await this.props.processActionResponse(
            eventsService.deactivateTicket(this.state.ticketToDeactivate.id, this.props.accountToken));

        if (!actionResult.isError){
            await this.loadData(1);
        }
    }

    handleTextFilterChange(event){
        this.setState({
            ...this.state,
            [event.target.id]: event.target.value
        });
    }

    handleActiveFilterChange(event){
        this.setState({
            ...this.state,
            isActiveOnlyFilter: (event.target.id == 'activeOnlyFilterTrue' ? event.target.checked : !event.target.checked)
        });
    }

    handleDeactivatedFilterChange(event){
        this.setState({
            ...this.state,
            isDeactivatedFilter: (event.target.id == 'deactivatedOnlyFilterTrue' ? event.target.checked : !event.target.checked)
        });
    }

    async handleRefreshList(event){
        event.preventDefault();

        await this.loadData();
    }

    async loadData(pageNum = 1){
        const actionResult = await this.props.processActionResponse(
            eventsService.getEventTickets(
                {
                    eventId: this.props.params.eventId,
                    accountName: this.state.accountNameFilter ? this.state.accountNameFilter : null,
                    comment: this.state.commentFilter,
                    isActive: this.state.isActiveOnlyFilter,
                    pagingFilter:{
                        entriesCount: this.pageEntriesCount,
                        pageNumber: pageNum
                    }
                },
                this.props.accountToken));

        let stateUpdate = this.state;

        if (!stateUpdate.orr){
            const eventResult = await this.props.processActionResponse(
                eventsService.getEvent(this.props.params.eventId, this.props.accountToken));

            const orr = await this.props.getOrganizationsRights(eventResult.data.ownersOrganizations.map(o => o.id));

            stateUpdate = {
                ...stateUpdate,
                orr: orr
            }
        }

        if (!actionResult.isError){
            stateUpdate = {
                ...stateUpdate,
                ticketsCollection: actionResult.data.items,
                totalPagesCount: Math.ceil(actionResult.data.totalCount / this.pageEntriesCount)
            };
        }

        this.setState(stateUpdate);
    }

    async handlePageChange(event){
        await this.loadData(event.target.value);
    }

    renderDeactivateApproveModal(){
        return (
            <div className="modal fade" id="deactivateApproveModal" tabIndex="-1" aria-labelledby="deactivateApproveModal" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            Билет будет аннулирован. Это действие невозможно отменить. Вы уверены?
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-warning border-0" data-bs-dismiss="modal">Отменить</button>
                            <button type="button" className="btn btn-outline-danger border-0" onClick={this.handleDeactivateTicket} data-bs-dismiss="modal">Аннулировать</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderList(){
        if (!this.state.ticketsCollection.some(t => !!t)){
            return [<>Билеты отсутствуют</>];
        }

        let showDetailsButton = false;
        let showEditButton = false;
        let showDeactivateButton = false;

        if (this.state.orr){
            showDetailsButton = this.state.orr.hasRight('ViewTicketDetails');
            showEditButton = this.state.orr.hasRight('UpdateTicket');
            showDeactivateButton = this.state.orr.hasRight('DeactivateTicket');
        }

        const hideMenuButton = !showDetailsButton && !showEditButton && !showDeactivateButton;

        return this.state.ticketsCollection.map(t =>{
            return (
                <EventTicketListElement
                    value={t}
                    key={`tic-k-${t.id}`}
                    titleButton='Dropdown'
                    buttonIcon='bi bi-gear'
                    buttonHidden={hideMenuButton}>
                    <div className={elementBlockMenuClass}>
                        <Link className="btn btn-primary text-nowrap" type="button" to={`/events/tickets/${t.id}`} hidden={!showDetailsButton}>
                            <i className="bi bi-info-square"/> Детали
                        </Link>
                        <Link className="btn btn-primary text-nowrap" type="button" to={`/events/tickets/${t.id}/update`} hidden={!showEditButton}>
                            <i className="bi bi-pencil-square"/> Изменить
                        </Link>
                        <button className="btn btn-danger text-nowrap" type="button" value={t.id} onClick={this.handleDeactivateModalOpen} data-bs-toggle="modal" data-bs-target="#deactivateApproveModal" hidden={!showDeactivateButton}>
                            <i className="bi bi-x-square"/> Аннулировать
                        </button>
                    </div>
                </EventTicketListElement>
            );
        });
    }

    render(){
        return(
            <div>
                <ElementsBase
                    bgClass='bg-transparent'
                    titleText='Билеты'
                    titleButton='ButtonCollapse'
                    buttonIcon='bi bi-funnel'>
                    <div className={elementsBaseCollapseBlockClass}>
                        <div className="form-floating mb-3">
                            <input id="accountNameFilter" type="text" className={`form-control`} onChange={this.handleTextFilterChange} value={this.state.accountNameFilter}/>
                            <label htmlFor="accountNameFilter" className="form-label">Имя пользователя</label>
                        </div>
                        <div className="form-floating mb-3">
                            <input id="commentFilter" type="text" className={`form-control`} onChange={this.handleTextFilterChange} value={this.state.commentFilter}/>
                            <label htmlFor="commentFilter" className="form-label">Комментарий</label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="inlineRadioOptions" onChange={this.handleActiveFilterChange} id="activeOnlyFilterTrue" checked={this.state.isActiveOnlyFilter}/>
                            <label className="form-check-label text-reset" htmlFor="activeOnlyFilterTrue">Действующие</label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="inlineRadioOptions" onChange={this.handleActiveFilterChange} id="activeOnlyFilterFalse" checked={!this.state.isActiveOnlyFilter}/>
                            <label className="form-check-label text-reset" htmlFor="activeOnlyFilterFalse">Недействующие</label>
                        </div>
                        <button className="btn btn-primary" type="button" onClick={this.handleRefreshList}>
                            <span className="sr-only">Найти</span>
                        </button>
                    </div>
                </ElementsBase>
                <ElementsBase>
                    <ElementsList includeSeparator>
                        {[...this.renderList(), <PagingElement key='pag-k' totalPagesCount={this.state.totalPagesCount} onChange={this.handlePageChange} />]}
                    </ElementsList>
                </ElementsBase>
                <Modal 
                    id="deactivateApproveModal"
                    modalType='danger'
                    cancelButtonText='Нет'
                    approveButtonText='Да'
                    approveButtonClick={this.handleDeactivateTicket}
                    infoText='Это действие невозможно отменить. Вы уверены что хотите удалить аннулировать билет?' />
            </div>
        )
    }

    async componentDidMount(){
        await this.loadData();

        document.title = "Билеты"; 
    }
}

const mapStateToProps = (state) => {
    return {
        accountToken: state.account.token
    }
}

export default connectInternal(mapStateToProps)(withParams(EventTickets));