import ElementsList from "./ElementsList";

const Modal = (props) => {
    let approveButtonStyle = '';
    let resultElements = [];

    const renderBody = () => {
        switch(props.modalType) {
            case 'danger':
                approveButtonStyle = 'btn-outline-danger';
                if (props.infoText){
                    resultElements.push(
                        <div key={`${props.id}-info`} className='text-danger text-center'>
                            <h1><i className="bi bi-exclamation-triangle"/></h1>
                            <h6>{props.infoText}</h6>
                        </div>
                    );
                }
                break;
            case 'positive':
                approveButtonStyle = 'btn-outline-success';
                if (props.infoText){
                    resultElements.push(
                        <div key={`${props.id}-info`} className='text-success text-center'>
                            <h1><i className="bi bi-exclamation-triangle"/></h1>
                            <h6>{props.infoText}</h6>
                        </div>
                    );
                }
                break;          
            default:
                break;
        }

        if (props.children){
            if (props.children.constructor.name == "Array"){
                resultElements = resultElements.concat(props.children);
            }
            else{
                resultElements.push(props.children);
            }
        }

        return resultElements;
    }

    const renderIcon = (iconClass) => {
        if (!iconClass){
            return <></>;
        }

        return <i className={iconClass}/>;
    }

    const renderButtons = () => {
        const buttons = [];

        if (props.cancelButtonText){
            buttons.push(<button key='dan-but-key' className={`btn btn-outline-warning border-0`} type="button" onClick={props.cancelButtonClick} data-bs-dismiss="modal">{renderIcon(props.cancelButtonIcon)}{props.cancelButtonText}</button>);
        }
        if (props.approveButtonText){
            let approveButtonDataBsDismiss = props.approveButtonDataBsDismiss;
            if (!props.approveButtonDataBsToggle && !props.approveButtonDataBsTarget && !approveButtonDataBsDismiss){
                approveButtonDataBsDismiss = 'modal';
            }
            buttons.push(<button key='suc-but-key' value={props.approveButtonValue} className={`btn ${approveButtonStyle} border-0`} type="button" onClick={props.approveButtonClick} data-bs-toggle={props.approveButtonDataBsToggle} data-bs-target={props.approveButtonDataBsTarget} data-bs-dismiss={approveButtonDataBsDismiss}>{renderIcon(props.approveButtonIcon)}{props.approveButtonText}</button>);
        }

        return <ElementsList horizontal>{buttons}</ElementsList>;
    }

    return (
        <div className="modal fade" id={props.id} tabIndex="-1" aria-labelledby={`${props.id}Label` } aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-body">
                        {renderBody()}
                    </div>
                    <div className="modal-footer">
                        {renderButtons()}
                    </div>
                </div>
            </div>
        </div>
    );
}

const showModal = (modalId) => {
    const toggleButton = document.createElement("button");
    toggleButton.setAttribute('data-bs-toggle', 'modal');
    toggleButton.setAttribute('data-bs-target', modalId);
    toggleButton.setAttribute('type', 'button');
    document.body.appendChild(toggleButton);
    toggleButton.style = "display: none";
    toggleButton.click();
}

export default Modal;
export { showModal };