const BeautyTextBlock = (props) => {
    if (props.hidden || !props.textData){
        return <></>;
    }

    const splittedData = props.textData.split(/\r|\n/);
    let counter = 0;

    return (
        <div>
            {splittedData.map(t =>{
                counter++;
                return t ? <p key={`bet-t-key-${counter}`} className="m-0 p-0">{t}</p> : <div key={`bet-t-key-${counter}`} className="p-2"/>
            })}
        </div>
    );
}

export default BeautyTextBlock;