const InfoBlock = (props) => {
    if (props.hidden){
        return <></>;
    }

    const bgClass = props.bgClass ?? '';

    return (
        <div className={props.className}>
            <div className={`rounded ${bgClass}`}>
                {props.title 
                    ? <div>
                        <h6 className="rounded p-1 m-0">
                            {props.title}
                        </h6>
                    </div>
                    : <></>}
                <div className="p-1 ps-3 d-flex flex-wrap">
                    {props.children}
                </div>
            </div>
        </div>
    );
}

export default InfoBlock;