import { createSlice } from "@reduxjs/toolkit";
import { setLocalStorageAccountData, getLocalStorageAccountData, clearLocalStorageAccountData } from '../Helpers/LocalStorageHelper'

const accountSlice = createSlice({
    name: 'account',
    initialState: {
        name: '',
        systemRoleId: 0,
        isSystemAdmin: false,
        token: '',
        organizationsRights: {}
    },
    reducers: {
      setLoginData(state, action){
        const storageData = getLocalStorageAccountData();

        setLocalStorageAccountData({
          ...storageData,
          name: action.payload.name,
          systemRoleId: action.payload.systemRoleId,
          token: action.payload.token});

        return {
            ...state,
            name: action.payload.name,
            systemRoleId: action.payload.systemRoleId,
            isSystemAdmin: (action.payload.systemRoleId == 2),
            token: action.payload.token
        };
      },
      setAccountName(state, action){
        const storageData = getLocalStorageAccountData();

        setLocalStorageAccountData({
          ...storageData,
          name: action.payload.name
        });

        return {
          ...state,
          name: action.payload.name
        };
      },
      clearData(state){
        clearLocalStorageAccountData();

        return {
          ...state,
          name:'',
          systemRoleId: 0,
          isSystemAdmin: false,
          token: '',
          organizationsRights: {}
        };
      },
      addOrganizationRights(state, action){
        return{
          ...state,
          organizationsRights: {
            ...state.organizationsRights,
            [action.payload.organizationId]: action.payload.organizationRights
          }
        };
      },
    }
  });

export const { setLoginData, clearData, setAccountName, addOrganizationRights, requireOrganizationRights } = accountSlice.actions;
export default accountSlice.reducer;