import ElementBlock, { elementBlockTitleClass } from "./ElementBlock";
import InfoBlock from "./InfoBlock";
import { getTicketStatusBadge } from '../../Helpers/EventTicketStatusHelper';

const EventTicketListElement = (props) => {
    const ticketData = props.value;
    const title = ticketData.account ? ticketData.account.name : '';

    return (
        <ElementBlock 
            directProps={props}>
            <div className={elementBlockTitleClass}>
                {getTicketStatusBadge(ticketData)}
                <h5>{title}</h5>
            </div>
            <h6>{ticketData.name}</h6>
            {!!ticketData.comment
                ? <InfoBlock
                    title='Комментарий'>
                    <p className="text-truncate m-0">{ticketData.comment}</p>
                </InfoBlock> 
                : <></>}
        </ElementBlock>
    );
}

export default EventTicketListElement;