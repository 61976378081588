const eventStatuses = {
    Planned: { id: 1, label: 'Запланировано' },
    Cancelled: { id: 2, label: 'Отменено' },
    Incoming: { id: 3, label: 'Запланировано' },
    InProgress: { id: 4, label: 'Идет...' },
    Finished: { id: 5, label: 'Завершено' },
}

const getLabelById = (filterId) => {
    for(let [key, {id, label}] of Object.entries(eventStatuses)){
        if (id == filterId){
            return label;
        }
    }

    return;
}

export { eventStatuses, getLabelById }