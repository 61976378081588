import { getDateTimeFormatString } from "../../Helpers/FormHelpers";
import ElementBlock, { elementBlockTitleClass } from "./ElementBlock";

const EventListElement = (props) => {
    const eventData = props.value;
    const eventDate = new Date(eventData.eventDatetime);

    return (
        <ElementBlock 
            directProps={props}>
            <div className={elementBlockTitleClass}>
                <span className="badge bg-danger" hidden={!eventData.isCancelled}>Отменено</span>
                <div className="badge p-1 border border-secondary">{`${getDateTimeFormatString(eventDate)}`}</div>
                <h5>{eventData.name}</h5>
            </div>
            {renderSpecs()}
        </ElementBlock>
    );
}

const renderSpecs = (specs) => {
    if (!specs){
        return <></>
    }

    return specs.map(s => 
        <span className = "badge p-1 me-1 mb-1 border border-secondary">
            {s.name}
        </span>
    )
}

export default EventListElement;