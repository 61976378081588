import React, { Component } from 'react';
import { connectInternal } from '../../Helpers/SystemHelper';
import accountsService from '../../Services/AccountsService';
import { handleInputChange, isFormStateValid } from '../../Helpers/FormHelpers';
import { validateAccountPassword, validateAccountPasswordVerification } from '../../Helpers/Validations';
import { validationFieldClass } from '../../Helpers/Constants';
import { withRouter } from '../../Helpers/SystemHelper';
import PasswordResetSuccess from './PasswordResetSuccess';
import ElementsBase from '../Elements/ElementsBase';
import InitFormsWrapper from '../Elements/InitFormsWrapper';

class PasswordResetForm extends Component {
    isCaptchaLoading = false;

    constructor(props){
        super(props);

        this.state = { 
            accountPassword: {
                validator: validateAccountPassword
            },
            accountPasswordVerification: {
                validator: validateAccountPasswordVerification,
                parameterElement: 'accountPassword'
            }
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.togglePasswordVisibility = this.togglePasswordVisibility.bind(this);
    }

    togglePasswordVisibility(){
        var passwordFieldElement = document.getElementById('accountPassword');
        var visibilityIconElement = document.getElementById('p-vis-icon');

        visibilityIconElement.classList.toggle("bi-eye");
        visibilityIconElement.classList.toggle("bi-eye-slash");

        if (visibilityIconElement.classList.contains("bi-eye")){
            passwordFieldElement.type = 'password';
        }
        else if (visibilityIconElement.classList.contains("bi-eye-slash")){
            passwordFieldElement.type = 'text';
        }
    }

    handleInputChange(event) {
        handleInputChange.call(this, [event.target]);
    }

    async handleSubmit(event) {
        event.preventDefault();

        if (!isFormStateValid.call(this)){
            return;
        }

        const actionResult = await this.props.processActionResponse(
            accountsService.resetAccountPassword({
                resetCode: this.props.router.params.resetCode,
                password: this.state.accountPassword.data
            }));

        if (!actionResult.isError){
            this.setState({
                actionFinished: true
            });
        }
    }

    render() {
        if (this.state.actionFinished){
            return <PasswordResetSuccess/>;
        }

        return(
            <InitFormsWrapper>
                <ElementsBase>
                    <div className='d-flex mb-3'>
                        <div className="form-floating flex-grow-1">                                    
                            <input id="accountPassword" type="password" className={`${validationFieldClass} form-control`} onChange={this.handleInputChange}/>
                            <label htmlFor="accountPassword" className='form-label'>Пароль</label>
                            <div className="invalid-feedback" htmlFor="accountPassword">{this.state.accountPassword.error}</div>
                        </div>
                        <div>
                            <button className="btn text-light input-group-text h-100" type='button' onClick={this.togglePasswordVisibility}><i id='p-vis-icon' className="bi bi-eye"/></button>
                        </div>
                    </div>
                    <div className="form-floating mb-3">
                        <input id="accountPasswordVerification" type="password" className={`${validationFieldClass} form-control`} onChange={this.handleInputChange}/>
                        <label htmlFor="accountPasswordVerification" className='form-label'>Подтверждение пароля</label>
                        <div className="invalid-feedback" htmlFor="accountPasswordVerification">{this.state.accountPasswordVerification.error}</div>
                    </div>
                    <button className="btn btn-secondary" type="button" onClick={this.handleSubmit}>Сохранить</button>
                </ElementsBase>
            </InitFormsWrapper>
        )
    }

    componentDidMount() {
        document.title = "Сброс пароля"; 
    }
}

const mapStateToProps = (state) => {
    return {
        accountToken: state.account.token
    }
}

export default connectInternal(mapStateToProps)(withRouter(PasswordResetForm));