import React, { Component } from 'react';
import { connectInternal } from '../../../Helpers/SystemHelper';
import accountsService from '../../../Services/AccountsService';
import organizationsService from '../../../Services/OrganizationsService';
import QrScanner from '../QrScanner';
import { areArraysEqual } from '../../../Helpers/FormHelpers';
import Modal from '../../Elements/Modal';
import AccountListElement from '../../Elements/AccountListElement';
import ElementsList from '../../Elements/ElementsList';
import ElementsBase from '../../Elements/ElementsBase';

class UserPicker extends Component {
    isValueChanged = false;
    deleteAccountId = '';

    constructor(props){
        super(props);

        this.id = props.id;

        this.state = {
            isSingleSelect: this.props.singleSelect,
            isAccountLinkAddDisabled: false,
            accountLinkInputValue: {
                code: ''
            },
            accountNameFilterValue: '',
            isElementLoading: false,
            accountsLinks: [],
            organizationAccounts: [],
            deleteAccountName: ''
        }

        this.handleAddAccountLink = this.handleAddAccountLink.bind(this);
        this.addAccountLink = this.addAccountLink.bind(this);
        this.handleRemoveAccountLink = this.handleRemoveAccountLink.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.dispatchOnChangeEvent = this.dispatchOnChangeEvent.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.onQrModalShow = this.onQrModalShow.bind(this);
        this.onAccountsListModalShow = this.onAccountsListModalShow.bind(this);
        this.loadOrganizationAccounts = this.loadOrganizationAccounts.bind(this);
        this.openDeleteApproveModal = this.openDeleteApproveModal.bind(this);
        this.handleOrgAccountClick = this.handleOrgAccountClick.bind(this);
        this.handleQrScanCompleted = this.handleQrScanCompleted.bind(this);
    }

    openDeleteApproveModal(event){
        this.deleteAccountId = event.currentTarget.value;
        this.setState({
            ...this.state,
            deleteAccountName: this.state.accountsLinks.find(l => l.id === this.deleteAccountId).name
        });
    }

    handleQrScanCompleted(event){
        if (!!event.target.value.code) {
            this.setState({
                ...this.state,
                isQrModalShown: false,
                isAccountLinkAddDisabled: false,
                accountLinkInputValue: {
                    code: event.target.value.code
                }
            });
        }
    }

    async handleAddAccountLink(event) {
        if (event){
            event.preventDefault();
        }
        
        const currentLink = this.state.accountLinkInputValue;

        await this.addAccountLink(currentLink);
    }

    async addAccountLink(currentLink) {
        this.accountsLinks = [
            ...this.getClearedStateCollection({id: currentLink.id, code: currentLink.code, accountsLinks: this.state.accountsLinks}),
            {
                id: currentLink.id || currentLink.code,
                code: currentLink.code,
                name: currentLink.name || currentLink.code,
                isNew: true
            }
        ];

        this.setState({
            ...this.state,
            isElementLoading: true,
            isAccountLinkAddDisabled: true,
            accountLinkInputValue: {
                code: ''
            },
            accountsLinks: this.accountsLinks
        });

        if (currentLink.id){
            this.isValueChanged = true;
            this.setState({
                ...this.state,
                isElementLoading: false
            })
            return;
        }

        const actionResult = await this.props.processActionResponse(
            accountsService.getAccountByLinkCode(currentLink.code, this.props.accountToken));

        let stateUpdate = {
            ...this.state,
            isElementLoading: false,
            isAccountLinkAddDisabled: false,
        };
        if (!actionResult.isError){
            if (this.state.accountsLinks.some(l => l.id === actionResult.data.id)){
                let tempAccountLinks = this.getClearedStateCollection({id: actionResult.data.id, code: currentLink.code, accountsLinks: stateUpdate.accountsLinks});
                this.accountsLinks = [
                    ...tempAccountLinks,
                    {
                        id: actionResult.data.id,
                        code: currentLink.code,
                        name: actionResult.data.name,
                        isNew: true
                    }
                ];
                stateUpdate = {
                    ...stateUpdate,
                    accountsLinks: this.accountsLinks
                };
            }
            else{
                this.isValueChanged = true;
                this.accountsLinks = [
                    ...this.getClearedStateCollection({code: currentLink.code, accountsLinks: stateUpdate.accountsLinks}),
                    {
                        id: actionResult.data.id,
                        code: currentLink.code,
                        name: actionResult.data.name,
                        isNew: true
                    }
                ];
                stateUpdate = {
                    ...stateUpdate,
                    accountsLinks: this.accountsLinks
                };
            }
        }
        else{
            stateUpdate = {
                ...stateUpdate,
                accountsLinks: this.getClearedStateCollection({code: currentLink.code, accountsLinks: stateUpdate.accountsLinks})
            };
        }

        this.setState(stateUpdate);
    }

    handleCloseModal(event) {
        event.preventDefault();

        this.setState({
            ...this.state,
            isQrModalShown: false,
            isAccountLinkAddDisabled: true,
            accountLinkInputValue: {
                code: ''
            }
        });
    }

    handleRemoveAccountLink() {
        this.isValueChanged = true;
        this.accountsLinks = this.getClearedStateCollection({id: this.deleteAccountId, accountsLinks: this.state.accountsLinks});
        this.setState({
            ...this.state,
            accountsLinks: this.accountsLinks
        });
    }

    getClearedStateCollection({code, id, accountsLinks}){
        const newLinksArray = accountsLinks;

        newLinksArray.forEach(l => l.isNew = false);

        let index = -1;
        if (code){
            index = newLinksArray.findIndex(l => l.code === code);
            
            if (index > -1) {
                newLinksArray.splice(index, 1);

                index = -1;
            }
        }
        
        if (id){
            index = newLinksArray.findIndex(l => l.id === id);

            if (index > -1) {
                newLinksArray.splice(index, 1);
            }
        }

        return newLinksArray;
    }

    handleInputChange(event){
        if (event.currentTarget.id == 'accountNameFilter'){
            this.setState({
                ...this.state,
                organizationAccounts: this.state.organizationAccountsBackup.filter(a => a.name.toUpperCase().includes(event.currentTarget.value.toUpperCase())),
                accountNameFilterValue: event.currentTarget.value
            });

            return;
        }

        let stateUpdate = {
            ...this.state,
            accountLinkInputValue: {
                code: event.target.value
            }
        };

        if (!stateUpdate.accountLinkInputValue.code){
            stateUpdate = {
                ...stateUpdate,
                isAccountLinkAddDisabled: true
            };
        }
        else{
            stateUpdate = {
                ...stateUpdate,
                isAccountLinkAddDisabled: false
            };
        }

        this.setState(stateUpdate);
    }

    async handleOrgAccountClick(event){
        event.preventDefault();

        const targetAccount = this.state.organizationAccounts.find(a => a.id == event.currentTarget.value);

        await this.addAccountLink(targetAccount);
    }

    onQrModalShow(event){
        this.setState({
            ...this.state,
            isQrModalShown: true,
            accountLinkInputValue: {
                code: ''
            }
        });
    }

    async loadOrganizationAccounts(){
        if (!this.state.organizationId){
            return;
        }

        const actionResult = await this.props.processActionResponse(
            organizationsService.getOrganizationAccounts(
                {
                    organizationId: this.state.organizationId,
                    filterName: '',
                    isNameFilterStrict: false
                }, 
                this.props.accountToken));

        if (!actionResult.isError){
            this.setState({
                ...this.state,
                organizationAccounts: actionResult.data,
                organizationAccountsBackup: actionResult.data
            });
        }
    }

    async onAccountsListModalShow(){
        await this.loadOrganizationAccounts();
    }

    renderOrgAccountsList(){
        if (this.state.organizationAccounts){
            return(
                <ElementsList includeSeparator>
                    {this.state.organizationAccounts.map(a => {
                        return (
                            <AccountListElement 
                                key={a.id}
                                value={a}
                                titleButton='Button'
                                buttonClick={this.handleOrgAccountClick}
                                buttonIcon="bi bi-check2-square"
                                buttonDataBsDismiss="modal"
                                buttonValue={a.id} />
                        )
                    })}
                </ElementsList>
            )
        }
        else{ return(<div/>) }
    }

    renderSelectedLinks(){
        if (this.state.accountsLinks && this.state.accountsLinks.some(l => !!l)){
            return (this.state.accountsLinks.map(l => 
                <div key={`selected-accs-${l.id}`} className="btn-group mt-3 me-1" role="group" >
                    <button type="button" value={l.id} className="btn btn-danger" onClick={this.openDeleteApproveModal} data-bs-toggle="modal" data-bs-target="#accountRemoveApproveModal" hidden={this.state.isElementLoading || this.props.disabled}>
                        <i className="bi bi-x-square" />
                    </button>
                    <button type='button' className={`btn ${l.isNew ? 'btn-outline-info' : 'btn-outline-primary'}`} disabled>{l.name}</button>
                </div>));
        }
        else{
            return (<div className='mb-2'/>)
        }
    }

    renderEditButtons(){
        if (this.props.isSingleSelect && this.state.accountsLinks.some(l => !!l)){
            return <></>;
        }

        return (
            <>
                <button type="button" className="btn btn-outline-primary mt-3" data-bs-toggle="modal" data-bs-target="#codeInputModal" disabled={this.state.isElementLoading} hidden={this.props.disabled}>
                    <i className="bi bi-input-cursor-text"/>
                </button>
                <button type="button" className="btn btn-outline-primary mt-3 ms-1" data-bs-toggle="modal" data-bs-target="#qrScanModal" onClick={this.onQrModalShow} disabled={this.state.isElementLoading} hidden={this.props.disabled}>
                    <i className="bi bi-qr-code-scan"/>
                </button>
                <button type="button" className="btn btn-outline-primary mt-3 ms-1" data-bs-toggle="modal" data-bs-target="#accountsListModal" onClick={this.onAccountsListModalShow} disabled={this.state.isElementLoading || this.props.disabled} hidden={this.props.disabled || !this.props.organizationId}>
                    <i className="bi bi-person-lines-fill"/>
                </button>
            </>
        );
    }

    render(){
        if (this.props.hidden){
            return <></>;
        }

        return (
            <div id={this.props.id}>
                <div className="container d-flex flex-wrap form-floating p-3 bg-light border rounded">
                    <input id="ghostUserPickerDiv" className='form-control' hidden/>
                    <label htmlFor="ghostUserPickerDiv" className='form-label'>{this.props.label}</label>
                    {this.renderSelectedLinks()}
                    {this.renderEditButtons()}
                </div>
                <Modal 
                    id="accountRemoveApproveModal"
                    modalType='danger'
                    cancelButtonText='Нет'
                    approveButtonText='Да'
                    approveButtonClick={this.handleRemoveAccountLink}
                    infoText={`Из группы будет удален аккаунт ${this.state.deleteAccountName}. Вы уверены?`}  />
                <div className="modal fade" id="codeInputModal" tabIndex="-1" aria-labelledby="codeInputModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="mb-3">
                                    <input type="number" className="form-control" placeholder='Код' onChange={this.handleInputChange} value={this.state.accountLinkInputValue.code}/>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-warning border-0" onClick={this.handleCloseModal} data-bs-dismiss="modal">Закрыть</button>
                                <button type="button" className="btn btn-outline-success border-0" onClick={this.handleAddAccountLink} data-bs-dismiss="modal" disabled={this.state.isAccountLinkAddDisabled}>Добавить</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="qrScanModal" tabIndex="-1" aria-labelledby="qrScanModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <QrScanner id='qr-code-accs-scanner' className="mb-3" onScanCompleted={this.handleQrScanCompleted} hidden={!this.state.isQrModalShown}/>
                                <div className='d-flex justify-content-center'>
                                    <h1 className="text-center" hidden={!this.state.accountLinkInputValue.code}>{this.state.accountLinkInputValue.code}</h1>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-success border-0" data-bs-dismiss="modal" onClick={this.handleAddAccountLink} disabled={this.state.isAccountLinkAddDisabled} hidden={!this.state.accountLinkInputValue.code}>Добавить</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="accountsListModal" tabIndex="-1" aria-labelledby="accountsListModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="form-floating mb-3">
                                    <input id="accountNameFilter" type="text" className='form-control' onChange={this.handleInputChange} value={this.state.accountNameFilterValue}/>
                                    <label htmlFor="accountNameFilter" className='form-label'>Аккаунт</label>
                                </div>
                                <ElementsBase>
                                    {this.renderOrgAccountsList()}
                                </ElementsBase>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount(){
        if (this.props.hidden){
            return;
        }

        const wrappingDiv = document.getElementById(this.props.id);
        wrappingDiv.className = this.props.className;

        this.className = wrappingDiv.className;
        this.classList = wrappingDiv.classList;

        var myModalEl = document.getElementById('qrScanModal');

        myModalEl.addEventListener('shown.bs.modal', this.onQrModalShow);

        myModalEl.addEventListener('hidden.bs.modal', this.handleCloseModal);
    }

    componentDidUpdate(){
        if (this.isValueChanged){
            this.isValueChanged = false;
            this.dispatchOnChangeEvent();
        }
    }

    dispatchOnChangeEvent(){
        this.value = this.accountsLinks;

        this.props.onChange({target: this});
    }

    static getDerivedStateFromProps(props, state){
        if (props.organizationId != state.organizationId
            || (props.value && !areArraysEqual(props.value, state.accountsLinks, (v, l) => v.id == l.id))){
            return ({ 
                ...state,
                organizationId: props.organizationId || state.organizationId,
                accountsLinks: props.value || state.accountsLinks
            })
        }

        return null;
    }
}

const mapStateToProps = (state) => {
    return {
        accountToken: state.account.token
    }
}

export default connectInternal(mapStateToProps)(UserPicker);