import { Link } from "react-router-dom";

const elementsBaseTitleClass = 'elements-base-title';
const elementsBaseMenuClass = 'elements-base-menu';
const elementsBaseCollapseBlockClass = 'elements-base-collapse';

const ElementsBase = (props) => {
    const propsToUse = props.directProps ?? props;
    const bgClass = propsToUse.bgClass ?? 'bg-primary';

    if (propsToUse.hidden){
        return <></>;
    }

    let hasTitleBlock = false;

    let currentChildren = [];
    if (props.children){
        currentChildren = props.children.constructor.name == "Array"
            ? props.children
            : [ props.children ];
    }

    const renderTitleBlock = () => {
        if (props.titleText){
            hasTitleBlock = true;
            return <h5>{propsToUse.titleText}</h5>;
        }

        const titleBlock = currentChildren.find(c => c.props.className == elementsBaseTitleClass);
        hasTitleBlock = !!titleBlock;
        
        return titleBlock ? <div>{titleBlock.props.children}</div> : <div></div>;
    }

    const renderTitleButton = () => {
        if (propsToUse.titleButton == 'Link'){
            hasTitleBlock = true;
            return (
                <Link to={propsToUse.linkTo} className="btn btn-primary" hidden={propsToUse.buttonHidden}>
                    <i className={propsToUse.buttonIcon}/> {propsToUse.buttonText}
                </Link>
            );
        }
        else if (propsToUse.titleButton == 'Button'){
            hasTitleBlock = true;
            return (
                <button className="btn btn-primary" value={propsToUse.buttonValue} onClick={propsToUse.buttonClick} data-bs-toggle={propsToUse.buttonDataBsToggle} data-bs-target={propsToUse.buttonDataBsTarget} hidden={propsToUse.buttonHidden}>
                    <i className={propsToUse.buttonIcon}/> {propsToUse.buttonText}
                </button>
            );
        }
        else if (propsToUse.titleButton == 'ButtonCollapse'){
            hasTitleBlock = true;
            return (
                <button className="btn btn-primary" data-bs-toggle="collapse" data-bs-target="#collapseBlock" aria-expanded="false" aria-controls="collapseBlock" hidden={propsToUse.buttonHidden}>
                    <i className={propsToUse.buttonIcon}/> {propsToUse.buttonText}
                </button>
            );
        }

        const menuBlock = currentChildren.find(c => c.props.className == elementsBaseMenuClass);
        
        if (menuBlock){
            hasTitleBlock = !menuBlock.props.hidden;
            return menuBlock.props.hidden ? <></> : menuBlock.props.children;
        }

        return <></>;
    }

    const renderCollapseBlock = () => {
        if (propsToUse.titleButton != 'ButtonCollapse'){
            return <></>;
        }
        
        const collapseBlock = currentChildren.find(c => c.props.className == elementsBaseCollapseBlockClass);

        if (!collapseBlock){
            return <></>;
        }

        return (
            <div className="collapse" id="collapseBlock">
                {collapseBlock.props.children}
            </div>
        );
    }

    const renderBody = () => {
        const bodyChildren = currentChildren.filter(c => c.props.className != elementsBaseTitleClass && c.props.className != elementsBaseCollapseBlockClass && c.props.className != elementsBaseMenuClass);

        const bodyChildrenClass = !!bodyChildren && hasTitleBlock ? 'mt-2' : '';

        return <div className={bodyChildrenClass}>{bodyChildren}</div>;
    }
    
    return (
        <div className={`rounded p-2 ${bgClass} w-100 elements-base-class`}>
            <div className={`d-flex`}>
                <div className="flex-grow-1">
                    {renderTitleBlock()}
                </div>
                <div>
                    {renderTitleButton()}
                </div>
            </div>
            <div className="flex-grow-1 pt-2">
                {renderCollapseBlock()}
            </div>
            {renderBody()}
        </div>
    );
}

export { elementsBaseCollapseBlockClass, elementsBaseTitleClass, elementsBaseMenuClass };
export default ElementsBase;