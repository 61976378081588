const InitFormsWrapper = (props) => {
    const logoSize = props.logoSize ?? 100;

    return (
        <div className="container">
            <div className='d-flex justify-content-center'>
                <img src={`${process.env.PUBLIC_URL}/img/logo.svg`} className="img-fluid mb-3" width={logoSize} height={logoSize} alt="Logo"/>
            </div>
            {props.children}
        </div>
    );
}

export default InitFormsWrapper;