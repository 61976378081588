import React, { Component } from 'react';
import { connectInternal } from '../../../Helpers/SystemHelper';
import organizationsService from '../../../Services/OrganizationsService';
import ElementsBase from '../../Elements/ElementsBase';
import ElementsList from '../../Elements/ElementsList';
import OrganizationRoleListElement from '../../Elements/OrganizationRoleListElement';

class OrganizationRolePicker extends Component {
    roleModalCheckboxName = 'role-modal-checkbox';

    constructor(props){
        super(props);

        this.id = props.id;

        this.state = {
            selectedRole: props.value || '',
            rolesList: []
        }

        this.handleSelectRole = this.handleSelectRole.bind(this);
        this.handleLoadRolesData = this.handleLoadRolesData.bind(this);
        this.handleDeselectRole = this.handleDeselectRole.bind(this);
    }

    handleDeselectRole(event){
        this.value = '';
        this.setState({
            ...this.state,
            selectedRole: ''
        });

        this.props.onChange({target: this});
    }

    handleSelectRole(event){
        event.preventDefault();

        if (this.state.selectedRole && this.state.selectedRole.id == event.currentTarget.value){
            return;
        }

        const selectedRole = this.state.rolesList.find(r => r.id == event.currentTarget.value);

        this.value = selectedRole
        this.setState({
            ...this.state,
            selectedRole: selectedRole
        });

        this.props.onChange({target: this});
    }

    async handleLoadRolesData(event){
        const actionResult = await this.props.processActionResponse(
            organizationsService.getOrganizationRoles(this.props.organizationId, this.props.accountToken));

        if (!actionResult.isError){
            this.setState({
                ...this.state,
                rolesList: actionResult.data
            });
        }
    }

    renderRolesList(){
        const elementsArray = [];

        for(let index in this.state.rolesList){
            const currentRole = this.state.rolesList[index];
            if (this.state.selectedRole){
                if (this.state.selectedRole && currentRole.id == this.state.selectedRole.id){
                    continue;
                }
            }

            elementsArray.push(
                <OrganizationRoleListElement 
                    key={`list-roles-${currentRole.id}`}
                    value={currentRole}
                    titleButton='Button'
                    buttonClick={this.handleSelectRole}
                    buttonIcon="bi bi-check2-square"
                    buttonDataBsDismiss="modal"
                    buttonValue={currentRole.id}/>
            );
        }

        return (
            <ElementsList includeSeparator>
                {elementsArray}
            </ElementsList>
        );
    }

    renderSelectedRole(){
        if (this.state.selectedRole){
            return (
                <div className="btn-group mt-3 me-1" role="group">
                    <button type='button' className="btn btn-outline-primary" onClick={this.handleLoadRolesData} data-bs-toggle="modal" data-bs-target="#roleSelectModal" disabled={this.props.disabled}>{this.state.selectedRole.name}</button>
                    <button type="button" className="btn btn-danger" onClick={this.handleDeselectRole} hidden={this.props.disabled}>
                        <i className="bi bi-x-square"/>
                    </button>
                </div>
            );
        }
        else if (this.props.disabled) {
            return (
                <div className='mb-2' />
            );
        }
        else {
            return (
                <button type='button' className="btn btn-outline-primary mt-3 me-1" onClick={this.handleLoadRolesData} data-bs-toggle="modal" data-bs-target="#roleSelectModal">
                    <i className="bi bi-universal-access-circle"/>
                </button>
            );
        }
    }

    render(){
        return (
            <div id={this.props.id}>
                <div className="container d-flex flex-wrap form-floating p-3 border rounded bg-light">
                    <input id="ghostRolePickerDiv" className='form-control' hidden/>
                    <label htmlFor="ghostRolePickerDiv" className='form-label'>Роль</label>
                    {this.renderSelectedRole()}
                    <div className="modal fade" id="roleSelectModal" tabIndex="-1" aria-labelledby="roleSelectModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <ElementsBase>
                                        {this.renderRolesList()}
                                    </ElementsBase>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    async componentDidMount(){
        const wrappingDiv = document.getElementById(this.props.id);
        wrappingDiv.className = this.props.className;

        this.className = wrappingDiv.className;
        this.classList = wrappingDiv.classList;
    }

    static getDerivedStateFromProps(props, state){
        if (props.organizationId != state.organizationId
            || (!props.value && state.selectedRole) 
            || (props.value && props.value.id != state.selectedRole.id)){
            return ({ 
                ...state,
                organizationId: props.organizationId,
                selectedRole: props.value
            })
        }

        return null;
    }
}

const mapStateToProps = (state) => {
    return {
        accountToken: state.account.token
    }
}

export default connectInternal(mapStateToProps)(OrganizationRolePicker);