import React, { Component } from 'react';
import { connectInternal, withRouter } from '../../Helpers/SystemHelper';
import { validationFieldClass } from '../../Helpers/Constants';
import { validateOrganizationSubscriptionTemplateName, validateOrganizationSubscriptionTemplateTotalValue, validateOrganizationSubscriptionTemplateValidityInterval, validateOrganizationSubscriptionTemplatePrice } from '../../Helpers/Validations';
import { handleInputChange, getNodaDurationFromDaysNumber, getDaysFormInputDurationFormatString, isFormStateValid, } from '../../Helpers/FormHelpers';
import organizationsService from '../../Services/OrganizationsService';
import OrganizationHeader from '../OrganizationHeader';
import ElementsBase, { elementsBaseMenuClass } from '../Elements/ElementsBase';
import ElementsList from '../Elements/ElementsList';
import Modal from '../Elements/Modal';

class OrganizationSubscriptionTemplateCreateUpdate extends Component {
    constructor(props){
        super(props);

        this.state = {
            isCreateState: !this.props.router.params.templateId,
            isReadState: this.props.router.params.templateId,
            isEditState: false,
            isActionFinished: false,
            organizationId: this.props.router.params.organizationId,
            templateId: this.props.router.params.templateId,
            templateName:{
                validator: validateOrganizationSubscriptionTemplateName,
                data: ''
            },
            templateTotalValue:{
                validator: validateOrganizationSubscriptionTemplateTotalValue,
                data: ''
            },
            templateValidityInterval:{
                validator: validateOrganizationSubscriptionTemplateValidityInterval,
                data: ''
            },
            templatePrice:{
                validator: validateOrganizationSubscriptionTemplatePrice,
                data: ''
            }
        };

        this.loadData = this.loadData.bind(this);
        this.hadleSetEditState = this.hadleSetEditState.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleDeleteTemplate = this.handleDeleteTemplate.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.isFormStateValid = isFormStateValid.bind(this);
    }

    async handleSubmit(event){
        event.preventDefault();

        if (!this.isFormStateValid()){
            return;
        }

        this.setState({
            ...this.state,
            isLoadingState: true
        });

        let actionResult;
        let stateUpdate = this.state;
        if (this.state.isCreateState){
            actionResult = await this.props.processActionResponse(
                organizationsService.createSubscriptionTemplate(
                    {
                        organizationId: this.state.organizationId,
                        name: this.state.templateName.data,
                        totalValue: this.state.templateTotalValue.data,
                        validityInterval: getNodaDurationFromDaysNumber(this.state.templateValidityInterval.data),
                        price: this.state.templatePrice.data ? this.state.templatePrice.data : null
                    },
                    this.props.accountToken), this.state);

            if (!actionResult.isError){
                if (this.state.orr.hasRight('ViewSubscriptionTemplateDetails')){
                    await this.props.router.navigate(`/organizations/subscriptions-templates/${actionResult.data}`, { replace: true });
                    await this.props.router.navigate(0);
                }
                else{
                    await this.props.router.navigate(`/organizations/${this.state.organizationId}/subscriptions-templates`, { replace: true });
                }
            }
            else{
                stateUpdate = {
                    ...actionResult.state,
                    isLoadingState: false
                };
            }
        }
        else if (this.state.isEditState){
            actionResult = await this.props.processActionResponse(
                organizationsService.updateSubscriptionTemplate(
                    {
                        templateId: this.state.templateId,
                        name: this.state.templateName.data,
                        totalValue: this.state.templateTotalValue.data,
                        validityInterval: getNodaDurationFromDaysNumber(this.state.templateValidityInterval.data),
                        price: this.state.templatePrice.data ? this.state.templatePrice.data : null
                    },
                    this.props.accountToken), this.state);
            
            if (!actionResult.isError){
                stateUpdate = {
                    ...this.state,
                    isCreateState: false,
                    isReadState: true,
                    isEditState: false,
                    isLoadingState: false
                };
            }
            else{
                stateUpdate = {
                    ...actionResult.state,
                    isLoadingState: false
                };
            }
        }

        this.setState(stateUpdate);
    }

    handleCancel(event){
        if (this.templateBackup){
            this.setState({
                ...this.state,
                isCreateState: false,
                isReadState: true,
                isEditState: false,
                templateName:{
                    ...this.state.templateName,
                    data: this.templateBackup.templateName
                },
                templateTotalValue:{
                    ...this.state.templateTotalValue,
                    data: this.templateBackup.templateTotalValue
                },
                templateValidityInterval:{
                    ...this.state.templateValidityInterval,
                    data: this.templateBackup.templateValidityInterval
                }
            });
        }
        else{
            this.setState({
                ...this.state,
                isActionFinished: true
            });
        }
    }

    async handleDeleteTemplate(event){
        const actionResult = await this.props.processActionResponse(
            organizationsService.deleteSubscriptionTemplate(this.state.templateId, this.props.accountToken));

        if (!actionResult.isError){
            await this.props.router.navigate(`/organizations/${this.state.organizationId}/subscriptions-templates`, { replace: true });
        }
    }

    hadleSetEditState(event){
        this.templateBackup = {
            templateName: this.state.templateName.data,
            templateTotalValue: this.state.templateTotalValue.data,
            templateValidityInterval: this.state.templateValidityInterval.data
        };

        this.setState({
            ...this.state,
            isCreateState: false,
            isReadState: false,
            isEditState: true
        });
    }

    handleInputChange(event) {
        handleInputChange.call(this, [event.target]);
    }

    async loadData(){
        let stateUpdate = this.state;
        let organizationId = this.state.organizationId;

        if (this.state.templateId){
            const actionResult = await this.props.processActionResponse(
                organizationsService.getSubscriptionTemplate(this.state.templateId, this.props.accountToken));
    
            organizationId = actionResult.data.organizationId;
            if (!actionResult.isError){
                stateUpdate = {
                    ...stateUpdate,
                    organizationId: organizationId,
                    templateName:{
                        ...this.state.templateName,
                        data: actionResult.data.name
                    },
                    templateTotalValue:{
                        ...this.state.templateTotalValue,
                        data: actionResult.data.totalValue
                    },
                    templateValidityInterval:{
                        ...this.state.templateValidityInterval,
                        data: getDaysFormInputDurationFormatString(actionResult.data.validityInterval)
                    },
                    templatePrice:{
                        ...this.state.templatePrice,
                        data: actionResult.data.price ?? ''
                    }
                };
            }
        }

        const orr = await this.props.getOrganizationRights(organizationId);
        stateUpdate = {
            ...stateUpdate,
            orr: orr
        };

        this.setState(stateUpdate);
    }

    render(){
        let showEditTemplateButton = false;
        let showDeleteTemplateButton = false;

        if (this.state.orr){
            showEditTemplateButton = this.state.orr.hasRight('UpdateSubscriptionTemplate');
            showDeleteTemplateButton = this.state.orr.hasRight('DeleteSubscriptionTemplate');
        }

        return (
            <div>
                <OrganizationHeader organizationId={this.state.organizationId} />
                <ElementsBase
                    titleText='Абонемент (шаблон)'>
                    <div className={elementsBaseMenuClass}>
                        <button className="btn btn-outline-danger border-0" type="button" onClick={event => event.preventDefault()} hidden={!this.state.isReadState || !showDeleteTemplateButton} data-bs-toggle="modal" data-bs-target="#approveDeleteModal">
                            <i className="bi bi-trash3-fill"/>
                        </button>
                        <button className="btn btn-primary ms-2" type="button" onClick={this.hadleSetEditState} hidden={!this.state.isReadState || !showEditTemplateButton}>
                            <i className="bi bi-pencil-square"/>
                        </button>
                        <button className="btn btn-outline-success border-0" type="submit" onClick={this.handleSubmit} hidden={this.state.isReadState} disabled={this.state.isLoadingState}>
                            <i className="bi bi-check-square" hidden={this.state.isLoadingState}/>
                            <span className="spinner-border spinner-border-sm" hidden={!this.state.isLoadingState}/>
                        </button>
                        <button className="btn btn-outline-warning border-0 ms-2" type="button" hidden={this.state.isReadState || this.state.isCreateState} onClick={this.handleCancel} disabled={this.state.isLoadingState}><i className="bi bi-x-square"/></button>
                    </div>
                    <ElementsList>
                        <div key='templateName-k' className="form-floating">
                            <input id="templateName" type="text" className={`${validationFieldClass} form-control`} onChange={this.handleInputChange} value={this.state.templateName.data} disabled={this.state.isReadState}/>
                            <label htmlFor="templateName" className='form-label'>Название</label>
                            <div className="invalid-feedback" htmlFor="templateName">{this.state.templateName.error}</div>
                        </div>
                        <div key='templateTotalValue-k' className="form-floating">
                            <input id="templateTotalValue" type="number" className={`${validationFieldClass} form-control`} onChange={this.handleInputChange} value={this.state.templateTotalValue.data} disabled={this.state.isReadState}/>
                            <label htmlFor="templateTotalValue" className='form-label'>Кол-во посещений</label>
                            <div className="invalid-feedback" htmlFor="templateTotalValue">{this.state.templateTotalValue.error}</div>
                        </div>
                        <div key='templateValidityInterval-k' className="form-floating">
                            <input id="templateValidityInterval" type="number" className={`${validationFieldClass} form-control`} onChange={this.handleInputChange} value={this.state.templateValidityInterval.data} disabled={this.state.isReadState}/>
                            <label htmlFor="templateValidityInterval" className='form-label'>Срок годности (дн.)</label>
                            <div className="invalid-feedback" htmlFor="templateValidityInterval">{this.state.templateValidityInterval.error}</div>
                        </div>
                        <div key='templatePrice-k' className="form-floating">
                            <input id="templatePrice" type="number" step='.01' className={`${validationFieldClass} form-control`} onChange={this.handleInputChange} value={this.state.templatePrice.data} disabled={this.state.isReadState}/>
                            <label htmlFor="templatePrice" className='form-label'>Цена</label>
                            <div className="invalid-feedback" htmlFor="templatePrice">{this.state.templatePrice.error}</div>
                        </div>
                    </ElementsList>
                </ElementsBase>
                <Modal 
                    id="approveDeleteModal"
                    modalType='danger'
                    cancelButtonText='Нет'
                    approveButtonText='Да'
                    approveButtonClick={this.handleDeleteTemplate}
                    infoText='Это действие невозможно отменить. Вы уверены что хотите удалить шаблон абонемента?' />
            </div>
        );
    }

    async componentDidMount(){
        await this.loadData();

        if (this.state.isCreateState){
            document.title = "Создание шаблона абонемента"; 
        }
        else{
            document.title = "Детали шаблона абонемента"; 
        }
    }

    async componentDidUpdate(){
        if (this.state.isActionFinished){
            this.setState({
                ...this.state,
                isActionFinished: false
            });
        }
    }
}

const mapStateToProps = (state) => {
    return {
        accountToken: state.account.token
    }
}

export default connectInternal(mapStateToProps)(withRouter(OrganizationSubscriptionTemplateCreateUpdate));