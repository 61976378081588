import ServiceBase from "./ServiceBase";

class EventsService extends ServiceBase {

    constructor(){
        super('events');
    }

    async getEvent(payload, token) {
        const request = {
            token: token,
            target: `${payload}`
        };

        return this.getJson(await this.get(request));
    }

    async copyEvent(payload, token) {
        const request = {
            token: token,
            target: `${payload.eventId}/copy`,
            payload:{
                amount: payload.amount,
                periodDays: payload.periodDays
            }
        };

        return this.getJson(await this.post(request));
    }

    async updateEvent(payload, token) {
        const request = {
            token: token,
            target: `${payload.eventId}`,
            payload: {
                name: payload.name,
                eventDatetime: payload.eventDatetime,
                eventDuration: payload.eventDuration,
                address: payload.address,
                description: payload.description,
                mastersAccountsLinks: payload.mastersAccountsLinks,
                specializationsIds: payload.specializationsIds,
                organizationsGroupsIds: payload.organizationsGroupsIds,
                attendanceFiltersIds: payload.attendanceFiltersIds,
                requiresApplication: payload.requiresApplication,
                requiresApplicationApprove: payload.requiresApplicationApprove,
                applicationLimit: payload.applicationLimit,
                applicationsFiltersIds: payload.applicationsFiltersIds,
                cityId: payload.cityId
            }
        };

        return this.getJson(await this.put(request));
    }

    async linkOrganizationOwnerToEvent(payload, token) {
        const request = {
            token: token,
            target: `${payload.eventId}/owner/${payload.organizationLinkCode}`
        };

        return this.getJson(await this.patch(request));
    }

    async deleteOrganizationOwnerLinkFromEvent(payload, token) {
        const request = {
            token: token,
            target: `${payload.eventId}/owner/${payload.organizationId}`
        };

        return this.getJson(await this.delete(request));
    }

    async deleteEvent(payload, token) {
        const request = {
            token: token,
            target: `${payload}`
        };

        return this.getJson(await this.delete(request));
    }

    async cancellEvent(payload, token) {
        const request = {
            token: token,
            target: `${payload}`
        };

        return this.getJson(await this.patch(request));
    }

    async getTicketsTemplates(payload, token) {
        const request = {
            token: token,
            target: `${payload}/tickets-templates`
        };

        return this.getJson(await this.get(request));
    }

    async getTicketTemplate(payload, token) {
        const request = {
            token: token,
            target: `tickets-templates/${payload}`
        };

        return this.getJson(await this.get(request));
    }

    async deleteTicketTemplate(payload, token) {
        const request = {
            token: token,
            target: `tickets-templates/${payload}`
        };

        return this.getJson(await this.delete(request));
    }

    async createTicketTemplate(payload, token) {
        const request = {
            token: token,
            target: 'tickets-templates',
            payload: {
                name: payload.name,
                eventsIds: payload.eventsIds,
                price: payload.price
            }
        };

        return this.getJson(await this.post(request));
    }

    async updateTicketTemplate(payload, token) {
        const request = {
            token: token,
            target: `tickets-templates/${payload.templateId}`,
            payload: {
                name: payload.name,
                eventsIds: payload.eventsIds,
                price: payload.price
            }
        };

        return this.getJson(await this.put(request));
    }

    async getPickerEvents(payload, token) {
        const request = {
            token: token,
            target: `picker-list`,
            payload:{
                name: payload.name,
                startDate: payload.startDate,
                endDate: payload.endDate,
                pagingFilter: payload.pagingFilter
            }
        };

        return this.getJson(await this.post(request));
    }

    async getEventTickets(payload, token) {
        const request = {
            token: token,
            target: `${payload.eventId}/tickets`,
            payload:{
                isActive: payload.isActive,
                accountName: payload.accountName,
                comment: payload.comment,
                excludeAttended: payload.excludeAttended,
                pagingFilter: payload.pagingFilter
            }
        };

        return this.getJson(await this.post(request));
    }

    async getTicket(payload, token) {
        const request = {
            token: token,
            target: `tickets/${payload}`
        };

        return this.getJson(await this.get(request));
    }

    async getTicketByCode(payload, token) {
        const request = {
            token: token,
            target: `tickets/code/${payload}`
        };

        return this.getJson(await this.get(request));
    }

    async deactivateTicket(payload, token) {
        const request = {
            token: token,
            target: `tickets/${payload}/deactivate`,
        };

        return this.getJson(await this.put(request));
    }

    async createTicket(payload, token) {
        const request = {
            token: token,
            target: `tickets-templates/${payload.templateId}/create-ticket`,
            payload:{
                accountLink: payload.accountLink,
                comment: payload.comment,
                price: payload.price
            }
        };

        return this.getJson(await this.post(request));
    }

    async updateTicket(payload, token) {
        const request = {
            token: token,
            target: `tickets/${payload.ticketId}`,
            payload:{
                comment: payload.comment,
                price: payload.price
            }
        };

        return this.getJson(await this.put(request));
    }

    async getAccountEventAttendanceVariants(payload, token) {
        const request = {
            token: token,
            target: `${payload.eventId}/attendances`,
            payload:{
                accountLink: payload.accountLink
            }
        };

        return this.getJson(await this.post(request));
    }

    async markAttendance(payload, token) {
        const request = {
            token: token,
            target: `${payload.eventId}/mark-attendance`,
            payload:{
                ticketId: payload.ticketId,
                subscriptionId: payload.subscriptionId,
                accountLink: payload.accountLink,
                comment: payload.comment,
                price: payload.price
            }
        };

        return this.getJson(await this.put(request));
    }

    async createEventApplication(payload, token) {
        const request = {
            token: token,
            target: `${payload.eventId}/applications`,
            payload:{
                accountComment: payload.accountComment
            }
        };

        return this.getJson(await this.post(request));
    }

    async cancellEventApplication(payload, token) {
        const request = {
            token: token,
            target: `${payload}/applications/cancell`
        };

        return this.getJson(await this.patch(request));
    }

    async openEventApplication(payload, token) {
        const request = {
            token: token,
            target: `${payload}/applications/open`
        };

        return this.getJson(await this.patch(request));
    }

    async closeEventApplication(payload, token) {
        const request = {
            token: token,
            target: `${payload}/applications/close`
        };

        return this.getJson(await this.patch(request));
    }

    async getEventApplications(payload, token) {
        const request = {
            token: token,
            target: `${payload}/applications`
        };

        return this.getJson(await this.get(request));
    }

    async getEventAttendances(payload, token) {
        const request = {
            token: token,
            target: `${payload}/attendances`
        };

        return this.getJson(await this.get(request));
    }

    async approveEventApplication(payload, token) {
        const request = {
            token: token,
            target: `${payload.eventId}/applications/approve`,
            payload:{
                accountId: payload.accountId
            }
        };

        return this.getJson(await this.patch(request));
    }

    async declineEventApplication(payload, token) {
        const request = {
            token: token,
            target: `${payload.eventId}/applications/decline`,
            payload:{
                accountId: payload.accountId
            }
        };

        return this.getJson(await this.patch(request));
    }

    async getSystemSubscriptionStatus(payload, token) {
        const request = {
            token: token,
            target: `${payload}/system-subscription`
        };

        return this.getJson(await this.get(request));
    }
}

const eventsService = new EventsService();

export default eventsService;