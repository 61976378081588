import React, { Component } from 'react';
import { withParams, connectInternal } from '../../Helpers/SystemHelper';
import organizationsService from '../../Services/OrganizationsService';
import OrganizationHeader from '../OrganizationHeader';
import ElementsBase from '../Elements/ElementsBase';
import ElementsList from '../Elements/ElementsList';
import InfoBlock from '../Elements/InfoBlock';
import ElementBlock from '../Elements/ElementBlock';

class OrganizationSystemSubscriptionDetails extends Component {
    constructor(props){
        super(props);

        this.state = {
            subscriptionStatusData: ''
        };

        this.loadData = this.loadData.bind(this);
    }

    async loadData(){
        const actionResult = await this.props.processActionResponse(
            organizationsService.getSystemSubscriptionStatus(this.props.params.organizationId, this.props.accountToken));

        if (!actionResult.isError){
            this.setState({
                ...this.state,
                subscriptionStatusData: actionResult.data
            });
        }
    }

    renderLimitStatusBar(fact, limit){
        const usagePercentage = +fact * 100 / limit;
        const progressStyle ={
            width: `${usagePercentage}%`
        }
        let progressColor = 'bg-info';
        if (usagePercentage > 60){
            progressColor = 'bg-warning'
        }
        if (usagePercentage >= 100){
            progressColor = 'bg-danger'
        }

        return (
            <div className='progress w-100'>
                <div className={`progress-bar ${progressColor}`} style={progressStyle} role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
        );
    }

    countLeftValue(fact, limit){
        return +limit - fact < 0 ? 0 : +limit - fact;
    }

    render(){
        if (this.state.subscriptionStatusData){
            const factMetrics = this.state.subscriptionStatusData.factMetrics;
            const limitMetrics = this.state.subscriptionStatusData.limitMetrics;

            const rolesLeft = this.countLeftValue(factMetrics.rolesCount, limitMetrics.rolesCount);
            const groupsLeft = this.countLeftValue(factMetrics.groupsCount, limitMetrics.groupsCount);
            const accountsLeft = this.countLeftValue(factMetrics.accountsCount, limitMetrics.accountsCount);
            const subscriptionsTemplatesLeft = this.countLeftValue(factMetrics.subscriptionsTemplatesCount, limitMetrics.subscriptionsTemplatesCount);
            const subscriptionsLeft = this.countLeftValue(factMetrics.subscriptionsCount, limitMetrics.subscriptionsCount);
            const eventsLeft = this.countLeftValue(factMetrics.eventsCount, limitMetrics.eventsCount);
            const reportsLeft = this.countLeftValue(factMetrics.reportsCount, limitMetrics.reportsCount);

            return(
                <div>
                    <OrganizationHeader organizationId={this.props.params.organizationId} />
                    <ElementsBase>
                        <ElementsList>
                            <ElementBlock
                                titleText='Статус ограничений'>
                                <ElementsList>
                                    <InfoBlock
                                        title='Роли'>
                                        <div>
                                            {`Доступно ${rolesLeft} из ${limitMetrics.rolesCount} (Использовано: ${factMetrics.rolesCount})`}
                                        </div>
                                        {this.renderLimitStatusBar(factMetrics.rolesCount, limitMetrics.rolesCount)}
                                    </InfoBlock>
                                    <InfoBlock
                                        title='Группы'>
                                        <div>
                                            {`Доступно ${groupsLeft} из ${limitMetrics.groupsCount} (Использовано: ${factMetrics.groupsCount})`}
                                        </div>
                                        {this.renderLimitStatusBar(factMetrics.groupsCount, limitMetrics.groupsCount)}
                                    </InfoBlock>
                                    <InfoBlock
                                        title='Аккаунты'>
                                        <div>
                                            {`Доступно ${accountsLeft} из ${limitMetrics.accountsCount} (Использовано: ${factMetrics.accountsCount})`}
                                        </div>
                                        {this.renderLimitStatusBar(factMetrics.accountsCount, limitMetrics.accountsCount)}
                                    </InfoBlock>
                                    <InfoBlock
                                        title='Шаблоны абонементов'>
                                        <div>
                                            {`Доступно ${subscriptionsTemplatesLeft} из ${limitMetrics.subscriptionsTemplatesCount} (Использовано: ${factMetrics.subscriptionsTemplatesCount})`}
                                        </div>
                                        {this.renderLimitStatusBar(factMetrics.subscriptionsTemplatesCount, limitMetrics.subscriptionsTemplatesCount)}
                                    </InfoBlock>
                                    <InfoBlock
                                        title='Абонементы'>
                                        <div>
                                            {`Доступно ${subscriptionsLeft} из ${limitMetrics.subscriptionsCount} (Использовано: ${factMetrics.subscriptionsCount})`}
                                        </div>
                                        {this.renderLimitStatusBar(factMetrics.subscriptionsCount, limitMetrics.subscriptionsCount)}
                                    </InfoBlock>
                                    <InfoBlock
                                        title='События'>
                                        <div>
                                            {`Доступно ${eventsLeft} из ${limitMetrics.eventsCount} (Использовано: ${factMetrics.eventsCount})`}
                                        </div>
                                        {this.renderLimitStatusBar(factMetrics.eventsCount, limitMetrics.eventsCount)}
                                    </InfoBlock>
                                    <InfoBlock
                                        title='Отчеты'>
                                        <div>
                                            {`Доступно ${reportsLeft} из ${limitMetrics.reportsCount} (Использовано: ${factMetrics.reportsCount})`}
                                        </div>
                                        {this.renderLimitStatusBar(factMetrics.reportsCount, limitMetrics.reportsCount)}
                                    </InfoBlock>
                                </ElementsList>
                            </ElementBlock>
                        </ElementsList>
                    </ElementsBase> 
                </div>
            );
        }
        else{
            return (<></>);
        }
    }

    async componentDidMount(){
        await this.loadData();

        document.title = "Статус ограничений (организация)"; 
    }
}

const mapStateToProps = (state) => {
    return {
        accountToken: state.account.token
    }
}

export default connectInternal(mapStateToProps)(withParams(OrganizationSystemSubscriptionDetails));