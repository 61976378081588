import React, { Component } from "react";
import { withParams } from '../../Helpers/SystemHelper';
import OrganizationHeader from "../OrganizationHeader";
import EventsList from "../EventsList";
import { connect } from "react-redux";

class OrganizationCalendar extends Component {
    render(){
        return(
            <div>
                <OrganizationHeader organizationId={this.props.params.organizationId} />
                <EventsList organizationId={this.props.params.organizationId}/>
            </div>
        )
    }

    componentDidMount() {
        document.title = "Календарь организации"; 
    }
}

const mapStateToProps = (state) => {
    return {
        accountToken: state.account.token
    }
}

export default connect(mapStateToProps)(withParams(OrganizationCalendar));