import React, { Component } from 'react';
import { areArraysEqual } from '../../../Helpers/FormHelpers';
import organizationsService from '../../../Services/OrganizationsService';
import { connectInternal } from '../../../Helpers/SystemHelper';
import Modal from '../../Elements/Modal';
import OrganizationGroupListElement from '../../Elements/OrganizationGroupListElement';
import ElementsBase from '../../Elements/ElementsBase';
import ElementsList from '../../Elements/ElementsList';

class OrganizationsGroupsPicker extends Component {
    constructor(props){
        super(props);

        this.id = props.id;

        this.state = {
            organizationsGroupsSelectList: '',
            organizationsGroupsSelected: props.value || [],
            isCollapseType: props.isCollapseType || false
        }

        this.loadData = this.loadData.bind(this);
        this.handleListButtonSelect = this.handleListButtonSelect.bind(this);
        this.handleRemoveItem = this.handleRemoveItem.bind(this);
    }

    async loadData(){
        if (this.props.hidden)
            return;

        let orgsIds;
        if (this.props.organizationsIds){
            orgsIds = this.props.organizationsIds;
        }
        else{
            orgsIds = [ this.props.organizationId ]
        }

        const actionResult = await this.props.processActionResponse(
            organizationsService.getOrganizationsGroups(orgsIds, this.props.accountToken));

        if (!actionResult.isError){
            this.setState({
                ...this.state,
                organizationsGroupsSelectList: actionResult.data
            });
        }
    }

    handleListButtonSelect(event){
        event.preventDefault();

        const targetItem = this.state.organizationsGroupsSelectList.find(i => i.id == event.currentTarget.value);

        this.value = [
            ...this.state.organizationsGroupsSelected,
            {
                ...targetItem,
                isEditable: true
            }
        ];

        this.setState({
            ...this.state,
            organizationsGroupsSelected: this.value
        });

        this.props.onChange({target: this});
    }

    handleRemoveItem(event){
        event.preventDefault();

        this.value = [...this.state.organizationsGroupsSelected];
        const targetIndex = this.value.findIndex(v => v.id == event.currentTarget.value);
        if (targetIndex > -1){
            this.value.splice(targetIndex, 1);
        }

        this.setState({
            ...this.state,
            organizationsGroupsSelected: this.value
        });

        this.props.onChange({target: this});
    }

    renderSelectList(){
        if (!this.state.organizationsGroupsSelectList){
            return <></>;
        }

        const elementsArray = [];

        for(let item of this.state.organizationsGroupsSelectList){
            if (this.state.organizationsGroupsSelected.some(i => i.id == item.id)){
                continue;
            };

            const elementToPush = this.state.isCollapseType
                ? <OrganizationGroupListElement 
                    key={`button-group-${item.id}`}
                    value={item}
                    titleButton='Button'
                    buttonIcon='bi bi-check2-square'
                    buttonValue={item.id}
                    buttonClick={this.handleListButtonSelect}
                    buttonDataBsToggle="collapse"
                    buttonDataBsTarget="#groupsSelectCollapse"/>
                : <OrganizationGroupListElement 
                    key={`button-group-${item.id}`}
                    value={item}
                    titleButton='Button'
                    buttonIcon='bi bi-check2-square'
                    buttonValue={item.id}
                    buttonClick={this.handleListButtonSelect}
                    buttonDataBsDismiss="modal"/>;

            elementsArray.push(elementToPush);
        }

        return (
            <ElementsBase>
                <ElementsList includeSeparator>
                    {elementsArray}
                </ElementsList>
            </ElementsBase>
        );
    }

    renderSelectedList(){
        const elementsArray = [];

        if (this.state.organizationsGroupsSelected){
            for(let groupSelected of this.state.organizationsGroupsSelected){
                let groupDisplayName = `${groupSelected.name}`;
                if (groupSelected.organizationName && this.props.showGroupOrganization){
                    groupDisplayName = `${groupDisplayName} (${groupSelected.organizationName})`;
                }

                elementsArray.push(
                    <div key={`selected-group-${groupSelected.id}`} className="btn-group mt-3 me-1" role="group" >
                        <button type="button" value={groupSelected.id} className="btn btn-danger" onClick={this.handleRemoveItem} disabled={!groupSelected.isEditable} hidden={this.props.disabled}>
                            <i className="bi bi-x-square" />
                        </button>
                        <button type='button' className="btn btn-outline-primary" disabled>{`${groupDisplayName}`}</button>
                    </div>
                );
            }
        }
        else {
            elementsArray.push(<div className='mb-2' />);
        }

        return elementsArray;
    }

    render(){
        const hideSelectButton = this.props.isSingleSelect && this.state.organizationsGroupsSelected && this.state.organizationsGroupsSelected.some(i => !!i);

        return (
            <div id={this.props.id} hidden={this.props.hidden}>
                <div className="container d-flex flex-wrap form-floating p-3 bg-light border rounded">
                    <input id="ghostGroupsPickerDiv" className='form-control' hidden/>
                    <label htmlFor="ghostGroupsPickerDiv" className='form-label'>{this.props.label}</label>
                    {this.renderSelectedList()}
                    {this.state.isCollapseType
                        ? <button type="button" className="btn btn-outline-primary mt-3" data-bs-toggle="collapse" data-bs-target="#groupsSelectCollapse" aria-expanded="false" aria-controls="groupsSelectCollapse" disabled={this.props.disabled} hidden={hideSelectButton}>
                            <i className="bi bi-pencil-square"/>
                        </button>
                        : <button type="button" className="btn btn-outline-primary mt-3" data-bs-toggle="modal" data-bs-target="#groupsSelectModal" disabled={this.props.disabled} hidden={hideSelectButton}>
                            <i className="bi bi-pencil-square"/>
                        </button>}
                </div>
                {this.state.isCollapseType
                        ? <div className="collapse" id="groupsSelectCollapse">
                            {this.renderSelectList()}
                        </div>
                        : <Modal 
                            id="groupsSelectModal"
                            cancelButtonText='Закрыть'>
                            {this.renderSelectList()}
                        </Modal>}
            </div>
        );
    }

    async componentDidMount(){
        const wrappingDiv = document.getElementById(this.props.id);
        wrappingDiv.className = this.props.className;

        this.className = wrappingDiv.className;
        this.classList = wrappingDiv.classList;

        await this.loadData();
    }

    static getDerivedStateFromProps(props, state){
        if (!areArraysEqual(props.value || [], state.organizationsGroupsSelected || [])) {
            return ({ 
                ...state,
                organizationsGroupsSelected: [...props.value] 
            })
        }

        return null;
    }
}

const mapStateToProps = (state) => {
    return {
        accountToken: state.account.token
    }
}

export default connectInternal(mapStateToProps)(OrganizationsGroupsPicker);