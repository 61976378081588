import React, { Component } from 'react';
import { connectInternal } from '../../Helpers/SystemHelper';
import { Navigate } from 'react-router-dom';
import { validationFieldClass } from '../../Helpers/Constants';
import { validateEventTicketTemplateName,
    validateEventTicketTemplateEvents,
    validateEventTicketTemplatePrice } from '../../Helpers/Validations';
import { handleInputChange, isFormStateValid } from '../../Helpers/FormHelpers';
import { withRouter } from '../../Helpers/SystemHelper';
import eventsService from '../../Services/EventsService';
import OrganizationsEventsPicker from '../FormComponents/OrganizationsEventsPicker';
import ElementsBase, { elementsBaseMenuClass } from '../Elements/ElementsBase';
import ElementsList from '../Elements/ElementsList';

class EventTicketTemplateCreateUpdate extends Component {
    constructor(props){
        super(props);

        this.state = {
            isCreateState: !this.props.router.params.templateId,
            isEditState: this.props.router.params.templateId,
            isActionFinished: false,
            templateId: this.props.router.params.templateId,
            templateName:{
                validator: validateEventTicketTemplateName,
                data: ''
            },
            templateEvents:{
                validator: validateEventTicketTemplateEvents,
                data: ''
            },
            templatePrice:{
                validator: validateEventTicketTemplatePrice,
                data: ''
            }
        };

        this.loadData = this.loadData.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.isFormStateValid = isFormStateValid.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    async handleCancel(){
        if (this.props.router.params.eventId){
            await this.props.router.navigate(`/events/${this.props.router.params.eventId}/tickets-templates`, { replace: true });

            return;
        }
        
        await this.props.router.navigate(`/events/tickets-templates/${this.props.router.params.templateId}`, { replace: true });
    }

    async handleSubmit(event){
        event.preventDefault();

        if (!this.isFormStateValid()){
            return;
        }

        this.setState({
            ...this.state,
            isLoadingState: true
        });

        let actionResult;
        let stateUpdate;
        if (this.state.isCreateState){
            actionResult = await this.props.processActionResponse(
                eventsService.createTicketTemplate(
                    {
                        name: this.state.templateName.data,
                        eventsIds: this.state.templateEvents.data.map(e => e.id),
                        price: this.state.templatePrice.data ? this.state.templatePrice.data : null
                    },
                    this.props.accountToken), this.state);

            if (!actionResult.isError){
                await this.props.router.navigate(`/events/tickets-templates/${actionResult.data}`, { replace: true });
            }
            else{
                stateUpdate = {
                    ...actionResult.state,
                    isLoadingState: false
                };
            }
        }
        else if (this.state.isEditState){
            actionResult = await this.props.processActionResponse(
                eventsService.updateTicketTemplate(
                    {
                        templateId: this.state.templateId,
                        name: this.state.templateName.data,
                        eventsIds: this.state.templateEvents.data.map(e => e.id),
                        price: this.state.templatePrice.data ? this.state.templatePrice.data : null
                    },
                    this.props.accountToken), this.state);
            
            if (!actionResult.isError){
                await this.props.router.navigate(`/events/tickets-templates/${this.props.router.params.templateId}`, { replace: true });
            }
            else{
                stateUpdate = {
                    ...actionResult.state,
                    isLoadingState: false
                };
            }
        }

        this.setState(stateUpdate);
    }

    handleInputChange(event) {
        handleInputChange.call(this, [event.target]);
    }

    async loadData(){
        if (this.state.templateId){
            const actionResult = await this.props.processActionResponse(
                eventsService.getTicketTemplate(this.state.templateId, this.props.accountToken));

            if (!actionResult.isError){
                this.setState({
                    ...this.state,
                    templateName:{
                        ...this.state.templateName,
                        data: actionResult.data.name
                    },
                    templateEvents:{
                        ...this.state.templateEvents,
                        data: actionResult.data.events
                    },
                    templatePrice:{
                        ...this.state.templatePrice,
                        data: actionResult.data.price ?? ''
                    }
                });
            }
        }
        if (this.props.router.params.eventId){
            const actionResult = await this.props.processActionResponse(
                eventsService.getEvent(this.props.router.params.eventId, this.props.accountToken));

            if (!actionResult.isError){
                this.setState({
                    ...this.state,
                    templateEvents:{
                        ...this.state.templateEvents,
                        data: [ actionResult.data ]
                    },
                    templatePrice:{
                        ...this.state.templatePrice,
                        data: actionResult.data.price ?? ''
                    }
                });
            }
        }
    }

    render(){
        return (
            <ElementsBase
                titleText='Шаблон билета'>
                <div className={elementsBaseMenuClass}>
                    <button className="btn btn-outline-success border-0 me-2" type="button" onClick={this.handleSubmit} disabled={this.state.isLoadingState}>
                        <i className="bi bi-check-square" hidden={this.state.isLoadingState}/>
                        <span className="spinner-border spinner-border-sm" hidden={!this.state.isLoadingState}/>
                    </button>
                    <button className="btn btn-outline-warning border-0" type="button" onClick={this.handleCancel} disabled={this.state.isLoadingState}><i className="bi bi-x-square"/></button>
                </div>
                <ElementsList>
                    <div className="form-floating">
                        <input id="templateName" type="text" className={`${validationFieldClass} form-control`} onChange={this.handleInputChange} value={this.state.templateName.data}/>
                        <label htmlFor="templateName" className='form-label'>Название</label>
                        <div className="invalid-feedback" htmlFor="templateName">{this.state.templateName.error}</div>
                    </div>
                    <div className="mb-3">
                        <OrganizationsEventsPicker id="templateEvents" label='События' className='border rounded' onChange={this.handleInputChange} value={this.state.templateEvents.data} />
                        <div className="invalid-feedback" htmlFor="templateEvents">{this.state.templateEvents.error}</div>
                    </div>
                    <div className="form-floating">
                        <input id="templatePrice" type="number" step='.01' className={`${validationFieldClass} form-control`} onChange={this.handleInputChange} value={this.state.templatePrice.data}/>
                        <label htmlFor="templatePrice" className='form-label'>Цена</label>
                        <div className="invalid-feedback" htmlFor="templatePrice">{this.state.templatePrice.error}</div>
                    </div>
                </ElementsList>
            </ElementsBase>
        );
    }

    async componentDidMount(){
        await this.loadData();

        if (this.state.isEditState){
            document.title = "Изменение шаблона билета";
        }
        else {
            document.title = "Создание шаблона билета";
        }
    }
}

const mapStateToProps = (state) => {
    return {
        accountToken: state.account.token
    }
}

export default connectInternal(mapStateToProps)(withRouter(EventTicketTemplateCreateUpdate));