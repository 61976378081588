import React, { Component } from "react";
import { connectInternal } from "../../Helpers/SystemHelper";
import { Link } from "react-router-dom";
import organizationsService from "../../Services/OrganizationsService";
import { withParams } from '../../Helpers/SystemHelper';
import OrganizationHeader from "../OrganizationHeader";
import OrganizationRoleListElement from "../Elements/OrganizationRoleListElement";
import ElementsBase from "../Elements/ElementsBase";
import ElementsList from "../Elements/ElementsList";

class OrganizationRoles extends Component {
    constructor(props){
        super(props);

        this.state = {
            rolesData: []
        };

        this.loadData = this.loadData.bind(this);
    }

    async loadData(){
        const actionResult = await this.props.processActionResponse(
            organizationsService.getOrganizationRoles(this.props.params.organizationId, this.props.accountToken));

        let stateUpdate = this.state;
        if (!actionResult.isError){
            const orr = await this.props.getOrganizationRights(this.props.params.organizationId);

            stateUpdate = {
                ...stateUpdate,
                orr: orr,
                rolesData: actionResult.data
            };
        }

        this.setState(stateUpdate);
    }

    renderList(){
        if (!this.state.rolesData.some(r => !!r)){
            return <>Роли отсутствуют</>;
        }

        let showRoleInfoButton = false;

        if (this.state.orr){
            showRoleInfoButton = this.state.orr.hasRight('ViewOrganizationRole');
        }

        return this.state.rolesData.map(r => (
            <OrganizationRoleListElement 
                    key={`role-k-${r.id}`}
                    value={r}
                    titleButton='Link'
                    linkTo={`/organizations/roles/${r.id}`}
                    buttonIcon="bi bi-info-square"
                    buttonHidden={!showRoleInfoButton} />
                )
            );
    }

    render(){
        if (this.state.rolesData){
            let showCreateRoleButton = false;

            if (this.state.orr){
                showCreateRoleButton = this.state.orr.hasRight('CreateOrganizationRole');
            }

            return(
                <div>
                    <OrganizationHeader organizationId={this.props.params.organizationId}/>
                    <ElementsBase
                        titleButton='Link'
                        linkTo={`/organizations/${this.props.params.organizationId}/roles/create`}
                        buttonIcon='bi bi-plus-circle'
                        buttonHidden={!showCreateRoleButton}
                        titleText='Роли'>
                        <ElementsList includeSeparator>
                            {this.renderList()}
                        </ElementsList>
                    </ElementsBase>
                </div>
            )
        }
        else{ return(<div/>) }
    }

    async componentDidMount(){
        this.loadData();

        document.title = "Роли"; 
    }
}

const mapStateToProps = (state) => {
    return {
        accountToken: state.account.token
    }
}

export default connectInternal(mapStateToProps)(withParams(OrganizationRoles));