const validateAccountLoginEmail = (data) => {
    if (!data){
        return 'Введите электронную почту'
    }

    return '';
}

const validateAccountLoginPassword = (data) => {
    if (!data){
        return 'Введите пароль'
    }

    return '';
}

const validateAccountEmail = (data) => {
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

    if (!data){
        return 'Введите электронную почту'
    }
    if (!data.match(emailRegex)){
        return 'Введите корректную электронную почту'
    }
    if (data.length > 256){
        return 'Длина электронной почты не должна превышать 256 символов'
    }

    return '';
}

const validateAccountPassword = (data) => {
    const passRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/g;

    if (!data){
        return 'Введите пароль'
    }
    if (!data.match(passRegex)){
        return 'Пароль должен состоять не менее чем из 8ми символов. В наборе должны присутствовать, как минимум, одна буква и одна цифра.'
    }
    if (data.length > 100){
        return 'Длина пароля не должна превышать 100 символов'
    }

    return '';
}

const validateAccountPasswordVerification = (data, data2) => {
    if (data !== data2){
        return 'Подтверждение пароля не совпадает'
    }

    return '';
}

const validateAccountName = (data) => {
    if (!data){
        return 'Введите имя'
    }
    if (data.length < 3){
        return 'Длина имени не должна быть меньше 3 символов'
    }
    if (data.length > 100){
        return 'Длина имени не должна превышать 100 символов'
    }

    return '';
}

const validateAccountTelegramChatId = (data) => {
    if (!data){
        return ''
    }
    if (data.length < 3){
        return 'Длина кода не должна быть меньше 3 символов'
    }
    if (data.length > 100){
        return 'Длина кода не должна превышать 100 символов'
    }

    return '';
}

const validateCaptchaToken = (data) => {
    if (!data){
        return 'Пройдите проверку :)'
    }

    return '';
}

const validateTermsOfUseState = (data) => {
    if (!data){
        return 'Для продолжения необходимо ознакомиться и подтвердить свое согласие с условиями пользовательского соглашения.'
    }

    return '';
}

const validateOrganizationName = (data) => {
    if (!data){
        return 'Введите имя'
    }

    return '';
}

const validateOrganizationOwnersAccounts = (data) => {
    if (!data || data.length === 0){
        return 'Выберите владельцев'
    }

    return '';
}

const validateOrganizationCity = (data) => {
    if (!data || data.length === 0){
        return 'Выберите город'
    }

    return '';
}

const validateSystemSubscriptionId = (data) => {
    if (!data){
        return 'Введите имя'
    }

    return '';
}

const validateSubscriptionExpirationDate = (data) => {
    if (!data){
        return '';
    }

    var date = new Date(data);

    if (date < new Date()){
        return 'Дата окончания подписки не может быть меньше текущей';
    }

    date.setDate(date.getDate() - 365);
    if (date > new Date()){
        return 'Дата окончания подписки должна быть в пределах 365 дней';
    }

    return '';
}

const validateOrganizationRoleName = (data) => {
    if (!data){
        return 'Введите имя'
    }
    if (data.length < 3){
        return 'Длина имени не должна быть меньше 3 символов'
    }
    if (data.length > 100){
        return 'Длина имени не должна превышать 100 символов'
    }

    return '';
}

const validateOrganizationRoleRights = (data) => {
    if (!data || data.length === 0){
        return 'Выберите права роли'
    }

    return '';
}

const validateGroupName = (data) => {
    if (!data){
        return 'Введите имя'
    }
    if (data.length < 3){
        return 'Длина имени не должна быть меньше 3 символов'
    }
    if (data.length > 100){
        return 'Длина имени не должна превышать 100 символов'
    }

    return '';
}

const validateEventName = (data) => {
    if (!data){
        return 'Введите имя'
    }
    if (data.length < 3){
        return 'Длина имени не должна быть меньше 3 символов'
    }
    if (data.length > 100){
        return 'Длина имени не должна превышать 100 символов'
    }

    return '';
}

const validateEventDatetime = (data) => {
    var date = new Date(data);

    if (date < new Date()){
        return 'Время начала не может быть меньше текущего';
    }

    date.setDate(date.getDate() - 365);
    if (date > new Date()){
        return 'Время начала должно быть в пределах 365 дней';
    }

    return '';
}

const validateEventDuration = (data) => {
    if (data == '00:00'){
        return 'Введите корректное значение';
    }

    return '';
}

const validateEventAddress = (data) => {
    if (!data){
        return ''
    }
    if (data.length < 3){
        return 'Длина адреса не должна быть меньше 3 символов'
    }
    if (data.length > 400){
        return 'Длина адреса не должна превышать 400 символов'
    }

    return '';
}

const validateEvenDescription = (data) => {
    if (!data){
        return ''
    }
    if (data.length > 2000){
        return 'Длина описания не должна превышать 2000 символов'
    }

    return '';
}

const validateEventAttendanceFilters = (data, data2) => {
    if (!data || !data.some(i => !!i)){
        return 'Выберите параметры посещения';
    }

    if (data2 && data.some(f => f.id === 2)){
        return 'Возможность посещения по билетам несовместима с событиями требующими записи'
    }

    return '';
}

const validateEventRequiresAttendance = (data, data2) => {
    if (data && data2.some(f => f.id === 2)){
        return 'Запись несовместима с событиями предоставляющими возможность посещения по билетам'
    }

    return '';
}

const validateEventApplicationLimit = (data) => {
    if (data == ''){
        return ''
    }
    if (data <= 0){
        return 'Введите корректное значение'
    }
    if (data > 999){
        return 'Количество мест не может превышать 999'
    }

    return '';
}

const validateEventCity = (data) => {
    if (!data || data.length === 0){
        return 'Выберите город'
    }

    return '';
}

const validateOrganizationSubscriptionTemplateName = (data) => {
    if (!data){
        return 'Введите имя'
    }
    if (data.length < 3){
        return 'Длина имени не должна быть меньше 3 символов'
    }
    if (data.length > 100){
        return 'Длина имени не должна превышать 100 символов'
    }

    return '';
}

const validateOrganizationSubscriptionTemplateTotalValue = (data) => {
    if (!data){
        return 'Введите значение'
    }
    if (data < 1){
        return 'Введите корректное значение'
    }
    if (data > 1000){
        return 'Значение не может превышать 1000'
    }

    return '';
}

const validateOrganizationSubscriptionTemplateValidityInterval = (data) => {
    if (!data){
        return 'Введите значение'
    }
    if (data < 1){
        return 'Введите корректное значение'
    }
    if (data > 365){
        return 'Значение не может превышать 365 дней'
    }

    return '';
}
const validateOrganizationSubscriptionTemplatePrice = (data) => {
    if (!data){
        return ''
    }
    if (data <= 0){
        return 'Введите корректное значение'
    }
    if (data > 999999.99){
        return 'Значение не может превышать 999999.99'
    }

    return '';
}

const validateOrganizationSubscriptionName = (data) => {
    return validateOrganizationSubscriptionTemplateName(data);
}

const validateOrganizationSubscriptionTotalValue = (data) => {
    return validateOrganizationSubscriptionTemplateTotalValue(data);
}

const validateOrganizationSubscriptionStartDate = (data) => {
    var date = new Date(data);
    date.setDate(date.getDate() - 90);
    if (date > new Date()){
        return 'Время начала действия должно быть в пределах 90 дней.';
    }

    return '';
}

const validateOrganizationSubscriptionValidityInterval = (data) => {
    return validateOrganizationSubscriptionTemplateValidityInterval(data);
}

const validateOrganizationSubscriptionPrice = (data) => {
    return validateOrganizationSubscriptionTemplatePrice(data);
}

const validateEventTicketTemplateName = (data) => {
    if (!data){
        return 'Введите имя'
    }
    if (data.length < 3){
        return 'Длина имени не должна быть меньше 3 символов'
    }
    if (data.length > 100){
        return 'Длина имени не должна превышать 100 символов'
    }

    return '';
}

const validateEventTicketTemplateEvents = (data) => {
    if (!data || !data.some(x => !!x)){
        return 'Выберите события'
    }

    return '';
}

const validateEventTicketTemplatePrice = (data) => {
    if (!data){
        return ''
    }
    if (data <= 0){
        return 'Введите корректное значение'
    }
    if (data > 999999.99){
        return 'Значение не может превышать 999999.99'
    }

    return '';
}

const validateEventTicketComment = (data) => {
    if (!data){
        return ''
    }
    if (data.length > 500){
        return 'Длина комментария не должна превышать 500 символов'
    }

    return '';
}

const validateEventTicketPrice = (data) => {
    if (!data){
        return ''
    }
    if (data <= 0){
        return 'Введите корректное значение'
    }
    if (data > 999999.99){
        return 'Цена не может превышать 999999.99'
    }

    return '';
}

const validateEventCustomAttendanceComment = (data, data2) => {
    if (!data2 && !data){
        return 'Введите комментарий (обязателен для посещения без привязки к аккаунту)'
    }

    return '';
}

const validateEventCustomAttendancePrice = (data) => {
    if (!data){
        return ''
    }
    if (data <= 0){
        return 'Введите корректное значение'
    }
    if (data > 999999.99){
        return 'Цена не может превышать 999999.99'
    }

    return '';
}

const validateEventCopyAmount = (data, data2) => {
    if (!data || data <= 0){
        return 'Введите корректное значение'
    }
    if (+data * data2 > 365){
        return 'Общий период копирования не может превышать 365 дней'
    }

    return '';
}

const validateEventCopyPeriod = (data, data2) => {
    if (!data || data <= 0){
        return 'Введите корректное значение'
    }
    if (+data * data2 > 365){
        return 'Общий период копирования не может превышать 365 дней'
    }

    return '';
}

const validateOrganizationDescription = (data) => {
    if (!data){
        return ''
    }
    if (data.length > 2000){
        return 'Длина описания не должна превышать 2000 символов'
    }

    return '';
}

const validateOrganizationVkLink = (data) => {
    if (!data){
        return ''
    }
    if (data.length > 2083){
        return 'Длина ссылки не должна превышать 2083 символа'
    }

    return '';
}

const validateOrganizationPhone = (data) => {
    if (!data){
        return ''
    }
    if (data.length > 100){
        return 'Длина номера не должна превышать 100 символов'
    }

    return '';
}

export {
    validateAccountLoginEmail,
    validateAccountLoginPassword,
    validateAccountEmail,
    validateAccountPassword,
    validateAccountName,
    validateAccountTelegramChatId,
    validateAccountPasswordVerification,
    validateCaptchaToken,
    validateTermsOfUseState,
    validateOrganizationOwnersAccounts,
    validateOrganizationName,
    validateOrganizationRoleName,
    validateOrganizationRoleRights,
    validateGroupName,
    validateEventName,
    validateEventDatetime,
    validateEventDuration,
    validateEventAddress,
    validateEvenDescription,
    validateEventAttendanceFilters,
    validateEventRequiresAttendance,
    validateEventApplicationLimit,
    validateOrganizationSubscriptionTemplateName,
    validateOrganizationSubscriptionTemplateTotalValue,
    validateOrganizationSubscriptionTemplateValidityInterval,
    validateOrganizationSubscriptionTemplatePrice,
    validateOrganizationSubscriptionName,
    validateOrganizationSubscriptionTotalValue,
    validateOrganizationSubscriptionStartDate,
    validateOrganizationSubscriptionValidityInterval,
    validateOrganizationSubscriptionPrice,
    validateEventTicketTemplateName,
    validateEventTicketTemplateEvents,
    validateEventTicketTemplatePrice,
    validateEventCustomAttendanceComment,
    validateEventTicketComment,
    validateEventTicketPrice,
    validateEventCustomAttendancePrice,
    validateOrganizationCity,
    validateSystemSubscriptionId,
    validateSubscriptionExpirationDate,
    validateEventCity,
    validateEventCopyAmount,
    validateEventCopyPeriod,
    validateOrganizationDescription,
    validateOrganizationVkLink,
    validateOrganizationPhone};