import React, { Component } from 'react';
import { areArraysEqual } from '../../../Helpers/FormHelpers';
import systemService from '../../../Services/SystemService';
import { connectInternal } from '../../../Helpers/SystemHelper';
import Modal from '../../Elements/Modal';
import ElementsBase from '../../Elements/ElementsBase';
import ElementsList from '../../Elements/ElementsList';
import ElementBlock from '../../Elements/ElementBlock';

class CitiesPicker extends Component {
    constructor(props){
        super(props);

        this.id = props.id;

        this.state = {
            isSingleSelect: this.props.singleSelect,
            citiesSelectList: '',
            citiesSelected: props.value || [],
        }

        this.loadData = this.loadData.bind(this);
        this.handleListButtonSelect = this.handleListButtonSelect.bind(this);
        this.handleRemoveItem = this.handleRemoveItem.bind(this);
    }

    async loadData(){
        const actionResult = await this.props.processActionResponse(
            systemService.getCities(this.props.accountToken));

        if (!actionResult.isError){
            this.setState({
                ...this.state,
                citiesSelectList: actionResult.data
            });
        }
    }

    handleListButtonSelect(event){
        event.preventDefault();

        const targetItem = this.state.citiesSelectList.find(i => i.id == event.currentTarget.value);

        this.value = [
            ...this.state.citiesSelected,
            {
                ...targetItem
            }
        ];

        this.setState({
            ...this.state,
            citiesSelected: this.value
        });

        this.props.onChange({target: this});
    }

    handleRemoveItem(event){
        event.preventDefault();

        this.value = [...this.state.citiesSelected];
        const targetIndex = this.value.findIndex(v => v.id == event.currentTarget.value);
        if (targetIndex > -1){
            this.value.splice(targetIndex, 1);
        }

        this.setState({
            ...this.state,
            citiesSelected: this.value
        });

        this.props.onChange({target: this});
    }

    renderSelectList(){
        if (!this.state.citiesSelectList){
            return <></>;
        }

        const elementsArray = [];

        for(let item of this.state.citiesSelectList){
            if (this.state.citiesSelected.some(i => i.id == item.id)){
                continue;
            };

            elementsArray.push(
                <ElementBlock 
                    key={`button-group-${item.id}`}
                    value={item}
                    titleButton='Button'
                    buttonIcon='bi bi-check2-square'
                    buttonValue={item.id}
                    buttonClick={this.handleListButtonSelect}
                    buttonDataBsDismiss="modal"
                    titleText={item.name} />
            );
        }

        return (
            <ElementsBase>
                <ElementsList includeSeparator>
                    {elementsArray}
                </ElementsList>
            </ElementsBase>
        );
    }

    renderSelectedList(){
        const elementsArray = [];

        if (this.state.citiesSelected){
            for(let citySelected of this.state.citiesSelected){
                elementsArray.push(
                    <div key={`selected-city-${citySelected.id}`} className="btn-group mt-3 me-1" role="group" >
                        <button type="button" value={citySelected.id} className="btn btn-danger" onClick={this.handleRemoveItem} hidden={this.props.disabled}>
                            <i className="bi bi-x-square" />
                        </button>
                        <button type='button' className="btn btn-outline-primary" disabled>{citySelected.name}</button>
                    </div>
                );
            }
        }
        else {
            elementsArray.push(<div className='mb-2' />);
        }

        return elementsArray;
    }

    render(){
        const renderEditButton = !this.state.citiesSelected.some(c => !!c) || !this.state.isSingleSelect;

        return (
            <div id={this.props.id}>
                <div className="container d-flex flex-wrap form-floating p-3 bg-light border rounded">
                    <input id="ghostGroupsPickerDiv" className='form-control' hidden/>
                    <label htmlFor="ghostGroupsPickerDiv" className='form-label'>{this.props.label}</label>
                    {this.renderSelectedList()}
                    <button type="button" className="btn btn-outline-primary mt-3" data-bs-toggle="modal" data-bs-target="#citiesSelectModal" hidden={!renderEditButton} disabled={this.props.disabled}>
                        <i className="bi bi-pencil-square"/>
                    </button>
                    <Modal 
                        id="citiesSelectModal">
                        {this.renderSelectList()}
                    </Modal>
                </div>
            </div>
        );
    }

    async componentDidMount(){
        const wrappingDiv = document.getElementById(this.props.id);
        wrappingDiv.className = this.props.className;

        this.className = wrappingDiv.className;
        this.classList = wrappingDiv.classList;

        await this.loadData();
    }

    static getDerivedStateFromProps(props, state){
        if (!areArraysEqual(props.value || [], state.citiesSelected || [])) {
            return ({ 
                ...state,
                citiesSelected: [...props.value] 
            })
        }

        return null;
    }
}

const mapStateToProps = (state) => {
    return {
        accountToken: state.account.token
    }
}

export default connectInternal(mapStateToProps)(CitiesPicker);