import React, { Component } from "react";
import eventsService from "../../Services/EventsService";
import { withParams, connectInternal } from '../../Helpers/SystemHelper';
import { Link } from "react-router-dom";
import ElementsBase from "../Elements/ElementsBase";
import ElementsList from "../Elements/ElementsList";
import ElementBlock, { elementBlockMenuClass } from "../Elements/ElementBlock";
import Modal from '../Elements/Modal';

class EventTicketsTemplates extends Component {
    templateIdToDelete = '';

    constructor(props){
        super(props);

        this.state = {
            ticketsTemplatesCollection: []
        };

        this.loadData = this.loadData.bind(this);
        this.handleDeleteSetClick = this.handleDeleteSetClick.bind(this);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
    }

    async handleDeleteClick(){
        const actionResult = await this.props.processActionResponse( 
            eventsService.deleteTicketTemplate(
                this.state.templateIdToDelete,
                this.props.accountToken
            ));
        
        if (!actionResult.isError){
            this.loadData();
        }
    }

    handleDeleteSetClick(event){
        this.setState({
            ...this.state,
            templateIdToDelete: event.currentTarget.value
        });
    }

    async loadData(){
        let stateUpdate = this.state;

        const actionResult = await this.props.processActionResponse(
            eventsService.getTicketsTemplates(
                this.props.params.eventId,
                this.props.accountToken
            ));

        if (!stateUpdate.orr){
            const eventResult = await this.props.processActionResponse(
                eventsService.getEvent(this.props.params.eventId, this.props.accountToken));

            const orr = await this.props.getOrganizationsRights(eventResult.data.ownersOrganizations.map(o => o.id));

            stateUpdate = {
                ...stateUpdate,
                orr: orr
            }
        }

        if (!actionResult.isError){
            
            this.setState({
                ...stateUpdate,
                ticketsTemplatesCollection: actionResult.data
            });
        }
    }

    renderList(){
        if (!this.state.ticketsTemplatesCollection.some(t => !!t)){
            return <>Шаблоны билетов отсутствуют</>;
        }

        let showEditButton = false;
        let showGiveButton = false;
        let showDeleteButton = false;

        if (this.state.orr){
            showEditButton = this.state.orr.hasRight('UpdateTicketTemplate');
            showGiveButton = this.state.orr.hasRight('CreateTicket');
            showDeleteButton = this.state.orr.hasRight('DeleteTicketTemplate');
        }

        const detailsButtonOnly = !showEditButton && !showGiveButton;

        if (detailsButtonOnly){
            return this.state.ticketsTemplatesCollection.map(t =>{
                return (
                    <ElementBlock 
                        key={`ticket-templates-list-${t.id}`}
                        titleText={t.name}
                        value={t}
                        titleButton='Link'
                        linkTo={`/events/tickets-templates/${t.id}`}
                        buttonIcon="bi bi-info-square" />
                );
            });
        }

        return this.state.ticketsTemplatesCollection.map(t =>{
            return (
                <ElementBlock 
                    key={`ticket-templates-list-${t.id}`}
                    titleText={t.name}
                    value={t}
                    titleButton='Dropdown'
                    buttonIcon="bi bi-gear" >
                    <div className={elementBlockMenuClass}>
                        <Link className="btn btn-primary" to={`/events/tickets-templates/${t.id}`}>
                            <i className="bi bi-info-square"/> Детали
                        </Link>
                        <Link className="btn btn-primary" type="button" to={`/events/tickets-templates/${t.id}/update`} hidden={!showEditButton}>
                            <i className="bi bi-pencil-square"/> Изменить
                        </Link>
                        <Link to={`/events/tickets-templates/${t.id}/create-ticket`} className="btn btn-primary" aria-current="true" hidden={!showGiveButton}>
                            <i className="bi bi-arrow-bar-up"/> Выдать
                        </Link>
                        <button className="btn btn-danger" aria-current="true" value={t.id} onClick={this.handleDeleteSetClick} hidden={!showDeleteButton} data-bs-toggle="modal" data-bs-target="#approveDeleteModal">
                            <i className="bi bi-trash3-fill"/> Удалить
                        </button>
                    </div>
                </ElementBlock>
            );
        });
    }

    render(){
        let showCreateButton = false;

        if (this.state.orr){
            showCreateButton = this.state.orr.hasRight('CreateTicketTemplate');
        }

        return(
            <div>
                <ElementsBase
                    titleButton='Link'
                    linkTo={`/events/${this.props.params.eventId}/tickets-templates/create`}
                    buttonIcon='bi bi-plus-circle'
                    titleText='Билеты'
                    buttonHidden={!showCreateButton}>
                    <ElementsList includeSeparator>
                        {this.renderList()}
                    </ElementsList>
                </ElementsBase>
                <Modal 
                    id="approveDeleteModal"
                    modalType='danger'
                    cancelButtonText='Нет'
                    approveButtonText='Да'
                    approveButtonClick={this.handleDeleteClick}
                    infoText='Это действие невозможно отменить. Вы уверены что хотите удалить шаблон билета?' />
            </div>
        )
    }
    

    async componentDidMount(){
        await this.loadData();

        document.title = "Шаблоны билетов"; 
    }
}

const mapStateToProps = (state) => {
    return {
        accountToken: state.account.token
    }
}

export default connectInternal(mapStateToProps)(withParams(EventTicketsTemplates));