import React, { Component } from "react";
import organizationsService from "../../Services/OrganizationsService";
import { withParams, connectInternal } from '../../Helpers/SystemHelper';
import OrganizationHeader from "../OrganizationHeader";
import ElementsBase from "../Elements/ElementsBase";
import ElementBlock from '../Elements/ElementBlock';
import ElementsList from "../Elements/ElementsList";
import Modal from "../Elements/Modal";

class OrganizationReports extends Component {
    pageEntriesCount = 10;

    constructor(props){
        super(props);

        this.state = {
            reportsData: ''
        };

        this.loadData = this.loadData.bind(this);
        this.handleDownloadClick = this.handleDownloadClick.bind(this);
        this.handleCreateReport = this.handleCreateReport.bind(this);
    }

    async handleCreateReport(){
        const actionResult = await this.props.processActionResponse(
            organizationsService.createReport(this.props.params.organizationId, this.props.accountToken));

        if (!actionResult.isError){
            await this.loadData();
        }
    }

    async handleDownloadClick(event){
        const actionResponse = await this.props.processActionResponse(
            organizationsService.getOrganizationReport(
                event.currentTarget.value,
                this.props.accountToken
            ));
        
            if (!actionResponse.isError){
                const docRef = document.createElement("a");
                document.body.appendChild(docRef);
                docRef.style = "display: none";
                docRef.href = actionResponse.data;
                docRef.click();
            }
    }

    async loadData(){
        const actionResponse = await this.props.processActionResponse(
            organizationsService.getOrganizationReports(
                this.props.params.organizationId,
                this.props.accountToken
            ));

        if (!actionResponse.isError){
            this.setState({
                ...this.state,
                reportsData: actionResponse.data
            });
        }
    }

    renderList(){
        if (!this.state.reportsData){
            return <>Отчеты отсутствуют</>;
        }

        return (
            <ElementsList includeSeparator>
                {this.state.reportsData.map(r =>{
                    return (
                        <ElementBlock
                            key={`rep-el-${r.id}`}
                            titleText={r.filename}
                            titleButton='Button'
                            buttonIcon='bi bi-box-arrow-down'
                            buttonValue={r.id}
                            buttonClick={this.handleDownloadClick} />
                    );
                })}
            </ElementsList>
        ); 
    }

    render(){
        return(
            <div>
                <OrganizationHeader organizationId={this.props.params.organizationId} />

                <ElementsBase
                    titleText='Отчеты'
                    titleButton='Button'
                    buttonDataBsToggle='modal'
                    buttonDataBsTarget='#approveCreateReportModal'
                    buttonIcon='bi bi-plus-circle'>
                    {this.renderList()}
                </ElementsBase>
                <Modal 
                    id="approveCreateReportModal"
                    modalType='positive'
                    cancelButtonText='Нет'
                    approveButtonText='Да'
                    approveButtonClick={this.handleCreateReport}
                    infoText='Будет создан отчет. Данное действие имеет ограничение по количеству вызовов в месяц. Вы уверены?' />
            </div>
        )
    }

    async componentDidMount(){
        await this.loadData();

        document.title = "Отчеты организации"; 
    }
}

const mapStateToProps = (state) => {
    return {
        accountToken: state.account.token
    }
}

export default connectInternal(mapStateToProps)(withParams(OrganizationReports));