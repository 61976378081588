import ServiceBase from "./ServiceBase";

class SystemService extends ServiceBase {

    constructor(){
        super('system');
    }

    async getSpecializations(token) {
        const request = {
            token: token,
            target: 'specializations'
        };

        return this.getJson(await this.get(request));
    }

    async getCities(token) {
        const request = {
            token: token,
            target: 'cities'
        };

        return this.getJson(await this.get(request));
    }
}

const systemService = new SystemService();

export default systemService;