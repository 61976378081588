import { invalidFormControllClassName, validationFieldClass } from "./Constants";
import {getErrorData} from "./ServerValidationCodes";

const setFormElementValidationState = (element, isValid) => {
    if (isValid){
        element.classList.remove(invalidFormControllClassName);
        element.classList.remove('border-danger');
    }
    else{
        element.classList.add(invalidFormControllClassName);
        element.classList.add('border-danger');
    }
}

const handleInputChange = function (targets, isFinalValidation) {
    let stateUpdate = this.state;
    if (this.state.isValidatedByServer){
        stateUpdate = resetServerValidation(stateUpdate);
    }

    let validationResult = true;
    for (let target of targets){
        let error;

        let tempValue
        if (target.type == 'checkbox'){
            tempValue = target.checked;
        }
        else{
            tempValue = target.value;
        }

        const targetValue = isFinalValidation ? this.state[target.id].data : tempValue;
        
        if (this.state[target.id].validator){
            if (this.state[target.id].parameterElement){
                var target2Id = this.state[target.id].parameterElement;
        
                error = this.state[target.id].validator(targetValue, this.state[target2Id].data);
            }
            else if (this.state[target.id].parameterValue){        
                error = this.state[target.id].validator(targetValue, this.state[target.id].parameterValue);
            }
            else{
                error = this.state[target.id].validator(targetValue);
            }
        }

        validationResult = validationResult && !error;

        setFormElementValidationState(target, !error);

        stateUpdate = {
            ...stateUpdate,
            [target.id]:{
                ...stateUpdate[target.id],
                data: targetValue,
                error: error
            }
        };
    }

    this.setState(stateUpdate);

    return validationResult;
}

const handleServerError = (errorCode, state) => {
    const errorData = getErrorData(errorCode);

    let stateUpdate = state;
    for (let fieldName of errorData.fieldsNames){
        setFormElementValidationState(document.getElementById(fieldName), false);

        stateUpdate = {
            ...stateUpdate,
            isValidatedByServer: true,
            [fieldName]:{
                ...stateUpdate[fieldName],
                error: errorData.message
            }
        }
    }

    return stateUpdate;
}

const resetServerValidation = (stateUpdate) => {
    const invalidElements = document.getElementsByClassName(invalidFormControllClassName);

    let invalidElementsCount = invalidElements.length;
    for (let i=0; i < invalidElementsCount; i++){
        let invalidElement = invalidElements[0];

        invalidElement.classList.remove(invalidFormControllClassName);

        stateUpdate = {
            ...stateUpdate,
            [invalidElement.id]:{
                ...stateUpdate[invalidElement.id],
                error: ''
            }
        }
    }

    stateUpdate = {
        ...stateUpdate,
        isValidatedByServer: false
    }

    return stateUpdate;
}

const isFormStateValid = function (validationClass = null) {
    const validationFields = Array.prototype.slice.call(document.getElementsByClassName(validationClass ?? validationFieldClass));

    return handleInputChange.call(this, validationFields, true);
}

const areArraysEqual = (arr1, arr2, comparerFunc) => {
    if (!comparerFunc){
        comparerFunc = (item1, item2) => {
            return item1 == item2;
        };
    }

    const arr2copy = [...arr2];

    for (let arr1element in arr1){
        if (!arr2copy.some(x => !!x)){
            return false;
        }

        const index = arr2copy.findIndex(el => comparerFunc(el, arr1[arr1element]));
        if (index > -1) {
            arr2copy.splice(index, 1);
        }
        else{
            return false;
        }
    }

    return !arr2copy.some(x => !!x);
}

const getDateFormatString = (date) => {
    return `${pad(date.getDate(), 2)}-${pad(date.getMonth()+1, 2)}-${date.getFullYear()}`;
}

const getTimeFormatString = (date) => {
    return `${pad(date.getHours(), 2)}:${pad(date.getMinutes(), 2)}`;
}

const getDateTimeFormatString = (date) => {
    return `${getDateFormatString(date)} ${getTimeFormatString(date)}`;
}

const getDurationDateTimeFormatString = (dateTimeStart, dateTimeEnd, duration = null) => {
    const dateStartString = getDateFormatString(dateTimeStart);
    const timeStartString = getTimeFormatString(dateTimeStart);
    const dateEndString = getDateFormatString(dateTimeEnd);
    const timeEndString = getTimeFormatString(dateTimeEnd);

    let result = `${dateStartString} ${timeStartString} - `;

    if (dateStartString != dateEndString){
        result += `${dateEndString} `;
    }

    result += `${timeEndString}`;

    if (duration){
        result += ` (${getDurationDisplayString(duration)})`;
    }

    return result;
}

const getFormInputDateFormatString = (date) => {
    if (!date){
        return '';
    }

    let dateInternal;
    if (date instanceof Date) {
        dateInternal = date;
    }
    else{
        dateInternal = new Date(date);
    }
    
    return `${dateInternal.getFullYear()}-${pad(dateInternal.getMonth()+1, 2)}-${pad(dateInternal.getDate(), 2)}`;
}

const getFormInputDatetimeFormatString = (date) => {
    if (!date){
        return '';
    }

    let dateInternal;
    if (date instanceof Date) {
        dateInternal = date;
    }
    else{
        dateInternal = new Date(date);
    }
    
    return `${getFormInputDateFormatString(date)}T${pad(date.getHours(), 2)}:${pad(date.getMinutes(), 2)}`;
}

const getLocalDateTimeOffset = () => {
    return (new Date()).toString().match(/\+\d{2}/)[0];
}

const getNodaDateTimeFormatString = (date) => {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    const offset = getLocalDateTimeOffset();

    return `${getFormInputDateFormatString(date)}T${pad(date.getHours(), 2)}:${pad(date.getMinutes(), 2)}:${pad(date.getSeconds(), 2)}${offset} ${timeZone}`;
}

const getNodaDurationFromString = (intervalString) => {
    return `${intervalString}:00`;
}

const getNodaDurationFromDaysNumber = (daysNumber) => {
    return `${daysNumber*24}:00:00`;
}

const getFormInputDurationFormatString = (intervalString) => {
    const [ hours, minutes, seconds ] = intervalString.split(':');

    return `${pad(+hours, 2)}:${pad(+minutes, 2)}`;
}

const getDurationDisplayString = (intervalString) => {
    const [ hours, minutes, seconds ] = intervalString.split(':');

    let result = '';
    const hoursString = hours;
    const minutesString = minutes;

    if (hoursString != '00'){
        result = hoursString + 'ч.';
    }
    if (minutesString != '00'){
        if (result){
            result = result + ' ';
        }

        result = result + minutesString + 'мин.';
    }

    return result;
}

const getDaysFormInputDurationFormatString = (intervalString) => {
    const [ hours, minutes, seconds ] = intervalString.split(':');

    return hours/24;
}

const pad = (num, size) => {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
}

const dateAddDays = (date, days) => {
    const result = new Date(date);
    result.setDate(date.getDate() + days);
    return result;
}

const parseISOLocalDate= (s) => {
    var b = s.split(/\D/);
    return new Date(b[0], b[1]-1, b[2], (b[3]||0), (b[4]||0), (b[5]||0), (b[6]||0));
}

export {handleInputChange, 
    isFormStateValid, 
    handleServerError, 
    areArraysEqual, 
    getDateFormatString,
    getTimeFormatString,
    getDateTimeFormatString,
    getFormInputDateFormatString,
    getFormInputDatetimeFormatString,
    getNodaDateTimeFormatString,
    getFormInputDurationFormatString,
    dateAddDays,
    getNodaDurationFromString,
    getNodaDurationFromDaysNumber,
    getDaysFormInputDurationFormatString,
    getDurationDisplayString,
    getDurationDateTimeFormatString,
    getLocalDateTimeOffset,
    parseISOLocalDate}