import React, { Component } from "react";
import { connectInternal } from "../../Helpers/SystemHelper";
import organizationsService from "../../Services/OrganizationsService";
import ElementsBase from "../Elements/ElementsBase";
import ElementBlock from "../Elements/ElementBlock";
import ElementsList from "../Elements/ElementsList";

class MyOrganizations extends Component {
    constructor(props){
        super(props);

        this.state = {
            organizationsData: []
        };

        this.loadData = this.loadData.bind(this);
    }

    async loadData(){
        const actionResult = await this.props.processActionResponse(
            organizationsService.getMyOrganizations(this.props.accountToken));

        let stateUpdate = this.state;
        if (!actionResult.isError){
            stateUpdate = {
                ...stateUpdate,
                organizationsData: actionResult.data
            };
        }

        this.setState(stateUpdate);
    }

    render(){
        if (this.state.organizationsData){
            return(
                <ElementsBase titleText='Мои организации'>
                    <ElementsList>
                        {this.state.organizationsData.map(o => {
                            return (
                                <ElementBlock key={`organizations-list-${o.id}`} 
                                    titleText={o.name}
                                    titleButton='Link'
                                    linkTo={`/organizations/${o.id}`}
                                    buttonIcon="bi bi-sign-turn-slight-right" />
                            )})}
                    </ElementsList>
                </ElementsBase>
            )
        }
        else{ return(<div/>) }
    }

    async componentDidMount(){
        this.loadData();

        document.title = "Мои организации"; 
    }
}

const mapStateToProps = (state) => {
    return {
        accountToken: state.account.token
    }
}

export default connectInternal(mapStateToProps)(MyOrganizations);