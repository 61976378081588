import React, { Component } from "react";
import accountsService from "../../Services/AccountsService";
import { withRouter, connectInternal } from '../../Helpers/SystemHelper';
import OrganizationHeader from "../OrganizationHeader";
import ElementsBase from "../Elements/ElementsBase";
import ElementsList from "../Elements/ElementsList";
import OrganizationGroupListElement from "../Elements/OrganizationGroupListElement";
import Modal from "../Elements/Modal";

class AccountGroups extends Component {
    constructor(props){
        super(props);

        this.state = {
            groupsData: []
        };

        this.loadData = this.loadData.bind(this);
        this.handleSetGroupToLeave = this.handleSetGroupToLeave.bind(this);
        this.handleLeaveGroup = this.handleLeaveGroup.bind(this);
    }

    async loadData(){
        const actionResult = await this.props.processActionResponse(
            accountsService.getGroups(this.props.accountToken));

        let stateUpdate = this.state;
        if (!actionResult.isError){            
            stateUpdate = {
                ...stateUpdate,
                groupsData: actionResult.data,
            };
        }

        this.setState(stateUpdate);
    }

    handleSetGroupToLeave(event){
        this.setState({
            groupIdToLeave: event.currentTarget.value
        });
    }

    async handleLeaveGroup(){
        const actionResult = await this.props.processActionResponse(
            accountsService.leaveOrganizationGroup(this.state.groupIdToLeave, this.props.accountToken));

        if (!actionResult.isError){            
            await this.props.router.navigate(0);
        }
    }

    renderList(){
        if (!this.state.groupsData.some(g => !!g)){
            return <>Группы отсутствуют</>
        }

        return this.state.groupsData.map(g =>{
            return (
                <OrganizationGroupListElement
                    key={`group-list-${g.id}`}
                    value={g}
                    titleButton='Button'
                    buttonValue={g.id}
                    buttonClick={this.handleSetGroupToLeave}
                    buttonDataBsToggle = 'modal'
                    buttonDataBsTarget = '#approveLeaveModal'
                    buttonIcon="bi bi-x-square" />
            );
        });
    }

    render(){
        if (this.state.groupsData){
            return(
                <div>
                    <ElementsBase titleText='Группы'>
                        <ElementsList>
                            {this.renderList()}
                        </ElementsList>
                    </ElementsBase>
                    <Modal 
                        id="approveLeaveModal"
                        modalType='danger'
                        cancelButtonText='Нет'
                        approveButtonText='Да'
                        approveButtonClick={this.handleLeaveGroup}
                        infoText='Это действие невозможно отменить. Вы уверены что хотите покинуть группу?' />
                </div>
            )
        }
        else{ return(<div/>) }
    }

    async componentDidMount(){
        this.loadData();

        document.title = "Мои группы"; 
    }
}

const mapStateToProps = (state) => {
    return {
        accountToken: state.account.token
    }
}

export default connectInternal(mapStateToProps)(withRouter(AccountGroups));