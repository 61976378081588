import ElementBlock from "./ElementBlock";

const OrganizationRoleListElement = (props) => {
    const roleData = props.value;

    return (
        <ElementBlock 
            directProps={{
                ...props,
                titleText: roleData.name
            }}>
        </ElementBlock>
    );
}

export default OrganizationRoleListElement;