import { Link } from "react-router-dom";
import ElementsList from "./ElementsList";

const elementBlockTitleClass = 'element-block-title';
const elementBlockMenuClass = 'element-block-menu';

const ElementBlock = (props) => {
    const propsToUse = props.directProps ?? props;

    let currentChildren = [];
    if (props.children){
        if (props.children.constructor.name == "Array"){
            currentChildren = currentChildren.concat(props.children);
        }
        else{
            currentChildren.push(props.children);
        }
    }
    if (props.directProps && props.directProps.children){
        if (props.directProps.children.constructor.name == "Array"){
            currentChildren = currentChildren.concat(propsToUse.children);
        }
        else{
            currentChildren.push(propsToUse.children);
        }
    }

    const renderTitleBlock = () => {
        if (propsToUse.titleText){
            return <h5>{propsToUse.titleText}</h5>;
        }

        const titleBlock = currentChildren.find(c => c.props.className == elementBlockTitleClass);
        
        return titleBlock ? titleBlock.props.children : <></>;
    }

    const renderTitleButton = () => {
        if (propsToUse.titleButton == 'Link'){
            return (
                <Link to={propsToUse.linkTo} className="btn btn-primary" hidden={propsToUse.buttonHidden}>
                    <i className={propsToUse.buttonIcon}/>
                </Link>
            );
        }
        else if (propsToUse.titleButton == 'href'){
            return (
                <a href={propsToUse.href} className="btn btn-primary" hidden={propsToUse.buttonHidden}>
                    <i className={propsToUse.buttonIcon}/>
                </a>
            );
        }
        else if (propsToUse.titleButton == 'Button'){
            return (
                <button className="btn btn-primary" value={propsToUse.buttonValue} onClick={propsToUse.buttonClick} data-bs-toggle={propsToUse.buttonDataBsToggle} data-bs-target={propsToUse.buttonDataBsTarget} data-bs-dismiss={propsToUse.buttonDataBsDismiss} hidden={propsToUse.buttonHidden}>
                    <i className={propsToUse.buttonIcon}/>
                </button>
            );
        }
        const menuBlock = currentChildren.find(c => c.props.className == elementBlockMenuClass);
        
        if (propsToUse.titleButton == 'Dropdown'){
            return (
                <div className="dropstart">
                    <button className="btn btn-primary" data-bs-toggle='dropdown' hidden={propsToUse.buttonHidden}>
                        <i className={propsToUse.buttonIcon}/>
                    </button>
                    <div className="dropdown-menu bg-secondary p-2" style={{"--bs-bg-opacity": ".25"}}>
                        <div className="">
                            <ElementsList className='text-nowrap'>
                                { menuBlock ? menuBlock.props.children : <></> }
                            </ElementsList>
                        </div>
                    </div>
                </div>
            );
        }

        if (menuBlock){
            return menuBlock.props.hidden ? <></> : menuBlock.props.children;
        }

        return <></>;
    }

    const bodyChildren = currentChildren.filter(c => c.props.className != elementBlockTitleClass && c.props.className != elementBlockMenuClass);

    return (
        <div className="rounded border-0 p-1 ps-2 w-100">
            <div className="d-flex">
                <div className="flex-grow-1">
                    {renderTitleBlock()}
                </div>
                <div>
                    {renderTitleButton()}
                </div>
            </div>
            <div>
                {bodyChildren}
            </div>
        </div>
    );
}

export { elementBlockTitleClass, elementBlockMenuClass };
export default ElementBlock;