import ServiceBase from "./ServiceBase";

class OrganizationsService extends ServiceBase {

    constructor(){
        super('organizations');
    }

    async getAdminOrganizations(payload, token) {
        const request = {
            token: token,
            target: 'adminList',
            payload: payload
        };

        return this.getJson(await this.post(request));
    }

    async getMyOrganizations(token) {
        const request = {
            token: token
        };

        return this.getJson(await this.get(request));
    }

    async getOrganization(payload, token) {
        const request = {
            token: token,
            target: `${payload}`
        };

        return this.getJson(await this.get(request));
    }

    async getOrganizationAdminDetails(payload, token) {
        const request = {
            token: token,
            target: `${payload}/admin`
        };

        return this.getJson(await this.get(request));
    }

    async getOrganizationRights(payload, token) {
        const request = {
            token: token,
            target: `${payload}/rights`
        };

        return this.getJson(await this.get(request));
    }

    async getOrganizationRoles(payload, token) {
        const request = {
            token: token,
            target: `${payload}/roles`
        };

        return this.getJson(await this.get(request));
    }

    async getOrganizationRole(payload, token) {
        const request = {
            token: token,
            target: `roles/${payload}`
        };

        return this.getJson(await this.get(request));
    }

    async deleteOrganizationRole(payload, token) {
        const request = {
            token: token,
            target: `roles/${payload}`
        };

        return this.getJson(await this.delete(request));
    }

    async createOrganization(payload, token) {
        const request = {
            token: token,
            payload: payload
        };

        return this.getJson(await this.post(request));
    }

    async updateOrganization(payload, token) {
        const request = {
            token: token,
            payload: payload,
            target: `${payload.id}`
        };

        return this.getJson(await this.put(request));
    }

    async updateOrganizationMainPicture(payload, token) {
        const request = {
            token: token,
            payload: payload,
            target: `${payload.id}/mainPicture`
        };

        return this.getJson(await this.put(request));
    }

    async adminUpdateOrganization(payload, token) {
        const request = {
            token: token,
            payload: payload,
            target: `${payload.id}/admin`
        };

        return this.getJson(await this.put(request));
    }

    async createOrganizationRole(payload, token) {
        const request = {
            token: token,
            payload: {
                name: payload.roleName,
                roleRights: payload.roleRights
            },
            target: `${payload.organizationId}/roles`
        };

        return this.getJson(await this.post(request));
    }

    async updateOrganizationRole(payload, token) {
        const request = {
            token: token,
            payload: {
                name: payload.roleName,
                roleRights: payload.roleRights
            },
            target: `roles/${payload.roleId}`
        };

        return this.getJson(await this.put(request));
    }

    async getOrganizationGroups(payload, token) {
        const request = {
            token: token,
            target: `${payload}/groups`
        };

        return this.getJson(await this.get(request));
    }

    async getOrganizationAccounts(payload, token) {
        const request = {
            token: token,
            target: `${payload.organizationId}/accounts`,
            payload: {
                name: payload.filterName,
                isNameFilterStrict: payload.isNameFilterStrict
            }
        };

        return this.getJson(await this.post(request));
    }

    async createOrganizationGroup(payload, token){
        const request = {
            token: token,
            payload:{
                name: payload.groupName,
                roleId: payload.groupRoleId
            },
            target: `${payload.organizationId}/groups`
        };

        return this.getJson(await this.post(request));
    }

    async updateOrganizationGroup(payload, token){
        const request = {
            token: token,
            payload:{
                name: payload.groupName,
                roleId: payload.groupRoleId
            },
            target: `groups/${payload.organizationGroupId}`
        };

        return this.getJson(await this.put(request));
    }

    async updateOrganizationGroupAccounts(payload, token){
        const request = {
            token: token,
            payload: payload.accountsLinks,
            target: `groups/${payload.organizationGroupId}/accounts`
        };

        return this.getJson(await this.put(request));
    }

    async getOrganizationGroup(payload, token) {
        const request = {
            token: token,
            target: `groups/${payload}`
        };

        return this.getJson(await this.get(request));
    }

    async getOrganizationsGroups(payload, token) {
        const request = {
            token: token,
            target: `groups`,
            payload: {
                organizationsIds: payload
            }
        };

        return this.getJson(await this.post(request));
    }

    async deleteOrganizationGroup(payload, token) {
        const request = {
            token: token,
            target: `groups/${payload}`
        };

        return this.getJson(await this.delete(request));
    }

    async getOrganizationEvents(payload, token) {
        const request = {
            token: token,
            target: `${payload.organizationId}/events/list`,
            payload: {
                startDatetime: payload.startDatetime,
                endDatetime: payload.endDatetime,
                pagingFilter: payload.pagingFilter
            }
        };

        return this.getJson(await this.post(request));
    }

    async createOrganizationEvent(payload, token) {
        const request = {
            token: token,
            target: `${payload.organizationId}/events`,
            payload: {
                name: payload.name,
                eventDatetime: payload.eventDatetime,
                eventDuration: payload.eventDuration,
                address: payload.address,
                description: payload.description,
                mastersAccountsLinks: payload.mastersAccountsLinks,
                specializationsIds: payload.specializationsIds,
                organizationsGroupsIds: payload.organizationsGroupsIds,
                attendanceFiltersIds: payload.attendanceFiltersIds,
                requiresApplication: payload.requiresApplication,
                requiresApplicationApprove: payload.requiresApplicationApprove,
                applicationLimit: payload.applicationLimit,
                applicationsFiltersIds: payload.applicationsFiltersIds,
                cityId: payload.cityId
            }
        };

        return this.getJson(await this.post(request));
    }

    async getLinkCode(payload, token) {
        const request = {
            token: token,
            target: `${payload}/linkCode`
        };

        return this.getJson(await this.get(request));
    }

    async getLinkCodeOrganizaiton(payload, token) {
        const request = {
            token: token,
            target: `linkCode/${payload}`
        };

        return this.getJson(await this.get(request));
    }

    async createSubscriptionTemplate(payload, token) {
        const request = {
            token: token,
            target: `${payload.organizationId}/subscription-templates`,
            payload: {
                name: payload.name,
                totalValue: payload.totalValue,
                validityInterval: payload.validityInterval,
                price: payload.price
            }
        };

        return this.getJson(await this.post(request));
    }

    async updateSubscriptionTemplate(payload, token) {
        const request = {
            token: token,
            target: `subscription-templates/${payload.templateId}`,
            payload: {
                name: payload.name,
                totalValue: payload.totalValue,
                validityInterval: payload.validityInterval,
                price: payload.price
            }
        };

        return this.getJson(await this.put(request));
    }

    async getSubscriptionTemplate(payload, token) {
        const request = {
            token: token,
            target: `subscription-templates/${payload}`
        };

        return this.getJson(await this.get(request));
    }

    async getSubscriptionsTemplates(payload, token) {
        const request = {
            token: token,
            target: `${payload}/subscription-templates`
        };

        return this.getJson(await this.get(request));
    }

    async deleteSubscriptionTemplate(payload, token) {
        const request = {
            token: token,
            target: `subscription-templates/${payload}`
        };

        return this.getJson(await this.delete(request));
    }

    async createSubscription(payload, token) {
        const request = {
            token: token,
            target: `${payload.organizationId}/subscriptions`,
            payload: {
                name: payload.name,
                totalValue: payload.totalValue,
                startDate: payload.startDate,
                validityInterval: payload.validityInterval,
                accountLink: payload.accountLink,
                comment: payload.comment,
                price: payload.price
            }
        };

        return this.getJson(await this.post(request));
    }

    async getSubscriptions(payload, token) {
        const request = {
            token: token,
            target: `${payload.organizationId}/subscriptions/list`,
            payload:{
                accountName: payload.accountName,
                commentText: payload.commentText,
                isActive: payload.isActive,
                organizationGroupId: payload.organizationGroupId,
                excludeAttendedEventId: payload.excludeAttendedEventId,
                pagingFilter: payload.pagingFilter
            }
        };

        return this.getJson(await this.post(request));
    }

    async getSubscription(payload, token) {
        const request = {
            token: token,
            target: `subscriptions/${payload}`
        };

        return this.getJson(await this.get(request));
    }

    async getSubscriptionByCode(payload, token) {
        const request = {
            token: token,
            target: `subscriptions/code/${payload}`
        };

        return this.getJson(await this.get(request));
    }

    async updateSubscription(payload, token) {
        const request = {
            token: token,
            target: `subscriptions/${payload.subscriptionId}`,
            payload: {
                name: payload.name,
                totalValue: payload.totalValue,
                startDate: payload.startDate,
                validityInterval: payload.validityInterval,
                accountLink: payload.accountLink,
                comment: payload.comment,
                price: payload.price
            }
        };

        return this.getJson(await this.put(request));
    }

    async deactivateSubscription(payload, token) {
        const request = {
            token: token,
            target: `subscriptions/${payload}/deactivate`
        };

        return this.getJson(await this.patch(request));
    }

    async getSystemSubscriptionStatus(payload, token) {
        const request = {
            token: token,
            target: `${payload}/system-subscription`
        };

        return this.getJson(await this.get(request));
    }

    async createReport(payload, token) {
        const request = {
            token: token,
            target: `${payload}/report`
        };

        return this.getJson(await this.post(request));
    }

    async getOrganizationReports(payload, token) {
        const request = {
            token: token,
            target: `${payload}/reports`
        };

        return this.getJson(await this.get(request));
    }

    async getOrganizationReport(payload, token) {
        const request = {
            token: token,
            target: `reports/${payload}`
        };

        return this.getJson(await this.get(request));
    }

    async getAttendanceVariants(payload, token) {
        const request = {
            token: token,
            target: `${payload.organizationId}/accountSubscriptions`,
            payload:{
                accountLink: payload.accountLink
            }
        };

        return this.getJson(await this.post(request));
    }

    async markSubscriptionUsage(payload, token) {
        const request = {
            token: token,
            target: `subscriptions/${payload.subscriptionId}/usage`,
            payload:{ 
                usageComment: payload.usageComment
            }
        };

        return this.getJson(await this.post(request));
    }
}

const organizationsService = new OrganizationsService();

export default organizationsService;