import React, { Component } from 'react';
import organizationsService from '../../Services/OrganizationsService';
import { Link } from 'react-router-dom';
import { connectInternal } from '../../Helpers/SystemHelper';
import ElementBlock from '../Elements/ElementBlock';
import ElementsList from '../Elements/ElementsList';
import ElementsBase from '../Elements/ElementsBase';

class OrganizationsAdminGrid extends Component {
    constructor(props){
        super(props);

        this.state = {
            isFormLoading: false
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.loadData = this.loadData.bind(this);
        this.renderList = this.renderList.bind(this);
    }

    async loadData(){
        if (this.state.isFormLoading){
            return;
        }

        this.setState({
            ...this.state,
            isFormLoading: true
        });

        const actionResult = await this.props.processActionResponse(
            organizationsService.getAdminOrganizations({name: this.state.organizationNameFilter}, this.props.accountToken));

        if (!actionResult.isError){
            this.setState({
                ...this.state,
                organizationsData: actionResult.data,
                isFormLoading: false
            });
        }
    }

    handleInputChange(event) {
        this.setState({
            ...this.state,
            organizationNameFilter: event.target.value
        });
    }

    async handleSubmit(event) {
        event.preventDefault();

        this.loadData();
    }

    async componentDidMount(){
        await this.loadData();

        document.title = "Организации"; 
    }

    renderList(){
        if (this.state.organizationsData){
            return(
                <ElementsList includeSeparator>
                    {this.state.organizationsData.map(o => 
                        <ElementBlock key={`organizations-list-${o.id}`} 
                            titleText={o.name}
                            titleButton='Link'
                            linkTo={`/admin/organizations/${o.id}/edit`}
                            buttonIcon="bi bi-sign-turn-slight-right" />)}
                </ElementsList>
            );
        }
        else{
            return(
                <p>Организации отсутствуют</p>
            );
        }
    }

    render(){
        return (
            <div>
                <div className="d-flex flex-row-reverse mb-3">
                    <Link to='/admin/organizations/create' className="btn btn-light" type="button">
                        <i className="bi bi-plus-circle"/>
                    </Link>
                    <button className="btn btn-light me-3" type="button" data-bs-toggle="collapse" data-bs-target="#filterCollapse" aria-expanded="false" aria-controls="filterCollapse">
                        <i className="bi bi-funnel"/>
                    </button>
                </div>
                <div className="collapse" id="filterCollapse">
                    <form className="container form-floating mb-3" onSubmit={this.handleSubmit}>
                        <div className="form-floating mb-3">
                            <input id="organizationNameFilter" type="text" className='form-control' onChange={this.handleInputChange} disabled={this.state.isFormLoading}/>
                            <label htmlFor="organizationNameFilter">Название</label>
                        </div>
                        <button className="btn btn-primary" type="submit" disabled={this.state.isFormLoading}>
                            <span className="sr-only" hidden={this.state.isFormLoading}>Найти</span>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" hidden={!this.state.isFormLoading}></span>
                        </button>
                    </form>
                </div>
                <ElementsBase>
                    {this.renderList()}
                </ElementsBase>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        accountToken: state.account.token
    }
}

export default connectInternal(mapStateToProps)(OrganizationsAdminGrid);