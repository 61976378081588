import React, { Component } from "react";
import organizationsService from "../../Services/OrganizationsService";
import { withParams, connectInternal } from '../../Helpers/SystemHelper';
import OrganizationHeader from "../OrganizationHeader";
import ElementsBase from "../Elements/ElementsBase";
import ElementsList from "../Elements/ElementsList";
import OrganizationGroupListElement from "../Elements/OrganizationGroupListElement";

class OrganizationGroups extends Component {
    constructor(props){
        super(props);

        this.state = {
            groupsData: []
        };

        this.loadData = this.loadData.bind(this);
    }

    async loadData(){
        const actionResult = await this.props.processActionResponse(
            organizationsService.getOrganizationGroups(this.props.params.organizationId, this.props.accountToken));

        let stateUpdate = this.state;
        if (!actionResult.isError){
            const orr = await this.props.getOrganizationRights(this.props.params.organizationId);
            
            stateUpdate = {
                ...stateUpdate,
                groupsData: actionResult.data,
                orr: orr,
            };
        }

        this.setState(stateUpdate);
    }

    renderList(showGroupDetailsButton){
        if (!this.state.groupsData.some(g => !!g)){
            return <>Группы отсутствуют</>;
        }

        return this.state.groupsData.map(g =>{
            return (
                <OrganizationGroupListElement
                    key={`group-list-${g.id}`}
                    value={g}
                    titleButton='Link'
                    linkTo={`/organizations/groups/${g.id}`}
                    buttonIcon="bi bi-info-square"
                    buttonHidden={!showGroupDetailsButton} />
            );
        });
    }

    render(){
        if (this.state.groupsData){
            let showCreateGroupButton = false;
            let showGroupDetailsButton = false;

            if (this.state.orr){
                showCreateGroupButton = this.state.orr.hasRight('CreateOrganizationGroup');
                showGroupDetailsButton = this.state.orr.hasRight('ViewOrganizationGroupDetails');
            }

            return(
                <div>
                    <OrganizationHeader organizationId={this.props.params.organizationId}/>
                    <ElementsBase
                        titleButton='Link'
                        linkTo={`/organizations/${this.props.params.organizationId}/groups/create`}
                        buttonIcon='bi bi-plus-circle'
                        buttonHidden={!showCreateGroupButton}
                        titleText='Группы'>
                        <ElementsList includeSeparator>
                            {this.renderList(showGroupDetailsButton)}
                        </ElementsList>
                    </ElementsBase>
                </div>
            )
        }
        else{ return(<div/>) }
    }

    async componentDidMount(){
        this.loadData();

        document.title = "Группы организации"; 
    }
}

const mapStateToProps = (state) => {
    return {
        accountToken: state.account.token
    }
}

export default connectInternal(mapStateToProps)(withParams(OrganizationGroups));