import ElementBlock from "./ElementBlock";

const OrganizationGroupListElement = (props) => {
    const groupData = props.value;

    return (
        <ElementBlock 
            directProps={{
                ...props,
                titleText: groupData.name
            }}>
        </ElementBlock>
    );
}

export default OrganizationGroupListElement;