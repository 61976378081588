import React, { Component } from 'react';
import { connectInternal } from '../../Helpers/SystemHelper';
import { withParams } from '../../Helpers/SystemHelper';
import QrCode from '../FormComponents/QrCode';
import organizationsService from '../../Services/OrganizationsService';
import { qrTypes } from '../../Helpers/QrHelper';
import OrganizationHeader from '../OrganizationHeader';
import ElementsBase from '../Elements/ElementsBase';

class OrganizationQr extends Component {
    constructor(props){
        super(props);

        this.state = {
            organizationCode: ''
        }

        this.loadCode = this.loadCode.bind(this);
    }

    async loadCode(){
        var actionResult = await this.props.processActionResponse(
            organizationsService.getLinkCode(this.props.params.organizationId, this.props.accountToken));

        if (!actionResult.isError){
            this.setState({
                ...this.state,
                organizationCode: actionResult.data
            });
        }
    }

    render(){
        return (
            <div>
                <OrganizationHeader organizationId={this.props.params.organizationId} />
                <ElementsBase>
                    <h3 className='text-center'>QR организации</h3>
                    <div className='d-flex justify-content-center'>
                        <div className='p-3 rounded bg-light'>
                            <QrCode abb={qrTypes.organizationLink} code={`${this.state.organizationCode}`} hidden={!this.state.organizationCode}/>
                        </div>
                    </div>
                    <div className='d-flex justify-content-center'>
                        <h1 className="text-center" hidden={!this.state.organizationCode}>{this.state.organizationCode}</h1>
                    </div>
                </ElementsBase>
            </div>
        );
    }
    
    async componentDidMount() {
        await this.loadCode();

        document.title = "QR организации"; 
    }
}

const mapStateToProps = (state) => {
    return {
        accountToken: state.account.token
    }
}

export default connectInternal(mapStateToProps)(withParams(OrganizationQr));