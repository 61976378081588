import React, { Component } from 'react';
import { connectInternal } from '../../Helpers/SystemHelper';
import * as accountActions from '../../Reducers/AccountReducer';
import { bindActionCreators } from '@reduxjs/toolkit';
import accountsService from '../../Services/AccountsService';
import { Navigate, Link } from 'react-router-dom';
import { validateAccountLoginEmail, validateAccountLoginPassword } from '../../Helpers/Validations';
import { handleInputChange, isFormStateValid, handleServerError } from '../../Helpers/FormHelpers';
import { validationFieldClass } from '../../Helpers/Constants';
import ElementsBase from "../Elements/ElementsBase";
import InitFormsWrapper from '../Elements/InitFormsWrapper';

class LoginForm extends Component {
    constructor(props){
        super(props);

        this.state = { 
            accountEmail: {
                validator: validateAccountLoginEmail
            },
            accountPassword: {
                validator: validateAccountLoginPassword
            },
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        handleInputChange.call(this, [event.target]);
    }

    async handleSubmit(event) {
        event.preventDefault();

        if (!isFormStateValid.call(this)){
            return;
        }

        this.setState({
            ...this.state,
            isFormLoading: true
        });

        const actionResult = await this.props.processActionResponse(
            accountsService.login({
                email: this.state.accountEmail.data, 
                password: this.state.accountPassword.data}), this.state);

        if (!actionResult.isError){
            this.props.setLoginData(actionResult.data.token, actionResult.data.systemRoleId, actionResult.data.name);
        }

        this.setState({
            ...this.state,
            ...actionResult.state,
            isFormLoading: false
        });
    }

    render() {
        if (this.props.accountToken){
            return <Navigate to='/main' />;
        }
        else{
            return(
                <InitFormsWrapper logoSize='200'>
                    <ElementsBase>
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-floating mb-3">
                                <input id="accountEmail" type="email" className={`${validationFieldClass} form-control`} onChange={this.handleInputChange} disabled={this.state.isFormLoading}/>
                                <label htmlFor="accountEmail" className='form-label'>Электронная почта</label>
                                <div className="invalid-feedback" htmlFor="accountEmail">{this.state.accountEmail.error}</div>
                            </div>
                            <div className="form-floating mb-3">
                                <input id="accountPassword" type="password" className={`${validationFieldClass} form-control`} onChange={this.handleInputChange} disabled={this.state.isFormLoading}/>
                                <label htmlFor="accountPassword" className='form-label'>Пароль</label>
                                <div className="invalid-feedback" htmlFor="accountPassword">{this.state.accountPassword.error}</div>
                            </div>
                            <button className="btn btn-secondary" type="submit" disabled={this.state.isFormLoading}>
                                <span className="sr-only" hidden={this.state.isFormLoading}>Войти</span>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" hidden={!this.state.isFormLoading}></span>
                            </button>
                            <Link className="btn btn-primary" to="/registration" disabled={this.state.isFormLoading}>Зарегистрироваться</Link>
                            <div>
                                <Link className="btn btn-primary" to="/resetPassword" disabled={this.state.isFormLoading}>Восстановить пароль</Link>
                            </div>
                        </form>
                    </ElementsBase>
                </InitFormsWrapper>
            )
        }
    }

    componentDidMount() {
        document.title = "Страница входа"; 
    }
}


const mapStateToProps = (state) => {
    return {
        accountToken: state.account.token
    }
}

const mapDispatchToProps = (dispatch) => {
    const { setLoginData } = bindActionCreators(accountActions, dispatch);
    return {
        setLoginData: (token, systemRoleId, name) => setLoginData({ token: token, systemRoleId: systemRoleId, name: name })
    }
}

export default connectInternal(mapStateToProps, mapDispatchToProps)(LoginForm);