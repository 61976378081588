import React from 'react';
import ElementsBase from '../Elements/ElementsBase';
import InitFormsWrapper from '../Elements/InitFormsWrapper';

const PasswordResetInProcess = () => {
    return (
        <InitFormsWrapper>
            <ElementsBase>
                <p className="fw-normal">Ссылка для изменения пароля отправлена (действительна в течение 30 минут).</p>
            </ElementsBase>
        </InitFormsWrapper>
    );
}

export default PasswordResetInProcess;