import React, { Component } from 'react';
import { withParams, connectInternal } from '../../Helpers/SystemHelper';
import eventsService from '../../Services/EventsService';
import OrganizationHeader from '../OrganizationHeader';
import ElementsBase from '../Elements/ElementsBase';
import ElementsList from '../Elements/ElementsList';
import InfoBlock from '../Elements/InfoBlock';
import ElementBlock from '../Elements/ElementBlock';

class EventSystemSubscriptionDetails extends Component {
    constructor(props){
        super(props);

        this.state = {
            subscriptionStatusData: ''
        };

        this.loadData = this.loadData.bind(this);
    }

    async loadData(){
        const actionResult = await this.props.processActionResponse(
            eventsService.getSystemSubscriptionStatus(this.props.params.eventId, this.props.accountToken));

        if (!actionResult.isError){
            this.setState({
                ...this.state,
                subscriptionStatusData: actionResult.data
            });
        }
    }

    renderLimitStatusBar(fact, limit){
        const usagePercentage = +fact * 100 / limit;
        const progressStyle ={
            width: `${usagePercentage}%`
        }
        let progressColor = 'bg-info';
        if (usagePercentage > 60){
            progressColor = 'bg-warning'
        }
        if (usagePercentage >= 100){
            progressColor = 'bg-danger'
        }

        return (
            <div className='progress w-100'>
                <div className={`progress-bar ${progressColor}`} style={progressStyle} role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
        );
    }

    countLeftValue(fact, limit){
        return +limit - fact < 0 ? 0 : +limit - fact;
    }

    render(){
        if (this.state.subscriptionStatusData){
            const factMetrics = this.state.subscriptionStatusData.factMetrics;
            const limitMetrics = this.state.subscriptionStatusData.limitMetrics;

            const attendancesLeft = this.countLeftValue(factMetrics.attendancesCount, limitMetrics.attendancesCount);
            const applicationsLeft = this.countLeftValue(factMetrics.applicationsCount, limitMetrics.applicationsCount);
            const ticketsTemplatesLeft = this.countLeftValue(factMetrics.ticketsTemplatesCount, limitMetrics.ticketsTemplatesCount);
            const ticketsLeft = this.countLeftValue(factMetrics.ticketsCount, limitMetrics.ticketsCount);

            return(
                <div>
                    <OrganizationHeader organizationId={this.props.params.organizationId} />
                    <ElementsBase>
                        <ElementsList>
                            <ElementBlock
                                titleText='Статус ограничений'>
                                <ElementsList>
                                    <InfoBlock
                                        title='Посещения'>
                                        <div>
                                            {`Доступно ${attendancesLeft} из ${limitMetrics.attendancesCount} (Использовано: ${factMetrics.attendancesCount})`}
                                        </div>
                                        {this.renderLimitStatusBar(factMetrics.attendancesCount, limitMetrics.attendancesCount)}
                                    </InfoBlock>
                                    <InfoBlock
                                        title='Заявки'>
                                        <div>
                                            {`Доступно ${applicationsLeft} из ${limitMetrics.applicationsCount} (Использовано: ${factMetrics.applicationsCount})`}
                                        </div>
                                        {this.renderLimitStatusBar(factMetrics.applicationsCount, limitMetrics.applicationsCount)}
                                    </InfoBlock>
                                    <InfoBlock
                                        title='Шаблоны билетов'>
                                        <div>
                                            {`Доступно ${ticketsTemplatesLeft} из ${limitMetrics.ticketsTemplatesCount} (Использовано: ${factMetrics.ticketsTemplatesCount})`}
                                        </div>
                                        {this.renderLimitStatusBar(factMetrics.ticketsTemplatesCount, limitMetrics.ticketsTemplatesCount)}
                                    </InfoBlock>
                                    <InfoBlock
                                        title='Билеты'>
                                        <div>
                                            {`Доступно ${ticketsLeft} из ${limitMetrics.ticketsCount} (Использовано: ${factMetrics.ticketsCount})`}
                                        </div>
                                        {this.renderLimitStatusBar(factMetrics.ticketsCount, limitMetrics.ticketsCount)}
                                    </InfoBlock>
                                </ElementsList>
                            </ElementBlock>
                        </ElementsList>
                    </ElementsBase> 
                </div>
            );
        }
        else{
            return (<></>);
        }
    }

    async componentDidMount(){
        await this.loadData();

        document.title = "Статус ограничений (событие)"; 
    }
}

const mapStateToProps = (state) => {
    return {
        accountToken: state.account.token
    }
}

export default connectInternal(mapStateToProps)(withParams(EventSystemSubscriptionDetails));