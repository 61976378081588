import ElementBlock from "./ElementBlock";

const AccountListElement = (props) => {
    const userData = props.value;

    return (
        <ElementBlock 
            directProps={{
                ...props,
                titleText: userData.name
            }}>
        </ElementBlock>
    );
}

export default AccountListElement;