const eventAttendanceFilters = {
    Subscriptions: { id: 1, label: 'Абонементы' },
    Tickets: { id: 2, label: 'Билеты' },
    Custom: { id: 3, label: 'Разовое посещение' },
}

const getLabelById = (filterId) => {
    for(let [key, {id, label}] of Object.entries(eventAttendanceFilters)){
        if (id == filterId){
            return label;
        }
    }

    return;
}

const getFilterById = (filterId) => {
    for(let [key, {id, label}] of Object.entries(eventAttendanceFilters)){
        if (id == filterId){
            return {
                id: id,
                name: label
            };
        }
    }

    return;
}

export { eventAttendanceFilters, getLabelById, getFilterById }