import ServiceBase from "./ServiceBase";

class AccountsService extends ServiceBase {

    constructor(){
        super('accounts');
    }

    async login(payload) {
        const request = {
            target: 'login',
            payload: payload
        };

        return this.getJson(await this.post(request));
    }

    async getRegistration() {     
        const request = {
            target: 'registration'
        };

        return this.getJson(await this.get(request));
    }

    async createAccount(payload) {
        const request = {
            payload: payload
        };

        return this.getJson(await this.post(request));
    }

    async initResetAccountPassword(payload) {
        const request = {
            target: 'init-reset-password',
            payload: {
                email: payload.email,
                captchaToken: payload.captchaToken
            }
        };

        return this.getJson(await this.post(request));
    }

    async resetAccountPassword(payload) {
        const request = {
            target: 'reset-password',
            payload: {
                resetCode: payload.resetCode,
                password: payload.password
            }
        };

        return this.getJson(await this.post(request));
    }

    async verifyAccount(payload) {  
        const request = {
            target: `${payload}/verify`
        };

        return this.getJson(await this.post(request));
    }

    async getAccount(token){
        const request = {
            token: token
        };

        return this.getJson(await this.get(request));
    }

    async updateAccount(payload, token){
        const request = {
            token: token,
            payload: payload
        };
        
        return this.getJson(await this.patch(request));
    }

    async getAccountByLinkCode(payload, token) {     
        const request = {
            token: token,
            target: `linkCode/${payload}`
        };

        return this.getJson(await this.get(request));
    }

    async getLinkCode(payload, token) {     
        const request = {
            token: token,
            target: `linkCode?withRefresh=${payload.withRefresh}`
        };

        return this.getJson(await this.post(request));
    }
    
    async getAccountEvents(payload, token) {
        const request = {
            token: token,
            target: 'events-list',
            payload: {
                startDatetime: payload.startDatetime,
                endDatetime: payload.endDatetime,
                pagingFilter: payload.pagingFilter
            }
        };

        return this.getJson(await this.post(request));
    }

    async getAccountTickets(payload, token) {
        const request = {
            token: token,
            target: 'tickets-list',
            payload: {
                isActive: payload.isActive
            }
        };

        return this.getJson(await this.post(request));
    }

    async getAccountSubscriptions(payload, token) {
        const request = {
            token: token,
            target: 'subscriptions-list',
            payload: {
                isActive: payload.isActive
            }
        };

        return this.getJson(await this.post(request));
    }

    async getAccountEntities(payload, token) {
        const request = {
            token: token,
            target: 'account-entities',
            payload: {
                eventsRequest: {
                    startDatetime: payload.eventsRequest.startDatetime,
                    endDatetime: payload.eventsRequest.endDatetime,
                    pagingFilter: payload.eventsRequest.pagingFilter
                },
                ticketsRequest: {
                    isActive: payload.ticketsRequest.isActive
                },
                subscriptionsRequest: {
                    isActive: payload.subscriptionsRequest.isActive
                }
            }
        };

        return this.getJson(await this.post(request));
    }

    async getGroups(token) {
        const request = {
            token: token,
            target: 'groups'
        };

        return this.getJson(await this.get(request));
    }

    async leaveOrganizationGroup(payload, token) {
        const request = {
            token: token,
            target: `leave-group/${payload}`
        };

        return this.getJson(await this.post(request));
    }
}

const accountsService = new AccountsService();

export default accountsService;