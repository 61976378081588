const eventTicketStatuses = {
    Active: { id: 1, label: 'Действующий' },
    Deactivated: { id: 2, label: 'Аннулирован' },
    Spent: { id: 3, label: 'Использован' }
}

const getLabelById = (filterId) => {
    for(let [key, {id, label}] of Object.entries(eventTicketStatuses)){
        if (id == filterId){
            return label;
        }
    }

    return;
}

const getTicketStatusBadge = (ticketData) => {
    const statusText = getLabelById(ticketData.statusId);

    switch(ticketData.statusId) {
        case 1:
            return <span className="badge bg-success">{statusText}</span>;
        case 2:
            return <span className="badge bg-danger">{statusText}</span>;
        case 3:
            return <span className="badge bg-warning">{statusText}</span>;            
        default:
            return <></>;
      }
}

export { eventTicketStatuses, getLabelById, getTicketStatusBadge }