import React, { Component } from "react";
import organizationsService from "../../Services/OrganizationsService";
import { getDateFormatString, getFormInputDateFormatString, getNodaDateTimeFormatString, dateAddDays, parseISOLocalDate } from '../../Helpers/FormHelpers';
import { connectInternal } from '../../Helpers/SystemHelper';
import ElementsBase, { elementsBaseCollapseBlockClass, elementsBaseTitleClass } from "../Elements/ElementsBase";
import EventListElement from "../Elements/EventListElement";
import ElementsList from "../Elements/ElementsList";

class EventsList extends Component {
    pageEntriesCount = 20;

    constructor(props){
        super(props);

        this.state = {
            pageNumber: 1,
            totalEntriesCount: 0,
            organizationRights: {},
            eventsCollection: [],
            filterStartDatetime: getFormInputDateFormatString(new Date()),
            filterEndDatetime: getFormInputDateFormatString(dateAddDays(new Date(), 14))
        };

        this.loadData = this.loadData.bind(this);
        this.getEventsDataStateUpdate = this.getEventsDataStateUpdate.bind(this);
        this.handleRefreshEvents = this.handleRefreshEvents.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
    }

    async loadData(){
        let stateUpdate = this.state;
        
        stateUpdate = await this.getEventsDataStateUpdate(stateUpdate);

        this.setState(stateUpdate);
    }

    async getEventsDataStateUpdate(stateUpdate){
        const actionResult = await this.props.processActionResponse(
            organizationsService.getOrganizationEvents(
                {
                    organizationId: this.props.organizationId,
                    startDatetime: getNodaDateTimeFormatString(parseISOLocalDate(this.state.filterStartDatetime)),
                    endDatetime: getNodaDateTimeFormatString(parseISOLocalDate(this.state.filterEndDatetime)),
                    pagingFilter: {
                        entriesCount: this.pageEntriesCount,
                        pageNumber: this.state.pageNumber
                    }
                },
                this.props.accountToken
            ));

        if (!actionResult.isError){
            stateUpdate = {
                ...stateUpdate,
                eventsCollection: actionResult.data.items,
                totalEntriesCount: actionResult.data.totalCount
            };
        }

        return stateUpdate;
    }

    async handleRefreshEvents(event){
        event.preventDefault();

        let stateUpdate = await this.getEventsDataStateUpdate(this.state);

        this.setState(stateUpdate);
    }

    handleInputChange(event){
        event.preventDefault();

        this.setState({
            ...this.state,
            [event.currentTarget.id]: getFormInputDateFormatString(new Date(event.currentTarget.value))
        });
    }

    renderEventsList(){
        if (!this.state.eventsCollection.some(e => !!e)){
            return <>События в выбранном периоде не найдены</>
        }

        return (
            <ElementsList spacing='2' includeSeparator>
                { this.state.eventsCollection.map(e => 
                    <EventListElement 
                        titleButton='Link'
                        linkTo={`/events/${e.id}`}
                        buttonIcon='bi bi-info-square'
                        key={`ev-l-${e.id}`} 
                        value={e} />) }
            </ElementsList>
        ); 
    }

    handlePageChange(event){
        event.preventDefault();

        this.setState({
            ...this.state,
            pageNumber: event.currentTarget.value
        });
    }

    renderPagingNav(){
        const prevDisabledClass = this.state.pageNumber <= 1 ? 'disabled' : '';
        const totalPagesCount = Math.floor(this.state.totalEntriesCount / this.pageEntriesCount);
        const nextDisabledClass = this.state.pageNumber >= totalPagesCount ? 'disabled' : '';

        if (totalPagesCount==0){
            return<></>;
        }

        const pagesLinksArray = [];
        for (var i = 1; i <= totalPagesCount; i++) {
            const pageActiveClass = i==this.state.pageNumber ? 'active' : '';
            pagesLinksArray.push(
                <li className={`page-item ${pageActiveClass}`}><button className="page-link" value={i} onClick={this.handlePageChange}>{i}</button></li>
            );
        }

        return(
            <nav aria-label="Page navigation example">
                <ul className="pagination">
                    <li className={`page-item ${prevDisabledClass}`}><button className="page-link" value={+this.state.pageNumber-1} onClick={this.handlePageChange}>Previous</button></li>
                    {pagesLinksArray.map(pl => pl)}
                    <li className={`page-item ${nextDisabledClass}`}><button className="page-link" value={+this.state.pageNumber+1} onClick={this.handlePageChange}>Next</button></li>
                </ul>
            </nav>
        );
    }

    render(){
        return(
            <div>
                <ElementsBase
                    bgClass='bg-transparent'
                    titleButton='ButtonCollapse'
                    buttonIcon='bi bi-funnel'>
                    <div className={elementsBaseTitleClass}>
                        <div className="d-flex justify-content-between">
                            <div className="ms-2">
                                <h5>Календарь событий</h5>
                                <h6>{getDateFormatString(new Date(this.state.filterStartDatetime))} - {getDateFormatString(new Date(this.state.filterEndDatetime))}</h6>
                            </div>
                        </div>
                    </div>
                    <div className={elementsBaseCollapseBlockClass}>
                        <form className="container form-floating mb-3" onSubmit={this.handleRefreshEvents}>
                            <div className="form-floating mb-3">
                                <input id="filterStartDatetime" type="date" className="form-control" onChange={this.handleInputChange} value={this.state.filterStartDatetime}/>
                                <label htmlFor="filterStartDatetime" className="form-label">От</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input id="filterEndDatetime" type="date" className="form-control" onChange={this.handleInputChange} value={this.state.filterEndDatetime}/>
                                <label htmlFor="filterEndDatetime" className="form-label">До</label>
                            </div>
                            <button className="btn btn-primary" type="submit" disabled={this.state.isFormLoading}>
                                <span className="sr-only" hidden={this.state.isFormLoading}>Найти</span>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" hidden={!this.state.isFormLoading}></span>
                            </button>
                        </form>
                    </div>
                </ElementsBase>
                <ElementsBase>
                        {this.renderEventsList()}
                        {this.renderPagingNav()}
                </ElementsBase>
            </div>
            
        )
    }
    

    async componentDidMount(){
        await this.loadData();
    }
}

const mapStateToProps = (state) => {
    return {
        accountToken: state.account.token
    }
}

export default connectInternal(mapStateToProps)(EventsList);