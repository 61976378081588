const dataDelimiter = '|';

const getQrString = ({ abb, code }) => {
    return `${abb}${dataDelimiter}${code}`;
}

const getDataFromQrString = (qrString) => {
    const [abb, code] = qrString.split(dataDelimiter);

    return {
        abb: abb,
        code: code
    };
}

const qrTypes = {
    organizationLink: 'orl',
    accountCode: 'acc',
    subscriptionCode: 'suc',
    ticketCode: 'tic'
}

export {
    getQrString,
    getDataFromQrString,
    qrTypes
}