import React, { Component } from "react";
import { withParams } from '../../../Helpers/SystemHelper';

class PagingElement extends Component {
    pageEntriesCount = 2;
    loadedPageNumber;

    constructor(props){
        super(props);

        this.state = {
            pageNumber: 1,
            totalEntriesCount: 0,
            subscriptionsCollection: [],
            isActiveOnlyFilter: true
        };

        this.handlePageChange = this.handlePageChange.bind(this);
    }

    async handlePageChange(event){
        this.setState({
            ...this.state,
            pageNumber: event.currentTarget.value
        });

        if (this.props.onChange){
            await this.props.onChange({
                preventDefault: event.preventDefault,
                target:{
                    value: event.currentTarget.value
                },
                currentTarget:{
                    value: event.currentTarget.value
                }
            });
        }
    }

    render(){
        const totalPagesCount = this.props.totalPagesCount;

        const prevDisabledClass = this.state.pageNumber <= 1 ? 'disabled' : '';
        const nextDisabledClass = this.state.pageNumber >= totalPagesCount ? 'disabled' : '';

        if (+totalPagesCount < 2){
            return<></>;
        }

        const pagesLinksArray = [];
        for (var i = 1; i <= totalPagesCount; i++) {
            const pageActiveClass = i==this.state.pageNumber ? 'active' : '';
            pagesLinksArray.push(
                <li key={`page-num-key-${i}`} className={`page-item ${pageActiveClass}`}><button className="page-link" value={i} onClick={this.handlePageChange}>{i}</button></li>
            );
        }

        return(
            <nav aria-label="Page navigation">
                <ul className="pagination m-0">
                    <li key='prev-paging-key' className={`page-item ${prevDisabledClass}`}><button className="page-link" value={+this.state.pageNumber-1} onClick={this.handlePageChange}><i className="bi bi-chevron-double-left"/></button></li>
                    {pagesLinksArray.map(pl => pl)}
                    <li key='next-paging-key' className={`page-item ${nextDisabledClass}`}><button className="page-link" value={+this.state.pageNumber+1} onClick={this.handlePageChange}><i className="bi bi-chevron-double-right"/></button></li>
                </ul>
            </nav>
        );
    }
}

export default withParams(PagingElement);