import React, { Component } from 'react';
import { connectInternal } from '../../Helpers/SystemHelper';
import * as accountActions from '../../Reducers/AccountReducer';
import { bindActionCreators } from '@reduxjs/toolkit';
import { Link } from 'react-router-dom';
import organizationsService from '../../Services/OrganizationsService';

class Header extends Component {
    constructor(props){
        super(props);

        this.state = {
            isMyOrganizationsExist: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.loadData = this.loadData.bind(this);
    }

    async handleSubmit(event) {
        event.preventDefault();

        this.props.clearData();
    }

    async loadData(){
        const actionResult = await this.props.processActionResponse(
            organizationsService.getMyOrganizations(this.props.accountToken));

        let stateUpdate = this.state;
        if (!actionResult.isError){
            stateUpdate = {
                ...stateUpdate,
                isMyOrganizationsExist: actionResult.data.some(x => !!x)
            };
        }

        this.setState(stateUpdate);
    }

    render() {
        return(
            <nav className="navbar navbar-dark bg-primary mb-3 rounded">
                <div className="container-fluid">

                    <Link className="navbar-brand" to={'/main'}>
                        <img src={`${process.env.PUBLIC_URL}/img/logo.svg`} alt='SferaSobytiy' width='30' height='30'></img>
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                            <li className="nav-item" hidden={!this.state.isMyOrganizationsExist}>
                                <Link className="nav-link" to={'/organizations'}>Мои организации</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to={'/groups'}>Мои группы</Link>
                            </li>
                            <li className="nav-item dropdown" hidden={!this.props.isSystemAdmin}>
                                <button type="button" className="btn btn-link nav-link dropdown-toggle" id="navbarDropdown" data-bs-toggle="dropdown" aria-expanded="false" >
                                    Админка
                                </button>
                                <ul className="dropdown-menu mb-3" aria-labelledby="navbarDropdown">
                                    <li><Link className="dropdown-item" to={'/admin/organizations'}>Организации</Link></li>
                                    <li><Link className="dropdown-item" to={'/admin/systemstatus'}>Статус системы</Link></li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to={'/account/qr'}>Мой QR</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to={'/account'}>Аккаунт ({this.props.accountName})</Link>
                            </li>
                            <li className="nav-item">
                                <button type="button" className="btn nav-link text-danger fw-bold" onClick={this.handleSubmit}>Выйти</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        )
    }

    async componentDidMount(){
        await this.loadData();
    }
}

const mapStateToProps = (state) => {
    return {
        accountName: state.account.name,
        accountToken: state.account.token,
        isSystemAdmin: state.account.isSystemAdmin
    }
}

const mapDispatchToProps = (dispatch) => {
    const { clearData } = bindActionCreators(accountActions, dispatch);
    return {
        clearData: () => clearData()
    }
}

export default connectInternal(mapStateToProps, mapDispatchToProps)(Header);