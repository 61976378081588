import React from 'react';
import { Link } from 'react-router-dom';
import ElementsBase from '../Elements/ElementsBase';
import InitFormsWrapper from '../Elements/InitFormsWrapper';

const PasswordResetSuccess = () => {
    return (
        <InitFormsWrapper>
            <ElementsBase>
                <p className="fw-normal">Пароль изменен.</p>
                <Link className="btn btn-secondary" to="/login">Перейти ко входу в систему</Link>
            </ElementsBase>
        </InitFormsWrapper>
    );
}

export default PasswordResetSuccess;