import React, { Component } from "react";
import { connectInternal } from "../../Helpers/SystemHelper";
import { validationFieldClass } from '../../Helpers/Constants';
import { validateAccountName, validateAccountTelegramChatId } from '../../Helpers/Validations';
import { handleInputChange, isFormStateValid, handleServerError } from '../../Helpers/FormHelpers';
import accountsService from '../../Services/AccountsService';
import { bindActionCreators } from '@reduxjs/toolkit';
import * as accountActions from '../../Reducers/AccountReducer';
import ElementsBase, { elementsBaseMenuClass } from '../Elements/ElementsBase';

class AccountDetails extends Component {
    constructor(props){
        super(props);

        this._telegramBotLink = process.env.REACT_APP_TELEGRAM_BOT_LINK;

        this.state = {
            isEditState: false,
            accountEmail: '',
            isTelegramChatLinked: false,
            unlinkTelegramChat: false,
            accountName: {
                validator: validateAccountName,
                data: '',
            },
            telegramChatId: {
                validator: validateAccountTelegramChatId,
                data: '',
            }
        }

        this.handleEdit = this.handleEdit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleTelegramChatUnlink = this.handleTelegramChatUnlink.bind(this);
    }

    handleTelegramChatUnlink(){
        this.setState({
            ...this.state,
            unlinkTelegramChat: true,
            isTelegramChatLinked: false,
        });
    }

    handleEdit(){
        this.setState({
            ...this.state,
            isEditState: true
        });
    }

    handleCancel(){
        this.setState({
            ...this.state,
            isEditState: false,
            accountName: {
                ...this.state.accountName,
                data: this.state.initialData.name
            },
            unlinkTelegramChat: false,
            isTelegramChatLinked: this.state.unlinkTelegramChat || this.state.isTelegramChatLinked
        });
    }

    handleInputChange(event) {
        handleInputChange.call(this, [event.target]);
    }

    async handleSubmit(event) {
        event.preventDefault();

        if (!isFormStateValid.call(this)){
            return;
        }

        this.setState({
            ...this.state,
            isLoadingState: true
        });

        const actionResult = await this.props.processActionResponse(
            accountsService.updateAccount({
                name: this.state.accountName.data,
                telegramChatId: this.state.telegramChatId.data ? this.state.telegramChatId.data : null,
                unlinkTelegramChat: this.state.unlinkTelegramChat
            }, 
            this.props.accountToken));
            
        if (actionResult.isError){
            this.setState({
                ...this.state,
                ...actionResult.state,
                isLoadingState: false
            });
        }
        else{
            this.setState({
                ...this.state,
                isEditState: false,
                initialData: {
                    ...this.state.initialData,
                    name: this.state.accountName.data
                },
                unlinkTelegramChat: false,
                telegramChatId:{
                    data: ''
                },
                isTelegramChatLinked: !this.state.unlinkTelegramChat && (this.state.isTelegramChatLinked || this.state.telegramChatId.data),
                isLoadingState: false
            });

            this.props.setAccountName(this.state.accountName.data);
        }
    }

    async loadData(){
        const accountData = await accountsService.getAccount(this.props.accountToken);

        this.setState({
            ...this.state,
            initialData: accountData.data,
            accountEmail: accountData.data.email,
            accountName:{
                ...this.state.accountName,
                data: accountData.data.name
            },
            isTelegramChatLinked: accountData.data.isTelegramChatLinked
        });
    }

    render(){
        const telegramInputLabel = 
            this.state.isTelegramChatLinked 
            ? 'Чат Telegram привязан' 
            : !this.state.isEditState || this.state.isFormLoading
                ? 'Чат Telegram  не привязан'
                : 'Код привязки чата Telegram';
        const telegramInputDisabled = this.state.isTelegramChatLinked || !this.state.isEditState || this.state.isFormLoading

        return(
            <ElementsBase>
                <div className={elementsBaseMenuClass}>
                    <button className="btn btn-primary" type="button" onClick={this.handleEdit} hidden={this.state.isEditState}>
                        <i className="bi bi-pencil-square"/>
                    </button>
                    <button className="btn btn-outline-success border-0 me-2" type="button" onClick={this.handleSubmit} disabled={this.state.isLoadingState} hidden={!this.state.isEditState}>
                        <i className="bi bi-check-square" hidden={this.state.isLoadingState}/>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" hidden={!this.state.isLoadingState}></span>
                    </button>
                    <button className="btn btn-outline-warning border-0" type="button" onClick={this.handleCancel} hidden={!this.state.isEditState} disabled={this.state.isLoadingState}><i className="bi bi-x-square"/></button>
                </div>
                <div className="form-floating mb-3">
                    <input id="accountEmail" type="email" className={'form-control'} value={this.state.accountEmail} disabled/>
                    <label htmlFor="accountEmail" className="form-label">Электронная почта</label>
                </div>
                <div className="form-floating mb-3">
                    <input id="accountName" type="text" className={`${validationFieldClass} form-control`} onChange={this.handleInputChange} disabled={!this.state.isEditState || this.state.isFormLoading} value={this.state.accountName.data}/>
                    <label htmlFor="accountName" className="form-label">Никнейм</label>
                    <div className="invalid-feedback" htmlFor="accountName">{this.state.accountName.error}</div>
                </div>
                <div className='d-flex mb-3'>
                    <div key='ev-aplmt' className="form-floating flex-grow-1">
                        <input id="telegramChatId" type="text" className="form-control" onChange={this.handleInputChange} disabled={telegramInputDisabled} value={this.state.telegramChatId.data} placeholder={telegramInputLabel}/>
                        <label htmlFor="telegramChatId" className='form-label'>{telegramInputLabel}</label>
                        <div className="invalid-feedback" htmlFor="telegramChatId">{this.state.telegramChatId.error}</div>
                    </div>
                    <div hidden={!this.state.isTelegramChatLinked || !this.state.isEditState}>
                        <button className="btn btn-outline-danger input-group-text h-100 ms-2" type='button' disabled={this.state.isFormLoading || !this.state.isTelegramChatLinked} onClick={this.handleTelegramChatUnlink}>Отвязать</button>
                    </div>
                    <div hidden={this.state.isTelegramChatLinked}>
                        <button className="btn btn btn-primary input-group-text h-100 ms-2" type='button' data-bs-toggle="collapse" data-bs-target="#telegramChatIdInfo" aria-expanded="false" aria-controls="telegramChatIdInfo">
                            <i className="bi bi-info-square" />
                        </button>
                    </div>
                </div>
                <div className="collapse" id="telegramChatIdInfo">
                    <div>
                        Для получения кода привязки напишите <a className="text-white" href={this._telegramBotLink} target="_blank" rel="noreferrer">боту Сферы Событий</a>.
                    </div>
                </div>
            </ElementsBase>
        )
    }

    componentDidMount(){
        this.loadData();
        
        document.title = "Мои данные"; 
    }
}

const mapStateToProps = (state) => {
    return {
        accountToken: state.account.token
    }
}

const mapDispatchToProps = (dispatch) => {
    const { setAccountName } = bindActionCreators(accountActions, dispatch);
    return {
        setAccountName: (name) => setAccountName({ name: name })
    }
}

export default connectInternal(mapStateToProps, mapDispatchToProps)(AccountDetails);