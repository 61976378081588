const getErrorData = (errorCode) => {
    const {fieldsNames, message} = codes.find(e => e.code == errorCode);

    return {fieldsNames, message}
}

const codes = [
    {code: 1, fieldsNames: ['accountEmail'], message: 'Электронная почта уже зарегистрирована в системе'},
    {code: 2, fieldsNames: ['captchaToken'], message: 'Проверьте капчу и попробуйте еще раз'},
    {code: 3, fieldsNames: ['accountEmail','accountPassword'], message: 'Неверные данные'},
    {code: 6, fieldsNames: ['organizationName'], message: 'Имя организации уже существует в системе'},
    {code: 7, fieldsNames: ['roleName'], message: 'Имя роли уже существует в системе'},
    {code: 8, fieldsNames: ['groupName'], message: 'Имя группы уже существует в системе'},
    {code: 10, fieldsNames: ['templateName'], message: 'Название шаблона уже существует в системе'},
]

const getSystemErrorData = (errorCode) => {
    const systemError = systemCodes.find(e => e.code == errorCode);
    if (systemError){
        const { message } = systemCodes.find(e => e.code == errorCode);
        return message;
    }
    
    return systemCodes.find(e => e.code == 0).message;
}

const systemCodes = [
    {code: 0, message: 'Неизвестная серверная ошибка.'},
    {code: 1, message: 'Токен невалиден.'},
]

const getSystemSubscriptionErrorData = (errorCode) => {
    const systemSubscriptionError = systemSubscriptionCodes.find(e => e.code == errorCode);
    if (systemSubscriptionError){
        const { message } = systemSubscriptionCodes.find(e => e.code == errorCode);
        return message;
    }
    
    return systemCodes.find(e => e.code == 0).message;
}

const systemSubscriptionCodes = [
    {code: 0, message: 'Ошибка подписки.'},
    {code: 1, message: 'Превышен лимит по количеству ролей.'},
    {code: 2, message: 'Превышен лимит по количеству групп.'},
    {code: 3, message: 'Превышен лимит по количеству аккаунтов.'},
    {code: 4, message: 'Превышен лимит по количеству шаблонов абонементов.'},
    {code: 5, message: 'Превышен лимит по количеству абонементов.'},
    {code: 6, message: 'Превышен лимит по количеству активных событий.'},
    {code: 7, message: 'Превышен лимит по количеству посещений события.'},
    {code: 8, message: 'Превышен лимит по количеству одобренных заявок на посещение события.'},
    {code: 9, message: 'Превышен лимит по количеству шаблонов билетов привязанных к событию.'},
    {code: 10, message: 'Превышен лимит по количеству билетов привязанных к событию.'},
    {code: 11, message: 'Превышен лимит по количеству отчетов (за месяц).'},
]

export { getErrorData, getSystemErrorData, getSystemSubscriptionErrorData };