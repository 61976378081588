import React, { Component } from 'react';
import eventsService from '../../../Services/EventsService';
import { connectInternal } from '../../../Helpers/SystemHelper';
import { areArraysEqual, getFormInputDateFormatString, getNodaDateTimeFormatString, dateAddDays } from '../../../Helpers/FormHelpers';
import EventListElement from '../../Elements/EventListElement';
import ElementsBase from '../../Elements/ElementsBase';
import ElementsList from '../../Elements/ElementsList';

class OrganizationsEventsPicker extends Component {
    pageEntriesCount = 20;

    constructor(props){
        super(props);

        this.id = props.id;

        this.state = {
            totalPagesCount: 0,
            eventsSelectList: '',
            eventsSelected: props.value || [],
            nameFilter: '',
            startDateFilter: getFormInputDateFormatString(new Date()),
            endDateFilter: getFormInputDateFormatString(dateAddDays(new Date(), 14))
        }

        this.loadData = this.loadData.bind(this);
        this.handleListButtonSelect = this.handleListButtonSelect.bind(this);
        this.handleRemoveItem = this.handleRemoveItem.bind(this);
        this.handleFilterInputChange = this.handleFilterInputChange.bind(this);
        this.handleRefreshSelectList = this.handleRefreshSelectList.bind(this);
    }

    async handleRefreshSelectList(event){
        event.preventDefault();

        await this.loadData();
    }

    handleFilterInputChange(event){
        if (event.target.id == 'nameFilter'){
            this.setState({
                ...this.state,
                nameFilter: event.target.value
            })
        }

        if (event.target.id == 'startDateFilter' || event.target.id == 'endDateFilter'){
            this.setState({
                ...this.state,
                [event.target.id]: getFormInputDateFormatString(new Date(event.target.value))
            })
        }
    }

    async loadData(pageNum = 1){
        const actionResult = await this.props.processActionResponse(
            eventsService.getPickerEvents(
                {
                    name: this.state.nameFilter,
                    startDate: getNodaDateTimeFormatString(new Date(this.state.startDateFilter)),
                    endDate: getNodaDateTimeFormatString(new Date(this.state.endDateFilter)),
                    pagingFilter:{
                        entriesCount: this.pageEntriesCount,
                        pageNumber: pageNum
                    }
                },
                this.props.accountToken));

        if (!actionResult.isError){
            this.setState({
                ...this.state,
                eventsSelectList: actionResult.data.items,
                totalPagesCount: Math.ceil(actionResult.data.totalCount / this.pageEntriesCount)
            });
        }
    }

    handleListButtonSelect(event){
        event.preventDefault();

        const targetItem = this.state.eventsSelectList.find(i => i.id == event.currentTarget.value);

        this.value = [
            ...this.state.eventsSelected,
            targetItem
        ];

        this.setState({
            ...this.state,
            eventsSelected: this.value
        });

        this.props.onChange({target: this});
    }

    handleRemoveItem(event){
        event.preventDefault();

        this.value = [...this.state.eventsSelected];
        const targetIndex = this.value.findIndex(v => v.id == event.currentTarget.value);
        if (targetIndex > -1){
            this.value.splice(targetIndex, 1);
        }

        this.setState({
            ...this.state,
            eventsSelected: this.value
        });

        this.props.onChange({target: this});
    }

    renderSelectList(){
        if (!this.state.eventsSelectList){
            return <></>;
        }

        const elementsArray = [];

        for(let item of this.state.eventsSelectList){
            if (this.state.eventsSelected.some(i => i.id == item.id)){
                continue;
            };

            elementsArray.push(
                <EventListElement 
                    key={`button-group-${item.id}`}
                    titleButton='Button'
                    buttonIcon='bi bi-check2-square'
                    buttonClick={this.handleListButtonSelect}
                    buttonDataBsDismiss='modal'
                    buttonValue={item.id}
                    value={item} />)
        }

        return elementsArray;
    }

    renderSelectedList(){
        const elementsArray = [];

        if (this.state.eventsSelected){
            for(let eventSelected of this.state.eventsSelected){
                elementsArray.push(
                    <div key={`selected-group-${eventSelected.id}`} className="btn-group mt-3 me-1" role="group" >
                        <button type="button" value={eventSelected.id} className="btn btn-danger" onClick={this.handleRemoveItem} hidden={this.props.disabled}>
                            <i className="bi bi-x-square" />
                        </button>
                        <button type='button' className="btn btn-outline-primary" disabled>{`${eventSelected.name}`}</button>
                    </div>
                );
            }
        }
        else {
            elementsArray.push(<div className='mb-2' />);
        }

        return elementsArray;
    }

    renderSelectModal(){
        return (
            <div className="modal fade" id="eventsSelectModal" tabIndex="-1" aria-labelledby="eventsSelectModal" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div>
                                <div className="form-floating mb-3">
                                    <input id="nameFilter" type="text" className='form-control' onChange={this.handleFilterInputChange} value={this.state.nameFilter}/>
                                    <label htmlFor="nameFilter" className='form-label'>Название</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input id="startDateFilter" type="date" className="form-control" onChange={this.handleFilterInputChange} value={this.state.startDateFilter}/>
                                    <label htmlFor="startDateFilter" className='form-label'>От</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input id="endDateFilter" type="date" className="form-control" onChange={this.handleFilterInputChange} value={this.state.endDateFilter}/>
                                    <label htmlFor="endDateFilter" className='form-label'>До</label>
                                </div>
                                <button type="button" className="btn btn-success" onClick={this.handleRefreshSelectList}>Найти</button>
                            </div>
                            <ElementsBase>
                                <ElementsList includeSeparator>
                                    {this.renderSelectList()}
                                </ElementsList>
                            </ElementsBase>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-warning border-0" data-bs-dismiss="modal">Закрыть</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render(){
        return (
            <div id={this.props.id}>
                <div className="container d-flex flex-wrap form-floating p-3 rounded bg-light">
                    <input id="ghostGroupsPickerDiv" className='form-control' hidden/>
                    <label htmlFor="ghostGroupsPickerDiv" className='form-label'>{this.props.label}</label>
                    {this.renderSelectedList()}
                    <button type="button" className="btn btn-outline-primary mt-3" data-bs-toggle="modal" data-bs-target="#eventsSelectModal" disabled={this.props.disabled}>
                        <i className="bi bi-pencil-square"/>
                    </button>
                    {this.renderSelectModal()}
                </div>
            </div>
        );
    }

    async componentDidMount(){
        const wrappingDiv = document.getElementById(this.props.id);
        wrappingDiv.className = this.props.className;

        this.className = wrappingDiv.className;
        this.classList = wrappingDiv.classList;

        await this.loadData();
    }

    static getDerivedStateFromProps(props, state){
        if (!areArraysEqual(props.value || [], state.eventsSelected || [])) {
            return ({ 
                ...state,
                eventsSelected: [...props.value] 
            })
        }

        return null;
    }
}

const mapStateToProps = (state) => {
    return {
        accountToken: state.account.token
    }
}

export default connectInternal(mapStateToProps)(OrganizationsEventsPicker);