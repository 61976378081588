import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import accountsService from '../../Services/AccountsService';
import { bindActionCreators } from '@reduxjs/toolkit';
import * as accountActions from '../../Reducers/AccountReducer';
import { connectInternal } from '../../Helpers/SystemHelper';
import { withParams } from '../../Helpers/SystemHelper';

class VerifyAccount extends Component {
    constructor(props){
        super(props);

        this.props.clearData();

        this.state = {
            verificationInProcess: true
        };
    }

    async verifyAccount() {
        const actionResult = await this.props.processActionResponse(
            accountsService.verifyAccount(this.props.params.verificationId));

        if (actionResult.isError){
            this.setState({
                ...this.state,
                verificationInProcess: false,
                isVerified: false
            });
        }
        else{
            this.setState({
                ...this.state,
                verificationInProcess: false,
                isVerified: true
            });
        }
    }

    render() {
        if (this.state.verificationInProcess){
            this.verifyAccount();
        }

        return(
            <div className="container h-100">
                <div className='row align-items-center h-100'>
                    <div className='col-0 col-md-4'/>
                    <div className='col-12 col-md-4'>
                        <div className="alert alert-primary text-center" role="alert">
                            <p className="fw-normal" hidden={!this.state.verificationInProcess}>Верифицируем аккаунт...</p>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" hidden={!this.state.verificationInProcess}></span>
                            <p className="fw-normal" hidden={this.state.verificationInProcess || !this.state.isVerified}>Аккаунт верифицирован</p>
                            <p className="fw-normal" hidden={this.state.verificationInProcess || !this.state.isVerified}>Добро пожаловать</p>
                            <Link className="btn btn-link" to="/login" hidden={this.state.verificationInProcess || !this.state.isVerified}>Перейти на страницу входа</Link>
                            <p className="fw-normal" hidden={this.state.verificationInProcess || this.state.isVerified}>Ошибка верификации</p>
                            <p className="fw-normal" hidden={this.state.verificationInProcess || this.state.isVerified}>Попробуйте еще раз</p>
                        </div>
                    </div>
                    <div className='col-0 col-md-4'/>
                </div>
            </div>
        )
    }

    componentDidMount() {
        document.title = "Подтверждение аккаунта"; 
    }
}

const mapStateToProps = (state) => {
    return {
        accountToken: state.account.token
    }
}

const mapDispatchToProps = (dispatch) => {
    const { clearData } = bindActionCreators(accountActions, dispatch);
    return {
        clearData: () => clearData()
    }
}

export default connectInternal(mapStateToProps, mapDispatchToProps)(withParams(VerifyAccount));