import React, { Component } from 'react';
import ElementsBase from '../Elements/ElementsBase';

class SystemStatus extends Component {

    render(){
        return (
            <ElementsBase>
                <h3 className='text-center'>Release 20230716.5</h3>
            </ElementsBase>
        );
    }
}

export default SystemStatus;