import React, { Component } from "react";
import eventsService from "../../Services/EventsService";
import { withParams, connectInternal } from '../../Helpers/SystemHelper';
import ElementsBase from '../Elements/ElementsBase';
import ElementBlock, { elementBlockTitleClass, elementBlockMenuClass } from "../Elements/ElementBlock";
import ElementsList from "../Elements/ElementsList";

class EventApplications extends Component {
    targetApplicationAccountId = '';

    constructor(props){
        super(props);

        this.state = {
            applicationsCollection: []
        };

        this.loadData = this.loadData.bind(this);
        this.handleDeclineApplication = this.handleDeclineApplication.bind(this);
        this.handleApproveApplication = this.handleApproveApplication.bind(this);
        this.handleApproveModalOpen = this.handleApproveModalOpen.bind(this);
    }

    handleApproveModalOpen(event){
        this.targetApplicationAccountId = event.target.value
    }

    async handleDeclineApplication(event){
        const actionResponse = await this.props.processActionResponse(
            eventsService.declineEventApplication(
                {
                    eventId: this.props.params.eventId,
                    accountId: this.targetApplicationAccountId
                },
                this.props.accountToken
            ));

        if (!actionResponse.isError){
            this.loadData();
        }
    }

    async handleApproveApplication(event){
        const actionResponse = await this.props.processActionResponse(
            eventsService.approveEventApplication(
                {
                    eventId: this.props.params.eventId,
                    accountId: this.targetApplicationAccountId
                },
                this.props.accountToken
            ));

        if (!actionResponse.isError){
            this.loadData();
        }
    }

    async loadData(){
        const actionResponse = await this.props.processActionResponse(
            eventsService.getEventApplications(
                this.props.params.eventId,
                this.props.accountToken
            ));

        if (!actionResponse.isError){
            const orr = await this.props.getOrganizationsRights(actionResponse.data.ownersOrganizations.map(o => o.id));

            this.setState({
                ...this.state,
                orr: orr,
                applicationsCollection: actionResponse.data.applications,
                newApprovesAvailable: actionResponse.data.newApprovesAvailable
            });
        }
    }

    renderDeclineApproveModal(){
        return (
            <div className="modal fade" id="declineApproveModal" tabIndex="-1" aria-labelledby="declineApproveModal" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            Запись будет отклонена.
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-warning border-0" data-bs-dismiss="modal">Отменить</button>
                            <button type="button" className="btn btn-outline-danger border-0" onClick={this.handleDeclineApplication} data-bs-dismiss="modal">Отклонить</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderApproveApproveModal(){
        return (
            <div className="modal fade" id="approveApproveModal" tabIndex="-1" aria-labelledby="approveApproveModal" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            Запись будет подтверждена.
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-warning border-0" data-bs-dismiss="modal">Отменить</button>
                            <button type="button" className="btn btn-outline-success border-0" onClick={this.handleApproveApplication} data-bs-dismiss="modal">Подтвердить</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderApplicationStatus(application){
        switch(application.statusId) 
        {
            case 1:
                return <span className="badge bg-warning text-dark">Ожидает решения...</span>;
            case 2:
                return <span className="badge bg-danger">Отклонено</span>;
            case 3:
                return <span className="badge bg-success">Подтверждено</span>;
            case 4:
                return <span className="badge bg-danger">Отменено</span>;
            default:
                return <></>;
        }
    }

    renderList(){
        if (!this.state.applicationsCollection.some(a => !!a)){
            return <>Заявки отсутствуют</>;
        }

        const showApproveButtonBase = this.state.newApprovesAvailable && this.state.orr.hasRight('OperateEventApplications');
        const showDeclineButtonBase = this.state.orr.hasRight('OperateEventApplications');

        return this.state.applicationsCollection.map(a =>{
            const showApproveButton = showApproveButtonBase && a.statusId != 3;
            const showDeclineButton = showDeclineButtonBase && a.statusId != 2;

            const showOperationsBlock = a.statusId != 4 && (showApproveButton || showDeclineButton);

            return (
                <ElementBlock key={`applications-list-${a.account.id}`} >
                    <div className={elementBlockTitleClass}>
                        {this.renderApplicationStatus(a)}
                        <h5 className="card-title">{a.account.name}</h5>
                        
                    </div>
                    <div className={elementBlockMenuClass} hidden={!showOperationsBlock}>
                        <div className="dropstart">
                            <button type="button" className="btn btn-primary" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="bi bi-gear"/>
                            </button>
                            <div className="dropdown-menu bg-secondary p-1">
                                <ElementsList>
                                    <button className="btn btn-success text-nowrap mb-2" type="button" value={a.account.id} onClick={this.handleApproveModalOpen} data-bs-toggle="modal" data-bs-target="#approveApproveModal" hidden={a.statusId == 3}>
                                        <i className="bi bi-check-square"/> Подтвердить запись
                                    </button>
                                    <button className="btn btn-danger text-nowrap" type="button" value={a.account.id} onClick={this.handleApproveModalOpen} data-bs-toggle="modal" data-bs-target="#declineApproveModal" hidden={a.statusId == 2}>
                                        <i className="bi bi-x-square"/> Отклонить запись
                                    </button>
                                </ElementsList>
                            </div>
                        </div>
                    </div>
                </ElementBlock>
            );
        });
    }

    render(){
        return(
            <div>
                <ElementsBase>
                    <ElementsList includeSeparator>
                        {this.renderList()}
                    </ElementsList>
                </ElementsBase>
                {this.renderDeclineApproveModal()}
                {this.renderApproveApproveModal()}
            </div>
        )
    }
    

    async componentDidMount(){
        await this.loadData();

        document.title = "Заявки на запись"; 
    }
}

const mapStateToProps = (state) => {
    return {
        accountToken: state.account.token
    }
}

export default connectInternal(mapStateToProps)(withParams(EventApplications));