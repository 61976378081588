class ServiceBase {
    constructor(controllerUrl) {
        this._baseUrl = process.env.REACT_APP_API_HOST;
        this._controllerUrl = controllerUrl;
    }

    getUrl(target = '') {
        return `${this._baseUrl}/${this._controllerUrl}/${target}`;
    }

    async delete({target, token}){
        return await this.makeRequest({
            method: 'DELETE',
            target: target,
            token: token
        });
    }

    async post({target, payload, token}){
        return await this.makeRequest({
            method: 'POST',
            target: target,
            payload: payload,
            token: token
        });
    }

    async patch({target, payload, token}){
        return await this.makeRequest({
            method: 'PATCH',
            target: target,
            payload: payload,
            token: token
        });
    }

    async put({target, payload, token}){
        return await this.makeRequest({
            method: 'PUT',
            target: target,
            payload: payload,
            token: token
        });
    }

    async get({target, token}){
        return await this.makeRequest({
            method: 'GET',
            target: target,
            token: token
        });
    }

    async makeRequest({target, method, payload, token}){
        let requestInit = {
            method: method
        };

        if (payload){
            requestInit = {
                ...requestInit,
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            }
        }

        if (token){
            requestInit = {
                ...requestInit,
                headers:{
                    ...requestInit.headers,
                    'Authorization': `Bearer ${token}`
                }
            };
        }

        return await fetch(this.getUrl(target), requestInit);
    }

    async getJson(requestPromise){
        const response = await requestPromise;

        if (response.status == 401){
            return { systemError: 1 }
        }

        return response.json();
    }
}

export default ServiceBase;