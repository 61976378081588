import ElementBlock from "./ElementBlock";
import { getDaysFormInputDurationFormatString } from "../../Helpers/FormHelpers";

const OrganizationSubscriptionTemplateListElement = (props) => {
    const templateData = props.value;

    return (
        <ElementBlock 
            directProps={{
                ...props,
                titleText: templateData.name
            }}>
            <h6>Посещений: <span className="badge text-dark bg-secondary">{`${templateData.totalValue}`}</span></h6>
            <h6>Срок действия (дн.): <span className="badge text-dark bg-secondary">{`${getDaysFormInputDurationFormatString(templateData.validityInterval)}`}</span></h6>
            <h6 hidden={!templateData.price}>Цена: <span className="badge text-dark bg-secondary">{`${templateData.price}`}</span></h6>
        </ElementBlock>
    );
}

export default OrganizationSubscriptionTemplateListElement;