const ElementsList = (props) => {
    let currentChildren = [];
    if (props.children){
        currentChildren = props.children.constructor.name == "Array"
            ? props.children
            : [ props.children ];
    }

    const spacing = props.spacing ?? 1;

    const renderList = () => {
        const listArray = [];
        const childrenCount = currentChildren.length;

        for (let i = 0; i < childrenCount; i++) {
            if (!currentChildren[i] || currentChildren[i].props.hidden){
                continue;
            }

            listArray.push(currentChildren[i]);

            let key = currentChildren[i].key;
            if (!key){
                key = i;
            }
            
            if (props.horizontal){
                listArray.push(<span key={`${key}-list-di-key`} className={`m-${spacing}`}/>);
            }
            else{
                if(props.includeSeparator){
                    listArray.push(<hr key={`${key}-list-hr-key`} className={`m-${spacing}`}/>);
                }
                else{
                    listArray.push(<div key={`${key}-list-di-key`} className={`m-${spacing}`}/>);
                }
            }
        }

        if (listArray.some(e => !!e)){
            listArray.pop();
        }

        return listArray; 
    }

    return (
        <div className={props.className}>
            {renderList()}
        </div>
    );
}

export default ElementsList;