import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

const RequireAuth = ({accountToken, children }) => {
    const userIsLogged = !!accountToken;
 
    if (!userIsLogged) {
       return <Navigate to={'/login'} />;
    }
    return children;
 };

 const mapStateToProps = (state) => {
   return {
       accountToken: state.account.token
   }
}

export default connect(mapStateToProps)(RequireAuth);