import React, { Component } from 'react';
import { connectInternal, withRouter } from '../../Helpers/SystemHelper';
import { Navigate } from 'react-router-dom';
import { validationFieldClass } from '../../Helpers/Constants';
import { handleInputChange, isFormStateValid } from '../../Helpers/FormHelpers';
import eventsService from '../../Services/EventsService';
import UserPicker from '../FormComponents/UserPicker';
import ElementsBase, { elementsBaseMenuClass } from '../Elements/ElementsBase';
import ElementsList from '../Elements/ElementsList';
import { validateEventTicketComment, validateEventTicketPrice } from '../../Helpers/Validations';

class EventTicketCreateUpdate extends Component {
    constructor(props){
        super(props);

        this.state = {
            isCreateState: this.props.router.params.templateId,
            isEditState: this.props.router.params.ticketId,
            isActionFinished: false,
            templateId: this.props.router.params.templateId,
            ticketId: this.props.router.params.ticketId,
            ticketAccountLink:{
                data: []
            },
            ticketComment:{
                validator: validateEventTicketComment,
                data: ''
            },
            ticketPrice:{
                validator: validateEventTicketPrice,
                data: ''
            }
        };

        this.loadData = this.loadData.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.isFormStateValid = isFormStateValid.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    async handleCancel(){
        await this.props.router.navigate(-1);
    }

    async handleSubmit(event){
        event.preventDefault();

        if (!this.isFormStateValid()){
            return;
        }

        this.setState({
            ...this.state,
            isLoadingState: true
        });

        let actionResult;
        let stateUpdate;
        if (this.state.isCreateState){
            actionResult = await this.props.processActionResponse(
                eventsService.createTicket(
                    {
                        templateId: this.state.templateId,
                        accountLink: this.state.ticketAccountLink.data[0],
                        comment: this.state.ticketComment.data ? this.state.ticketComment.data : null,
                        price: this.state.ticketPrice.data ? this.state.ticketPrice.data : null
                    },
                    this.props.accountToken), this.state);

            if (!actionResult.isError){
                await this.props.router.navigate(-1);
            }
            else{
                stateUpdate = {
                    ...actionResult.state,
                    isLoadingState: false
                };
            }
        }
        else if (this.state.isEditState){
            actionResult = await this.props.processActionResponse(
                eventsService.updateTicket(
                    {
                        ticketId: this.state.ticketId,
                        comment: this.state.ticketComment.data ? this.state.ticketComment.data : null,
                        price: this.state.ticketPrice.data ? this.state.ticketPrice.data : null
                    },
                    this.props.accountToken), this.state);
            
            if (!actionResult.isError){
                await this.props.router.navigate(-1);
            }
            else{
                stateUpdate = {
                    ...actionResult.state,
                    isLoadingState: false
                };
            }
        }

        this.setState(stateUpdate);
    }

    handleInputChange(event) {
        handleInputChange.call(this, [event.target]);
    }

    async loadData(){
        if (this.state.ticketId){
            const actionResult = await this.props.processActionResponse(
                eventsService.getTicket(this.state.ticketId, this.props.accountToken));

            if (!actionResult.isError){
                this.setState({
                    ...this.state,
                    ticketAccountLink:{
                        ...this.state.ticketAccountLink,
                        data: actionResult.data.account ? [actionResult.data.account] : []
                    },
                    ticketComment:{
                        ...this.state.ticketComment,
                        data: actionResult.data.comment ?? ''
                    },
                    ticketPrice:{
                        ...this.state.ticketPrice,
                        data: actionResult.data.price ?? ''
                    }
                });
            }
        }
        if (this.state.templateId){
            const actionResult = await this.props.processActionResponse(
                eventsService.getTicketTemplate(this.state.templateId, this.props.accountToken));

            if (!actionResult.isError){
                this.setState({
                    ...this.state,
                    ticketPrice:{
                        ...this.state.ticketPrice,
                        data: actionResult.data.price ?? ''
                    }
                });
            }
        }
    }

    render(){
        return (
            <ElementsBase
                titleText='Билет'>
                <div className={elementsBaseMenuClass}>
                    <button className="btn btn-outline-success border-0 me-2" type="button" onClick={this.handleSubmit} disabled={this.state.isLoadingState}>
                        <i className="bi bi-check-square" hidden={this.state.isLoadingState}/>
                        <span className="spinner-border spinner-border-sm" hidden={!this.state.isLoadingState}/>
                    </button>
                    <button className="btn btn-outline-warning border-0" type="button" onClick={this.handleCancel} disabled={this.state.isLoadingState}><i className="bi bi-x-square"/></button>
                </div>
                <ElementsList>
                    <div>
                        <UserPicker id="ticketAccountLink" label='Пользователь' className={`${validationFieldClass} border rounded`} onChange={this.handleInputChange} value={this.state.ticketAccountLink.data} isSingleSelect='true' disabled={this.state.isEditState}/>
                        <div className="invalid-feedback" htmlFor="ticketAccountLink">{this.state.ticketAccountLink.error}</div>
                    </div>
                    <div className="form-floating">
                        <textarea className="form-control h-25" id="ticketComment" rows="5" onChange={this.handleInputChange} value={this.state.ticketComment.data}></textarea>
                        <label htmlFor="ticketComment" className="form-label">Комментарий</label>
                        <div className="invalid-feedback" htmlFor="ticketComment">{this.state.ticketComment.error}</div>
                    </div>
                    <div className="form-floating">
                        <input id="ticketPrice" type="number" step='.01' className={`${validationFieldClass} form-control`} onChange={this.handleInputChange} value={this.state.ticketPrice.data}/>
                        <label htmlFor="ticketPrice" className="form-label">Цена</label>
                        <div className="invalid-feedback" htmlFor="ticketPrice">{this.state.ticketPrice.error}</div>
                    </div>
                </ElementsList>
            </ElementsBase>
        );
    }

    async componentDidMount(){
        await this.loadData();

        if (this.state.isEditState){
            document.title = "Изменение билета"; 
        }
        else{
            document.title = "Выдача билета"; 
        }
    }
}

const mapStateToProps = (state) => {
    return {
        accountToken: state.account.token
    }
}

export default connectInternal(mapStateToProps)(withRouter(EventTicketCreateUpdate));