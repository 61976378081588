import React, { Component } from 'react';
import { areArraysEqual } from '../../../Helpers/FormHelpers';
import { connectInternal } from '../../../Helpers/SystemHelper';
import { eventApplicationFilters } from '../../../Helpers/EventsApplicationFilteringHelper';

class ApplicationFilterPicker extends Component {
    checkboxPrefix = "app-f-cb-";
    checkboxClass = "app-f-cb-class";
    indeterminateClass = "set-pseudo-indeterminate";
    backupSelected = [];

    constructor(props){
        super(props);

        this.id = props.id;

        this.state = {
            specializationsSelected: props.value || [],
            specializationsSelectList:[],
            selectionState:[],
            expandedCollapseStates:{},
            valueUpdated: !!props.value
        }

        this.loadData = this.loadData.bind(this);
        this.handleOpenSelect = this.handleOpenSelect.bind(this);
        this.handleDismissSelected = this.handleDismissSelected.bind(this);
        this.handleUpdateSelected = this.handleUpdateSelected.bind(this);
        this.handleExpandClick = this.handleExpandClick.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    }

    async loadData(){
        const items = [];
        for(let [key, {id, label}] of Object.entries(eventApplicationFilters)){
            items.push({
                id: id,
                name: label,
            });
        }

        this.setState({
            ...this.state,
            specializationsSelectList: items,
            selectionState: items
        });
    }

    handleExpandClick(event){
        this.setState({
            ...this.state,
            expandedCollapseStates:{
                ...this.state.expandedCollapseStates,
                [event.currentTarget.value]: !this.state.expandedCollapseStates[event.currentTarget.value]
            }
        });
    }

    handleCheckboxChange(event){
        const selectionStateUpdate = this.getSelectionState(this.state.selectionState, event.target.id, event.target.checked);

        this.setState({
            ...this.state,
            selectionState: selectionStateUpdate
        });
    }

    getSelectionState(specializations, targetId, isChecked){
        let result = [];

        for(let specialization of specializations){
            let tempSingleResult = {
                ...specialization
            }

            const isTargetInput = `${this.checkboxPrefix}${specialization.id}` == targetId;

            const hasChildElements = specialization.childSpecializations && specialization.childSpecializations.some(s => !!s);

            if (isTargetInput){
                if (hasChildElements){
                    tempSingleResult = {
                        ...tempSingleResult,
                        childSpecializations: this.getChildrenState(specialization.childSpecializations, isChecked)
                    }
                }

                result = [
                    ...result,
                    {
                        ...tempSingleResult,
                        checked: isChecked,
                        indeterminate: isChecked ? false : tempSingleResult.indeterminate
                    }
                ];
            }
            else{
                if (hasChildElements){
                    tempSingleResult = {
                        ...tempSingleResult,
                        childSpecializations: this.getSelectionState(specialization.childSpecializations, targetId, isChecked)
                    };
                }

                let childResults = tempSingleResult.childSpecializations;

                result = [
                    ...result,
                    {
                        ...tempSingleResult,
                        checked: childResults && childResults.some(c => !!c) ?  childResults.every(c => c.checked) : tempSingleResult.checked,
                        indeterminate: childResults && childResults.some(c => !!c) ? (childResults.some(c => c.checked) && childResults.some(c => !c.checked)) || childResults.some(c => c.indeterminate) : false
                    }
                ];
            }
        }

        return result;
    }

    getChildrenState(specializations, checked){
        let result = [];
        for(let specialization of specializations){
            let tempSingleResult = {
                ...specialization,
                checked: checked,
                indeterminate: checked ? false : specialization.indeterminate
            };

            if (specialization.childSpecializations && specialization.childSpecializations.some(s => !!s)){
                tempSingleResult = {
                    ...tempSingleResult,
                    childSpecializations: this.getChildrenState(specialization.childSpecializations, checked)
                };
            }

            result = [
                ...result,
                tempSingleResult
            ];
        }

        return result;
    }

    handleOpenSelect(event){
        this.backupSelected = this.state.selectionState;

        if (this.state.valueUpdated){
            let selectionStateUpdate = this.state.selectionState;

            for (let spec of this.state.specializationsSelected){
                selectionStateUpdate = this.getSelectionState(selectionStateUpdate, `${this.checkboxPrefix}${spec.id}`, true);
            }

            this.backupSelected = selectionStateUpdate;
            this.setState({
                ...this.state,
                selectionState: selectionStateUpdate,
                valueUpdated: false
            });
        }
    }

    handleDismissSelected(event){
        this.setState({
            ...this.state,
            selectionState: this.backupSelected
        });
    }

    handleUpdateSelected(event){
        this.value = this.getSelectedElements(this.state.selectionState);

        this.setState({
            ...this.state,
            specializationsSelected: this.value
        });

        this.props.onChange({target: this});
    }

    getSelectedElements(specializations){
        let result = [];

        for(let specialization of specializations){
            if (specialization.childSpecializations && specialization.childSpecializations.some(s => !!s)){
                result = [
                    ...result,
                    ...this.getSelectedElements(specialization.childSpecializations)
                ];
            }
            else if (specialization.checked){
                result = [
                    ...result,
                    specialization
                ];
            }
        }

        return result
    }

    renderSelectedList(){
        if (!this.state.specializationsSelected){
            return <></>;
        }

        return this.getSpecializaitonsSelectedArray(this.state.specializationsSelected);
    }

    getSpecializaitonsSelectedArray(specializations, iteration = 0){
        const elementsArray = [];

        for(let specialization of specializations){
            elementsArray.push(
                <div key={`selected-item-${specialization.id}`} className={`form-check ms-${iteration*2}`}>
                    <label className="form-check-label" htmlFor="flexCheckDefault">
                        {specialization.name}
                    </label>
                </div>
            );
        }

        return elementsArray;
    }

    renderSelectList(){
        if (!this.state.selectionState){
            return <></>;
        }

        return this.getSpecializaitonsCheckboxesArray(this.state.selectionState);
    }

    getSpecializaitonsCheckboxesArray(specializations, iteration = 0){
        const elementsArray = [];
        let counterInternal = 0;

        for(let specialization of specializations){
            const hasChildElements = specialization.childSpecializations && specialization.childSpecializations.some(s => !!s);
            const iterationKey = `${iteration}-${counterInternal}`;
            const collapseId = `spec-picker-collapse-${iterationKey}`;

            let expandButtonElement = <></>;

            if(hasChildElements){
                expandButtonElement = (
                    <button className='btn btn-link p-0 me-1' value={collapseId} onClick={this.handleExpandClick} type="button" data-bs-toggle="collapse" data-bs-target={`#${collapseId}`} aria-expanded="false" aria-controls={collapseId}>
                        {this.state.expandedCollapseStates[collapseId] ? <i className="bi bi-caret-down"/> : <i className="bi bi-caret-right"/>}
                    </button>
                );
            }

            elementsArray.push(
                <div key={iterationKey} className={`form-check ms-${iteration*2}`}>
                    {expandButtonElement}
                    <input id={`${this.checkboxPrefix}${specialization.id}`} className={`form-check-input ${this.checkboxClass} ${!!specialization.indeterminate ? this.indeterminateClass : ''}`} type="checkbox" onChange={this.handleCheckboxChange} checked={!!specialization.checked}/>
                    <label className="form-check-label text-reset" htmlFor="flexCheckDefault">
                        {specialization.name}
                    </label>
                </div>
            );

            if (hasChildElements){
                elementsArray.push(
                    <div key={`coll-${iterationKey}`} className='collapse' id={collapseId} >
                        {this.getSpecializaitonsCheckboxesArray(specialization.childSpecializations, iteration + 1)}
                    </div>
                );
            }

            counterInternal++;
        }

        return elementsArray;
    }

    isAnySelected(elements, specId){        
        for(let element of elements){
            if (element.id == specId){
                return true;
            }

            if (element.childSpecializations && element.childSpecializations.some(s => !!s)){
                if (this.isAnySelected(element.childSpecializations)){
                    return true;
                }
            }
        }

        return false;
    }

    render(){
        return (
            <div id={this.props.id}>
                <div className="container d-flex justify-content-between form-floating p-3 bg-light border rounded">
                    <input id="ghostSpecsPickerDiv" className='form-control' hidden/>
                    <label htmlFor="ghostSpecsPickerDiv" className='form-label'>{this.props.label}</label>
                    <div className='mt-3'>
                        {this.renderSelectedList()}
                    </div>
                    <div>
                        <button type="button" className="btn btn-outline-primary mt-3" onClick={this.handleOpenSelect} data-bs-toggle="modal" data-bs-target="#applicationFiltersPickerModal" disabled={this.props.disabled}>
                            <i className="bi bi-pencil-square"/>
                        </button>
                    </div>
                    <div className="modal fade" id="applicationFiltersPickerModal" tabIndex="-1" aria-labelledby="applicationFiltersPickerModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-body">
                                    {this.renderSelectList()}
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-outline-warning" onClick={this.handleDismissSelected} data-bs-dismiss="modal">Закрыть</button>
                                    <button type="button" className="btn btn-outline-success" onClick={this.handleUpdateSelected} data-bs-dismiss="modal">ОК</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    async componentDidUpdate(){
        const checkboxes = document.getElementsByClassName(this.checkboxClass);

        for (let checkbox of checkboxes){
            checkbox.indeterminate = checkbox.classList.contains(this.indeterminateClass);
        }
    }

    async componentDidMount(){
        const wrappingDiv = document.getElementById(this.props.id);
        wrappingDiv.className = this.props.className;

        this.className = wrappingDiv.className;
        this.classList = wrappingDiv.classList;

        await this.loadData();
    }

    static getDerivedStateFromProps(props, state){
        if (!areArraysEqual(props.value || [], state.specializationsSelected || [], (v, s) => v.id == s.id)) {
            return ({ 
                ...state,
                specializationsSelected: [...props.value],
                valueUpdated: true
            })
        }

        return null;
    }
}

const mapStateToProps = (state) => {
    return {
        accountToken: state.account.token
    }
}

export default connectInternal(mapStateToProps)(ApplicationFilterPicker);