import React, { Component } from 'react';
import organizationsService from '../../Services/OrganizationsService';
import { connectInternal, withRouter } from '../../Helpers/SystemHelper';
import { validationFieldClass } from '../../Helpers/Constants';
import { getFormInputDateFormatString, getNodaDateTimeFormatString, handleInputChange, isFormStateValid } from '../../Helpers/FormHelpers';
import UserPicker from '../FormComponents/UserPicker';
import CitiesPicker from '../FormComponents/CitiesPicker/CitiesPicker';
import { validateOrganizationOwnersAccounts, validateOrganizationName, validateOrganizationCity, validateSystemSubscriptionId, validateSubscriptionExpirationDate } from '../../Helpers/Validations';
import ElementsBase, { elementsBaseMenuClass } from '../Elements/ElementsBase';
import ElementsList from '../Elements/ElementsList';

class OrganizationCreateForm extends Component {
    constructor(props){
        super(props);

        this.state = {
            isEditState: !!this.props.router.params.organizationId,
            organizationName:{
                validator: validateOrganizationName,
                data: ''
            },
            ownersAccountsLinks:{
                validator: validateOrganizationOwnersAccounts,
                data: ''
            },
            city:{
                validator: validateOrganizationCity,
                data: []
            },
            systemSubscriptionId:{
                validator: validateSystemSubscriptionId,
                data: ''
            },
            subscriptionExpirationDate:{
                validator: validateSubscriptionExpirationDate,
                data: ''
            }
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.loadData = this.loadData.bind(this);
        this.handleCancell = this.handleCancell.bind(this);
    }

    async handleCancell(event) {
        await this.props.router.navigate(`/admin/organizations`, { replace: true });
    }

    handleInputChange(event) {
        handleInputChange.call(this, [event.target]);
    }

    async loadData(){
        if (!this.props.router.params.organizationId){
            return;
        }

        const actionResult = await this.props.processActionResponse(
            organizationsService.getOrganizationAdminDetails(this.props.router.params.organizationId, this.props.accountToken));

        if (!actionResult.isError){
            this.setState({
                ...this.state,
                organizationName:{
                    ...this.state.organizationName,
                    data: actionResult.data.name
                },
                city:{
                    ...this.state.city,
                    data: [actionResult.data.city]
                },
                systemSubscriptionId:{
                    ...this.state.systemSubscriptionId,
                    data: actionResult.data.systemSubscriptionTypeId
                },
                subscriptionExpirationDate:{
                    ...this.state.subscriptionExpirationDate,
                    data: !!actionResult.data.subscriptionExpirationDate ? getFormInputDateFormatString(new Date(actionResult.data.subscriptionExpirationDate)) : ''
                }
            });
        }
    }

    async handleSubmit(event) {
        event.preventDefault();

        if (!isFormStateValid.call(this)){
            return;
        }

        if (this.state.isEditState){
            const updateRequest = {
                id: this.props.router.params.organizationId,
                name: this.state.organizationName.data,
                cityId: this.state.city.data[0].id,
                systemSubscriptionTypeId: this.state.systemSubscriptionId.data,
                subscriptionExpirationDate: !this.state.subscriptionExpirationDate.data ? null : getNodaDateTimeFormatString(new Date(this.state.subscriptionExpirationDate.data))
            }
    
            const actionResult = await this.props.processActionResponse(
                organizationsService.adminUpdateOrganization(updateRequest, this.props.accountToken));

            if (!actionResult.isError){
                await this.props.router.navigate(`/admin/organizations`, { replace: true });
            }
        }
        else{
            const createRequest = {
                name: this.state.organizationName.data,
                cityId: this.state.city.data[0].id,
                systemSubscriptionTypeId: this.state.systemSubscriptionId.data,
                subscriptionExpirationDate: !this.state.subscriptionExpirationDate.data ? null : getNodaDateTimeFormatString(new Date(this.state.subscriptionExpirationDate.data)),
                ownersAccountsLinks: this.state.ownersAccountsLinks.data.map(l => {
                    return { 
                        code: l.code,
                        id: l.id
                    }
                })
            }
    
            const actionResult = await this.props.processActionResponse(
                organizationsService.createOrganization(createRequest, this.props.accountToken));
    
            if (!actionResult.isError){
                await this.props.router.navigate(`/admin/organizations`, { replace: true });
            }
        }
    }

    render(){
        return (
            <ElementsBase>
                <div className={elementsBaseMenuClass}>
                    <button className="btn btn-outline-success border-0 me-2" type="button" onClick={this.handleSubmit} disabled={this.state.isFormLoading}>
                        <span className="sr-only" hidden={this.state.isFormLoading}><i className="bi bi-check-square"/></span>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" hidden={!this.state.isFormLoading}/>
                    </button>
                    <button className="btn btn-outline-warning border-0" type="button" onClick={this.handleCancell}><i className="bi bi-x-square"/></button>
                </div>
                <ElementsList>
                    <div className="form-floating">
                        <input id="organizationName" type="text" value={this.state.organizationName.data} className={`${validationFieldClass} form-control`} onChange={this.handleInputChange} disabled={this.state.isFormLoading}/>
                        <label htmlFor="organizationName" className='form-label'>Название</label>
                        <div className="invalid-feedback" htmlFor="organizationName">{this.state.organizationName.error}</div>
                    </div>
                    <div>
                        <UserPicker id="ownersAccountsLinks" label='Владельцы' className={`${validationFieldClass} mb-3 border rounded`} onChange={this.handleInputChange} hidden={this.state.isEditState}/>
                        <div className="invalid-feedback" htmlFor="ownersAccountsLinks">{this.state.ownersAccountsLinks.error}</div>
                    </div>
                    <div>
                        <CitiesPicker id="city" label='Город' singleSelect value={this.state.city.data} className={`${validationFieldClass} mb-3 border rounded`} onChange={this.handleInputChange}/>
                        <div className="invalid-feedback" htmlFor="city">{this.state.city.error}</div>
                    </div>
                    <div className="form-floating">
                        <input id="systemSubscriptionId" type="number" className={`${validationFieldClass} form-control`} onChange={this.handleInputChange} value={this.state.systemSubscriptionId.data}/>
                        <label htmlFor="systemSubscriptionId" className='form-label'>ID подписки</label>
                        <div className="invalid-feedback" htmlFor="systemSubscriptionId">{this.state.systemSubscriptionId.error}</div>
                    </div>
                    <div className="form-floating">
                        <input id="subscriptionExpirationDate" type="date" className={`${validationFieldClass} form-control`} onChange={this.handleInputChange} value={this.state.subscriptionExpirationDate.data}/>
                        <label htmlFor="subscriptionExpirationDate" className='form-label'>Дата окончания действия подписки</label>
                        <div className="invalid-feedback" htmlFor="subscriptionExpirationDate">{this.state.subscriptionExpirationDate.error}</div>
                    </div>
                </ElementsList>
            </ElementsBase>
        )
    }

    async componentDidMount(){
        await this.loadData();

        document.title = "Создание организации"; 
    }
}

const mapStateToProps = (state) => {
    return {
        accountToken: state.account.token
    }
}

export default connectInternal(mapStateToProps)(withRouter(OrganizationCreateForm));