import React, { Component } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { withParams, connectInternal } from '../../Helpers/SystemHelper';
import eventsService from '../../Services/EventsService';
import organizationsService from '../../Services/OrganizationsService';
import QrScanner from '../FormComponents/QrScanner';
import { getDurationDateTimeFormatString, handleInputChange, getLocalDateTimeOffset, isFormStateValid } from '../../Helpers/FormHelpers';
import InfoBlock from '../Elements/InfoBlock';
import OrganizationSubscriptionListElement from '../Elements/OrganizationSubscriptionListElement';
import EventTicketListElement from '../Elements/EventTicketListElement';
import ElementBlock, { elementBlockTitleClass, elementBlockMenuClass } from '../Elements/ElementBlock';
import ElementsBase from '../Elements/ElementsBase';
import { validateEventCustomAttendanceComment, validateEventCustomAttendancePrice, validateEventCopyAmount, validateEventCopyPeriod } from '../../Helpers/Validations';
import { validationFieldClass } from '../../Helpers/Constants';
import { getLabelById as getStatusLabel } from '../../Helpers/EventStatusHelper';
import BeautyTextBlock from '../Elements/BeautyTextBlock';
import ElementsList from '../Elements/ElementsList';
import OrganizationHeader from '../OrganizationHeader';
import OrganizationsGroupsPicker from '../FormComponents/OrganizationsGroupsPicker';

class EventApplications extends Component {
    targetApplicationAccountId = '';

    constructor(props){
        super(props);

        this.setMarkAttendanceModalRef = element => {
            if (element){
                element.addEventListener('hidden.bs.modal', this.onAttendanceModalHide);
            }
        };
        this.setApproveAttendanceModalRef = element => {
            if (element){
                element.addEventListener('hidden.bs.modal', this.handleAttendanceApproveModalClose);
            }
        };
        this.setAttendanceResultModalRef = element => {
            if (element){
                element.addEventListener('hidden.bs.modal', this.handleAttendanceResultModalClose);
            }
        };

        this.state = {
            eventData:null,
            organizationId: null,
            attendanceData: {
                showSelection: true,
                scanQrCode: false,
            },
            ticketsFiterName:{
                data: ''
            },
            ticketsFiterComment:{
                data: ''
            },
            subscriptionsFiterName:{
                data: ''
            },
            subscriptionsFiterComment:{
                data: ''
            },
            customAttendanceComment:{
                validator: validateEventCustomAttendanceComment,
                data: ''
            },
            customAttendancePrice:{
                validator: validateEventCustomAttendancePrice,
                data: ''
            },
            subscriptionAttendanceComment:{
                data: ''
            },
            subsOrganizationGroupFilter:{
                data: ''
            }
        };

        this.onAttendanceModalHide = this.onAttendanceModalHide.bind(this);
        this.getAttendanceResetState = this.getAttendanceResetState.bind(this);
        this.handleAttendanceApproveModalClose = this.handleAttendanceApproveModalClose.bind(this);
        this.getAttendanceApproveResetState = this.getAttendanceApproveResetState.bind(this);
        this.handleQrScanCompleted = this.handleQrScanCompleted.bind(this);
        this.onQrScanShow = this.onQrScanShow.bind(this);
        this.onSelectSubscriptionShow = this.onSelectSubscriptionShow.bind(this);
        this.onSelectTicketShow = this.onSelectTicketShow.bind(this);
        this.handleSelectCustomAttendance = this.handleSelectCustomAttendance.bind(this);
        this.handleSelectSubscriptionAttendance = this.handleSelectSubscriptionAttendance.bind(this);
        this.handleSelectTicketAttendance = this.handleSelectTicketAttendance.bind(this);
        this.onSelectSubscriptionOrganizationShow = this.onSelectSubscriptionOrganizationShow.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.onSelectSubscriptionUpdate = this.onSelectSubscriptionUpdate.bind(this);
        this.handleSubscriptionListSelect = this.handleSubscriptionListSelect.bind(this);
        this.onSelectTicketsUpdate = this.onSelectTicketsUpdate.bind(this);
        this.handleTicketListSelect = this.handleTicketListSelect.bind(this);
        this.handleApproveMarkAttendance = this.handleApproveMarkAttendance.bind(this);
        this.handleMarkSubscriptionAttendance = this.handleMarkSubscriptionAttendance.bind(this);
        this.handleMarkTicketAttendance = this.handleMarkTicketAttendance.bind(this);
        this.loadAccountAttendanceVariants = this.loadAccountAttendanceVariants.bind(this);
        this.loadData = this.loadData.bind(this);
        this.onAttendanceModalHide = this.onAttendanceModalHide.bind(this);
        this.handleAttendanceApproveModalClose = this.handleAttendanceApproveModalClose.bind(this);
        this.handleAttendanceResultModalClose = this.handleAttendanceResultModalClose.bind(this);
        this.setSelectSubscriptionOrganizationShow = this.setSelectSubscriptionOrganizationShow.bind(this);
    }
    
    async loadAccountAttendanceVariants({ accountCode, subscriptionCode, ticketCode }){
        let stateUpdate = {
            ...this.state,
            attendanceData: {
                ...this.getAttendanceResetState(),
                accountVariants: {}
            }
        };

        if (this.state.eventData && accountCode){
            const actionResult = await this.props.processActionResponse(
                eventsService.getAccountEventAttendanceVariants(
                    { 
                        eventId: this.state.eventData.id, 
                        accountLink: {
                            code: accountCode
                        }
                    },
                    this.props.accountToken
                ));
            
            if (!actionResult.isError){
                stateUpdate = {
                    ...this.state,
                    attendanceData: {
                        ...this.getAttendanceResetState(),
                        accountCode: accountCode,
                        accountVariants: actionResult.data
                    }
                };
            }
        }
        if (this.state.organizationId && accountCode){
            const actionResult = await this.props.processActionResponse(
                organizationsService.getAttendanceVariants(
                    { 
                        organizationId: this.state.organizationId, 
                        accountLink: {
                            code: accountCode
                        }
                    },
                    this.props.accountToken
                ));
            
            if (!actionResult.isError){
                stateUpdate = {
                    ...this.state,
                    attendanceData: {
                        ...this.getAttendanceResetState(),
                        accountCode: accountCode,
                        accountVariants: actionResult.data
                    }
                };
            }
        }
        else if (subscriptionCode 
            && (!this.state.eventData || this.state.eventData.attendanceFiltersIds.some(f => f == 1))){
            const actionResult = await this.props.processActionResponse(
                organizationsService.getSubscriptionByCode(
                    subscriptionCode,
                    this.props.accountToken
                ));
            
            if (!actionResult.isError){
                stateUpdate = {
                    ...this.state,
                    attendanceData: {
                        ...this.getAttendanceResetState(),
                        accountVariants: {
                            subscriptions: [ actionResult.data ]
                        }
                    }
                };
            }
        }
        else if (ticketCode 
            && this.state.eventData
            && this.state.eventData.attendanceFiltersIds.some(f => f == 2)){
            const actionResult = await this.props.processActionResponse(
                eventsService.getTicketByCode(
                    ticketCode,
                    this.props.accountToken
                ));

            const tickets = [];
            
            if (!actionResult.isError){
                const ticketData = actionResult.data;
                const eventAttData = ticketData.events.find(e => e.id == this.props.params.eventId);

                if (eventAttData && !eventAttData.isVisited){
                    tickets.push(ticketData);
                }
                
                stateUpdate = {
                    ...this.state,
                    attendanceData: {
                        ...this.getAttendanceResetState(),
                        accountVariants: {
                            tickets: tickets
                        }
                    }
                };
            }
        }

        this.setState(stateUpdate);
    }

    async handleMarkCustomAttendance(accountCode, comment, price){
        const actionResult = await this.props.processActionResponse(
            eventsService.markAttendance(
                { 
                    eventId: this.state.eventData.id, 
                    accountLink: !!accountCode ? { code: accountCode } : null,
                    comment: !!comment ? comment : null,
                    price: !!price ? price : null,
                },
                this.props.accountToken
            ));
        
        if (!actionResult.isError){
            this.setState({
                ...this.state,
                customMarked: true
            });
        }
        else{
            this.setState({
                ...this.state,
                attendanceFailed: true
            });
        }
    }

    async handleMarkTicketAttendance(ticketId){
        const actionResult = await this.props.processActionResponse(
            eventsService.markAttendance(
                { 
                    eventId: this.state.eventData.id, 
                    ticketId: ticketId
                },
                this.props.accountToken
            ));
        
        if (!actionResult.isError){
            this.setState({
                ...this.state,
                ticketMarked: true
            });
        }
        else{
            this.setState({
                ...this.state,
                attendanceFailed: true
            });
        }
    }

    async handleMarkSubscriptionAttendance(subscriptionId){
        let actionResult;
        if (this.state.eventData){
            actionResult = await this.props.processActionResponse(
            eventsService.markAttendance(
                { 
                    eventId: this.state.eventData.id, 
                    subscriptionId: subscriptionId
                },
                this.props.accountToken
            ));
        }
        if (this.state.organizationId){
            actionResult = await this.props.processActionResponse(
            organizationsService.markSubscriptionUsage(
                {
                    subscriptionId: subscriptionId,
                    usageComment: this.state.subscriptionAttendanceComment.data ? this.state.subscriptionAttendanceComment.data : null
                },
                this.props.accountToken
            ));
        }
        
        if (!actionResult.isError){
            this.setState({
                ...this.state,
                subscriptionMarked: true
            });
        }
        else{
            this.setState({
                ...this.state,
                attendanceFailed: true
            });
        }
    }

    async handleApproveMarkAttendance(){
        if (this.state.subscriptionToMark){
            const subscriptionId = this.state.subscriptionToMark.id;
            this.setState({
                ...this.state,
                subscriptionToMark: ''
            });
            await this.handleMarkSubscriptionAttendance(subscriptionId);
        }
        if (this.state.ticketToMark){
            const ticketId = this.state.ticketToMark.id;
            this.setState({
                ...this.state,
                ticketToMark: ''
            });
            await this.handleMarkTicketAttendance(ticketId);
        }
        if (this.state.customAttendanceMark){
            const accountCode = this.state.customAttendanceAccountCode;
            const comment = this.state.customAttendanceComment.data;
            const price = this.state.customAttendancePrice.data;
            this.setState({
                ...this.state,
                customAttendanceMark: false,
                customAttendanceAccountCode: ''
            });
            await this.handleMarkCustomAttendance(accountCode, comment, price);
        }
    }

    handleTicketListSelect(event){
        const ticketData = this.state.attendanceData.ticketsList.find(t => t.id == event.currentTarget.value);
        
        this.setState({
            ...this.state,
            attendanceData: {
                ...this.getAttendanceResetState(),
                accountVariants: {
                    tickets: [ ticketData ]
                }
            }
        });
    }

    async onSelectTicketsUpdate(){
        const actionResult = await this.props.processActionResponse(
            eventsService.getEventTickets(
                {
                    eventId: this.state.eventData.id,
                    isDeactivated: false,
                    accountName: this.state.ticketsFiterName.data ? this.state.ticketsFiterName.data : null,
                    comment: this.state.ticketsFiterComment.data ? this.state.ticketsFiterComment.data : null,
                    excludeAttended: true,
                    isActive: true,
                    pagingFilter:{
                        entriesCount: 10,
                        pageNumber: 1
                    }
                }, 
                this.props.accountToken));

        if (!actionResult.isError){
            this.setState({
                ...this.state,
                attendanceData: {
                    ...this.getAttendanceResetState(),
                    ticketsList: actionResult.data.items,
                    selectTicketsFromList: true
                }
            });
        };
    }

    handleSubscriptionListSelect(event){
        const subscriptionData = this.state.attendanceData.subscriptionsList.find(s => s.id == event.currentTarget.value);
        
        this.setState({
            ...this.state,
            attendanceData: {
                ...this.getAttendanceResetState(),
                accountVariants: {
                    subscriptions: [ subscriptionData ]
                }
            }
        });
    }

    async onSelectSubscriptionUpdate(){
        const organizationId = this.state.attendanceData.subscriptionsOrganizationId;

        const actionResult = await this.props.processActionResponse(
            organizationsService.getSubscriptions(
                {
                    organizationId: organizationId,
                    isActive: true,
                    accountName: this.state.subscriptionsFiterName.data ? this.state.subscriptionsFiterName.data : null,
                    commentText: this.state.subscriptionsFiterComment.data ? this.state.subscriptionsFiterComment.data : null,
                    organizationGroupId: this.state.subsOrganizationGroupFilter.data && this.state.subsOrganizationGroupFilter.data.some(i => !!i) ? this.state.subsOrganizationGroupFilter.data[0].id : null,
                    excludeAttendedEventId: this.state.eventData ? this.state.eventData.id : null,
                    pagingFilter:{
                        entriesCount: 10,
                        pageNumber: 1
                    }
                }, 
                this.props.accountToken));

        if (!actionResult.isError){
            this.setState({
                ...this.state,
                attendanceData: {
                    ...this.getAttendanceResetState(),
                    subscriptionsList: actionResult.data.items,
                    subscriptionsListActive: true,
                    subscriptionsOrganizationId: organizationId
                }
            });
        };
    }

    handleInputChange(event) {
        handleInputChange.call(this, [event.target]);
    }

    onSelectSubscriptionOrganizationShow(event){
        const organizationId = event.currentTarget.value;

        this.setSelectSubscriptionOrganizationShow(organizationId);
    }

    setSelectSubscriptionOrganizationShow(organizationId){
        this.setState({
            ...this.state,
            attendanceData: {
                ...this.getAttendanceResetState(),
                subscriptionsListActive: true,
                subscriptionsOrganizationId: organizationId
            }
        });
    }

    handleSelectTicketAttendance(event){
        this.setState({
            ...this.getAttendanceApproveResetState(),
            ticketToMark: this.state.attendanceData.accountVariants.tickets.find(t => t.id == event.currentTarget.value)
        });
    }

    handleSelectSubscriptionAttendance(event){
        this.setState({
            ...this.getAttendanceApproveResetState(),
            subscriptionToMark: this.state.attendanceData.accountVariants.subscriptions.find(s => s.id == event.currentTarget.value)
        });
    }

    handleSelectCustomAttendance(event){
        this.setState({
            ...this.getAttendanceApproveResetState(),
            customAttendanceMark: true,
            customAttendanceAccountCode: event.currentTarget.value ?? '',
            customAttendanceComment:{
                ...this.state.customAttendanceComment,
                error: !event.currentTarget.value ? '1' : '',
                parameterValue: event.currentTarget.value ?? ''
            }
        });
    }

    onSelectTicketShow(){
        this.setState({
            ...this.state,
            attendanceData: {
                ...this.getAttendanceResetState(),
                selectTicketsFromList: true
            }
        });
    }

    onSelectSubscriptionShow(){
        if ((this.state.eventData && this.state.eventData.ownersOrganizations.length == 1)){
            this.setSelectSubscriptionOrganizationShow(this.state.eventData.ownersOrganizations[0].id);
        }
        else if (this.state.eventData){
            this.setState({
                ...this.state,
                attendanceData: {
                    ...this.getAttendanceResetState(),
                    selectSubscriptionFromList: true
                }
            });
        }
        else if (this.state.organizationId){
            this.setSelectSubscriptionOrganizationShow(this.state.organizationId);
        }
    }

    onQrScanShow(){
        this.setState({
            ...this.state,
            attendanceData: {
                ...this.getAttendanceResetState(),
                scanQrCode: true
            }
        });
    }

    onAttendanceModalHide(){
        this.setState({
            ...this.state,
            attendanceData: {
                ...this.state.attendanceData,
                ...this.getAttendanceResetState(),
                showSelection: true
            }
        });
    }

    handleAttendanceApproveModalClose(){
        this.setState(this.getAttendanceApproveResetState());
    }

    handleAttendanceResultModalClose(event){
        this.setState({
            ...this.state,
            ticketMarked: false,
            subscriptionMarked: false,
            attendanceFailed: false,
            customMarked: false
        });
    }

    async handleQrScanCompleted(event){
        this.setState({
            ...this.state,
            attendanceData: this.getAttendanceResetState()
        });

        const scanResult = event.target.value;

        if (scanResult.abb === 'acc'){
            await this.loadAccountAttendanceVariants({accountCode: scanResult.code});
        }
        if (scanResult.abb === 'suc'){
            await this.loadAccountAttendanceVariants({subscriptionCode: scanResult.code});
        }
        if (scanResult.abb === 'tic'){
            await this.loadAccountAttendanceVariants({ticketCode: scanResult.code});
        }
    }

    getAttendanceResetState(){
        return {
            ...this.state.attendanceData,
            showSelection: false,
            scanQrCode: false,
            selectSubscriptionFromList: false,
            accountCode: '',
            accountVariants: '',
            subscriptionMarked: false,
            ticketMarked: false,
            subscriptionsList: [],
            subscriptionsListActive: false,
            subscriptionsOrganizationId: '',
            selectTicketsFromList: false,
            ticketsList: []
        }
    }

    getAttendanceApproveResetState(){
        return {
            ...this.state,
            ticketToMark: '',
            subscriptionToMark: '',
            customAttendanceMark: false,
            customAttendanceAccountCode: '',
            customAttendanceComment: {
                ...this.state.customAttendanceComment,
                data: '',
                error: '',
                parameterValue: ''
            }
        }
    }

    render(){
        if ((!this.state.eventData && !this.state.organizationId) || !this.state.orr){
            return <></>;
        }

        return(
            <div>
                {this.renderMarkAttendanceModal()}
                {this.renderApproveAttendanceModal()}
                {this.renderAttendanceResultModal()}
            </div>
        )
    }

    renderMarkAttendanceModal(){
        return (
            <div className="modal fade" ref={this.setMarkAttendanceModalRef} id={`${this.props.id}-init-modal`} tabIndex="-1" aria-labelledby={`${this.props.id}-init-modal`} aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            {this.renderMarkAttendanceInitialSelect()}
                            {this.renderAccountAttendanceVariants()}
                            {this.renderAccountAttendanceSubscriptions()}
                            {this.renderAccountAttendanceTickets()}
                            <QrScanner className="mb-3" onScanCompleted={this.handleQrScanCompleted} hidden={!this.state.attendanceData.scanQrCode}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderMarkAttendanceInitialSelect(){
        const showSubscriptionsListButton = 
            this.state.orr.hasRight('MarkSubscriptionUsage') && this.state.orr.hasRight('ViewSubscriptions')
            && (!this.state.eventData || this.state.eventData.attendanceFiltersIds.some(f => f == 1));
        const showTicketsListButton = 
            !!this.state.eventData
            && this.state.orr.hasRight('MarkTicketUsage') && this.state.orr.hasRight('ViewTickets')
            && (this.state.eventData && this.state.eventData.attendanceFiltersIds.some(f => f == 2));
        const showCustomAttendanceButton = 
            !!this.state.eventData
            && this.state.orr.hasRight('MarkCustomAttendance')
            && (this.state.eventData
                && this.state.eventData.attendanceFiltersIds.some(f => f == 3)
                && !this.state.eventData.requiresApplication);

        if (!this.state.attendanceData.showSelection){
            return <></>;
        }

        return (
            <ElementsList className="w-100">
                <button className="btn btn-primary mb-2 w-100" type="button" onClick={this.onQrScanShow}>
                    <i className="bi bi-qr-code-scan"/> Сканировать QR код
                </button>
                <button className="btn btn-primary mb-2 w-100" type="button" onClick={this.onSelectSubscriptionShow} hidden={!showSubscriptionsListButton}>
                    <i className="bi bi-qr-code-scan"/> Выбрать абонемент
                </button>
                <button className="btn btn-primary mb-2 w-100" type="button" onClick={this.onSelectTicketShow} hidden={!showTicketsListButton}>
                    <i className="bi bi-qr-code-scan"/> Выбрать билет
                </button>
                <button className="btn btn-primary w-100" type="button" onClick={this.handleSelectCustomAttendance} hidden={!showCustomAttendanceButton} data-bs-toggle="modal" data-bs-target="#approveAttendanceModal">
                    <i className="bi bi-qr-code-scan"/> Разовое посещение
                </button>
            </ElementsList>
        );
    }

    renderAccountAttendanceVariants(){
        if (!this.state.attendanceData.accountVariants){
            return <></>;
        }
        const accountHasSubscriptionsAvailable = this.state.attendanceData.accountVariants.subscriptions && this.state.attendanceData.accountVariants.subscriptions.some(s => !!s);
        const accountHasTicketsAvailable = this.state.attendanceData.accountVariants.tickets && this.state.attendanceData.accountVariants.tickets.some(s => !!s);

        if (!accountHasSubscriptionsAvailable
            && !accountHasTicketsAvailable
            && !this.state.attendanceData.accountVariants.hasDirectAccess){
            return <div className='text-reset'>Доступные варианты посещения не найдены.</div>
        }

        const resultElements = [];
        if (accountHasSubscriptionsAvailable){
            resultElements.push(
                <ElementsBase titleText='Абонементы'>
                    {this.state.attendanceData.accountVariants.subscriptions.map(s =>
                        <OrganizationSubscriptionListElement 
                            key={`sel-sub-${s.id}`} 
                            value={s} 
                            titleButton='Button' 
                            buttonValue={s.id}
                            buttonClick={this.handleSelectSubscriptionAttendance}
                            buttonIcon='bi bi-check2-square'
                            buttonDataBsToggle='modal'
                            buttonDataBsTarget='#approveAttendanceModal' />
                    )}
                </ElementsBase>);
        }
        if (accountHasTicketsAvailable){
            resultElements.push(
                <ElementsBase titleText='Билеты'>
                    {this.state.attendanceData.accountVariants.tickets.map(t =>
                        <EventTicketListElement 
                            key={`sel-tic-${t.id}`} 
                            value={t} 
                            titleButton='Button' 
                            buttonValue={t.id}
                            buttonClick={this.handleSelectTicketAttendance}
                            buttonIcon='bi bi-check2-square'
                            buttonDataBsToggle='modal'
                            buttonDataBsTarget='#approveAttendanceModal' />
                    )}
                </ElementsBase>);
        }
        if (this.state.attendanceData.accountVariants.hasDirectAccess){
            resultElements.push(
                <div key='dir-ac-but'>
                    <button className="btn btn-primary me-2 w-100" value={this.state.attendanceData.accountCode} onClick={this.handleSelectCustomAttendance} data-bs-toggle='modal' data-bs-target='#approveAttendanceModal'>
                        Разовое посещение
                    </button>
                </div>);
        }

        return (
            <ElementsList>
                {resultElements}
            </ElementsList>
        );
    }

    renderAccountAttendanceSubscriptions(){
        if (this.state.attendanceData.selectSubscriptionFromList){
            return (
                <div>
                    <h6>Выберите организацию:</h6>
                    <div className="btn-group-vertical w-100">
                        {this.state.eventData.ownersOrganizations.map(o => <button key={`sel-btn-${o.id}`} className='btn btn-primary mb-2' onClick={this.onSelectSubscriptionOrganizationShow} value={o.id}>{o.name}</button>)}
                    </div>
                </div>
            );
        }

        if (this.state.attendanceData.subscriptionsListActive){
            const showGroupsFilterButton = this.state.orr.hasRight('ViewOrganizationGroupsList');
            const organizationsIds = [this.state.organizationId ?? this.state.attendanceData.subscriptionsOrganizationId];

            return (
                <div>
                    <div className="form-floating mb-3">
                        <input id="subscriptionsFiterName" type="text" className='form-control' onChange={this.handleInputChange} value={this.state.subscriptionsFiterName.data}/>
                        <label htmlFor="subscriptionsFiterName" className='form-label'>Аккаунт</label>
                    </div>
                    <div className="form-floating mb-3">
                        <input id="subscriptionsFiterComment" type="text" className='form-control' onChange={this.handleInputChange} value={this.state.subscriptionsFiterComment.data}/>
                        <label htmlFor="subscriptionsFiterComment" className='form-label'>Комментарий</label>
                    </div>
                    <OrganizationsGroupsPicker 
                        id="subsOrganizationGroupFilter" 
                        organizationsIds={organizationsIds} 
                        label='Группы' 
                        className='border rounded mb-3' 
                        onChange={this.handleInputChange} value={this.state.subsOrganizationGroupFilter.data}
                        isCollapseType
                        isSingleSelect
                        hidden={!showGroupsFilterButton} />
                    <button className="btn btn-primary mb-3" type="button" onClick={this.onSelectSubscriptionUpdate}>
                        <span className="sr-only">Найти</span>
                    </button>
                    <ElementsBase>
                        <ElementsList includeSeparator>
                            {this.state.attendanceData.subscriptionsList.map(s => 
                                <OrganizationSubscriptionListElement 
                                    key={`sel-sub-${s.id}`} 
                                    value={s} 
                                    titleButton='Button' 
                                    buttonValue={s.id}
                                    buttonClick={this.handleSubscriptionListSelect}
                                    buttonIcon='bi bi-check2-square' />
                                )}
                        </ElementsList>
                    </ElementsBase>
                </div>
            );
        }

        return <></>;
    }

    renderAccountAttendanceTickets(){
        if (!this.state.attendanceData.selectTicketsFromList){
            return <></>;
        }

        return (
            <div>
                <div className="form-floating mb-3">
                    <input id="ticketsFiterName" type="text" className='form-control' onChange={this.handleInputChange} value={this.state.ticketsFiterName.data}/>
                    <label htmlFor="ticketsFiterName" className='form-label'>Аккаунт</label>
                </div>
                <div className="form-floating mb-3">
                    <input id="ticketsFiterComment" type="text" className='form-control' onChange={this.handleInputChange} value={this.state.ticketsFiterComment.data}/>
                    <label htmlFor="ticketsFiterComment" className='form-label'>Комментарий</label>
                </div>
                <button className="btn btn-primary mb-3" type="button" onClick={this.onSelectTicketsUpdate}>
                    <span className="sr-only">Найти</span>
                </button>
                <ElementsBase>
                    <ElementsList includeSeparator>
                        {this.state.attendanceData.ticketsList.map(t => 
                            <EventTicketListElement 
                                key={`sel-tic-${t.id}`} 
                                value={t} 
                                titleButton='Button' 
                                buttonValue={t.id}
                                buttonClick={this.handleTicketListSelect}
                                buttonIcon='bi bi-check2-square' />
                            )}
                    </ElementsList>
                </ElementsBase>
            </div>
        );
    }

    renderApproveAttendanceModal(){
        return (
            <div className="modal fade" ref={this.setApproveAttendanceModalRef} id="approveAttendanceModal" tabIndex="-1" aria-labelledby="approveAttendanceModal" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            {this.renderApproveAttendanceModalBody()}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-warning border-0" data-bs-dismiss="modal">Отменить</button>
                            <button type="button" disabled={this.state.customAttendanceComment.error} className="btn btn-outline-success border-0" onClick={this.handleApproveMarkAttendance} data-bs-toggle="modal" data-bs-target="#attendanceResultModal">OK</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderApproveAttendanceModalBody(){
        if (this.state.subscriptionToMark){
            return (
                <>
                    <p>Будет списано событие по абонементу</p>
                    <div className="form-floating mb-3" hidden={!!this.state.eventData}>
                        <textarea className="form-control h-25" id="subscriptionAttendanceComment" rows="3" onChange={this.handleInputChange} value={this.state.subscriptionAttendanceComment.data}></textarea>
                        <label htmlFor="subscriptionAttendanceComment" className="form-label">Комментарий</label>
                    </div>
                </>
            );
        }
        if (this.state.ticketToMark){
            return <>Будет списано событие по билету</>
        }
        if (this.state.customAttendanceMark){
            let attText;
            if (this.state.customAttendanceAccountCode){
                attText = 'Будет учтено разовое посещение (с учетом аккаунта)';
            }
            else{
                attText = 'Будет учтено разовое посещение (без учета аккаунта)';
            }

            return (
                <>
                    <p>{attText}</p>
                    <div className="form-floating mb-3">
                        <textarea className={`${validationFieldClass} form-control h-25`} id="customAttendanceComment" rows="3" onChange={this.handleInputChange} value={this.state.customAttendanceComment.data}></textarea>
                        <label htmlFor="customAttendanceComment" className="form-label">Комментарий</label>
                        <div className="invalid-feedback" htmlFor="customAttendanceComment">{this.state.customAttendanceComment.error}</div>
                    </div>
                    <div className="form-floating">
                        <input id="customAttendancePrice" type="number" step='.01' className={`${validationFieldClass} form-control`} onChange={this.handleInputChange} value={this.state.customAttendancePrice.data}/>
                        <label htmlFor="customAttendancePrice" className="form-label">Цена</label>
                        <div className="invalid-feedback" htmlFor="customAttendancePrice">{this.state.customAttendancePrice.error}</div>
                    </div>
                </>
            );
        }
    }

    renderAttendanceResultModal(){
        return (
            <div className="modal fade" ref={this.setAttendanceResultModalRef} id="attendanceResultModal" tabIndex="-1" aria-labelledby="attendanceResultModal" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            {this.renderMarkResult()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderMarkResult(){
        if (this.state.attendanceFailed){
            return (
                <div className='text-danger text-center'>
                    <h1><i className="bi bi-check-square"/></h1>
                    <h6>Ошибка!</h6>
                </div>
            );
            
        }
        if (this.state.subscriptionMarked){
            return (
                <div className='text-success text-center'>
                    <h1><i className="bi bi-check-square "/></h1>
                    <h6>Списано событие по абонементу.</h6>
                </div>
            );
        }
        if (this.state.ticketMarked){
            return (
                <div className='text-success text-center'>
                    <h1><i className="bi bi-check-square"/></h1>
                    <h6>Списано событие по билету.</h6>
                </div>
            );
        }
        if (this.state.customMarked){
            return (
                <div className='text-success text-center'>
                    <h1><i className="bi bi-check-square"/></h1>
                    <h6>Разовое посещение учтено.</h6>
                </div>
            );
        }
    }

    async loadData(){
        if (this.state.eventData && !this.state.orr){
            const orr = await this.props.getOrganizationsRights(this.state.eventData.ownersOrganizations.map(o => o.id));

            this.setState({
                ...this.state,
                orr: orr
            });
        }
        if (this.state.organizationId && !this.state.orr){
            const orr = await this.props.getOrganizationRights(this.state.organizationId);

            this.setState({
                ...this.state,
                orr: orr
            });
        }
    }

    async componentDidUpdate(){
        await this.loadData();
    }

    async componentDidMount(){
        await this.loadData();
    }

    static getDerivedStateFromProps(props, state){
        if (!state.eventData && props.eventData) {
            return ({ 
                ...state,
                eventData: props.eventData
            });
        }
        if (!state.organizationId && props.organizationId) {
            return ({ 
                ...state,
                organizationId: props.organizationId
            });
        }

        return null;
    }
}

const mapStateToProps = (state) => {
    return {
        accountToken: state.account.token
    }
}

export default connectInternal(mapStateToProps)(withParams(EventApplications));