import { configureStore } from '@reduxjs/toolkit';
import accountReducer from './Reducers/AccountReducer';
import systemReducer from './Reducers/SystemReducer';

const store = configureStore({
    reducer: {
      account: accountReducer,
      system: systemReducer
    }});

export default store;