import React, { Component } from "react";
import { Link } from "react-router-dom";
import organizationsService from "../../Services/OrganizationsService";
import { withParams, connectInternal } from '../../Helpers/SystemHelper';
import OrganizationHeader from "../OrganizationHeader";
import ElementsBase from "../Elements/ElementsBase";
import ElementsList from "../Elements/ElementsList";
import OrganizationSubscriptionTemplateListElement from "../Elements/OrganizationSubscriptionTemplateListElement";
import { elementBlockMenuClass } from "../Elements/ElementBlock";

class OrganizationSubscriptionsTemplates extends Component {
    constructor(props){
        super(props);

        this.state = {
            templatesData: []
        };

        this.loadData = this.loadData.bind(this);
        this.giveSubscription = this.giveSubscription.bind(this);
    }

    async giveSubscription(event){

    }

    async loadData(){
        const actionResult = await this.props.processActionResponse(
            organizationsService.getSubscriptionsTemplates(this.props.params.organizationId, this.props.accountToken));

        let stateUpdate = this.state;
        if (!actionResult.isError){        
            const orr = await this.props.getOrganizationRights(this.props.params.organizationId);

            stateUpdate = {
                ...stateUpdate,
                orr: orr,
                templatesData: actionResult.data,
            };
        }

        this.setState(stateUpdate);
    }

    renderList(){
        if (!this.state.templatesData.some(t => !!t)){
            return <>Шаблоны абонементов отсутствуют</>;
        }

        let showTemplateDetailsButton = false;
        let showTemplateGiveButton = false;
        
        if (this.state.orr){
            showTemplateDetailsButton = this.state.orr.hasRight('ViewSubscriptionTemplateDetails');
            showTemplateGiveButton = this.state.orr.hasRight('CreateSubscription');
        }

        let showMenuButton = showTemplateDetailsButton || showTemplateGiveButton;

        return this.state.templatesData.map(t => {
            return (
                <OrganizationSubscriptionTemplateListElement
                    key={`templates-list-${t.id}`}
                    value={t}
                    titleButton='Dropdown'
                    buttonIcon="bi bi-gear"
                    buttonHidden={!showMenuButton} >
                    <div className={elementBlockMenuClass}>
                        <Link className="btn btn-primary text-nowrap" type="button" to={`/organizations/subscriptions-templates/${t.id}`} hidden={!showTemplateDetailsButton}>
                            <i className="bi bi-info-square"/> Детали
                        </Link>
                        <Link className="btn btn-primary text-nowrap" type="button" to={`/organizations/subscriptions-templates/${t.id}/create-subscription`} hidden={!showTemplateGiveButton}>
                            <i className="bi bi-pencil-square"/> Выдать
                        </Link>
                    </div>
                </OrganizationSubscriptionTemplateListElement>
            )
        })
    }

    render(){
        let showCreateTemplateButton = false;
        if (this.state.orr){
            showCreateTemplateButton = this.state.orr.hasRight('CreateSubscriptionTemplate');
        }
        return(
            <div>
                <OrganizationHeader organizationId={this.props.params.organizationId} />
                <ElementsBase
                    titleButton='Link'
                    linkTo={`/organizations/${this.props.params.organizationId}/subscriptions-templates/create`}
                    buttonIcon='bi bi-plus-circle'
                    buttonHidden={!showCreateTemplateButton}
                    titleText='Абонементы'>
                    <ElementsList includeSeparator>
                        {this.renderList()}
                    </ElementsList>
                </ElementsBase>
            </div>
        )
    }

    async componentDidMount(){
        this.loadData();

        document.title = "Шаблоны абонементов"; 
    }
}

const mapStateToProps = (state) => {
    return {
        accountToken: state.account.token
    }
}

export default connectInternal(mapStateToProps)(withParams(OrganizationSubscriptionsTemplates));