import React, { Component } from 'react';
import { connectInternal } from '../../Helpers/SystemHelper';
import accountsService from '../../Services/AccountsService';
import { handleInputChange, isFormStateValid } from '../../Helpers/FormHelpers';
import {validateAccountEmail, validateCaptchaToken, validateTermsOfUseState } from '../../Helpers/Validations';
import { validationFieldClass } from '../../Helpers/Constants';
import PasswordResetInProcess from './PasswordResetInProcess';
import { SmartCaptcha } from '@yandex/smart-captcha';
import { Link } from 'react-router-dom';
import * as ReactDOM from 'react-dom/client';
import ElementsBase from '../Elements/ElementsBase';
import InitFormsWrapper from '../Elements/InitFormsWrapper';

class PasswordResetFormInit extends Component {
    isCaptchaLoading = false;

    constructor(props){
        super(props);

        this.state = { 
            accountEmail: {
                validator: validateAccountEmail,
                data: ''
            },
            captchaToken: {
                validator: validateCaptchaToken,
                data: ''
            },
            termsOfUseState:{
                validator: validateTermsOfUseState,
                data: false
            }
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.setCaptchaToken = this.setCaptchaToken.bind(this);
        this.renderCaptcha = this.renderCaptcha.bind(this);
    }

    renderCaptcha(){
        const captchaContainer = document.getElementById('captchaToken');

        this.captchaRoot = ReactDOM.createRoot(captchaContainer);

        const captchaElement = <SmartCaptcha sitekey={this.props.captchaKey} onSuccess={this.setCaptchaToken} language='ru' />;

        this.captchaRoot.render(captchaElement);
    }

    setCaptchaToken(token){
        const captchaWrapDiv = document.getElementById('captchaToken');
        captchaWrapDiv.value = token;

        handleInputChange.call(this, [captchaWrapDiv]);

        this.setState({
            ...this.state,
            captchaToken:{
                ...this.state.captchaToken,
                data: token
            }
        });
    }

    handleInputChange(event) {
        if (!this.captchaRoot && event.currentTarget.id === 'termsOfUseState' && event.currentTarget.checked){
            this.renderCaptcha();
        }
        
        handleInputChange.call(this, [event.target]);
    }

    async handleSubmit(event) {
        event.preventDefault();

        if (!isFormStateValid.call(this)){
            return;
        }

        const actionResult = await this.props.processActionResponse(
            accountsService.initResetAccountPassword(
                {
                    email: this.state.accountEmail.data,
                    captchaToken: this.state.captchaToken.data
                }));

        if (!actionResult.isError){
            this.setState({
                actionFinished: true
            });
        }
    }

    render() {
        if (this.state.actionFinished){
            return <PasswordResetInProcess/>;
        }

        return(
            <InitFormsWrapper>
                <ElementsBase>
                    <div className="form-floating mb-3">
                        <input id="accountEmail" type="email" className={`${validationFieldClass} form-control`} onChange={this.handleInputChange}/>
                        <label htmlFor="accountEmail" className='form-label'>Электронная почта</label>
                        <div className="invalid-feedback" htmlFor="accountEmail">{this.state.accountEmail.error}</div>
                    </div>
                    <div className="form-check mb-3">
                        <input id="termsOfUseState" className={`${validationFieldClass} form-check-input`} type="checkbox" value="" onChange={this.handleInputChange} checked={!!this.state.termsOfUseState.data}/>
                        <label className="form-check-label text-light" htmlFor="termsOfUseState">
                            С условиями <Link className='text-light' to='/termsofuse' target="_blank" rel="noopener noreferrer" >пользовательского соглашения</Link> ознакомлен и согласен.
                        </label>
                        <div className="invalid-feedback" htmlFor="termsOfUseState">{this.state.termsOfUseState.error}</div>
                    </div>
                    <div className='mb-2' hidden={!this.state.termsOfUseState.data}>
                        <div id="captchaToken" className={`${validationFieldClass}`}/>
                        <div className="invalid-feedback" htmlFor="captchaToken">{this.state.captchaToken.error}</div>
                    </div>
                    <button className="btn btn-secondary" type="button" onClick={this.handleSubmit}>Отправить</button>
                </ElementsBase>
            </InitFormsWrapper>
        )
    }

    componentDidMount() {
        document.title = "Сброс пароля"; 
    }
}

const mapStateToProps = (state) => {
    return {
        accountToken: state.account.token,
        captchaKey: state.system.captchaKey
    }
}

export default connectInternal(mapStateToProps)(PasswordResetFormInit);