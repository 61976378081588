import React, { Component } from "react";
import organizationsService from "../../Services/OrganizationsService";
import { getDateTimeFormatString, getFormInputDateFormatString, getNodaDateTimeFormatString, dateAddDays } from '../../Helpers/FormHelpers';
import { withParams, connectInternal } from '../../Helpers/SystemHelper';
import PagingElement from "../FormComponents/PagingElement";
import { Link } from "react-router-dom";
import OrganizationHeader from "../OrganizationHeader";
import ElementsBase, { elementsBaseCollapseBlockClass } from "../Elements/ElementsBase";
import ElementsList from "../Elements/ElementsList";
import OrganizationSubscriptionListElement from '../Elements/OrganizationSubscriptionListElement';
import { elementBlockMenuClass } from "../Elements/ElementBlock";
import Modal from '../Elements/Modal';

class OrganizationSubscriptions extends Component {
    pageEntriesCount = 10;

    constructor(props){
        super(props);

        this.state = {
            totalPagesCount: 0,
            subscriptionsCollection: [],
            accountNameFilter: '',
            commentFilter: '',
            isActiveOnlyFilter: true
        };

        this.loadData = this.loadData.bind(this);
        this.handleRefreshList = this.handleRefreshList.bind(this);
        this.handleTextFilterChange = this.handleTextFilterChange.bind(this);
        this.handleActiveFilterChange = this.handleActiveFilterChange.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleDeactivateModalOpen = this.handleDeactivateModalOpen.bind(this);
        this.handleDeactivateSubscription = this.handleDeactivateSubscription.bind(this);
    }

    handleDeactivateModalOpen(event){
        const currentSubscription = this.state.subscriptionsCollection.find(s => s.id == event.currentTarget.value);

        this.setState({
            ...this.state,
            subscriptionToDeactivate: {
                id: currentSubscription.id
            }
        });
    }

    async handleDeactivateSubscription(event){
        const actionResponse = await this.props.processActionResponse(
            organizationsService.deactivateSubscription(this.state.subscriptionToDeactivate.id, this.props.accountToken));

        if (!actionResponse.isError){
            await this.loadData(1);
        }
    }

    async loadData(pageNumber = 1){
        const actionResponse = await this.props.processActionResponse(
            organizationsService.getSubscriptions(
                {
                    organizationId: this.props.params.organizationId,
                    accountName: this.state.accountNameFilter,
                    commentText: this.state.commentFilter,
                    isActive: this.state.isActiveOnlyFilter,
                    pagingFilter: {
                        entriesCount: this.pageEntriesCount,
                        pageNumber: pageNumber
                    }
                },
                this.props.accountToken
            ));

        if (!actionResponse.isError){
            const orr = await this.props.getOrganizationRights(this.props.params.organizationId);

            this.setState({
                ...this.state,
                orr: orr,
                subscriptionsCollection: actionResponse.data.items,
                totalPagesCount: Math.ceil(actionResponse.data.totalCount / this.pageEntriesCount)
            });
        }
    }

    async handleRefreshList(event){
        event.preventDefault();

        await this.loadData(1);
    }

    handleTextFilterChange(event){
        this.setState({
            ...this.state,
            [event.target.id]: event.target.value
        });
    }

    handleActiveFilterChange(event){
        this.setState({
            ...this.state,
            isActiveOnlyFilter: (event.target.id == 'activeOnlyFilterTrue' ? event.target.checked : !event.target.checked)
        });
    }

    handlePageChange(event){
        this.loadData(event.target.value);
    }

    renderDeactivateApproveModal(){
        return (
            <Modal 
                id="deactivateApproveModal"
                modalType='danger'
                cancelButtonText='Нет'
                approveButtonText='Да'
                approveButtonClick={this.handleDeactivateSubscription}
                infoText='Это действие невозможно отменить. Вы уверены что хотите удалить аннулировать абонемент?' />
        );
    }

    renderList(){
        if (!this.state.subscriptionsCollection.some(s => !!s)){
            return <>Абонементы отсутствуют</>;
        }

        let showDetailsButton = false;
        let showEditButton = false;
        let showDeactivateButton = false;

        if (this.state.orr){
            showDetailsButton = this.state.orr.hasRight('ViewSubscriptionDetails');
            showEditButton = this.state.orr.hasRight('UpdateSubscription');
            showDeactivateButton = this.state.orr.hasRight('DeactivateSubscription');
        }

        const showMenuButton = showDetailsButton || showEditButton || showDeactivateButton;

        return (
            <ElementsList includeSeparator>
                {this.state.subscriptionsCollection.map(s =>{
                    return (
                        <OrganizationSubscriptionListElement
                            key={`subscriptions-list-${s.id}`}
                            value={s}
                            titleButton='Dropdown'
                            buttonIcon="bi bi-gear" 
                            buttonHidden={!showMenuButton}>
                            <div className={elementBlockMenuClass}>
                                <Link key='det-b' className="btn btn-primary" type="button" to={`/organizations/subscriptions/${s.id}`} hidden={!showDetailsButton}>
                                    <i className="bi bi-info-square"/> Детали
                                </Link>
                                <Link key='det-u' className="btn btn-primary" type="button" to={`/organizations/subscriptions/${s.id}/edit`} hidden={!(showEditButton && s.canBeUpdated)}>
                                    <i className="bi bi-pencil-square"/> Редактировать
                                </Link>
                                <button key='det-d' className="btn btn-danger" type="button" value={s.id} onClick={this.handleDeactivateModalOpen} data-bs-toggle="modal" data-bs-target="#deactivateApproveModal" hidden={!(showDeactivateButton && s.canBeUpdated)} >
                                    <i className="bi bi-x-square"/> Аннулировать
                                </button>
                            </div>
                        </OrganizationSubscriptionListElement>
                    );
                })}
            </ElementsList>
        ); 
    }

    render(){
        return(
            <div>
                <OrganizationHeader organizationId={this.props.params.organizationId} />
                <ElementsBase
                    bgClass='bg-transparent'
                    titleButton='ButtonCollapse'
                    buttonIcon='bi bi-funnel'>
                    <div className={elementsBaseCollapseBlockClass}>
                        <form className="container form-floating mb-3" onSubmit={this.handleRefreshList}>
                            <div className="form-floating mb-3">
                                <input id="accountNameFilter" type="text" className={`form-control`} onChange={this.handleTextFilterChange} value={this.state.accountNameFilter}/>
                                <label htmlFor="accountNameFilter" className="form-label">Имя пользователя</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input id="commentFilter" type="text" className={`form-control`} onChange={this.handleTextFilterChange} value={this.state.commentFilter}/>
                                <label htmlFor="commentFilter" className="form-label">Комментарий</label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="inlineRadioOptions" onChange={this.handleActiveFilterChange} id="activeOnlyFilterTrue" checked={this.state.isActiveOnlyFilter}/>
                                <label className="form-check-label text-reset" htmlFor="activeOnlyFilterTrue">Действующие</label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="inlineRadioOptions" onChange={this.handleActiveFilterChange} id="activeOnlyFilterFalse" checked={!this.state.isActiveOnlyFilter}/>
                                <label className="form-check-label text-reset" htmlFor="activeOnlyFilterFalse">Недействующие</label>
                            </div>
                            <button className="btn btn-primary" type="submit">
                                <span className="sr-only">Найти</span>
                            </button>
                        </form>
                    </div>
                </ElementsBase>
                <ElementsBase>
                    {this.renderList()}
                    <PagingElement totalPagesCount={this.state.totalPagesCount} onChange={this.handlePageChange} />
                </ElementsBase>
                {this.renderDeactivateApproveModal()}
            </div>
        )
    }

    async componentDidMount(){
        await this.loadData();

        document.title = "Абонементы"; 
    }
}

const mapStateToProps = (state) => {
    return {
        accountToken: state.account.token
    }
}

export default connectInternal(mapStateToProps)(withParams(OrganizationSubscriptions));