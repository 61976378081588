import React, { Component } from "react";
import eventsService from "../../Services/EventsService";
import { withParams, connectInternal } from '../../Helpers/SystemHelper';
import ElementsBase from '../Elements/ElementsBase';
import ElementBlock, { elementBlockTitleClass } from "../Elements/ElementBlock";
import InfoBlock from "../Elements/InfoBlock";
import BeautyTextBlock from "../Elements/BeautyTextBlock";
import ElementsList from "../Elements/ElementsList";

class EventAttendances extends Component {
    targetApplicationAccountId = '';

    constructor(props){
        super(props);

        this.state = {
            attendancesCollection: []
        };

        this.loadData = this.loadData.bind(this);
    }

    async loadData(){
        const actionResponse = await this.props.processActionResponse(
            eventsService.getEventAttendances(
                this.props.params.eventId,
                this.props.accountToken
            ));

        if (!actionResponse.isError){
            this.setState({
                ...this.state,
                attendancesCollection: actionResponse.data
            });
        }
    }

    renderList(){
        if (!this.state.attendancesCollection.some(a => !!a)){
            return <>Записи о посещении отсутствуют</>;
        }

        let counter = 0;
        return this.state.attendancesCollection.map(a =>{
            counter++;

            return (
                <ElementBlock key={`attendances-list-${counter}`} >
                    <div className={elementBlockTitleClass}>
                        <h5>{a.account ? a.account.name : 'Аноним'}</h5>
                    </div>
                    {!!a.ticket 
                        ? <h6>Билет: <span className="badge text-dark bg-secondary">{a.ticket.name}</span></h6>
                        : <></>}
                    {!!a.subscription 
                        ? <h6>Абонемент: <span className="badge text-dark bg-secondary">{a.subscription.name}</span></h6>
                        : <></>}
                    {!!a.markedAccount 
                        ? <h6>Сотрудник: <span className="badge text-dark bg-secondary">{a.markedAccount.name}</span></h6>
                        : <></>}
                    {!!a.comment 
                        ? <InfoBlock
                            title='Комментарий'>
                                <BeautyTextBlock
                                    textData={a.comment}/>
                            </InfoBlock>
                        : <></>}
                </ElementBlock>
            );
        });
    }

    render(){
        return(
            <div>
                <ElementsBase>
                    <ElementsList includeSeparator>
                        {this.renderList()}
                    </ElementsList>
                </ElementsBase>
            </div>
        )
    }
    

    async componentDidMount(){
        await this.loadData();

        document.title = "Отмеченные посещения"; 
    }
}

const mapStateToProps = (state) => {
    return {
        accountToken: state.account.token
    }
}

export default connectInternal(mapStateToProps)(withParams(EventAttendances));