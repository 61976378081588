import React, { Component } from 'react';
import { validationFieldClass } from '../../Helpers/Constants';
import OrganizationRoleRightsPicker from '../FormComponents/OrganizationRoleRightsPicker';
import { validateOrganizationRoleName, validateOrganizationRoleRights } from '../../Helpers/Validations';
import { withRouter, connectInternal } from '../../Helpers/SystemHelper';
import { handleInputChange, isFormStateValid, handleServerError } from '../../Helpers/FormHelpers';
import organizationsService from '../../Services/OrganizationsService';
import OrganizationHeader from '../OrganizationHeader';
import { getRightById } from '../../Helpers/OrganizationRights';
import ElementsBase, { elementsBaseMenuClass } from '../Elements/ElementsBase';
import ElementsList from '../Elements/ElementsList';
import Modal from '../Elements/Modal';

class OrganizationRoleCreateUpdate extends Component {
    constructor(props){
        super(props);

        this.state = {
            organizationId: this.props.router.params.organizationId,
            isEditState: !props.router.params.organizationRoleId,
            roleName:{
                validator: validateOrganizationRoleName,
                data: ''
            },
            roleRights:{
                validator: validateOrganizationRoleRights
            }
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.loadData = this.loadData.bind(this);
        this.setEditState = this.setEditState.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleDeleteGroup = this.handleDeleteGroup.bind(this);
    }

    async handleDeleteGroup(event){
        event.preventDefault();

        await this.props.processActionResponse(
            organizationsService.deleteOrganizationRole(this.props.router.params.organizationRoleId, this.props.accountToken));

        await this.props.router.navigate(`/organizations/${this.state.organizationId}/roles`, { replace: true });
    }

    setEditState(){
        this.dataBackup = {
            roleName: this.state.roleName.data,
            roleRights: [...this.state.roleRights.data]
        };

        this.setState({
            ...this.state,
            isEditState: true,
        });
    }

    handleCancel(){
        this.setState({
            ...this.state,
            isEditState: false,
            roleName: {
                ...this.state.roleName,
                data: this.dataBackup.roleName
            },
            roleRights: {
                ...this.state.roleRights,
                data: this.dataBackup.roleRights
            },
        });
    }

    handleInputChange(event) {
        handleInputChange.call(this, [event.target]);
    }

    async handleSubmit(event){
        event.preventDefault();

        if (!isFormStateValid.call(this)){
            return;
        }

        this.setState({
            ...this.state,
            isLoadingState: true
        });

        let acitonResult;
        let stateUpdate = this.state;
        if (this.props.router.params.organizationRoleId){
            acitonResult = await this.props.processActionResponse(
                organizationsService.updateOrganizationRole(
                    {
                        roleId: this.props.router.params.organizationRoleId,
                        roleName: this.state.roleName.data,
                        roleRights: this.state.roleRights.data.map(r => r.id)
                    },
                    this.props.accountToken
                ), this.state);

            if (!acitonResult.isError){
                stateUpdate = {
                    ...stateUpdate,
                    isLoadingState: false,
                    isEditState: false,
                    organizationId: this.state.organizationId || this.props.router.params.organizationId
                };
            }
            else{
                stateUpdate = {
                    ...acitonResult.state,
                    isLoadingState: false
                };
            }
        }
        else{
            acitonResult = await this.props.processActionResponse(
                organizationsService.createOrganizationRole(
                    {
                        organizationId: this.props.router.params.organizationId,
                        roleName: this.state.roleName.data,
                        roleRights: this.state.roleRights.data.map(r => r.id)
                    },
                    this.props.accountToken
                ), this.state);
            
            if (!acitonResult.isError){
                if (this.state.orr.hasRight('ViewOrganizationRole')){
                    await this.props.router.navigate(`/organizations/roles/${acitonResult.data}`, { replace: true });
                    await this.props.router.navigate(0);
                }
                else{
                    await this.props.router.navigate(`/organizations/${this.state.organizationId}/roles`, { replace: true });
                }
            }
            else{
                stateUpdate = {
                    ...acitonResult.state,
                    isLoadingState: false
                };
            }
        }
    
        this.setState(stateUpdate);
    }

    async loadData(){
        let stateUpdate = this.state;
        let organizationId = this.state.organizationId;

        if (this.props.router.params.organizationRoleId){
            const acitonResult = await this.props.processActionResponse(
                organizationsService.getOrganizationRole(this.props.router.params.organizationRoleId, this.props.accountToken), this.state);
    
            organizationId = acitonResult.data.organizationId;
    
            stateUpdate = {
                ...stateUpdate,
                roleName:{
                    ...this.state.roleName,
                    data: acitonResult.data.name
                },
                roleRights:{
                    ...this.state.roleRights,
                    data: acitonResult.data.organizationRights.map(i => getRightById(i))
                },
                organizationId: acitonResult.data.organizationId
            };
        }

        const orr = await this.props.getOrganizationRights(organizationId);
        stateUpdate = {
            ...stateUpdate,
            canEditRole: orr.hasRight('UpdateOrganizationRole'),
            canDeleteRole: orr.hasRight('DeleteOrganizationRole'),
            orr: orr
        }
        
        this.setState(stateUpdate);
    }

    render(){
        return (
            <div>
                <OrganizationHeader organizationId={this.state.organizationId}/>
                <ElementsBase
                    titleText='Роль'>
                    <div className={elementsBaseMenuClass}>
                        <button className="btn btn-outline-danger border-0 me-2" type="button" disabled={this.state.isEditAccountsState} onClick={event => event.preventDefault()} hidden={this.state.isEditState || !this.state.canDeleteRole} data-bs-toggle="modal" data-bs-target="#approveDeleteModal">
                            <i className="bi bi-trash3-fill"/>
                        </button>
                        <button className="btn btn-primary border-0" type="button" onClick={this.setEditState} hidden={this.state.isEditState || !this.state.canEditRole}>
                            <i className="bi bi-pencil-square"/>
                        </button>
                        <button className="btn btn-outline-success border-0 me-2" type="button" onClick={this.handleSubmit} disabled={this.state.isLoadingState} hidden={!this.state.isEditState}>
                            <span className="sr-only" hidden={this.state.isLoadingState}><i className="bi bi-check-square"/></span>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" hidden={!this.state.isLoadingState}/>
                        </button>
                        <button className="btn btn-outline-warning border-0" type="button" disabled={this.state.isLoadingState} hidden={!this.state.isEditState || !this.props.router.params.organizationRoleId} onClick={this.handleCancel}><i className="bi bi-x-square"/></button>
                    </div>
                    <ElementsList>
                        <div className="form-floating">
                            <input id="roleName" type="text" className={`${validationFieldClass} form-control`} onChange={this.handleInputChange} disabled={this.state.isFormLoading || !this.state.isEditState} value={this.state.roleName.data}/>
                            <label htmlFor="roleName" className='form-label'>Название</label>
                            <div className="invalid-feedback" htmlFor="roleName">{this.state.roleName.error}</div>
                        </div>
                        <div>
                            <OrganizationRoleRightsPicker label='Права роли' id="roleRights" className={`${validationFieldClass} border rounded`} onChange={this.handleInputChange} disabled={this.state.isFormLoading || !this.state.isEditState} value={this.state.roleRights.data}/>
                            <div className="invalid-feedback" htmlFor="roleRights">{this.state.roleRights.error}</div>
                        </div>
                    </ElementsList>
                </ElementsBase>
                <Modal 
                    id="approveDeleteModal"
                    modalType='danger'
                    cancelButtonText='Нет'
                    approveButtonText='Да'
                    approveButtonClick={this.handleDeleteGroup}
                    infoText='Это действие невозможно отменить. Вы уверены что хотите удалить роль?' />
            </div>
        );
    }

    async componentDidMount(){
        await this.loadData();

        if (this.state.isEditState){
            document.title = "Создание роли"; 
        }
        else {
            document.title = "Детали роли"; 
        }
    }
}

const mapStateToProps = (state) => {
    return {
        accountToken: state.account.token
    }
}

export default connectInternal(mapStateToProps)(withRouter(OrganizationRoleCreateUpdate));