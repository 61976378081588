import { createSlice } from "@reduxjs/toolkit";
import { setLocalStorageSystemData, getLocalStorageSystemData } from '../Helpers/LocalStorageHelper'

const systemSlice = createSlice({
    name: 'system',
    initialState: {
        systemError: '',
        captchaKey: '',
        showCookieNotification: false
    },
    reducers: {
      setSystemError(state, action){
        return {
          ...state,
          systemError: action.payload
        };
      },
      removeSystemError(state){
        return {
          ...state,
          systemError: ''
        };
      },
      setCaptchaKey(state, action){
        return {
          ...state,
          captchaKey: action.payload
        };
      },
      hideCookieNotification(state){
        const storageData = getLocalStorageSystemData();

        setLocalStorageSystemData({
          ...storageData,
          showCookieNotification: false
        });

        return {
          ...state,
          showCookieNotification: false
        };
      },
      showCookieNotification(state){
        const storageData = getLocalStorageSystemData();

        setLocalStorageSystemData({
          ...storageData,
          showCookieNotification: true
        });

        return {
          ...state,
          showCookieNotification: true
        };
      }
    }
  });

export const { setSystemError, removeSystemError, setCaptchaKey, hideCookieNotification, showCookieNotification } = systemSlice.actions;
export default systemSlice.reducer;