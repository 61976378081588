import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import { validationFieldClass } from '../../Helpers/Constants';
import { validateGroupName } from '../../Helpers/Validations';
import { connectInternal, withRouter } from '../../Helpers/SystemHelper';
import { handleInputChange, isFormStateValid } from '../../Helpers/FormHelpers';
import organizationsService from '../../Services/OrganizationsService';
import OrganizationRolePicker from '../FormComponents/OrganizationRolesPicker';
import UserPicker from '../FormComponents/UserPicker';
import OrganizationHeader from '../OrganizationHeader';
import ElementsBase, { elementsBaseMenuClass } from '../Elements/ElementsBase';
import ElementsList from '../Elements/ElementsList';
import Modal from '../Elements/Modal';

class OrganizationGroupCreateUpdate extends Component {
    constructor(props){
        super(props);

        this.state = {
            isCreateGroupState: !props.router.params.organizationGroupId,
            isEditGroupState: !props.router.params.organizationGroupId,
            isEditAccountsState: false,
            organizationId: props.router.params.organizationId,
            groupName:{
                validator: validateGroupName,
                data: ''
            },
            groupRole:{
                data: ''
            },
            groupAccounts:{}
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleEditGroupSubmit = this.handleEditGroupSubmit.bind(this);
        this.handleEditAccountsSubmit = this.handleEditAccountsSubmit.bind(this);
        this.loadData = this.loadData.bind(this);
        this.setEditGroupState = this.setEditGroupState.bind(this);
        this.setEditAccountsState = this.setEditAccountsState.bind(this);
        this.handleEditGroupCancel = this.handleEditGroupCancel.bind(this);
        this.handleEditAccountsCancel = this.handleEditAccountsCancel.bind(this);
        this.handleDeleteGroup = this.handleDeleteGroup.bind(this);
    }

    async handleDeleteGroup(event){
        event.preventDefault();

        const actionResult = await this.props.processActionResponse(
            organizationsService.deleteOrganizationGroup(this.props.router.params.organizationGroupId, this.props.accountToken));

        if (!actionResult.isError){
            await this.props.router.navigate(`/organizations/${this.state.organizationId}/groups`, { replace: true });
        }
    }

    setEditGroupState(){
        this.dataBackup = {
            ...this.dataBackup,
            groupName: this.state.groupName,
            groupRole: this.state.groupRole
        };

        this.setState({
            ...this.state,
            isEditGroupState: true,
        });
    }

    setEditAccountsState(){
        this.dataBackup = {
            ...this.dataBackup,
            groupAccounts: [...this.state.groupAccounts.data]
        };

        this.setState({
            ...this.state,
            isEditAccountsState: true,
        });
    }

    handleEditGroupCancel(){
        this.setState({
            ...this.state,
            isEditGroupState: false,
            groupName: this.dataBackup.groupName,
            groupRole: this.dataBackup.groupRole
        });
    }

    handleEditAccountsCancel(){
        this.setState({
            ...this.state,
            isEditAccountsState: false,
            groupAccounts: {
                ...this.state.groupAccounts,
                data: this.dataBackup.groupAccounts
            },
        });
    }

    handleInputChange(event) {
        handleInputChange.call(this, [event.target]);
    }

    async handleEditGroupSubmit(event){
        event.preventDefault();

        if (!isFormStateValid.call(this)){
            return;
        }

        this.setState({
            ...this.state,
            isLoadingState: true
        });

        let actionResult;
        let stateUpdate;
        if (this.props.router.params.organizationGroupId){
            actionResult = await this.props.processActionResponse(
                organizationsService.updateOrganizationGroup(
                    {
                        organizationGroupId: this.props.router.params.organizationGroupId,
                        groupName: this.state.groupName.data,
                        groupRoleId: this.state.groupRole.data ? this.state.groupRole.data.id : null
                    },
                    this.props.accountToken
                ), this.state);

            if (!actionResult.isError){
                stateUpdate = {
                    ...this.state,
                    isActionFinished: true,
                    isEditGroupState: false,
                    isLoadingState: false
                };
            }
            else{
                stateUpdate = {
                    ...actionResult.state,
                    isLoadingState: false
                };
            }
        }
        else{
            actionResult = await this.props.processActionResponse(
                organizationsService.createOrganizationGroup(
                    {
                        organizationId: this.props.router.params.organizationId,
                        groupName: this.state.groupName.data,
                        groupRoleId: this.state.groupRole.data.id
                    },
                    this.props.accountToken
                ), this.state);
            
            if (!actionResult.isError){
                if (this.state.canViewGroupDetails){
                    await this.props.router.navigate(`/organizations/groups/${actionResult.data}`, { replace: true });
                    await this.props.router.navigate(0);
                }
                else{
                    await this.props.router.navigate(`/organizations/${this.state.organizationId}/groups`, { replace: true });
                }
            }
            else{
                stateUpdate = {
                    ...actionResult.state,
                    isLoadingState: false
                };
            }
        }
    
        this.setState(stateUpdate);
    }

    async handleEditAccountsSubmit(event){
        event.preventDefault();

        this.setState({
            ...this.state,
            isLoadingState: true
        });

        const actionResult = await this.props.processActionResponse(
            organizationsService.updateOrganizationGroupAccounts(
                {
                    organizationGroupId: this.props.router.params.organizationGroupId,
                    accountsLinks: this.state.groupAccounts.data
                },
                this.props.accountToken
            ), this.state);

        let stateUpdate;
        if (!actionResult.isError){
            stateUpdate = {
                ...this.state,
                isActionFinished: true,
                isEditAccountsState: false,
                isLoadingState: false
            };
        }
        else{
            stateUpdate = {
                ...actionResult.state,
                isLoadingState: false
            };
        }

        this.setState(stateUpdate);
    }

    async loadData(){
        let stateUpdate = this.state;
        if (this.props.router.params.organizationGroupId){
            const actionResult = await this.props.processActionResponse(
                organizationsService.getOrganizationGroup(this.props.router.params.organizationGroupId, this.props.accountToken));

            stateUpdate = {
                ...stateUpdate,
                organizationId: actionResult.data.organizationId,
                groupName:{
                    ...this.state.roleName,
                    data: actionResult.data.name
                },
                groupRole:{
                    ...this.state.roleRights,
                    data: actionResult.data.organizationRole
                },
                groupAccounts:{
                    ...this.state.groupAccounts,
                    data: actionResult.data.groupAccounts
                },
            };
        }
        else{
            stateUpdate = {
                ...stateUpdate,
                organizationId: this.props.router.params.organizationId,
            }
        }

        const orr = await this.props.getOrganizationRights(stateUpdate.organizationId);
        stateUpdate = {
            ...stateUpdate,
            orr: orr,
            canEditGroup: orr.hasRight('UpdateOrganizationGroup'),
            canEditAccounts: orr.hasRight('UpdateOrganizationGroupAccounts'),
            canUpdateGroupRole: orr.hasRight('UpdateOrganizationGroupRole'),
            canDeleteGroup: orr.hasRight('DeleteOrganizationGroup'),
            canViewOrgAccounts: orr.hasRight('ViewOrganizationAccounts'),
            canViewGroupDetails: orr.hasRight('ViewOrganizationGroupDetails')
        };

        this.setState(stateUpdate);
    }

    render(){
        return (
            <div>
                <OrganizationHeader organizationId={this.state.organizationId} />
                <ElementsList>
                    <ElementsBase
                        titleText='Группа'>
                        <div className={elementsBaseMenuClass}>
                            <button className="btn btn-outline-danger border-0 me-2" type="button" disabled={this.state.isEditAccountsState} onClick={event => event.preventDefault()} hidden={this.state.isEditGroupState || !this.state.canDeleteGroup} data-bs-toggle="modal" data-bs-target="#approveDeleteModal">
                                <i className="bi bi-trash3-fill"/>
                            </button>
                            <button className="btn btn-primary" type="button" disabled={this.state.isEditAccountsState} onClick={this.setEditGroupState} hidden={this.state.isEditGroupState || !this.state.canEditGroup}>
                                <i className="bi bi-pencil-square"/>
                            </button>
                            <button className="btn btn-outline-success border-0 me-2" type="button" onClick={this.handleEditGroupSubmit} disabled={this.state.isLoadingState} hidden={!this.state.isEditGroupState}>
                                <span className="sr-only" hidden={this.state.isLoadingState}><i className="bi bi-check-square"/></span>
                                <span className="spinner-border spinner-border-sm" hidden={!this.state.isLoadingState}/>
                            </button>
                            <button className="btn btn-outline-warning border-0" type="button" disabled={this.state.isLoadingState} hidden={!this.state.isEditGroupState || !this.props.router.params.organizationGroupId} onClick={this.handleEditGroupCancel}><i className="bi bi-x-square"/></button>
                        </div>
                        <ElementsList>
                            <div key='groupName' className="form-floating">
                                <input id="groupName" type="text" className={`${validationFieldClass} form-control`} onChange={this.handleInputChange} disabled={this.state.isFormLoading || !this.state.isEditGroupState} value={this.state.groupName.data}/>
                                <label htmlFor="groupName" className='form-label'>Название</label>
                                <div className="invalid-feedback" htmlFor="groupName">{this.state.groupName.error}</div>
                            </div>
                            <div key='groupRole'>
                                <OrganizationRolePicker id="groupRole" className={validationFieldClass} onChange={this.handleInputChange} disabled={this.state.isFormLoading || !this.state.isEditGroupState || !this.state.canUpdateGroupRole} value={this.state.groupRole.data} organizationId={this.state.organizationId}/>
                                <div className="invalid-feedback" htmlFor="groupRole">{this.state.groupRole.error}</div>
                            </div>
                        </ElementsList>
                    </ElementsBase>
                    <ElementsBase hidden={this.state.isCreateGroupState}>
                        <div className={elementsBaseMenuClass}>
                            <button className="btn btn-primary" type="button" disabled={this.state.isEditGroupState} onClick={this.setEditAccountsState} hidden={this.state.isEditAccountsState || this.state.isCreateGroupState || !this.state.canEditAccounts}>
                                <i className="bi bi-pencil-square"/>
                            </button>
                            <button className="btn btn-outline-success border-0 me-2" type="submit" onClick={this.handleEditAccountsSubmit} disabled={this.state.isLoadingState} hidden={!this.state.isEditAccountsState}>
                                <span className="sr-only" hidden={this.state.isLoadingState || !this.props.router.params.organizationGroupId}><i className="bi bi-check-square"/></span>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" hidden={!this.state.isLoadingState}/>
                            </button>
                            <button className="btn btn-outline-warning border-0" type="button" disabled={this.state.isLoadingState} hidden={!this.state.isEditAccountsState || !this.props.router.params.organizationGroupId} onClick={this.handleEditAccountsCancel}><i className="bi bi-x-square"/></button>
                        </div>
                        <UserPicker id="groupAccounts" label='Члены группы' className={validationFieldClass} onChange={this.handleInputChange} organizationId={this.state.canViewOrgAccounts ? this.state.organizationId : ''} value={this.state.groupAccounts.data} disabled={!this.state.isEditAccountsState}/>
                        <div className="invalid-feedback" htmlFor="groupAccounts">{this.state.groupAccounts.error}</div>
                    </ElementsBase>
                </ElementsList>
                <Modal
                    id="approveDeleteModal"
                    modalType='danger'
                    cancelButtonText='Нет'
                    approveButtonText='Да'
                    approveButtonClick={this.handleDeleteGroup}
                    infoText='Это действие невозможно отменить. Вы уверены что хотите удалить группу?' />
            </div>
        );
    }

    async componentDidMount(){
        await this.loadData();

        if (this.state.isCreateGroupState){
            document.title = "Создание группы"; 
        }
        else{
            document.title = "Детали группы"; 
        }
    }
}

const mapStateToProps = (state) => {
    return {
        accountToken: state.account.token
    }
}

export default connectInternal(mapStateToProps)(withRouter(OrganizationGroupCreateUpdate));