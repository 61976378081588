import React from 'react';
import { Link } from 'react-router-dom';
import ElementsBase from '../Elements/ElementsBase';
import InitFormsWrapper from '../Elements/InitFormsWrapper';

const RegistrationSuccess = () => {
    return (
        <InitFormsWrapper>
            <ElementsBase>
                <p>Для завершения процесса создания учетной записи подтвердите электронную почту.</p>
                <p>Ссылка для подтверждения отправлена на указанный адрес электронной почты (действительна в течение 30 минут).</p>
                <Link className="btn btn-secondary" to="/login">Перейти ко входу в систему</Link>
            </ElementsBase>
        </InitFormsWrapper>
    );
}

export default RegistrationSuccess;