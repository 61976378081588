const eventApplicationFilters = {
    EventGroupsMembers: { id: 1, label: 'Члены групп события' },
    OrganizationsMembers: { id: 2, label: 'Члены организаций' },
}

const getLabelById = (filterId) => {
    for(let [key, {id, label}] of Object.entries(eventApplicationFilters)){
        if (id == filterId){
            return label;
        }
    }

    return;
}

const getFilterById = (filterId) => {
    for(let [key, {id, label}] of Object.entries(eventApplicationFilters)){
        if (id == filterId){
            return {
                id: id,
                name: label
            };
        }
    }

    return;
}

export { eventApplicationFilters, getLabelById, getFilterById }