import { storageAccountDataKey, storageSystemDataKey } from "../Helpers/Constants";

const setLocalStorageAccountData = (accountData) => {
    return setLocalStorageItem(storageAccountDataKey, JSON.stringify(accountData));
}

const getLocalStorageAccountData = () => {
    return getLocalStorageItem(storageAccountDataKey);
}

const clearLocalStorageAccountData = () => {
    localStorage.removeItem(storageAccountDataKey);
}

const setLocalStorageSystemData = (systemData) => {
    return setLocalStorageItem(storageSystemDataKey, JSON.stringify(systemData));
}

const getLocalStorageSystemData = () => {
    return getLocalStorageItem(storageSystemDataKey);
}

const clearLocalStorageSystemData = () => {
    localStorage.removeItem(storageSystemDataKey);
}

const setLocalStorageItem = (key, data) => {
    return localStorage.setItem(key, JSON.stringify(data));
}

const getLocalStorageItem = (key) => {
    return JSON.parse(JSON.parse(localStorage.getItem(key)));
}

export { setLocalStorageAccountData, getLocalStorageAccountData, clearLocalStorageAccountData, setLocalStorageSystemData, getLocalStorageSystemData, clearLocalStorageSystemData };