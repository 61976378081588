import React, { Component } from 'react';
import { withParams, connectInternal } from '../../Helpers/SystemHelper';
import organizationsService from '../../Services/OrganizationsService';
import QrCode from '../FormComponents/QrCode';
import { qrTypes } from '../../Helpers/QrHelper';
import OrganizationHeader from '../OrganizationHeader';
import ElementsBase from '../Elements/ElementsBase';
import ElementsList from '../Elements/ElementsList';
import InfoBlock from '../Elements/InfoBlock';
import ElementBlock, { elementBlockTitleClass } from '../Elements/ElementBlock';
import { Link } from 'react-router-dom';
import { getSubscriptionStatusBadge } from '../../Helpers/OrgSubscriptionStatusHelper';

class OrganizationSubscriptionDetails extends Component {
    constructor(props){
        super(props);

        this.state = {
            subscriptionData: ''
        };

        this.loadData = this.loadData.bind(this);
    }

    async loadData(){
        const actionResult = await this.props.processActionResponse(
            organizationsService.getSubscription(this.props.params.subscriptionId, this.props.accountToken));

        if (!actionResult.isError){
            this.setState({
                ...this.state,
                subscriptionData: actionResult.data
            });
        }
    }

    render(){
        if (this.state.subscriptionData){
            return(
                <div>
                    <OrganizationHeader organizationId={this.state.subscriptionData.organization.id} />
                    <ElementsBase>
                        <ElementsList>
                            <ElementBlock>
                                <div className={elementBlockTitleClass}>
                                    {getSubscriptionStatusBadge(this.state.subscriptionData)}
                                    <h5>{this.state.subscriptionData.name}</h5>
                                </div>
                                <ElementsList>
                                    {!!this.state.subscriptionData.account
                                        ? <InfoBlock
                                            key='sub-acc'
                                            title='Аккаунт'>
                                            {this.state.subscriptionData.account.name}
                                        </InfoBlock>
                                        : <></>}
                                    <InfoBlock
                                        key='sub-val'
                                        title='Посещений'>
                                        {`Осталось ${this.state.subscriptionData.leftValue} из ${this.state.subscriptionData.totalValue}`}
                                    </InfoBlock>
                                    <InfoBlock
                                        key='sub-org'
                                        title='Организация'>
                                        <p className='rounded border p-1 m-0'>
                                            {this.state.subscriptionData.organization.name} <Link to={`/organizations/${this.state.subscriptionData.organization.id}`} className="btn btn-primary p-0"><i className="bi bi-info-square"/></Link>
                                        </p>
                                    </InfoBlock>
                                    {!!this.state.subscriptionData.comment
                                        ? <InfoBlock
                                            key='sub-com'
                                            title='Комментарий'>
                                            {this.state.subscriptionData.comment}
                                        </InfoBlock> 
                                        : <></>}
                                </ElementsList>
                            </ElementBlock>
                            <div className='d-flex justify-content-center'>
                                <div className='p-2 rounded bg-light'>
                                    <QrCode abb={qrTypes.subscriptionCode} code={`${this.state.subscriptionData.code}`}/>
                                </div>
                            </div>
                        </ElementsList>
                    </ElementsBase> 
                </div>
            );
        }
        else{
            return (<></>);
        }
    }

    async componentDidMount(){
        await this.loadData();

        document.title = "Детали абонемента"; 
    }
}

const mapStateToProps = (state) => {
    return {
        accountToken: state.account.token
    }
}

export default connectInternal(mapStateToProps)(withParams(OrganizationSubscriptionDetails));