import React, { Component } from "react";
import accountsService from '../../Services/AccountsService';
import { getNodaDateTimeFormatString, dateAddDays } from '../../Helpers/FormHelpers';
import { withParams, connectInternal } from '../../Helpers/SystemHelper';
import PagingElement from "../FormComponents/PagingElement";
import EventListElement from "../Elements/EventListElement";
import EventTicketListElement from "../Elements/EventTicketListElement";
import OrganizationSubscriptionListElement from "../Elements/OrganizationSubscriptionListElement";
import ElementsList from "../Elements/ElementsList";
import ElementsBase from "../Elements/ElementsBase";

class AccountMain extends Component {
    accountEventsEntriesCount = 5;

    constructor(props){
        super(props);

        this.state = {
            accountEventsTotalPagesCount: 0,
            accountEventsCollection: [],
            accountTicketsCollection: [],
            accountSubscriptionsCollection: []
        };

        this.loadData = this.loadData.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
    }

    async handlePageChange(event){
        event.preventDefault();

        await this.loadData(event.currentTarget.value);
    }

    async loadData(eventsPageNumber = 1){
        const entitiesResponse = await this.props.processActionResponse(
            accountsService.getAccountEntities(
                {
                    eventsRequest: {
                        startDatetime: getNodaDateTimeFormatString(new Date()),
                        endDatetime: getNodaDateTimeFormatString(dateAddDays(new Date(), 30)),
                        pagingFilter: {
                            entriesCount: this.accountEventsEntriesCount,
                            pageNumber: eventsPageNumber
                        }
                    },
                    ticketsRequest: {
                        isActive: true
                    },
                    subscriptionsRequest: {
                        isActive: true
                    }
                },
                this.props.accountToken
            ));

        if (!entitiesResponse.isError){
            this.setState({
                ...this.state,
                accountEventsCollection: entitiesResponse.data.events.items,
                accountEventsTotalPagesCount: Math.ceil(entitiesResponse.data.events.totalCount / this.accountEventsEntriesCount),
                accountTicketsCollection: entitiesResponse.data.tickets,
                accountSubscriptionsCollection: entitiesResponse.data.subscriptions
            });
        }
    }

    renderAccountEventsList(){
        if (!this.state.accountEventsCollection || !this.state.accountEventsCollection.some(e => !!e)){
            return (<div>Запланированные события не найдены</div>);
        }

        return (
            <ElementsList includeSeparator>
                {this.state.accountEventsCollection.map(e => 
                    <EventListElement 
                        titleButton='Link'
                        linkTo={`/events/${e.id}`}
                        buttonIcon='bi bi-info-square'
                        key={`events-list-${e.id}`} 
                        value={e} />)}
            </ElementsList>
        ); 
    }

    renderAccountTicketsList(){
        if (!this.state.accountTicketsCollection || !this.state.accountTicketsCollection.some(e => !!e)){
            return (<div>Билеты не найдены</div>);
        }

        return (
            <ElementsList includeSeparator>
                {this.state.accountTicketsCollection.map(t => 
                    <EventTicketListElement 
                        key={`tickets-list-${t.id}`} 
                        value={t}
                        titleButton='Link' 
                        linkTo={`/events/tickets/${t.id}`}
                        buttonIcon='bi bi-info-square' />)}
            </ElementsList>
        );
            
    }

    renderAccountSubscriptionsList(){
        if (!this.state.accountSubscriptionsCollection || !this.state.accountSubscriptionsCollection.some(e => !!e)){
            return (<div>Абонементы не найдены</div>);
        }

        return (
            <ElementsList>
                {this.state.accountSubscriptionsCollection.map(s => 
                    <OrganizationSubscriptionListElement 
                        key={`subscriptions-list-${s.id}`} 
                        value={s} 
                        titleButton='Link' 
                        linkTo={`/organizations/subscriptions/${s.id}`}
                        buttonIcon='bi bi-info-square' />)}
            </ElementsList>
        );
    }

    render(){
        return(
            <div className="rounded p-2 bg-primary">
                <div id="accCollParent">
                    <div className="d-flex mb-2">
                        <div className="flex-grow-1">
                            <button className="btn btn-primary w-100" type="button" data-bs-toggle="collapse" data-bs-target="#accountEventsCollapse1" aria-expanded="true">
                                События
                            </button>
                        </div>
                        <div className="flex-grow-1">
                            <button className="btn btn-primary w-100" type="button" data-bs-toggle="collapse" data-bs-target="#accountSubscriptionsCollapse1" aria-expanded="false">
                                Абонементы
                            </button>
                        </div>
                        <div className="flex-grow-1">
                            <button className="btn btn-primary w-100" type="button" data-bs-toggle="collapse" data-bs-target="#accountTicketsCollapse1" aria-expanded="false">
                                Билеты
                            </button>
                        </div>
                    </div>
                    <div className="collapse show" data-bs-parent="#accCollParent" id="accountEventsCollapse1">
                        <ElementsBase>
                            <ElementsList>
                                {this.renderAccountEventsList()}
                                <PagingElement totalPagesCount={this.state.accountEventsTotalPagesCount} onChange={this.handlePageChange} />
                            </ElementsList>
                        </ElementsBase>
                    </div>
                    <div className="collapse" data-bs-parent="#accCollParent" id="accountTicketsCollapse1">
                        <ElementsBase>
                            <ElementsList>
                                {this.renderAccountTicketsList()}
                            </ElementsList>
                        </ElementsBase>
                    </div>
                    <div className="collapse" data-bs-parent="#accCollParent" id="accountSubscriptionsCollapse1">
                        <ElementsBase>
                            <ElementsList>
                                {this.renderAccountSubscriptionsList()}
                            </ElementsList>
                        </ElementsBase>
                    </div>
                </div>
            </div>
        )
    }
    

    async componentDidMount(){
        await this.loadData();

        document.title = "Главная"; 
    }
}

const mapStateToProps = (state) => {
    return {
        accountToken: state.account.token
    }
}

export default connectInternal(mapStateToProps)(withParams(AccountMain));