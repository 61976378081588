import React, { Component } from 'react';
import { connectInternal, withRouter } from '../../Helpers/SystemHelper';
import { Link } from 'react-router-dom';
import { validationFieldClass } from '../../Helpers/Constants';
import { validateOrganizationSubscriptionName,
    validateOrganizationSubscriptionTotalValue,
    validateOrganizationSubscriptionStartDate,
    validateOrganizationSubscriptionValidityInterval,
    validateOrganizationSubscriptionPrice } from '../../Helpers/Validations';
import { getFormInputDatetimeFormatString, handleInputChange, getNodaDurationFromDaysNumber, getDaysFormInputDurationFormatString, isFormStateValid, getNodaDateTimeFormatString, parseISOLocalDate } from '../../Helpers/FormHelpers';
import organizationsService from '../../Services/OrganizationsService';
import UserPicker from '../FormComponents/UserPicker';
import OrganizationHeader from '../OrganizationHeader';
import ElementsBase, { elementsBaseMenuClass } from '../Elements/ElementsBase';
import ElementsList from '../Elements/ElementsList';

class OrganizationSubscriptionCreateUpdate extends Component {
    constructor(props){
        super(props);

        this.state = {
            isCreateState: this.props.router.params.templateId || this.props.router.params.organizationId,
            isEditState: this.props.router.params.subscriptionId,
            isActionFinished: false,
            organizationId: this.props.router.params.organizationId,
            templateId: this.props.router.params.templateId,
            subscriptionId: this.props.router.params.subscriptionId,
            subscriptionName:{
                validator: validateOrganizationSubscriptionName,
                data: ''
            },
            subscriptionTotalValue:{
                validator: validateOrganizationSubscriptionTotalValue,
                data: ''
            },
            subscriptionStartDate:{
                validator: validateOrganizationSubscriptionStartDate,
                data: getFormInputDatetimeFormatString(new Date((new Date()).getTime() + 60000))
            },
            subscriptionValidityInterval:{
                validator: validateOrganizationSubscriptionValidityInterval,
                data: ''
            },
            subscriptionAccountLink:{
                data: []
            },
            subscriptionComment:{
                data: ''
            },
            subscriptionPrice:{
                validator: validateOrganizationSubscriptionPrice,
                data: ''
            }
        };

        this.loadData = this.loadData.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.isFormStateValid = isFormStateValid.bind(this);
    }

    async handleSubmit(event){
        event.preventDefault();

        if (!this.isFormStateValid()){
            return;
        }

        this.setState({
            ...this.state,
            isLoadingState: true
        });

        let actionResult;
        let stateUpdate;
        if (this.state.isCreateState){
            actionResult = await this.props.processActionResponse(
                organizationsService.createSubscription(
                    {
                        organizationId: this.state.organizationId,
                        name: this.state.subscriptionName.data,
                        totalValue: this.state.subscriptionTotalValue.data,
                        startDate: getNodaDateTimeFormatString(new Date(this.state.subscriptionStartDate.data)),
                        validityInterval: getNodaDurationFromDaysNumber(this.state.subscriptionValidityInterval.data),
                        accountLink: this.state.subscriptionAccountLink.data[0],
                        comment: this.state.subscriptionComment.data || null,
                        price: this.state.subscriptionPrice.data || null
                    },
                    this.props.accountToken), this.state);

            if (!actionResult.isError){
                if (await this.state.orr.hasRight('ViewSubscriptionDetails')){
                    await this.props.router.navigate(`/organizations/subscriptions/${actionResult.data}`, { replace: true });
                }
                else{
                    await this.props.router.navigate(`/organizations/${this.state.organizationId}/subscriptions/list`, { replace: true });
                }
            }
            else{
                stateUpdate = {
                    ...actionResult.state,
                    isLoadingState: false
                };
            }
        }
        else if (this.state.isEditState){
            actionResult = await this.props.processActionResponse(
                organizationsService.updateSubscription(
                    {
                        subscriptionId: this.state.subscriptionId,
                        name: this.state.subscriptionName.data,
                        totalValue: this.state.subscriptionTotalValue.data,
                        startDate: getNodaDateTimeFormatString(new Date(this.state.subscriptionStartDate.data)),
                        validityInterval: getNodaDurationFromDaysNumber(this.state.subscriptionValidityInterval.data),
                        accountLink: this.state.subscriptionAccountLink.data[0],
                        comment: this.state.subscriptionComment.data || null,
                        price: this.state.subscriptionPrice.data || null
                    },
                    this.props.accountToken), this.state);

            if (!actionResult.isError){
                await this.props.router.navigate(`/organizations/subscriptions/${this.state.subscriptionId}`, { replace: true });
            }
            else{
                stateUpdate = {
                    ...actionResult.state,
                    isLoadingState: false
                };
            }
        }

        this.setState(stateUpdate);
    }

    handleInputChange(event) {
        handleInputChange.call(this, [event.target]);
    }

    async loadData(){
        if (this.state.templateId){
            const actionResult = await this.props.processActionResponse(
                organizationsService.getSubscriptionTemplate(this.state.templateId, this.props.accountToken));

            if (!actionResult.isError){
                this.setState({
                    ...this.state,
                    organizationId: actionResult.data.organizationId,
                    orr: await this.props.getOrganizationRights(actionResult.data.organizationId),
                    subscriptionName:{
                        ...this.state.subscriptionName,
                        data: actionResult.data.name
                    },
                    subscriptionTotalValue:{
                        ...this.state.subscriptionTotalValue,
                        data: actionResult.data.totalValue
                    },
                    subscriptionValidityInterval:{
                        ...this.state.subscriptionValidityInterval,
                        data: getDaysFormInputDurationFormatString(actionResult.data.validityInterval)
                    },
                    subscriptionPrice:{
                        ...this.state.subscriptionPrice,
                        data: actionResult.data.price ?? ''
                    }
                });
            }
        }
        else if (this.state.subscriptionId){
            const actionResult = await this.props.processActionResponse(
                organizationsService.getSubscription(this.state.subscriptionId, this.props.accountToken));

            if (!actionResult.isError){
                this.setState({
                    ...this.state,
                    organizationId: actionResult.data.organization.id,
                    orr: await this.props.getOrganizationRights(actionResult.data.organization.id),
                    subscriptionName:{
                        ...this.state.subscriptionName,
                        data: actionResult.data.name
                    },
                    subscriptionTotalValue:{
                        ...this.state.subscriptionTotalValue,
                        data: actionResult.data.totalValue
                    },
                    subscriptionStartDate:{
                        ...this.state.subscriptionStartDate,
                        data: getFormInputDatetimeFormatString(new Date(actionResult.data.startDate)),
                        isEditDisabled: actionResult.data.usageHistory && actionResult.data.usageHistory.some(u => !!u)
                    },
                    subscriptionValidityInterval:{
                        ...this.state.subscriptionValidityInterval,
                        data: getDaysFormInputDurationFormatString(actionResult.data.validityInterval)
                    },
                    subscriptionAccountLink:{
                        ...this.state.subscriptionAccountLink,
                        data: [actionResult.data.account],
                        isEditDisabled: actionResult.data.account
                    },
                    subscriptionComment:{
                        ...this.state.subscriptionAccountLink,
                        data: actionResult.data.comment ?? ''
                    },
                    subscriptionPrice:{
                        ...this.state.subscriptionPrice,
                        data: actionResult.data.price ?? ''
                    }
                });
            }
        }
    }

    render(){
        if (this.state.organizationId){
            const canViewOrgAccounts = this.state.orr.hasRight('ViewOrganizationAccounts');

            return (
                <div>
                    <OrganizationHeader organizationId={this.state.organizationId} />
                    <ElementsBase
                        titleText='Абонемент'>
                        <div className={elementsBaseMenuClass}>
                            <button className="btn btn-outline-success border-0 me-2" type="button" onClick={this.handleSubmit} disabled={this.state.isLoadingState}>
                                <i className="bi bi-check-square" hidden={this.state.isLoadingState}/>
                                <span className="spinner-border spinner-border-sm" hidden={!this.state.isLoadingState}/>
                            </button>
                            <Link className="btn btn-outline-warning border-0" to={`/organizations/${this.state.organizationId}/subscriptions/list`} hidden={this.state.isCreateState} disabled={this.state.isLoadingState}><i className="bi bi-x-square"/></Link>
                        </div>
                        <ElementsList>
                            <div key='sub-name' className="form-floating">
                                <input id="subscriptionName" type="text" className={`${validationFieldClass} form-control`} onChange={this.handleInputChange} value={this.state.subscriptionName.data}/>
                                <label htmlFor="subscriptionName" className='form-label'>Название</label>
                                <div className="invalid-feedback" htmlFor="templateName">{this.state.subscriptionName.error}</div>
                            </div>
                            <div key='sub-tot-v' className="form-floating">
                                <input id="subscriptionTotalValue" type="number" className={`${validationFieldClass} form-control`} onChange={this.handleInputChange} value={this.state.subscriptionTotalValue.data}/>
                                <label htmlFor="subscriptionTotalValue" className='form-label'>Кол-во посещений</label>
                                <div className="invalid-feedback" htmlFor="subscriptionTotalValue">{this.state.subscriptionTotalValue.error}</div>
                            </div>
                            <div key='sub-st-d' className="form-floating">
                                <input id="subscriptionStartDate" type="datetime-local" className={`${validationFieldClass} form-control`} onChange={this.handleInputChange} value={this.state.subscriptionStartDate.data} disabled={this.state.subscriptionStartDate.isEditDisabled}/>
                                <label htmlFor="subscriptionStartDate" className='form-label'>Начало действия</label>
                                <div className="invalid-feedback" htmlFor="subscriptionStartDate">{this.state.subscriptionStartDate.error}</div>
                            </div>
                            <div key='sub-val-i' className="form-floating">
                                <input id="subscriptionValidityInterval" type="number" className={`${validationFieldClass} form-control`} onChange={this.handleInputChange} value={this.state.subscriptionValidityInterval.data} />
                                <label htmlFor="subscriptionValidityInterval" className='form-label'>Срок действия (дн.)</label>
                                <div className="invalid-feedback" htmlFor="subscriptionValidityInterval">{this.state.subscriptionValidityInterval.error}</div>
                            </div>
                            <div key='sub-a-l'>
                                <UserPicker id="subscriptionAccountLink" label='Пользователь' organizationId={canViewOrgAccounts ? this.state.organizationId : ''} onChange={this.handleInputChange} value={this.state.subscriptionAccountLink.data} isSingleSelect='true' disabled={this.state.subscriptionAccountLink.isEditDisabled}/>
                            </div>
                            <div key='sub-com' className="form-floating">
                                <textarea className="form-control h-25" id="subscriptionComment" rows="5" onChange={this.handleInputChange} value={this.state.subscriptionComment.data}></textarea>
                                <label htmlFor="subscriptionComment" className="form-label">Комментарий</label>
                            </div>
                            <div key='sub-pr' className="form-floating">
                                <input id="subscriptionPrice" type="number" step='.01' className={`${validationFieldClass} form-control`} onChange={this.handleInputChange} value={this.state.subscriptionPrice.data}/>
                                <label htmlFor="subscriptionPrice" className='form-label'>Цена</label>
                                <div className="invalid-feedback" htmlFor="subscriptionPrice">{this.state.subscriptionPrice.error}</div>
                            </div>
                        </ElementsList>
                    </ElementsBase>
                </div>
            );
        }

        return <></>;
    }

    async componentDidMount(){
        await this.loadData();

        if (this.state.isCreateState){
            document.title = "Выдача абонемента"; 
        }
        else{
            document.title = "Изменение абонемента"; 
        }
    }
}

const mapStateToProps = (state) => {
    return {
        accountToken: state.account.token
    }
}

export default connectInternal(mapStateToProps)(withRouter(OrganizationSubscriptionCreateUpdate));