import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoginForm from "../LoginForm/LoginForm";
import AppMain from '../AppMain';
import RegistrationForm from '../RegistrationForm/RegistrationForm';
import {BrowserRouter as Router, Routes, Route, Navigate, Link} from 'react-router-dom';
import { bindActionCreators } from '@reduxjs/toolkit';
import * as accountActions from '../../Reducers/AccountReducer';
import * as systemActions from '../../Reducers/SystemReducer';
import { getLocalStorageAccountData, getLocalStorageSystemData } from '../../Helpers/LocalStorageHelper'
import VerifyAccount from '../VerifyAccount/VerifyAccount';
import RequireAuth from '../RequireAuth';
import RequireAnonymous from '../RequireAnonymous';
import PasswordResetForm from '../PasswordResetForm/PasswordResetForm';
import PasswordResetFormInit from '../PasswordResetForm/PasswordResetFormInit';
import TermsOfUse from '../TermsOfUse';

class App extends Component{
  operateStorage(){
    const accountStorageData = getLocalStorageAccountData();
    const systemStorageData = getLocalStorageSystemData();

    this.props.setCaptchaKey(process.env.REACT_APP_CAPTCHA_KEY);

    if (accountStorageData){
      this.props.setLoginData(accountStorageData.token, accountStorageData.systemRoleId, accountStorageData.name);
    }

    if (systemStorageData){
      if (systemStorageData.showCookieNotification){
        this.props.showCookieNotification();
      }
      else{
        this.props.hideCookieNotification();
      }
    }
    else{
      this.props.showCookieNotification();
    }
  }

  render(){
    this.operateStorage();

    return (
      <div className="App py-2">
        <div className='container'>
          <div className='row'>
            <div className='col'/>
            <div className='col-xxl-5 col-xl-6 col-lg-7 col-md-9'>
              <div className='container' hidden={!this.props.systemError}>
                <div className="alert alert-danger alert-dismissible" role="alert">
                  {this.props.systemError}
                  <button type="button" className="btn-close" onClick={this.props.removeSystemError} aria-label="Close"></button>
                </div>
              </div>
              <div className='container' hidden={!this.props.isShowCookieNotification}>
                <div className="alert alert-warning alert-dismissible fade show" role="alert">
                  <strong>Мы используем файлы cookie для обеспечения работоспособности сайта. Продолжая его использование вы подтверждаете согласие на использование.</strong>
                  <button type="button" className="btn-close" data-bs-dismiss="alert" onClick={this.props.hideCookieNotification} aria-label="Close"></button>
                </div>
              </div>
              <Router>
                <Routes>
                  <Route path="/" element={<RequireAuth><Navigate to={'/main'}/></RequireAuth>} />
                  <Route path="/*" element={<RequireAuth><AppMain/></RequireAuth>} />
                  <Route path="/login" element={<RequireAnonymous><LoginForm/></RequireAnonymous>} />
                  <Route path="/registration" element={<RequireAnonymous><RegistrationForm/></RequireAnonymous>} />
                  <Route path="/termsofuse" element={<TermsOfUse/>} />
                  <Route path="/resetPassword" element={<RequireAnonymous><PasswordResetFormInit/></RequireAnonymous>} />
                  <Route path="/resetPassword/:resetCode" element={<RequireAnonymous><PasswordResetForm/></RequireAnonymous>} />
                  <Route path="/verifyAccount/:verificationId" element={<VerifyAccount/>} />
                </Routes>
                <div className="container">
                  <footer className="d-flex flex-wrap justify-content-between align-items-center border-top py-2 mt-2">
                    <a href='https://t.me/sfera_sobytiy' target="_blank" rel="noreferrer">Поддержка</a>
                  </footer>
                </div>
                <div className="container">
                  <footer className="d-flex flex-wrap justify-content-between align-items-center border-top py-2">
                    <p className="col-md-4 mb-0 text-muted">2023 Сфера Событий</p>
                    <Link to='/termsofuse' target="_blank" rel="noopener noreferrer">Пользовательское соглашение</Link>
                  </footer>
                </div>
              </Router>
            </div>
            <div className='col'/>
          </div>
        </div>
      </div>
        
    );
  }
}

const mapStateToProps = (state) => {
  return {
      accountToken: state.account.token,
      systemError: state.system.systemError,
      isShowCookieNotification: state.system.showCookieNotification
  }
}

const mapDispatchToProps = (dispatch) => {
  const { setLoginData } = bindActionCreators(accountActions, dispatch);
  const { removeSystemError, setCaptchaKey, hideCookieNotification, showCookieNotification } = bindActionCreators(systemActions, dispatch);
  return {
    setLoginData: (token, systemRoleId, name) => setLoginData({ token: token, systemRoleId: systemRoleId, name: name }),
    removeSystemError: () => removeSystemError(),
    setCaptchaKey: (key) => setCaptchaKey(key),
    hideCookieNotification: () => hideCookieNotification(),
    showCookieNotification: () => showCookieNotification()
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
