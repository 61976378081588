import React, { Component } from "react";
import { connectInternal, withRouter } from '../../Helpers/SystemHelper';
import OrganizationHeader from "../OrganizationHeader";
import organizationsService from "../../Services/OrganizationsService";
import ElementsBase from "../Elements/ElementsBase";
import ElementsList from "../Elements/ElementsList";
import ElementBlock from "../Elements/ElementBlock";
import InfoBlock from "../Elements/InfoBlock";
import BeautyTextBlock from "../Elements/BeautyTextBlock";
import { Link } from "react-router-dom";

class OrganizationDetails extends Component {
    constructor(props){
        super(props);

        this.state = {
            organizationData: ''
        };

        this.loadData = this.loadData.bind(this);
    }

    async loadData(){
        const actionResult = await this.props.processActionResponse(
            organizationsService.getOrganization(this.props.router.params.organizationId, this.props.accountToken));

        if (!actionResult.isError){
            this.setState({
                ...this.state,
                organizationData: actionResult.data
            });
        }
    }

    render(){
        if (!this.state.organizationData){
            return <></>;
        }

        return(
            <div>
                <OrganizationHeader organizationId={this.props.router.params.organizationId} />
                {!!this.state.organizationData.mainPictureUrl
                        ? <img src={this.state.organizationData.mainPictureUrl} className="rounded img-fluid w-100 mb-3" alt="MainPicture"/>
                        : <div hidden></div>}
                <ElementsBase
                    titleText={this.state.organizationData.name}>
                    <ElementsList includeSeparator>
                        <div className="d-flex justify-content-end">
                            <Link className="btn btn-secondary" to={`/organizations/${this.props.router.params.organizationId}/calendar`}>Календарь</Link>
                        </div>
                        <InfoBlock
                            title='Город'>
                            {this.state.organizationData.city.name}
                        </InfoBlock>
                        {!!this.state.organizationData.description
                            ? <InfoBlock
                                title='Описание'>
                                <BeautyTextBlock textData={this.state.organizationData.description} />
                            </InfoBlock>
                            : <div hidden></div>}
                        {this.renderContactsBlock()}
                    </ElementsList>
                </ElementsBase> 
            </div>
        )
    }

    renderContactsBlock(){
        const elements = [];

        if (!!this.state.organizationData.phoneNumber){
            elements.push(
                <a key='ph-n-b' className="btn btn-secondary px-1" href={`tel:${this.state.organizationData.phoneNumber}`}>
                    <i className="bi bi-telephone"/> {this.state.organizationData.phoneNumber}
                </a>);
        }
        if (!!this.state.organizationData.vkLink){
            elements.push(
                <a key='vk-l-b' target="_blank" className="btn p-2" href={this.state.organizationData.vkLink} rel="noreferrer">
                    <img src={`${process.env.PUBLIC_URL}/img/vk_compact_logo.png`} alt='VK' width='30' height='30'></img>
                </a>);
        }

        if (!elements.some(e => !!e)){
            return <div hidden></div>;
        }

        return (
            <InfoBlock>
                <ElementsList horizontal>
                    {elements}
                </ElementsList>
            </InfoBlock>
        );
    }

    async componentDidMount(){
        await this.loadData();

        document.title = "Детали организации"; 
    }
}

const mapStateToProps = (state) => {
    return {
        accountToken: state.account.token
    }
}

export default connectInternal(mapStateToProps)(withRouter(OrganizationDetails));