import React from "react";
import Header from "../Header";
import { Routes, Route, useLocation, useNavigate, useParams } from 'react-router-dom';
import AccountDetails from '../AccountDetails';
import OrganizationsAdminGrid from "../OrganizationsAdminGrid";
import OrganizationCreateForm from "../OrganizationCreateForm";
import MyOrganizations from "../MyOrganizations";
import OrganizationDetails from "../OrganizationDetails";
import OrganizationRoles from "../OrganizationRoles";
import OrganizationRoleCreateUpdate from "../OrganizationRoleCreateUpdate";
import AccountQr from "../AccountQr";
import OrganizationGroups from "../OrganizationGroups";
import OrganizationGroupCreateUpdate from "../OrganizationGroupCreateUpdate";
import EventDetails from "../EventDetails";
import EventCreateUpdate from "../EventCreateUpdate";
import OrganizationQr from "../OrganizationQr";
import OrganizationSubscriptionsTemplates from "../OrganizationSubscriptionsTemplates";
import OrganizationSubscriptionTemplateCreateUpdate from "../OrganizationSubscriptionTemplateCreateUpdate";
import OrganizationSubscriptionCreateUpdate from "../OrganizationSubscriptionCreateUpdate";
import OrganizationSubscriptions from "../OrganizationSubscriptions";
import OrganizationSubscriptionDetails from "../OrganizationSubscriptionDetails";
import EventTicketsTemplates from "../EventTicketsTemplates";
import EventTicketTemplateDetails from "../EventTicketTemplateDetails";
import EventTicketTemplateCreateUpdate from "../EventTicketTemplateCreateUpdate";
import EventTickets from "../EventTickets";
import EventTicketCreateUpdate from "../EventTicketCreateUpdate";
import TicketDetails from "../TicketDetails";
import AccountMain from "../AccountMain";
import EventApplications from "../EventApplications";
import EventAttendances from "../EventAttendances";
import AccountGroups from "../AccountGroups";
import OrganizationSystemSubscriptionDetails from "../OrganizationSystemSubscriptionDetails";
import EventSystemSubscriptionDetails from "../EventSystemSubscriptionDetails";
import OrganizationReports from "../OrganizationReports";
import OrganizationUpdateDetailsForm from "../OrganizationUpdateDetailsForm";
import OrganizationCalendar from "../OrganizationCalendar";
import SystemStatus from "../SystemStatus";

const AppMain = () => {
    return (
        <div className="container">
            <Header/>
            <Routes>
                <Route path="/main" element={<AccountMain/>} />
                <Route path="/account" element={<AccountDetails/>} />
                <Route path="/admin/organizations" element={<OrganizationsAdminGrid/>} />
                <Route path="/admin/organizations/create" element={<OrganizationCreateForm/>} />
                <Route path="/admin/organizations/:organizationId/edit" element={<OrganizationCreateForm/>} />
                <Route path="/admin/systemstatus" element={<SystemStatus/>} />
                <Route path="/organizations/:organizationId/edit" element={<OrganizationUpdateDetailsForm/>} />
                <Route path="/organizations" element={<MyOrganizations/>} />
                <Route path="/organizations/:organizationId" element={<OrganizationDetails/>} />
                <Route path="/organizations/:organizationId/calendar" element={<OrganizationCalendar/>} />
                <Route path="/organizations/:organizationId/roles" element={<OrganizationRoles/>} />
                <Route path="/organizations/:organizationId/roles/create" element={<OrganizationRoleCreateUpdate/>} />
                <Route path="/organizations/roles/:organizationRoleId" element={<OrganizationRoleCreateUpdate/>} />
                <Route path="/account/qr" element={<AccountQr/>} />
                <Route path="/organizations/:organizationId/groups" element={<OrganizationGroups/>} />
                <Route path="/organizations/:organizationId/groups/create" element={<OrganizationGroupCreateUpdate/>} />
                <Route path="/organizations/groups/:organizationGroupId" element={<OrganizationGroupCreateUpdate/>} />
                <Route path="/events/:eventId" element={<EventDetails/>} />
                <Route path="/organizations/:organizationId/events/create" element={<EventCreateUpdate/>} />
                <Route path="/events/:eventId/edit" element={<EventCreateUpdate/>} />
                <Route path="/organizations/:organizationId/qr" element={<OrganizationQr/>} />
                <Route path="/organizations/:organizationId/subscriptions-templates" element={<OrganizationSubscriptionsTemplates/>} />
                <Route path="/organizations/:organizationId/subscriptions-templates/create" element={<OrganizationSubscriptionTemplateCreateUpdate/>} />
                <Route path="/organizations/subscriptions-templates/:templateId" element={<OrganizationSubscriptionTemplateCreateUpdate/>} />
                <Route path="/organizations/subscriptions-templates/:templateId/create-subscription" element={<OrganizationSubscriptionCreateUpdate/>} />
                <Route path="/organizations/:organizationId/subscriptions/list" element={<OrganizationSubscriptions/>} />
                <Route path="/organizations/subscriptions/:subscriptionId/edit" element={<OrganizationSubscriptionCreateUpdate/>} />
                <Route path="/organizations/subscriptions/:subscriptionId" element={<OrganizationSubscriptionDetails/>} />
                <Route path="/events/:eventId/tickets-templates" element={<EventTicketsTemplates/>} />
                <Route path="/events/tickets-templates/:templateId" element={<EventTicketTemplateDetails/>} />
                <Route path="/events/:eventId/tickets-templates/create" element={<EventTicketTemplateCreateUpdate/>} />
                <Route path="/events/tickets-templates/:templateId/update" element={<EventTicketTemplateCreateUpdate/>} />
                <Route path="/events/:eventId/tickets" element={<EventTickets/>} />
                <Route path="/events/tickets-templates/:templateId/create-ticket" element={<EventTicketCreateUpdate/>} />
                <Route path="/events/tickets/:ticketId/update" element={<EventTicketCreateUpdate/>} />
                <Route path="/events/tickets/:ticketId" element={<TicketDetails/>} />
                <Route path="/events/:eventId/applications" element={<EventApplications/>} />
                <Route path="/events/:eventId/attendances" element={<EventAttendances/>} />
                <Route path="/groups" element={<AccountGroups/>} />
                <Route path="/organizations/:organizationId/system-subscription" element={<OrganizationSystemSubscriptionDetails/>} />
                <Route path="/events/:eventId/system-subscription" element={<EventSystemSubscriptionDetails/>} />
                <Route path="/organizations/:organizationId/reports" element={<OrganizationReports/>} />
            </Routes>
        </div>
    );
}

export default AppMain;