import React, { Component } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { withParams, connectInternal } from '../../Helpers/SystemHelper';
import eventsService from '../../Services/EventsService';
import organizationsService from '../../Services/OrganizationsService';
import QrScanner from '../FormComponents/QrScanner';
import { getDurationDateTimeFormatString, handleInputChange, getLocalDateTimeOffset, isFormStateValid } from '../../Helpers/FormHelpers';
import InfoBlock from '../Elements/InfoBlock';
import OrganizationSubscriptionListElement from '../Elements/OrganizationSubscriptionListElement';
import EventTicketListElement from '../Elements/EventTicketListElement';
import ElementBlock, { elementBlockTitleClass, elementBlockMenuClass } from '../Elements/ElementBlock';
import ElementsBase from '../Elements/ElementsBase';
import { validateEventCustomAttendanceComment, validateEventCustomAttendancePrice, validateEventCopyAmount, validateEventCopyPeriod } from '../../Helpers/Validations';
import { validationFieldClass } from '../../Helpers/Constants';
import { getLabelById as getStatusLabel } from '../../Helpers/EventStatusHelper';
import BeautyTextBlock from '../Elements/BeautyTextBlock';
import ElementsList from '../Elements/ElementsList';
import OrganizationHeader from '../OrganizationHeader';
import AttendanceMarkElement from '../Elements/AttendanceMarkElement';

class EventDetails extends Component {
    copyEventValClass = 'copy-event-val-class';

    constructor(props){
        super(props);

        this.setCopyEventModalRef = element => {
            if (element){
                element.addEventListener('hidden.bs.modal', this.handleCopyEventModalClose);
            }
        };

        this.state = {
            eventData: '',
            organizationLinkCode: '',
            isOrganizationLinkAddDisabled: true,
            organizationData: '',
            reloadPage: false,
            organizationToRemove: '',
            applicationComment:{
                data: ''
            },
            copyEventAmount: {
                validator: validateEventCopyAmount,
                data: '',
                parameterElement: 'copyEventPeriod'
            },
            copyEventPeriod: {
                validator: validateEventCopyPeriod,
                data: '',
                parameterElement: 'copyEventAmount'
            }
        };

        this.loadData = this.loadData.bind(this);
        this.handleOrgLinkInputChange = this.handleOrgLinkInputChange.bind(this);
        this.handleAddOrganizationLinkClose = this.handleAddOrganizationLinkClose.bind(this);
        this.handleAddOrganizationLink = this.handleAddOrganizationLink.bind(this);
        this.handleAddOrganizationClose = this.handleAddOrganizationClose.bind(this);
        this.handleAddOrganization = this.handleAddOrganization.bind(this);
        this.handleSetDeleteOrganization = this.handleSetDeleteOrganization.bind(this);
        this.handleDeleteOrganization = this.handleDeleteOrganization.bind(this);
        this.handleDeleteOrganizationCancel = this.handleDeleteOrganizationCancel.bind(this);
        this.handleDeleteEvent = this.handleDeleteEvent.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleCreateApplication = this.handleCreateApplication.bind(this);
        this.handleCancellApplication = this.handleCancellApplication.bind(this);
        this.handleCancellEvent = this.handleCancellEvent.bind(this);
        this.handleOpenApplication = this.handleOpenApplication.bind(this);
        this.handleCloseApplication = this.handleCloseApplication.bind(this);
        this.isFormStateValid = isFormStateValid.bind(this);
        this.handleCopyEventPreSubmit = this.handleCopyEventPreSubmit.bind(this);
        this.handleCopyEventSubmit = this.handleCopyEventSubmit.bind(this);
        this.handleCopyEventModalClose = this.handleCopyEventModalClose.bind(this);
        this.getEventCopyResetState = this.getEventCopyResetState.bind(this);
    }

    async handleCopyEventPreSubmit(){
        if (!this.isFormStateValid(this.copyEventValClass)){
            return;
        }

        this.setState({
            ...this.state,
            eventCopyDataPrepared: true
        });
    }

    async handleCopyEventSubmit(){
        const actionResult = await this.props.processActionResponse(
            eventsService.copyEvent(
                {
                    eventId: this.props.params.eventId,
                    amount: this.state.copyEventAmount.data,
                    periodDays: this.state.copyEventPeriod.data
                },
                this.props.accountToken
            ));

        if (!actionResult.isError){
            this.setState({
                ...this.getEventCopyResetState(),
                eventCopySucceded: true
            });
        }
    }

    async handleCopyEventModalClose(){
        this.setState({
            ...this.getEventCopyResetState()
        });
    }

    getEventCopyResetState(){
        return {
            ...this.state,
            eventCopySucceded: false,
            eventCopyDataPrepared: false,
            copyEventAmount: {
                ...this.state.copyEventAmount,
                data: '',
                error: ''
            },
            copyEventPeriod: {
                ...this.state.copyEventPeriod,
                data: '',
                error: ''
            }
        };
    }

    async handleCreateApplication(){
        const actionResult = await this.props.processActionResponse(
            eventsService.createEventApplication(
                { 
                    eventId: this.props.params.eventId, 
                    accountComment: this.state.applicationComment.data
                },
                this.props.accountToken
            ));
        
        if (!actionResult.isError){
            this.loadData();
        }
    }

    async handleCancellApplication(){
        const actionResult = await this.props.processActionResponse(
            eventsService.cancellEventApplication(
                this.props.params.eventId,
                this.props.accountToken
            ));
        
        if (!actionResult.isError){
            this.loadData();
        }
    }

    async handleOpenApplication(){
        const actionResult = await this.props.processActionResponse(
            eventsService.openEventApplication(
                this.props.params.eventId,
                this.props.accountToken
            ));
        
        if (!actionResult.isError){
            this.loadData();
        }
    }

    async handleCloseApplication(){
        const actionResult = await this.props.processActionResponse(
            eventsService.closeEventApplication(
                this.props.params.eventId,
                this.props.accountToken
            ));
        
        if (!actionResult.isError){
            this.loadData();
        }
    }

    handleInputChange(event) {
        handleInputChange.call(this, [event.target]);
    }

    async handleDeleteEvent(event){
        const actionResult = await this.props.processActionResponse(
            eventsService.deleteEvent(this.props.params.eventId, this.props.accountToken));

        if (!actionResult.isError){
            this.setState({
                ...this.state,
                eventDeleted: true
            });
        };
    }

    async handleCancellEvent(event){
        const actionResult = await this.props.processActionResponse(
            eventsService.cancellEvent(this.props.params.eventId, this.props.accountToken));

        if (!actionResult.isError){
            this.loadData();
        };
    }

    async handleDeleteOrganizationCancel(event){
        this.setState({
            ...this.state,
            organizationToRemove: ''
        });
    }

    async handleDeleteOrganization(event){
        const actionResult = await this.props.processActionResponse(
            eventsService.deleteOrganizationOwnerLinkFromEvent(
                { eventId: this.props.params.eventId, organizationId: this.state.organizationToRemove.id },
                this.props.accountToken
            ));

        if (!actionResult.isError){
            this.setState({
                ...this.state,
                reloadPage: true
            });
        };
    }

    async handleSetDeleteOrganization(event){
        this.setState({
            ...this.state,
            organizationToRemove: this.state.eventData.ownersOrganizations.find(o => o.id == event.currentTarget.value)
        });
    }

    async handleAddOrganization(event){
        const actionResult = await this.props.processActionResponse(
            eventsService.linkOrganizationOwnerToEvent(
                { eventId: this.props.params.eventId, organizationLinkCode: this.state.organizationLinkCode },
                this.props.accountToken));

        if (!actionResult.isError){
            this.setState({
                ...this.state,
                reloadPage: true
            });
        }
    }

    handleAddOrganizationClose(event){
        
    }

    async handleAddOrganizationLink(event){
        const actionResult = await this.props.processActionResponse(
            organizationsService.getLinkCodeOrganizaiton(this.state.organizationLinkCode, this.props.accountToken));

        if (!actionResult.isError){
            this.setState({
                ...this.state,
                organizationData: actionResult.data
            });
        }
        else{
            this.setState({
                ...this.state,
                isOrganizationLinkAddDisabled: true,
                organizationLinkCode: '',
                organizationData: ''
            });
        }
    }

    handleAddOrganizationLinkClose(event){
        this.setState({
            ...this.state,
            isOrganizationLinkAddDisabled: true,
            organizationLinkCode: ''
        });
    }

    handleOrgLinkInputChange(event){
        this.setState({
            ...this.state,
            isOrganizationLinkAddDisabled: !event.currentTarget.value,
            organizationLinkCode: event.currentTarget.value
        });
    }

    async loadData(){
        if (this.eventDeleted){
            return;
        }

        const actionResult = await this.props.processActionResponse(
            eventsService.getEvent(this.props.params.eventId, this.props.accountToken));

        const ownersOrganizationsData = actionResult.data.ownersOrganizations;

        const orr = await this.props.getOrganizationsRights(actionResult.data.ownersOrganizations.map(o => o.id));

        for(let ownerOrganization of ownersOrganizationsData){
            ownerOrganization.canBeRemoved = orr.hasOrganizationRight(ownerOrganization.id, 'DeleteEvent');
        }

        if (!actionResult.isError){
            this.setState({
                ...this.state,
                orr: orr,
                singleOrganizationId: ownersOrganizationsData.length == 1 ? ownersOrganizationsData[0].id : null,
                eventData: {
                    ...actionResult.data,
                    ownersOrganizations: ownersOrganizationsData,
                    eventLocalOffset: actionResult.data.city.offsetValue
                }
            });
        }
    }

    renderAddOrganizationLinkModal(){
        return (
            <div className="modal fade" id="addOrganizationCodeModal" tabIndex="-1" aria-labelledby="addOrganizationCodeModal" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Добавление организации</h5>
                        </div>
                        <div className="modal-body">
                            <div className="mb-3">
                                <input type="number" className="form-control" placeholder='Код' onChange={this.handleOrgLinkInputChange} value={this.state.organizationLinkCode}/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-warning border-0" onClick={this.handleAddOrganizationLinkClose} data-bs-dismiss="modal">Отменить</button>
                            <button type="button" className="btn btn-outline-success border-0" onClick={this.handleAddOrganizationLink} data-bs-toggle="modal" data-bs-target="#addOrganizationModal" disabled={this.state.isOrganizationLinkAddDisabled}>Добавить</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderAddOrganizationModal(){
        let bodyText = 'Загрузка...';
        if (this.state.organizationData){
            if (this.state.eventData.ownersOrganizations.some(o => o.id == this.state.organizationData.id)){
                bodyText = `Организация уже добавлена`
            }
            else{
                bodyText = `Будет добавлена организация ${this.state.organizationData.name}`
            }
        }

        return (
            <div className="modal fade" id="addOrganizationModal" tabIndex="-1" aria-labelledby="addOrganizationModal" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="mb-3">
                                {bodyText}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-warning border-0" onClick={this.handleAddOrganizationClose} data-bs-dismiss="modal">Отменить</button>
                            <button type="button" className="btn btn-outline-success border-0" onClick={this.handleAddOrganization} data-bs-dismiss="modal" disabled={!this.state.organizationData}>Добавить</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderDeleteOrganizationModal(){
        return (
            <div className="modal fade" id="deleteOrganizationLinkModal" tabIndex="-1" aria-labelledby="deleteOrganizationLinkModal" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Удаление организации</h5>
                        </div>
                        <div className="modal-body">
                            {this.state.eventData.ownersOrganizations.filter(o => o.canBeRemoved).map(o => <button key={`del-btn-${o.id}`} className='btn btn-outline-primary' onClick={this.handleSetDeleteOrganization} value={o.id} data-bs-toggle="modal" data-bs-target="#deleteOrganizationLinkApproveModal">{o.name}</button>)}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-warning border-0" data-bs-dismiss="modal">Отменить</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderDeleteOrganizationApproveModal(){
        return (
            <div className="modal fade" id="deleteOrganizationLinkApproveModal" tabIndex="-1" aria-labelledby="deleteOrganizationLinkApproveModal" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            Будет удалена привязка организации {this.state.organizationToRemove ? this.state.organizationToRemove.name : ''}. Вы уверены?
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-warning border-0" onClick={this.handleDeleteOrganizationCancel} data-bs-dismiss="modal">Нет</button>
                            <button type="button" className="btn btn-outline-danger border-0" onClick={this.handleDeleteOrganization} data-bs-dismiss="modal">Да</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderDeleteEventApproveModal(){
        return (
            <div className="modal fade" id="deleteEventApproveModal" tabIndex="-1" aria-labelledby="deleteEventApproveModal" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            Событие будет удалено. Вы уверены?
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-warning border-0" data-bs-dismiss="modal">Нет</button>
                            <button type="button" className="btn btn-outline-danger border-0" onClick={this.handleDeleteEvent} data-bs-dismiss="modal">Да</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderCancellEventApproveModal(){
        return (
            <div className="modal fade" id="cancellEventApproveModal" tabIndex="-1" aria-labelledby="cancellEventApproveModal" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            Событие будет отменено. Вы уверены?
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-warning border-0" data-bs-dismiss="modal">Нет</button>
                            <button type="button" className="btn btn-outline-danger border-0" onClick={this.handleCancellEvent} data-bs-dismiss="modal">Да</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderCreateApplicationModal(){
        return (
            <div className="modal fade" id="createApplicationModal" tabIndex="-1" aria-labelledby="createApplicationModal" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="form-floating mb-3">
                                <input id="applicationComment" type="text" className="form-control" onChange={this.handleInputChange} value={this.state.applicationComment.data}/>
                                <label htmlFor="eventAddress" className='form-label'>Комментарий</label>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-warning border-0" data-bs-dismiss="modal">Отменить</button>
                            <button type="button" className="btn btn-outline-success border-0" onClick={this.handleCreateApplication} data-bs-dismiss="modal">Записаться</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderCopyEventModal(){
        return (
            <div className="modal fade" ref={this.setCopyEventModalRef} id="copyEventModal" tabIndex="-1" aria-labelledby="copyEventModal" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            {this.renderCopyEventModalBody()}
                        </div>
                        <div className="modal-footer">
                            <button type="button" hidden={this.state.eventCopyDataPrepared || this.state.eventCopySucceded} className="btn btn-outline-warning border-0" data-bs-dismiss="modal">Отменить</button>
                            <button type="button" hidden={this.state.eventCopyDataPrepared || this.state.eventCopySucceded} className="btn btn-outline-success border-0" onClick={this.handleCopyEventPreSubmit}>Скопировать</button>
                            <button type="button" hidden={!this.state.eventCopyDataPrepared || this.state.eventCopySucceded} className="btn btn-outline-warning border-0" data-bs-dismiss="modal">Нет</button>
                            <button type="button" hidden={!this.state.eventCopyDataPrepared || this.state.eventCopySucceded} className="btn btn-outline-success border-0" onClick={this.handleCopyEventSubmit}>Да</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderCopyEventModalBody(){
        if (!this.state.eventCopyDataPrepared && !this.state.eventCopySucceded){
            return(
                <div>
                    <div className="form-floating mb-3">
                        <input id="copyEventAmount" type="number" className={`${this.copyEventValClass} form-control`} onChange={this.handleInputChange} value={this.state.copyEventAmount.data}/>
                        <label htmlFor="copyEventAmount" className='form-label'>Количество копий</label>
                        <div className="invalid-feedback" htmlFor="copyEventAmount">{this.state.copyEventAmount.error}</div>
                    </div>
                    <div className="form-floating mb-3">
                        <input id="copyEventPeriod" type="number" className={`${this.copyEventValClass} form-control`} onChange={this.handleInputChange} value={this.state.copyEventPeriod.data}/>
                        <label htmlFor="copyEventPeriod" className='form-label'>Промежуток (дн.) между копиями</label>
                        <div className="invalid-feedback" htmlFor="copyEventPeriod">{this.state.copyEventPeriod.error}</div>
                    </div>
                </div>
            );
        }
        if (this.state.eventCopySucceded){
            return(
                <div className='text-success text-center'>
                    <h1><i className="bi bi-exclamation-triangle"/></h1>
                    <h6>Копии созданы.</h6>
                </div>
            );
        }
        if (this.state.eventCopyDataPrepared){
            return(
                <div className='text-danger text-center'>
                    <h1><i className="bi bi-exclamation-triangle"/></h1>
                    <h6>Будет создано копий: {this.state.copyEventAmount.data} шт. с периодом {this.state.copyEventPeriod.data} дн. Вы уверены?</h6>
                </div>
            );
        }
    }

    renderCancellApplicationModal(){
        return (
            <div className="modal fade" id="cancellApplicationModal" tabIndex="-1" aria-labelledby="cancellApplicationModal" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">Заявка будет отменена. Вы уверены?
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-warning border-0" data-bs-dismiss="modal">Нет</button>
                            <button type="button" className="btn btn-outline-danger border-0" onClick={this.handleCancellApplication} data-bs-dismiss="modal">Да</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderOpenApplicationModal(){
        return (
            <div className="modal fade" id="openApplicationModal" tabIndex="-1" aria-labelledby="openApplicationModal" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">Запись на событие будет открыта. Вы уверены?
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-warning border-0" data-bs-dismiss="modal">Нет</button>
                            <button type="button" className="btn btn-outline-success border-0" onClick={this.handleOpenApplication} data-bs-dismiss="modal">Да</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderCloseApplicationModal(){
        return (
            <div className="modal fade" id="closeApplicationModal" tabIndex="-1" aria-labelledby="closeApplicationModal" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">Запись на событие будет закрыта. Вы уверены?
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-warning border-0" data-bs-dismiss="modal">Нет</button>
                            <button type="button" className="btn btn-outline-danger border-0" onClick={this.handleCloseApplication} data-bs-dismiss="modal">Да</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderEventMenu(){
        const eventData = this.state.eventData;
        
        const eventHasTicketAttendanceFilter = eventData.attendanceFiltersIds.some(f => f === 2);

        const isEventCancelled = eventData.isCancelled;
        const showCancellEventButton = !isEventCancelled && this.state.orr.hasRight('CancellEvent') && eventData.canBeUpdated;
        const showDeleteOrganizationButton = eventData.ownersOrganizations.length > 1 && eventData.ownersOrganizations.some(o => o.canBeRemoved) && eventData.canBeUpdated;
        const showDeleteEventButton = eventData.ownersOrganizations.every(o => o.canBeRemoved);
        const showApplicationsButton = this.state.orr.hasRight('ViewEventApplications') && eventData.requiresApplication;
        const showEditButton = this.state.orr.hasRight('UpdateEvent') && eventData.canBeUpdated;
        const showTicketsButton = this.state.orr.hasRight('ViewTickets') && eventHasTicketAttendanceFilter;
        const showTicketsTemplatesButton = (this.state.orr.hasRight('CreateTicketTemplate') || this.state.orr.hasRight('UpdateTicketTemplate') || this.state.orr.hasRight('DeleteTicketTemplate')) && eventData.canBeUpdated && eventHasTicketAttendanceFilter;
        const showLinkOrganizationButton = this.state.orr.hasRight('LinkOrganizationToEvent') && eventData.canBeUpdated;
        const showMarkAttendanceButton = (this.state.orr.hasRight('MarkCustomAttendance') || this.state.orr.hasRight('MarkSubscriptionUsage') || this.state.orr.hasRight('MarkTicketUsage')) && eventData.canAttendanceBeMarked;
        const showCloseApplicationButton = this.state.orr.hasRight('OperateEventApplications') && eventData.requiresApplication && !eventData.applicationClosed && eventData.canBeUpdated;
        const showOpenApplicationButton = this.state.orr.hasRight('OperateEventApplications') && eventData.requiresApplication && eventData.applicationClosed && eventData.canBeUpdated;
        const showAttendancesButton = this.state.orr.hasRight('ViewEventAttendances');
        const showSubscriptionDetailsButton = this.state.orr.hasRight('ViewSystemSubscriptionStatus');
        const showEventCopyButton = this.state.orr.hasRight('CreateEvent');

        if (!showDeleteEventButton && !showCancellEventButton
            && !showDeleteOrganizationButton && !showApplicationsButton
            && !showEditButton && !showTicketsButton && !showTicketsTemplatesButton
            && !showLinkOrganizationButton && !showMarkAttendanceButton
            && !showAttendancesButton && !showSubscriptionDetailsButton
            && !showEventCopyButton){
                return <></>;
            }

        return (
            <div className="dropstart">
                <button type="button" className="btn btn-primary" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="bi bi-gear"/>
                </button>
                <div className="dropdown-menu bg-secondary text-nowrap" style={{"--bs-bg-opacity": ".25"}}>
                    <div className='p-1'>
                        <ElementsList>
                            <Link className="btn btn-primary" type="button" to={`/events/${this.props.params.eventId}/edit`} hidden={!showEditButton}>
                                <i className="bi bi-pencil-square"/> Редактировать
                            </Link>
                            <Link className="btn btn-primary" type="button" to={`/events/${this.props.params.eventId}/applications`} hidden={!showApplicationsButton}>
                                <i className="bi bi-hand-index"/> Заявки на посещение
                            </Link>
                            <button className="btn btn-success text-white" type="button" onClick={event => event.preventDefault()} hidden={!showOpenApplicationButton} data-bs-toggle="modal" data-bs-target="#openApplicationModal">
                                <i className="bi bi-arrow-clockwise"/> Открыть запись
                            </button>
                            <button className="btn btn-warning" type="button" onClick={event => event.preventDefault()} hidden={!showCloseApplicationButton} data-bs-toggle="modal" data-bs-target="#closeApplicationModal">
                                <i className="bi bi-x-octagon"/> Закрыть запись
                            </button>
                            <Link className="btn btn-primary" type="button" to={`/events/${this.props.params.eventId}/tickets-templates`} hidden={!showTicketsTemplatesButton}>
                                <i className="bi bi-ticket"/> Шаблоны билетов
                            </Link>
                            <Link className="btn btn-primary" type="button" to={`/events/${this.props.params.eventId}/tickets`} hidden={!showTicketsButton}>
                                <i className="bi bi-ticket-perforated"/> Билеты
                            </Link>
                            <Link className="btn btn-primary" type="button" to={`/events/${this.props.params.eventId}/system-subscription`} hidden={!showSubscriptionDetailsButton}>
                                <i className="bi bi-ticket-perforated"/> Статус ограничений
                            </Link>
                            {/* <button className="btn btn-primary" type="button" onClick={event => event.preventDefault()} data-bs-toggle="modal" data-bs-target="#addOrganizationCodeModal" hidden={!showLinkOrganizationButton}>
                                <i className="bi bi-house-add"/> Добавить организацию
                            </button>
                            <button className="btn btn-danger" type="button" onClick={event => event.preventDefault()} hidden={!showDeleteOrganizationButton} data-bs-toggle="modal" data-bs-target="#deleteOrganizationLinkModal">
                                <i className="bi bi-house-dash"/> Удалить организацию
                            </button> */}
                            <button className="btn btn-primary" type="button" onClick={event => event.preventDefault()} data-bs-toggle="modal" data-bs-target="#markAttendanceElement-init-modal" hidden={!showMarkAttendanceButton}>
                                <i className="bi bi-check-square"/> Отметить посещение
                            </button>
                            <Link className="btn btn-primary" type="button" to={`/events/${this.props.params.eventId}/attendances`} hidden={!showAttendancesButton}>
                                <i className="bi bi-card-checklist"/> Отмеченные посещения
                            </Link>
                            <button className="btn btn-primary" type="button" onClick={event => event.preventDefault()} data-bs-toggle="modal" data-bs-target="#copyEventModal" hidden={!showEventCopyButton}>
                                <i className="bi bi-check-square"/> Копировать событие
                            </button>
                            <button className="btn btn-danger text-white" type="button" onClick={event => event.preventDefault()} hidden={!showCancellEventButton} data-bs-toggle="modal" data-bs-target="#cancellEventApproveModal">
                                <i className="bi bi-trash3-fill"/> Отменить событие
                            </button>
                            <button className="btn btn-danger text-white" type="button" onClick={event => event.preventDefault()} hidden={!showDeleteEventButton} data-bs-toggle="modal" data-bs-target="#deleteEventApproveModal">
                                <i className="bi bi-trash3-fill"/> Удалить событие
                            </button>
                        </ElementsList>
                    </div>
                </div>
            </div>
        );
    }

    getModifiedDate(date, offset){
        const resultDate = new Date();

        resultDate.setTime(date.getTime() + (offset*60*60*1000));

        return resultDate;
    }

    renderEventDetails(){
        const eventData = this.state.eventData;

        let eventTimeTitle = 'Время проведения';
        let offsetDelta;
        let factEventStartTime = null;
        let factEventEndTime = null;
        if (eventData.eventLocalOffset != getLocalDateTimeOffset()){
            offsetDelta = +eventData.eventLocalOffset - getLocalDateTimeOffset();

            factEventStartTime = this.getModifiedDate(new Date(eventData.eventDatetime), offsetDelta);
            factEventEndTime = this.getModifiedDate(new Date(eventData.eventEndDatetime), offsetDelta);

            eventTimeTitle = `Время проведения (в вашей временной зоне)`;
        }

        return (
            <ElementsList includeSeparator>
                <InfoBlock className='mb-2' title={eventTimeTitle}>
                    <div className='fs-6'>
                        {getDurationDateTimeFormatString(new Date(eventData.eventDatetime), new Date(eventData.eventEndDatetime), eventData.eventDuration)}
                    </div>
                </InfoBlock>
                <InfoBlock className='mb-2' title='Время проведения (во временной зоне события)' hidden={!offsetDelta}>
                    <div className='fs-6'>
                        {getDurationDateTimeFormatString(new Date(factEventStartTime), new Date(factEventEndTime), eventData.eventDuration)}
                    </div>
                </InfoBlock>
                <InfoBlock className='mb-2' title='Ведущие' hidden={!eventData.mastersAccounts || !eventData.mastersAccounts.some(a => !!a)}>
                    <div className="d-flex flex-wrap">
                        {eventData.mastersAccounts.map(o => 
                            <h6 key={`key-mas-${o.id}}`} className="rounded border p-1 d-inline me-1">
                                {o.name}
                            </h6>)}
                    </div>
                </InfoBlock>
                <InfoBlock hidden={!eventData.specializations || !eventData.specializations.some(a => !!a)}>
                    <div className="d-flex flex-wrap">
                        {eventData.specializations.map(s => 
                            <h6 key={`key-specs-${s.id}}`} className="rounded border p-1 d-inline me-1">
                                {s.name}
                            </h6>)}
                    </div>
                </InfoBlock>
                <InfoBlock className='mb-2' title='Адрес' hidden={!eventData.address}>
                    <div className='fs-6'>
                        {eventData.address}
                    </div>
                </InfoBlock>
                <InfoBlock className='mb-2' title='Описание' hidden={!eventData.description}>
                    <div className='fs-6'>
                        <BeautyTextBlock textData={eventData.description} />
                    </div>
                </InfoBlock>
                <InfoBlock className='mb-2' title='Группы' hidden={!eventData.organizationsGroups || !eventData.organizationsGroups.some(a => !!a)}>
                    <div className="d-flex flex-wrap">
                        {eventData.organizationsGroups.map(g => 
                            <h6 key={`key-grps-${g.id}}`} className="rounded border p-1 d-inline me-1">
                                {g.name}
                            </h6>)}
                    </div>
                </InfoBlock>
                <InfoBlock className='mb-2' title='Организаторы'>
                    <div className="d-flex flex-wrap">
                        {eventData.ownersOrganizations.map(o => 
                            <h6 key={`key-ownrs-${o.id}}`} className="rounded border p-1 d-inline me-1">
                                {o.name}
                            </h6>)}
                    </div>
                </InfoBlock>
                {this.renderAdditionalInfo()}
            </ElementsList>
        );
    }

    renderAdditionalInfo(){
        const eventData = this.state.eventData;
        const elements = [];
        const postElements = [];
        let applicationCreate = false;
        let cancellApplication = false;
        let hasApprovedApplication = false;

        if (eventData.requiresApplication){
            elements.push(
                <h6 key='key-app-only' className="rounded border p-1 d-inline me-1 border-warning">
                    <i className = "bi bi-exclamation-triangle text-warning"/> Только по записи
                </h6>);

            const application = eventData.accountApplication;

            if (!application || application.statusId == 4){
                applicationCreate = eventData.applicationAvailable;
            }
            else if (application.statusId == 3 || application.statusId == 1 || application.statusId == 2){
                if (application.statusId == 3){
                    hasApprovedApplication = true;

                    elements.push(
                        <h6 key='key-app-succ' className="rounded border p-1 d-inline me-1 border-success">
                            <i className = "bi bi-check text-success"/> Вы записаны
                        </h6>);
                }
                else if (application.statusId == 1){
                    elements.push(
                        <h6 key='key-app-await' className="rounded border p-1 d-inline me-1 border-warning">
                            <i className = "bi bi-exclamation-triangle text-warning"/> Запись ожидает подтверждения
                        </h6>);
                }
                else if (application.statusId == 2){
                    elements.push(
                        <h6 key='key-app-dclnd' className="rounded border p-1 d-inline me-1 border-danger">
                            <i className = "bi bi-x-octagon text-danger"/> Запись отклонена
                        </h6>);
                }

                cancellApplication = application.statusId == 3 || application.statusId == 1;
            }

            if (eventData.applicationClosed){
                elements.push(
                    <h6 key='key-app-clsd' className="rounded border p-1 d-inline me-1 border-warning">
                        <i className = "bi bi-x-octagon text-warning"/> Запись закрыта
                    </h6>);
            }
            else if (eventData.applicationLimit && !hasApprovedApplication){
                const applicationsLeft = +eventData.applicationLimit - eventData.existingApplicationsCount;

                if (applicationsLeft <= 0){
                    elements.push(
                        <h6 key='key-app-no-lim' className="rounded border p-1 d-inline me-1 border-warning">
                            <i className = "bi bi-exclamation-triangle text-warning"/> Мест нет
                        </h6>);
                }
                else {
                    elements.push(
                        <h6 key='key-app-lim-left' className="rounded border p-1 d-inline me-1 border-warning">
                            <i className = "bi bi-exclamation-triangle text-warning"/> Осталось мест {applicationsLeft} из {eventData.applicationLimit}
                        </h6>);
                }
            }
        }

        if (eventData.attendanceFiltersIds && eventData.attendanceFiltersIds.some(f => !!f)){
            if (eventData.attendanceFiltersIds.filter(f => f == 1 || f == 2).length == 2){
                elements.push(
                    <h6 key='key-att-sub-tic' className="rounded border p-1 d-inline me-1 border-warning">
                        <i className = "bi bi-exclamation-triangle text-warning"/> Посещение по абонементам и билетам
                    </h6>);
            }
            else if (eventData.attendanceFiltersIds.some(f => f == 1)){
                elements.push(
                    <h6 key='key-att-sub' className="rounded border p-1 d-inline me-1 border-warning">
                        <i className = "bi bi-exclamation-triangle text-warning"/> Посещение по абонементам
                    </h6>);
            }
            else if (eventData.attendanceFiltersIds.some(f => f == 2)){
                elements.push(
                    <h6 key='key-att-tic' className="rounded border p-1 d-inline me-1 border-warning">
                        <i className = "bi bi-exclamation-triangle text-warning"/> Посещение по билетам
                    </h6>);
            }
        }

        if (applicationCreate && this.state.eventData.canBeUpdated){
            postElements.push(
                <button key='key-app-crt' className="btn btn-secondary me-1 p-1" data-bs-toggle="modal" data-bs-target="#createApplicationModal">
                    Записаться
                </button>);
        }

        if (cancellApplication && this.state.eventData.canBeUpdated){
            postElements.push(
                <button key='key-app-cncl' className="btn btn-warning me-1 p-1" data-bs-toggle="modal" data-bs-target="#cancellApplicationModal">
                    Отменить запись
                </button>);
        }

        if (!elements.some(e => !!e)){
            return <></>;
        }

        return (
            <div>
                <InfoBlock className='mb-2' title='Дополнительная информация'>
                    {elements}
                </InfoBlock>
                {postElements}
            </div>
        );
    }

    renderEventStatus(eventData){
        const statusText = getStatusLabel(eventData.statusId);

        switch(eventData.statusId) {
            case 1:
            case 3:
                return <span className="badge bg-info text-dark">{statusText}</span>;
            case 2:
                return <span className="badge bg-danger">{statusText}</span>;
            case 4:
            case 5:
                return <span className="badge bg-success">{statusText}</span>;
            default:
                return <></>;
          }
    }

    render(){
        if (this.state.reloadPage){
            return <Navigate to={`/events/${this.props.params.eventId}`}/>;
        }
        if (this.state.eventDeleted){
            return <Navigate to={'/'}/>;
        }

        if (this.state.eventData){
            return(
                <div>
                    <ElementsList>
                        <OrganizationHeader organizationId={this.state.singleOrganizationId}/>
                        <ElementsBase>
                            <ElementBlock>
                                <div className={elementBlockTitleClass}>
                                    {this.renderEventStatus(this.state.eventData)}
                                    <h5>{this.state.eventData.name}</h5>
                                </div>
                                <div className={elementBlockMenuClass}>
                                    {this.renderEventMenu()}
                                </div>
                                {this.renderEventDetails()}
                            </ElementBlock>
                        </ElementsBase>
                    </ElementsList>
                    {this.renderAddOrganizationLinkModal()}
                    {this.renderAddOrganizationModal()}
                    {this.renderDeleteOrganizationModal()}
                    {this.renderDeleteOrganizationApproveModal()}
                    {this.renderDeleteEventApproveModal()}
                    {this.renderCancellEventApproveModal()}
                    {this.renderCreateApplicationModal()}
                    {this.renderCancellApplicationModal()}
                    {this.renderCloseApplicationModal()}
                    {this.renderOpenApplicationModal()}
                    {this.renderCopyEventModal()}
                    <AttendanceMarkElement 
                        id="markAttendanceElement"
                        eventData={this.state.eventData}/>
                </div>
            );
        }
        else{
            return (<></>);
        }
    }

    async componentDidMount(){
        await this.loadData();

        document.title = "Детали события"; 
    }
}

const mapStateToProps = (state) => {
    return {
        accountToken: state.account.token
    }
}

export default connectInternal(mapStateToProps)(withParams(EventDetails));