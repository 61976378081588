import React, { Component } from 'react';
import { connectInternal } from '../../Helpers/SystemHelper';
import { getFormInputDatetimeFormatString, handleInputChange, isFormStateValid, getNodaDateTimeFormatString, getNodaDurationFromString, getFormInputDurationFormatString, parseISOLocalDate } from '../../Helpers/FormHelpers';
import { withParams } from '../../Helpers/SystemHelper';
import eventsService from '../../Services/EventsService';
import organizationsService from '../../Services/OrganizationsService';
import { validationFieldClass } from '../../Helpers/Constants';
import { validateEventName, validateEventDatetime, validateEventDuration, validateEventAttendanceFilters, validateEventAddress, validateEvenDescription, validateEventApplicationLimit, validateEventCity, validateEventRequiresAttendance } from '../../Helpers/Validations';
import UserPicker from '../FormComponents/UserPicker';
import SpecializationsPicker from '../FormComponents/SpecializationsPicker';
import OrganizationsGroupsPicker from '../FormComponents/OrganizationsGroupsPicker';
import { Navigate } from 'react-router-dom';
import AttendanceFilterPicker from '../FormComponents/AttendanceFilterPicker';
import ApplicationFilterPicker from '../FormComponents/ApplicationFilterPicker';
import { getFilterById } from '../../Helpers/EventsAttendanceFilteringHelper';
import { getFilterById as getApplicationFilterById } from '../../Helpers/EventsApplicationFilteringHelper';
import ElementsBase, { elementsBaseMenuClass } from '../Elements/ElementsBase';
import ElementsList from '../Elements/ElementsList';
import CitiesPicker from '../FormComponents/CitiesPicker/CitiesPicker';
import OrganizationHeader from '../OrganizationHeader';
import Modal, {showModal} from '../Elements/Modal';

class EventCreateUpdate extends Component {
    constructor(props){
        super(props);

        this.state = {
            eventData: '',
            isUpdateState: !!this.props.params.eventId,
            currentOrganizationsIds: [ this.props.params.organizationId ],
            eventName:{
                validator: validateEventName,
                data: ''
            },
            eventDatetime:{
                validator: validateEventDatetime,
                data: getFormInputDatetimeFormatString(new Date((new Date()).getTime() + 600000))
            },
            eventDuration:{
                validator: validateEventDuration,
                data: '01:00'
            },
            eventAddress:{
                validator: validateEventAddress,
                data: ''
            },
            eventDescription:{
                validator: validateEvenDescription,
                data: ''
            },
            mastersAccountsLinks:{
                data: []
            },
            specializations:{
                data: []
            },
            organizationsGroups:{
                data: []
            },
            attendanceFilters:{
                validator: validateEventAttendanceFilters,
                data: [1, 2, 3].map(i => getFilterById(i)),
                parameterElement: 'requiresApplicationFlag'
            },
            requiresApplicationFlag:{
                validator: validateEventRequiresAttendance,
                data: false,
                parameterElement: 'attendanceFilters'
            },
            requiresApplicationApproveFlag:{
                data: false
            },
            applicationLimit:{
                validator: validateEventApplicationLimit,
                data: ''
            },
            applicationFilters:{
                data: []
            },
            city: {
                validator: validateEventCity,
                data: []
            }
        };

        this.loadData = this.loadData.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.isFormStateValid = isFormStateValid.bind(this);
        this.showModal = showModal.bind(this);
    }

    async loadData(){
        let stateUpdate = this.state;

        if (this.state.isUpdateState){
            const actionResult = await this.props.processActionResponse(
                eventsService.getEvent(this.props.params.eventId, this.props.accountToken));

            this.initialEventData = actionResult.data;

            if (!actionResult.isError){
                stateUpdate = {
                    ...stateUpdate,
                    eventName:{
                        ...stateUpdate.eventName,
                        data: actionResult.data.name
                    },
                    eventDatetime:{
                        ...stateUpdate.eventDatetime,
                        data: getFormInputDatetimeFormatString(new Date(actionResult.data.eventDatetime))
                    },
                    eventDuration:{
                        ...stateUpdate.eventDuration,
                        data: getFormInputDurationFormatString(actionResult.data.eventDuration)
                    },
                    eventAddress:{
                        ...stateUpdate.eventAddress,
                        data: actionResult.data.address ?? ''
                    },
                    eventDescription:{
                        ...stateUpdate.eventDescription,
                        data: actionResult.data.description ?? ''
                    },
                    mastersAccountsLinks:{
                        ...stateUpdate.mastersAccountsLinks,
                        data: actionResult.data.mastersAccounts
                    },
                    specializations:{
                        ...stateUpdate.specializations,
                        data: actionResult.data.specializations
                    },
                    organizationsGroups:{
                        ...stateUpdate.organizationsGroups,
                        data: actionResult.data.organizationsGroups
                    },
                    attendanceFilters:{
                        ...stateUpdate.attendanceFilters,
                        data: actionResult.data.attendanceFiltersIds ? actionResult.data.attendanceFiltersIds.map(i => getFilterById(i)) : []
                    },
                    requiresApplicationFlag:{
                        ...stateUpdate.requiresApplicationFlag,
                        data: actionResult.data.requiresApplication
                    },
                    requiresApplicationApproveFlag:{
                        ...stateUpdate.requiresApplicationApproveFlag,
                        data: actionResult.data.requiresApplicationApprove
                    },
                    applicationLimit:{
                        ...stateUpdate.applicationLimit,
                        data: actionResult.data.applicationLimit ?? ''
                    },
                    applicationFilters:{
                        ...stateUpdate.applicationFilters,
                        data: actionResult.data.applicationsFiltersIds ? actionResult.data.applicationsFiltersIds.map(i => getApplicationFilterById(i)) : []
                    },
                    city:{
                        ...stateUpdate.city,
                        data: [actionResult.data.city]
                    },
                    currentOrganizationsIds: actionResult.data.ownersOrganizations.map(o => o.id)
                };

                if (actionResult.data.ownersOrganizations.length == 1){
                    stateUpdate = {
                        ...stateUpdate,
                        singleOrganizationId: actionResult.data.ownersOrganizations[0].id
                    }
                }
            }
        }
        else {
            const organizationActionResult = await this.props.processActionResponse(
                organizationsService.getOrganization(this.props.params.organizationId, this.props.accountToken));

                stateUpdate = {
                    ...stateUpdate,
                    singleOrganizationId: this.props.params.organizationId,
                    city:{
                        ...stateUpdate.city,
                        data: [organizationActionResult.data.city]
                    }
                };
        }

        this.setState(stateUpdate);
    }

    handleInputChange(event) {
        handleInputChange.call(this, [event.target]);
    }

    handleCancel(event){
        event.preventDefault();

        this.setState({
            ...this.state,
            actionCompleted: true
        });
    }

    async handleSave(event){
        event.preventDefault();

        if (!this.isFormStateValid()){
            return;
        }

        this.setState({
            ...this.state,
            isLoadingState: true
        });

        let actionResult;
        if (this.props.params.eventId){
            if (this.initialEventData.requiresApplication 
                && !this.state.requiresApplicationFlag.data 
                && event.currentTarget.value !== 'applRemoveSubmitApproveModal'){
                showModal('#applRemoveSubmitApproveModal');
                return;
            }
            
            if (this.initialEventData.attendanceFiltersIds.some(f => f === 2) 
                && !this.state.attendanceFilters.data.some(f => f.id === 2)
                && event.currentTarget.value !== 'ticketAttRemoveSubmitApproveModal'){
                showModal('#ticketAttRemoveSubmitApproveModal');
                return;
            }

            actionResult = await this.props.processActionResponse(
                eventsService.updateEvent(
                    {
                        eventId: this.props.params.eventId,
                        name: this.state.eventName.data,
                        eventDatetime: getNodaDateTimeFormatString(parseISOLocalDate(this.state.eventDatetime.data)),
                        eventDuration: getNodaDurationFromString(this.state.eventDuration.data),
                        address: this.state.eventAddress.data || null,
                        description: this.state.eventDescription.data || null,
                        mastersAccountsLinks: this.state.mastersAccountsLinks.data || null,
                        specializationsIds: this.state.specializations.data.map(s => s.id),
                        organizationsGroupsIds: this.state.organizationsGroups.data.map(g => g.id),
                        attendanceFiltersIds: this.state.attendanceFilters.data.map(f => f.id),
                        requiresApplication: this.state.requiresApplicationFlag.data,
                        requiresApplicationApprove: this.state.requiresApplicationApproveFlag.data,
                        applicationLimit: this.state.applicationLimit.data ? this.state.applicationLimit.data : null,
                        applicationsFiltersIds: this.state.applicationFilters.data.map(f => f.id),
                        cityId: this.state.city.data[0].id
                    },
                    this.props.accountToken
                ), this.state);
        }
        else{
            actionResult = await this.props.processActionResponse(
                organizationsService.createOrganizationEvent(
                    {
                        organizationId: this.props.params.organizationId,
                        name: this.state.eventName.data,
                        eventDatetime: getNodaDateTimeFormatString(parseISOLocalDate(this.state.eventDatetime.data)),
                        eventDuration: getNodaDurationFromString(this.state.eventDuration.data),
                        address: this.state.eventAddress.data || null,
                        description: this.state.eventDescription.data || null,
                        mastersAccountsLinks: this.state.mastersAccountsLinks.data || null,
                        specializationsIds:  this.state.specializations.data.map(s => s.id),
                        organizationsGroupsIds: this.state.organizationsGroups.data.map(g => g.id),
                        attendanceFiltersIds: this.state.attendanceFilters.data.map(f => f.id),
                        requiresApplication: this.state.requiresApplicationFlag.data,
                        requiresApplicationApprove: this.state.requiresApplicationApproveFlag.data,
                        applicationLimit: this.state.applicationLimit.data ? this.state.applicationLimit.data : null,
                        applicationsFiltersIds: this.state.applicationFilters.data.map(f => f.id),
                        cityId: this.state.city.data[0].id
                    },
                    this.props.accountToken
                ), this.state);
        }

        let stateUpdate;
        if (!actionResult.isError){
            stateUpdate = {
                ...this.state,
                actionCompleted: true,
                createdEventId: actionResult.data,
                isLoadingState: false
            };
        }
        else{
            stateUpdate = {
                ...actionResult.state,
                isLoadingState: false
            };
        }

        this.setState(stateUpdate);
    }

    renderApplicationBlock(){
        return (
            <div>
                <div key='ev-apf' className="form-check">
                    <input id="requiresApplicationFlag" className="form-check-input" type="checkbox" value="" onChange={this.handleInputChange} checked={!!this.state.requiresApplicationFlag.data}/>
                    <label className="form-check-label text-reset" htmlFor="requiresApplicationFlag">
                        Необходима запись
                    </label>
                    <div className="invalid-feedback" htmlFor="requiresApplicationFlag">{this.state.requiresApplicationFlag.error}</div>
                </div>
                <div key='ev-apl' hidden={!this.state.requiresApplicationFlag.data}>
                    <ElementsList>
                        <div key='ev-rapf' className="form-check">
                            <input id="requiresApplicationApproveFlag" className="form-check-input" type="checkbox" value="" onChange={this.handleInputChange} checked={!!this.state.requiresApplicationApproveFlag.data}/>
                            <label className="form-check-label text-reset" htmlFor="requiresApplicationApproveFlag">
                                Необходимо подтверждение записи
                            </label>
                        </div>
                        <div key='ev-aplmt' className="form-floating">
                            <input id="applicationLimit" type="number" className="form-control" onChange={this.handleInputChange} value={this.state.applicationLimit.data}/>
                            <label htmlFor="applicationLimit" className='form-label'>Количество мест</label>
                            <div className="invalid-feedback" htmlFor="applicationLimit">{this.state.applicationLimit.error}</div>
                        </div>
                        <div key='ev-apfp'>
                            <ApplicationFilterPicker id="applicationFilters" label='Ограничение записи' onChange={this.handleInputChange} value={this.state.applicationFilters.data} />
                        </div>
                    </ElementsList>
                </div>
            </div>
        );
    }

    renderGroupsPicker(){
        if (this.state.currentOrganizationsIds.some(i => !!i)){
            return (
                <div key='ev-gp'>
                    <OrganizationsGroupsPicker 
                        id="organizationsGroups" 
                        organizationsIds={this.state.currentOrganizationsIds} 
                        label='Группы' 
                        className='border rounded' 
                        onChange={this.handleInputChange} 
                        value={this.state.organizationsGroups.data} 
                        showGroupOrganization={!this.state.singleOrganizationId} />
                </div>
            );
        }

        return <></>;
    }

    render(){
        if (this.state.actionCompleted){
            return <Navigate to={`/events/${this.state.createdEventId ?? this.props.params.eventId}`} />
        }

        return(
            <div>
                <ElementsList>
                    <OrganizationHeader organizationId={this.state.singleOrganizationId}/>
                    <ElementsBase
                        titleText='Событие'>
                        <div className={elementsBaseMenuClass}>
                            <button className="btn btn-outline-success border-0 me-2" type="button" onClick={this.handleSave} disabled={this.state.isLoadingState}>
                                <i className="bi bi-check-square" hidden={this.state.isLoadingState}/>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" hidden={!this.state.isLoadingState}></span>
                            </button>
                            <button className="btn btn-outline-warning border-0" type="button" onClick={this.handleCancel} hidden={!this.state.isUpdateState} disabled={this.state.isLoadingState}><i className="bi bi-x-square"/></button>
                        </div>
                        <ElementsList>
                            <div key='ev-n' className="form-floating">
                                <input id="eventName" type="text" className={`${validationFieldClass} form-control`} onChange={this.handleInputChange} value={this.state.eventName.data}/>
                                <label htmlFor="eventName" className='form-label'>Название</label>
                                <div className="invalid-feedback" htmlFor="eventName">{this.state.eventName.error}</div>
                            </div>
                            <div key='ev-dt' className="form-floating">
                                <input id="eventDatetime" type="datetime-local" className={`${validationFieldClass} form-control`} onChange={this.handleInputChange} value={this.state.eventDatetime.data}/>
                                <label htmlFor="eventDatetime" className='form-label'>Время события</label>
                                <div className="invalid-feedback" htmlFor="eventDatetime">{this.state.eventDatetime.error}</div>
                            </div>
                            <div key='ev-dr' className="form-floating">
                                <input id="eventDuration" type="time" className={`${validationFieldClass} form-control`} onChange={this.handleInputChange} value={this.state.eventDuration.data}/>
                                <label htmlFor="eventDuration" className='form-label'>Продолжительность</label>
                                <div className="invalid-feedback" htmlFor="eventDuration">{this.state.eventDuration.error}</div>
                            </div>
                            <div key='ev-ad' className="form-floating">
                                <input id="eventAddress" type="text" className={`${validationFieldClass} form-control`} onChange={this.handleInputChange} value={this.state.eventAddress.data}/>
                                <label htmlFor="eventAddress" className='form-label'>Адрес</label>
                                <div className="invalid-feedback" htmlFor="eventAddress">{this.state.eventAddress.error}</div>
                            </div>
                            <div key='ev-dsc' className="form-floating">
                                <textarea className={`${validationFieldClass} form-control h-25`} id="eventDescription" rows="5" onChange={this.handleInputChange} value={this.state.eventDescription.data}></textarea>
                                <label htmlFor="eventDescription" className="form-label">Описание</label>
                                <div className="invalid-feedback" htmlFor="eventDescription">{this.state.eventDescription.error}</div>
                            </div>
                            <div key='ev-up'>
                                <UserPicker id="mastersAccountsLinks" label='Ведущие' className='border rounded' organizationId={this.state.singleOrganizationId} onChange={this.handleInputChange} value={this.state.mastersAccountsLinks.data} />
                            </div>
                            <div key='ev-sp'>
                                <SpecializationsPicker id="specializations" label='Специализации' className='border rounded' onChange={this.handleInputChange} value={this.state.specializations.data} />
                            </div>
                            {this.renderGroupsPicker()}
                            <div>
                                <CitiesPicker id="city" label='Город' singleSelect value={this.state.city.data} className={`${validationFieldClass} border rounded`} onChange={this.handleInputChange}/>
                                <div className="invalid-feedback" htmlFor="city">{this.state.city.error}</div>
                            </div>
                            <div>
                                <AttendanceFilterPicker id="attendanceFilters" label='Ограничение посещения' className={`${validationFieldClass} border rounded`} onChange={this.handleInputChange} value={this.state.attendanceFilters.data} />
                                <div className="invalid-feedback" htmlFor="attendanceFilters">{this.state.attendanceFilters.error}</div>
                            </div>
                            {this.renderApplicationBlock()}
                        </ElementsList>
                    </ElementsBase>
                </ElementsList>
                <Modal 
                    id="applRemoveSubmitApproveModal"
                    modalType='danger'
                    infoText='Вы удаляете требование записи на событие. Данное действие удалит все существующие на данном событии записи, а также настройки записи события и его невозможно отменить. Вы уверены?'
                    approveButtonText='Да'
                    approveButtonClick={this.handleSave}
                    approveButtonValue='applRemoveSubmitApproveModal'
                    cancelButtonText='Нет'>
                </Modal>
                <Modal 
                    id="ticketAttRemoveSubmitApproveModal"
                    modalType='danger'
                    infoText='Вы удаляете возможность посещения события по билету. Данное действие удалит все существующие на данном событии билеты, шаблоны билетов и посещения по билетам и его невозможно отменить. Вы уверены?'
                    approveButtonText='Да'
                    approveButtonClick={this.handleSave}
                    approveButtonValue='ticketAttRemoveSubmitApproveModal'
                    cancelButtonText='Нет'>
                </Modal>
            </div>
        );
    }

    async componentDidMount(){
        await this.loadData();

        if (this.state.isUpdateState){
            document.title = "Изменение события"; 
        }
        else{
            document.title = "Создание события"; 
        }
    }
}

const mapStateToProps = (state) => {
    return {
        accountToken: state.account.token
    }
}

export default connectInternal(mapStateToProps)(withParams(EventCreateUpdate));