import React, { Component } from 'react';
import { connectInternal } from '../../Helpers/SystemHelper';
import { withParams } from '../../Helpers/SystemHelper';
import accountsService from '../../Services/AccountsService';
import QrCode from '../FormComponents/QrCode';
import { qrTypes } from '../../Helpers/QrHelper';
import ElementsBase from '../Elements/ElementsBase';

class QrCodeScanner extends Component {
    constructor(props){
        super(props);

        this.state = {
            accountCode: ''
        }

        this.loadCode = this.loadCode.bind(this);
        this.loadCodeWithRefresh = this.loadCodeWithRefresh.bind(this);
    }

    async loadCodeWithRefresh(){
        await this.loadCode(true);
    }

    async loadCode(withRefresh = false){
        if (this.state.accountCode && !withRefresh){
            return;
        }

        var actionResult = await this.props.processActionResponse(
            accountsService.getLinkCode({
                    withRefresh: withRefresh
                },
                this.props.accountToken));

        if (!actionResult.isError){
            this.setState({
                ...this.state,
                accountCode: actionResult.data
            });
        }
    }

    render(){
        return (
            <ElementsBase>
                <h3 className='text-center'>Мой QR</h3>
                <div className='d-flex justify-content-center'>
                    <div className='p-3 rounded bg-light'>
                        <QrCode abb={qrTypes.accountCode} code={`${this.state.accountCode}`} hidden={!this.state.accountCode}/>
                    </div>
                </div>
                <div className='d-flex justify-content-center'>
                    <h1 className="text-center" hidden={!this.state.accountCode}>{this.state.accountCode}</h1>
                </div>
                <div className='d-flex justify-content-center'>
                    <button className="btn btn-primary" onClick={this.loadCodeWithRefresh}>
                        Обновить код
                    </button>
                </div>
            </ElementsBase>
        );
    }
    
    async componentDidMount() {
        await this.loadCode();

        document.title = "Мой QR"; 
    }
}

const mapStateToProps = (state) => {
    return {
        accountToken: state.account.token
    }
}

export default connectInternal(mapStateToProps)(withParams(QrCodeScanner));