const subscriptionStatuses = {
    NotYetStarted: { id: 1, label: 'Не начал действовать' },
    Active: { id: 2, label: 'Действующий' },
    Spent: { id: 3, label: 'Израсходован' },
    Overdue: { id: 4, label: 'Просрочен' },
    Deactivated: { id: 5, label: 'Аннулирован' },
}

const getLabelById = (filterId) => {
    for(let [key, {id, label}] of Object.entries(subscriptionStatuses)){
        if (id == filterId){
            return label;
        }
    }

    return;
}

const getSubscriptionStatusBadge = (subscriptionData) => {
    const statusText = getLabelById(subscriptionData.statusId);

    switch(subscriptionData.statusId) {
        case 1:
            return <span className="badge bg-info text-dark">{statusText}</span>;
        case 2:
            return <span className="badge bg-success">{statusText}</span>;
        case 3:
        case 4:
        case 5:
            return <span className="badge bg-danger">{statusText}</span>;
        default:
            return <></>;
      }
}

export { subscriptionStatuses, getLabelById, getSubscriptionStatusBadge }