import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import * as accountActions from '../Reducers/AccountReducer';
import * as systemActions from '../Reducers/SystemReducer';
import organizationsService from "../Services/OrganizationsService";
import OrganizationRoleResolver from "./OrganizationRights";
import { handleServerError } from './FormHelpers';
import { getSystemErrorData, getSystemSubscriptionErrorData } from './ServerValidationCodes';

const base64toBlob = (b64Data, contentType='', sliceSize=512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
    
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
    
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
  
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
}

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

function withRouter(Component) {
    function ComponentWithRouterProp(props) {
      let location = useLocation();
      let navigate = useNavigate();
      let params = useParams();
      return (
        <Component
          {...props}
          router={{ location, navigate, params }}
        />
      );
    }
  
    return ComponentWithRouterProp;
}

function connectInternal(mapStateToProps, mapDispatchToProps) {
    let stateContainer = {};

    const mapStateToPropsInternal = (state) => {
        stateContainer = {
            ...stateContainer,
            state
        };

        if (mapStateToProps){
            return mapStateToProps(state);
        }
        else{
            return mapStateToProps;
        }
    };

    const mapDispatchToPropsInternal = (dispatch) => {
        const { addOrganizationRights, clearData } = bindActionCreators(accountActions, dispatch);
        const { setSystemError } = bindActionCreators(systemActions, dispatch);

        let result = {
            getOrganizationRights: async (organizationId) => {
                let organizationRights = stateContainer.state.account.organizationsRights[organizationId];
                const accountToken = stateContainer.state.account.token;

                if (!organizationRights && accountToken) {
                    const result = await organizationsService.getOrganizationRights(organizationId, accountToken);

                    addOrganizationRights({ organizationId: organizationId, organizationRights: result.data });

                    organizationRights = result.data;
                }

                return new OrganizationRoleResolver([{ organizationId: organizationId, organizationRights: organizationRights }]);
            },
            getOrganizationsRights: async (organizationsIds) => {
                let organizationsRights = [];
                const accountToken = stateContainer.state.account.token;

                for (let organizationId of organizationsIds){
                    let organizationRights = stateContainer.state.account.organizationsRights[organizationId];
    
                    if (!organizationRights && accountToken) {
                        const result = await organizationsService.getOrganizationRights(organizationId, accountToken);
    
                        addOrganizationRights({ organizationId: organizationId, organizationRights: result.data });
    
                        organizationRights = result.data;
                    }

                    organizationsRights = [
                        ...organizationsRights,
                        { organizationId: organizationId, organizationRights: organizationRights }
                    ];
                }

                return new OrganizationRoleResolver(organizationsRights);
            },
            processActionResponse: async (promise, state = null) =>{
                try{
                    const actionResult = await promise;
                    
                    let isError = false;
                    let stateUpdate = state;
                    let actionData;

                    if (actionResult instanceof Blob){
                        actionData = actionResult;
                    }
                    else{
                        actionData = actionResult.data;

                        if (actionResult.validationError){
                            isError = true;
        
                            if (stateUpdate){
                                stateUpdate = handleServerError(actionResult.validationError, stateUpdate);
                            }  
                        }
                        if (actionResult.systemError || actionResult.systemError == 0){
                            if (actionResult.systemError == 1){
                                clearData();
                            }
                            else{
                                isError = true;
                            
                                const errorMessage = getSystemErrorData(actionResult.systemError);
            
                                setSystemError(errorMessage);
                            }
                        }
                        if (actionResult.systemSubscriptionErrorMetricType || actionResult.systemSubscriptionErrorMetricType == 0){
                            isError = true;
                            
                            const errorMessage = getSystemSubscriptionErrorData(actionResult.systemSubscriptionErrorMetricType);
        
                            setSystemError(errorMessage);
                        }
                        if (actionResult.errors){
                            isError = true;
        
                            setSystemError(getSystemErrorData(0));
                        }
                    }
    
                    return {
                        data: actionData,
                        isError,
                        state: stateUpdate
                    };
                }
                catch (ex){
                    setSystemError(getSystemErrorData(0));

                    throw ex;
                }
            }
        };

        if (mapDispatchToProps){
            result = {
                ...result,
                ...mapDispatchToProps(dispatch)
            };
        }

        return result;
    };

    return connect(mapStateToPropsInternal, mapDispatchToPropsInternal);
}

export { withParams, withRouter, connectInternal, base64toBlob };