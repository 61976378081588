import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

const RequireAnonymous = ({accountToken, children }) => {
    const userIsLogged = !!accountToken;
 
    if (userIsLogged) {
       return <Navigate to={'/main'} />;
    }
    return children;
 };

 const mapStateToProps = (state) => {
   return {
       accountToken: state.account.token
   }
}

export default connect(mapStateToProps)(RequireAnonymous);