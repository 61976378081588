import React, { Component } from 'react';
import QRCode from 'react-qr-code';
import { getQrString } from '../../../Helpers/QrHelper';

class QrCode extends Component {
    constructor(props){
        super(props);

        this.id = props.id;

        this.state = {
            value: getQrString({ code: props.code, abb: props.abb })
        }
    }

    render(){
        return (
            <QRCode value={this.state.value} hidden={this.props.hidden}/>
        );
    }

    static getDerivedStateFromProps(props, state){
        const qrString = getQrString({ code: props.code, abb: props.abb });
        if (qrString != state.value){
            return ({ 
                ...state,
                value: qrString
            })
        }

        return null;
    }
}

export default QrCode;