import React, { Component } from "react";
import organizationsService from "../../Services/OrganizationsService";
import { withRouter, connectInternal, b64toBlob } from '../../Helpers/SystemHelper';
import { Link } from "react-router-dom";
import Modal from "../Elements/Modal";
import ElementsBase from "../Elements/ElementsBase";
import ElementBlock from "../Elements/ElementBlock";
import AttendanceMarkElement from '../Elements/AttendanceMarkElement';
import * as ReactDOM from 'react-dom/client';

class OrganizationHeader extends Component {
    constructor(props){
        super(props);

        this.state = {
            organizationRights: {},
        };

        this.loadData = this.loadData.bind(this);
    }

    async loadData(){
        if (!this.props.organizationId){
            return;
        }

        const organizationDetailsResponse = await this.props.processActionResponse(
            organizationsService.getOrganization(this.props.organizationId, this.props.accountToken));

        let stateUpdate = this.state;
        const orr = await this.props.getOrganizationRights(this.props.organizationId);

        const viewOrganizationRole = orr.hasRight('ViewOrganizationRolesList');
        const viewOrganizationGroups = orr.hasRight('ViewOrganizationGroupsList');
        const createEvent = orr.hasRight('CreateEvent');
        const linkOrganizationToEvent = orr.hasRight('LinkOrganizationToEvent');
        const viewSubscriptions = orr.hasRight('ViewSubscriptions');
        const viewSystemSubscriptionStatus = orr.hasRight('ViewSystemSubscriptionStatus');
        const viewReports = orr.hasRight('OperateWithReport');
        const updateOrganizationDetails = orr.hasRight('UpdateOrganizationData');
        const markSubscriptionUsage = orr.hasRight('MarkSubscriptionUsage');
        stateUpdate = {
            ...stateUpdate,
            organizationName: organizationDetailsResponse.data.name,
            viewOrganizationRole: viewOrganizationRole,
            viewOrganizationGroups: viewOrganizationGroups,
            createEvent: createEvent,
            linkOrganizationToEvent: linkOrganizationToEvent,
            viewSubscriptions: viewSubscriptions,
            viewSystemSubscriptionStatus: viewSystemSubscriptionStatus,
            viewReports: viewReports,
            updateOrganizationDetails: updateOrganizationDetails,
            markSubscriptionUsage: markSubscriptionUsage,
            viewOrganizationMenu: viewOrganizationRole || viewOrganizationGroups || createEvent || linkOrganizationToEvent || viewSubscriptions || viewSystemSubscriptionStatus || viewReports || updateOrganizationDetails || markSubscriptionUsage
        };

        this.setState(stateUpdate);
    }

    render(){
        const hideOrgAttendance = document.title == "Детали события";

        if (this.state.viewOrganizationMenu){
            return(
                <div className="mb-3">
                    <nav className="navbar navbar-dark bg-primary rounded">
                        <div className="container-fluid">
                            <div className="navbar-brand ms-auto">
                                <Link className="nav-link d-inline" to={`/organizations/${this.props.organizationId}`}>
                                    {this.state.organizationName}
                                </Link>
                            </div>
                            <button className="btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent2" aria-controls="navbarSupportedContent2" aria-expanded="false" aria-label="Toggle navigation" hidden={!this.state.viewOrganizationMenu}>
                                <i className="bi bi-gear"/>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarSupportedContent2">
                                <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                                    <li className="nav-item" hidden={!this.state.viewOrganizationRole}>
                                        <Link className="nav-link" to={`/organizations/${this.props.organizationId}/roles`}>Роли</Link>
                                    </li>
                                    <li className="nav-item" hidden={!this.state.viewOrganizationGroups}>
                                        <Link className="nav-link" to={`/organizations/${this.props.organizationId}/groups`}>Группы</Link>
                                    </li>
                                    <li className="nav-item" hidden={!this.state.viewSubscriptions}>
                                        <Link className="nav-link" to={`/organizations/${this.props.organizationId}/subscriptions/list`}>Абонементы</Link>
                                    </li>
                                    <li className="nav-item" >
                                        <Link className="nav-link" to={`/organizations/${this.props.organizationId}/subscriptions-templates`}>Шаблоны абонементов</Link>
                                    </li>
                                    <li className="nav-item" >
                                        <div className="nav-link" type="button" onClick={event => event.preventDefault()} data-bs-toggle="modal" data-bs-target="#markAttendanceElement-init-modal" hidden={hideOrgAttendance}>
                                            Списать абонемент
                                        </div>
                                    </li>
                                    <li className="nav-item" hidden={!this.state.createEvent}>
                                        <Link className="nav-link" to={`/organizations/${this.props.organizationId}/events/create`}>Создать событие</Link>
                                    </li>
                                    {/* <li className="nav-item" hidden={!this.state.linkOrganizationToEvent}>
                                        <Link className="nav-link" to={`/organizations/${this.props.organizationId}/qr`}>Создать QR привязки</Link>
                                    </li> */}
                                    <li className="nav-item" hidden={!this.state.viewSystemSubscriptionStatus}>
                                        <Link className="nav-link" to={`/organizations/${this.props.organizationId}/system-subscription`}>Статус ограничений</Link>
                                    </li>
                                    <li className="nav-item" hidden={!this.state.viewReports}>
                                        <Link className="nav-link" to={`/organizations/${this.props.organizationId}/reports`}>Отчеты</Link>
                                    </li>
                                    <li className="nav-item" hidden={!this.state.updateOrganizationDetails}>
                                        <Link className="nav-link" to={`/organizations/${this.props.organizationId}/edit`}>Редактировать детали</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                    {hideOrgAttendance ? <></> : <AttendanceMarkElement id="markAttendanceElement" organizationId={this.props.organizationId}/>}
                </div>
            )
        }
        else{ return(<div/>) }
    }

    async componentDidMount(){
        await this.loadData();
    }

    async componentDidUpdate(){
        if (!this.state.organizationName){
            await this.loadData();
        }
    }
}

const mapStateToProps = (state) => {
    return {
        accountToken: state.account.token
    }
}

export default connectInternal(mapStateToProps)(withRouter(OrganizationHeader));