import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withParams, connectInternal } from '../../Helpers/SystemHelper';
import eventsService from '../../Services/EventsService';
import QrCode from '../FormComponents/QrCode';
import { qrTypes } from '../../Helpers/QrHelper';
import ElementsBase from '../Elements/ElementsBase';
import ElementsList from '../Elements/ElementsList';
import ElementBlock, { elementBlockTitleClass } from '../Elements/ElementBlock';
import InfoBlock from '../Elements/InfoBlock';
import BeautyTextBlock from '../Elements/BeautyTextBlock';
import { getTicketStatusBadge } from '../../Helpers/EventTicketStatusHelper';

class TicketDetails extends Component {
    constructor(props){
        super(props);

        this.state = {
            ticketData: ''
        };

        this.loadData = this.loadData.bind(this);
    }

    async loadData(){
        const actionResult = await this.props.processActionResponse(
            eventsService.getTicket(this.props.params.ticketId, this.props.accountToken));

        if (!actionResult.isError){
            this.setState({
                ...this.state,
                ticketData: actionResult.data
            });
        }
    }

    render(){
        if (this.state.ticketData){
            return(
                <ElementsBase>
                    <ElementsList>
                        <ElementBlock>
                            <div className={elementBlockTitleClass}>
                                {getTicketStatusBadge(this.state.ticketData)}
                                <h5>{this.state.ticketData.name}</h5>
                            </div>
                            <ElementsList includeSeparator>
                                {!!this.state.ticketData.account
                                    ? <InfoBlock
                                        key='sub-acc'
                                        title='Аккаунт'>
                                        {this.state.ticketData.account.name}
                                    </InfoBlock>
                                    : <></>}
                                <InfoBlock
                                    title='Событие (-я)'>
                                    {this.state.ticketData.events.map(e =>{
                                        const textStyle = e.isVisited ? 'text-decoration-line-through' : '';
                                        return (
                                            <p key={`ev-k-${e.id}`} className={`rounded border p-1 m-0 ${textStyle}`}>
                                                {e.name} <Link to={`/events/${e.id}`} className='btn btn-primary p-0'><i className="bi bi-info-square"/></Link>
                                            </p>
                                        );
                                    })}
                                </InfoBlock>
                                {!!this.state.ticketData.comment
                                    ? <InfoBlock
                                        key='sub-com'
                                        title='Комментарий'>
                                        <BeautyTextBlock
                                            textData={this.state.ticketData.comment} />
                                    </InfoBlock> 
                                    : <></>}
                            </ElementsList>
                        </ElementBlock>
                        <div className='d-flex justify-content-center'>
                            <div className='p-2 rounded bg-light'>
                                <QrCode abb={qrTypes.ticketCode} code={`${this.state.ticketData.code}`}/>
                            </div>
                        </div>
                    </ElementsList>
                </ElementsBase>
            );
        }
        else{
            return (<></>);
        }
    }

    async componentDidMount(){
        await this.loadData();

        document.title = "Детали билета"; 
    }
}

const mapStateToProps = (state) => {
    return {
        accountToken: state.account.token
    }
}

export default connectInternal(mapStateToProps)(withParams(TicketDetails));