import React, { Component } from 'react';
import { QrReader } from 'react-qr-reader';
import * as ReactDOM from 'react-dom/client';
import { getDataFromQrString } from '../../../Helpers/QrHelper';

class QrScanner extends Component {
    constructor(props){
        super(props);

        this.id = props.id ?? 'qr-code-edu-scanner';

        this.state = {
            isHidden: true
        };
        
        this.onQrScanCompleted = this.onQrScanCompleted.bind(this);
        this.reRenderScanner = this.reRenderScanner.bind(this);
    }

    onQrScanCompleted(result, error){
        if (!!result) {
            this.value = getDataFromQrString(result.text);

            this.props.onScanCompleted({
                target: this
            });
        }
    }

    reRenderScanner(){
        if (!this.state.isHidden && !this.qrRoot){
            const qrContainer = document.getElementById(this.id);

            this.qrRoot = ReactDOM.createRoot(qrContainer);

            const qrElement = <QrReader onResult={this.onQrScanCompleted} constraints={{facingMode: 'environment'}}/>;
            this.qrRoot.render(qrElement);
        }
        else if (this.state.isHidden && this.qrRoot){
            this.qrRoot.unmount();

            this.qrRoot = undefined;
        }
    }

    render(){
        return (
            <div>
                <div id={this.id} className={this.props.className}/>
            </div>
        );
    }

    componentDidUpdate(){
        setTimeout(this.reRenderScanner, 0)
    }

    static getDerivedStateFromProps(props, state){
        if (props.hidden != state.isHidden){
            return ({ 
                ...state,
                isHidden: props.hidden
            })
        }

        return null;
    }
}

export default QrScanner;