import React, { Component } from 'react';
import organizationsService from '../../Services/OrganizationsService';
import { connectInternal, withRouter } from '../../Helpers/SystemHelper';
import { validationFieldClass } from '../../Helpers/Constants';
import { handleInputChange, isFormStateValid } from '../../Helpers/FormHelpers';
import ElementsBase, { elementsBaseMenuClass } from '../Elements/ElementsBase';
import { validateOrganizationDescription, validateOrganizationVkLink, validateOrganizationPhone } from '../../Helpers/Validations';
import ElementsList from '../Elements/ElementsList';
import OrganizationHeader from '../OrganizationHeader';

class OrganizationUpdateDetailsForm extends Component {
    constructor(props){
        super(props);

        this.state = {
            isDetailsEditState: false,
            isPicturesEditState: false,
            vkLink:{
                validator: validateOrganizationVkLink,
                data: ''
            },
            phoneNumber:{
                validator: validateOrganizationPhone,
                data: ''
            },
            description:{
                validator: validateOrganizationDescription,
                data: ''
            },
            mainPictureFileData: ''
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleMainPictureSubmit = this.handleMainPictureSubmit.bind(this);
        this.loadData = this.loadData.bind(this);
        this.setDetailsEditState = this.setDetailsEditState.bind(this);
        this.removeDetailsEditState = this.removeDetailsEditState.bind(this);
        this.removePicturesEditState = this.removePicturesEditState.bind(this);
        this.setPicturesEditState = this.setPicturesEditState.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
    }

    onFileChange(event){
        this.setState({
            ...this.state,
            mainPictureFileData: event.target.files[0]
        });
    }

    removePicturesEditState(event) {
        this.setState({
            ...this.state,
            isPicturesEditState: false,
            mainPictureFileData: this.pictureBackup.mainPictureFileData
        });
    }

    setPicturesEditState(event) {
        this.pictureBackup = {
            mainPictureFileData: this.state.mainPictureFileData
        };

        this.setState({
            ...this.state,
            isPicturesEditState: true
        });
    }

    removeDetailsEditState(event) {
        this.setState({
            ...this.state,
            isDetailsEditState: false,
            vkLink:{
                ...this.state.vkLink,
                data: this.backup.vkLink
            },
            phoneNumber:{
                ...this.state.phoneNumber,
                data: this.backup.phoneNumber
            },
            description:{
                ...this.state.description,
                data: this.backup.description
            }
        });
    }

    setDetailsEditState(event) {
        this.backup = {
            vkLink: this.state.vkLink.data,
            phoneNumber: this.state.phoneNumber.data,
            description: this.state.description.data,
        };

        this.setState({
            ...this.state,
            isDetailsEditState: true
        });
    }

    handleInputChange(event) {
        handleInputChange.call(this, [event.target]);
    }

    async loadData(){
        if (!this.props.router.params.organizationId){
            return;
        }

        const actionResult = await this.props.processActionResponse(
            organizationsService.getOrganizationAdminDetails(this.props.router.params.organizationId, this.props.accountToken));

        if (!actionResult.isError){
            this.setState({
                ...this.state,
                vkLink:{
                    ...this.state.vkLink,
                    data: actionResult.data.vkLink ?? ''
                },
                phoneNumber:{
                    ...this.state.phoneNumber,
                    data: actionResult.data.phoneNumber ?? ''
                },
                description:{
                    ...this.state.description,
                    data: actionResult.data.description ?? ''
                },
                mainPictureFileUrl: actionResult.data.mainPictureUrl
            });
        }
    }

    async handleMainPictureSubmit(event) {
        event.preventDefault();

        if (!this.state.mainPictureFileData){
            return;
        }

        this.setState({
            ...this.state,
            isLoadingState: true
        });

        var reader = new FileReader();
        reader.readAsDataURL(this.state.mainPictureFileData);
        reader.onload = async () => {
            const base64String = reader.result
                .replace('data:', '')
                .replace(/^.+,/, '');

            const updateRequest = {
                id: this.props.router.params.organizationId,
                data: base64String
            }
    
            const actionResult = await this.props.processActionResponse(
                organizationsService.updateOrganizationMainPicture(updateRequest, this.props.accountToken), this.state);
    
            let stateUpdate;
            if (!actionResult.isError){
                stateUpdate = {
                    ...this.state,
                    isPicturesEditState: false,
                    isLoadingState: false
                };
            }
            else{
                stateUpdate = {
                    ...actionResult.state,
                    isLoadingState: false
                };
            }

            this.setState(stateUpdate);
        };
    }

    async handleSubmit(event) {
        event.preventDefault();

        if (!isFormStateValid.call(this)){
            return;
        }

        this.setState({
            ...this.state,
            isLoadingState: true
        });

        let stateUpdate;
        const updateRequest = {
            id: this.props.router.params.organizationId,
            vkLink: this.state.vkLink.data ? this.state.vkLink.data : null,
            phoneNumber: this.state.phoneNumber.data ? this.state.phoneNumber.data : null,
            description: this.state.description.data ? this.state.description.data : null,
        }

        const actionResult = await this.props.processActionResponse(
            organizationsService.updateOrganization(updateRequest, this.props.accountToken), this.state);

        if (!actionResult.isError){
            stateUpdate = {
                ...this.state,
                isDetailsEditState: false,
                isLoadingState: false
            };
        }
        else{
            stateUpdate = {
                ...actionResult.state,
                isLoadingState: false
            };
        }

        this.setState(stateUpdate);
    }

    render(){
        return (
            <ElementsList>
                <OrganizationHeader organizationId={this.props.router.params.organizationId}/>
                <ElementsBase>
                    <div className={elementsBaseMenuClass}>
                        <button className="btn btn-primary me-2" type="button" onClick={this.setDetailsEditState} hidden={this.state.isDetailsEditState} disabled={this.state.isPicturesEditState}>
                            <i className="bi bi-pencil-square"/>
                        </button>
                        <button className="btn btn-outline-success border-0 me-2" type="button" hidden={!this.state.isDetailsEditState} onClick={this.handleSubmit} disabled={this.state.isLoadingState}>
                            <span className="sr-only" hidden={this.state.isLoadingState}><i className="bi bi-check-square"/></span>
                            <span className="spinner-border spinner-border-sm" hidden={!this.state.isLoadingState}/>
                        </button>
                        <button className="btn btn-outline-warning border-0" type="button" hidden={!this.state.isDetailsEditState} onClick={this.removeDetailsEditState} disabled={this.state.isLoadingState}><i className="bi bi-x-square"/></button>
                    </div>
                    <ElementsList>
                        <div className="form-floating">
                            <textarea className={`${validationFieldClass} form-control h-25`} id="description" rows="5" onChange={this.handleInputChange} value={this.state.description.data} disabled={!this.state.isDetailsEditState}></textarea>
                            <label htmlFor="description" className="form-label">Описание</label>
                            <div className="invalid-feedback" htmlFor="description">{this.state.description.error}</div>
                        </div>
                        <div className="form-floating">
                            <input id="phoneNumber" type="text" className={`${validationFieldClass} form-control`} onChange={this.handleInputChange} value={this.state.phoneNumber.data} disabled={!this.state.isDetailsEditState}/>
                            <label htmlFor="phoneNumber" className="form-label">Номер телефона</label>
                            <div className="invalid-feedback" htmlFor="phoneNumber">{this.state.phoneNumber.error}</div>
                        </div>
                        <div className="form-floating">
                            <input id="vkLink" type="text" className={`${validationFieldClass} form-control`} onChange={this.handleInputChange} value={this.state.vkLink.data} disabled={!this.state.isDetailsEditState}/>
                            <label htmlFor="vkLink" className="form-label">Ссылка ВК</label>
                            <div className="invalid-feedback" htmlFor="vkLink">{this.state.vkLink.error}</div>
                        </div>
                    </ElementsList>
                </ElementsBase>
                <ElementsBase>
                    <div className={elementsBaseMenuClass}>
                        <button className="btn btn-primary me-2" onClick={this.setPicturesEditState} hidden={this.state.isPicturesEditState} disabled={this.state.isDetailsEditState}>
                            <i className="bi bi-pencil-square"/>
                        </button>
                        <button className="btn btn-outline-success border-0 me-2" type="button" hidden={!this.state.isPicturesEditState || !this.state.mainPictureFileData} onClick={this.handleMainPictureSubmit} disabled={this.state.isLoadingState}>
                            <i className="bi bi-check-square" hidden={this.state.isLoadingState}/>
                            <span className="spinner-border spinner-border-sm" hidden={!this.state.isLoadingState}/>
                        </button>
                        <button className="btn btn-outline-warning border-0" type="button" hidden={!this.state.isPicturesEditState} onClick={this.removePicturesEditState} disabled={this.state.isLoadingState}><i className="bi bi-x-square"/></button>
                    </div>
                    <ElementsList>
                        <input 
                            className="form-control" 
                            type="file" 
                            onChange={this.onFileChange} 
                            id="mainPictureSelect" 
                            accept=".jpg, .jpeg, .png"
                            hidden={!this.state.isPicturesEditState}/>
                        {this.renderMainImage()}
                    </ElementsList>
                </ElementsBase>
            </ElementsList>
        )
    }

    renderMainImage(){
        if (this.state.mainPictureFileData){
            return <img src={URL.createObjectURL(this.state.mainPictureFileData)} className="rounded img-fluid w-100" alt="MainPicture"/>;
        }
        else if (this.state.mainPictureFileUrl){
            return <img src={this.state.mainPictureFileUrl} className="rounded img-fluid w-100" alt="MainPicture"/>;
        }
        
        return <></>;
    }

    async componentDidMount(){
        await this.loadData();

        document.title = "Изменение деталей организации"; 
    }
}

const mapStateToProps = (state) => {
    return {
        accountToken: state.account.token
    }
}

export default connectInternal(mapStateToProps)(withRouter(OrganizationUpdateDetailsForm));