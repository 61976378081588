const rightsLabels = {
    CreateOrganizationRole: { id: 100, label: 'Создание роли' },
    UpdateOrganizationRole: { id: 101, label: 'Редактирование роли' },
    ViewOrganizationRole: { id: 102, label: 'Просмотр деталей роли' },
    ViewOrganizationRolesList: { id: 103, label: 'Просмотр списка ролей' },
    DeleteOrganizationRole: { id: 104, label: 'Удаление ролей' },

    CreateOrganizationGroup: { id: 200, label: 'Создание групп' },
    UpdateOrganizationGroup: { id: 201, label: 'Редактирование групп' },
    ViewOrganizationGroupDetails: { id: 202, label: 'Просмотр деталей группы' },
    ViewOrganizationGroupsList: { id: 203, label: 'Просмотр списка групп' },
    UpdateOrganizationGroupAccounts: { id: 204, label: 'Управление пользователями в группе' },
    UpdateOrganizationGroupRole: { id: 205, label: 'Изменение ролей группы' },
    DeleteOrganizationGroup: { id: 206, label: 'Удаление групп' },
    
    ViewOrganizationAccounts: {id: 300, label: 'Просмотр пользователей' },
    ViewSystemSubscriptionStatus: {id: 301, label: 'Просмотр статуса подписки организации' },
    OperateWithReport: {id: 302, label: 'Работа с отчетами' },
    UpdateOrganizationData: {id: 303, label: 'Изменение деталей организации' },

    CreateEvent: {id: 400, label: 'Создание событий' },
    UpdateEvent: {id: 401, label: 'Редактирование событий' },
    DeleteEvent: {id: 402, label: 'Удаление событий' },
    CancellEvent: {id: 403, label: 'Отмена событий' },
    LinkOrganizationToEvent: {id: 404, label: 'Добавление организации к событию' },
    DeleteOrganizationFromEvent: {id: 405, label: 'Удаление организации с события' },
    OperateEventApplications: {id: 406, label: 'Управление заявками на событие' },
    ViewEventApplications: {id: 407, label: 'Просмотр заявок на событие' },
    MarkCustomAttendance: {id: 408, label: 'Отметка разового посещения' },
    ViewEventAttendances: {id: 409, label: 'Просмотр посещений' },

    CreateSubscriptionTemplate: {id: 500, label: 'Создание шаблона абонемента' },
    UpdateSubscriptionTemplate: {id: 501, label: 'Редактирование шаблона абонемента' },
    DeleteSubscriptionTemplate: {id: 502, label: 'Удаление шаблона абонемента' },
    ViewSubscriptionTemplateDetails: {id: 503, label: 'Просмотр деталей шаблона абонемента' },

    CreateSubscription: {id: 600, label: 'Создание абонемента' },
    UpdateSubscription: {id: 601, label: 'Редактирование абонемента' },
    DeactivateSubscription: {id: 602, label: 'Аннулирование абонемента' },
    ViewSubscriptions: {id: 603, label: 'Просмотр абонементов организации' },
    MarkSubscriptionUsage: {id: 604, label: 'Списывание абонемента (использование)' },
    ViewSubscriptionDetails: {id: 605, label: 'Просмотр деталей абонемента' },

    CreateTicketTemplate: {id: 700, label: 'Создание шаблона билета' },
    UpdateTicketTemplate: {id: 701, label: 'Редактирование шаблона билета' },
    DeleteTicketTemplate: {id: 702, label: 'Удаление шаблона билета' },

    CreateTicket: {id: 800, label: 'Создание билета' },
    UpdateTicket: {id: 801, label: 'Редактирование билета' },
    DeactivateTicket: {id: 802, label: 'Аннулирование билета' },
    MarkTicketUsage: {id: 803, label: 'Списывание билета (использование)' },
    ViewTickets: {id: 804, label: 'Просмотр билетов события' },
    ViewTicketDetails: {id: 805, label: 'Просмотр деталей билета' },
}

class OrganizationRoleResolver{
    constructor(organizationsRightsResponses){
        this.organizationsRightsResponses = organizationsRightsResponses;
    }

    hasRight = (rightKey) => {
        const targetRight = rightsLabels[rightKey];

        for (let organizationRightsResponse of this.organizationsRightsResponses){
            if (organizationRightsResponse.organizationRights.isOwner || organizationRightsResponse.organizationRights.rightsIds.some(r => r == targetRight.id)){
                return true;
            }
        }

        return false;
    }

    hasOrganizationRight = (organizationId, rightKey) => {
        const targetRight = rightsLabels[rightKey];

        for (let organizationRightsResponse of this.organizationsRightsResponses.filter(r => r.organizationId == organizationId)){
            if (organizationRightsResponse.organizationRights.isOwner || organizationRightsResponse.organizationRights.rightsIds.some(r => r == targetRight.id)){
                return true;
            }
        }

        return false;
    }
}

const getLabelById = (rightId) => {
    for(let [key, {id, label}] of Object.entries(rightsLabels)){
        if (id == rightId){
            return label;
        }
    }

    return;
}

const getRightById = (rightId) => {
    for(let [key, {id, label}] of Object.entries(rightsLabels)){
        if (id == rightId){
            return ({
                id: id,
                name: label
            });
        }
    }

    return;
}

export default OrganizationRoleResolver;
export { rightsLabels, getLabelById, getRightById };