import React, { Component } from 'react';
import { connectInternal } from '../../Helpers/SystemHelper';
import accountsService from '../../Services/AccountsService';
import { handleInputChange, isFormStateValid, handleServerError } from '../../Helpers/FormHelpers';
import {validateAccountEmail, validateAccountPassword, validateAccountName, validateAccountPasswordVerification, validateCaptchaToken, validateTermsOfUseState} from '../../Helpers/Validations';
import { validationFieldClass } from '../../Helpers/Constants';
import RegistrationSuccess from './RegistrationSuccess';
import { Link } from 'react-router-dom';
import { SmartCaptcha } from '@yandex/smart-captcha';
import * as ReactDOM from 'react-dom/client';
import ElementsBase from '../Elements/ElementsBase';
import InitFormsWrapper from '../Elements/InitFormsWrapper';

class RegistrationForm extends Component {
    isCaptchaLoading = false;

    constructor(props){
        super(props);

        this.state = { 
            accountEmail: {
                validator: validateAccountEmail
            },
            accountName: {
                validator: validateAccountName
            },
            accountPassword: {
                validator: validateAccountPassword
            },
            accountPasswordVerification: {
                validator: validateAccountPasswordVerification,
                parameterElement: 'accountPassword'
            },
            captchaToken: {
                validator: validateCaptchaToken,
                data: ''
            },
            termsOfUseState:{
                validator: validateTermsOfUseState,
                data: false
            }
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.setCaptchaToken = this.setCaptchaToken.bind(this);
        this.togglePasswordVisibility = this.togglePasswordVisibility.bind(this);
        this.renderCaptcha = this.renderCaptcha.bind(this);
    }

    renderCaptcha(){
        const captchaContainer = document.getElementById('captchaToken');

        this.captchaRoot = ReactDOM.createRoot(captchaContainer);

        const captchaElement = <SmartCaptcha sitekey={this.props.captchaKey} onSuccess={this.setCaptchaToken} language='ru' />;

        this.captchaRoot.render(captchaElement);
    }

    togglePasswordVisibility(){
        var passwordFieldElement = document.getElementById('accountPassword');
        var visibilityIconElement = document.getElementById('p-vis-icon');

        visibilityIconElement.classList.toggle("bi-eye");
        visibilityIconElement.classList.toggle("bi-eye-slash");

        if (visibilityIconElement.classList.contains("bi-eye")){
            passwordFieldElement.type = 'password';
        }
        else if (visibilityIconElement.classList.contains("bi-eye-slash")){
            passwordFieldElement.type = 'text';
        }
    }

    setCaptchaToken(token){
        const captchaWrapDiv = document.getElementById('captchaToken');
        captchaWrapDiv.value = token;

        handleInputChange.call(this, [captchaWrapDiv]);

        this.setState({
            ...this.state,
            captchaToken:{
                ...this.state.captchaToken,
                data: token
            }
        });
    }

    handleInputChange(event) {
        if (!this.captchaRoot && event.currentTarget.id === 'termsOfUseState' && event.currentTarget.checked){
            this.renderCaptcha();
        }

        handleInputChange.call(this, [event.target]);
    }

    async handleSubmit(event) {
        event.preventDefault();

        if (!isFormStateValid.call(this)){
            return;
        }

        this.isCaptchaLoading = true;
        this.setState({
            ...this.state,
            isFormLoading: true
        });

        const actionResult = await this.props.processActionResponse(
            accountsService.createAccount({
                email: this.state.accountEmail.data,
                name: this.state.accountName.data,
                password: this.state.accountPassword.data,
                captchaToken: this.state.captchaToken.data
            }), this.state);

        let stateUpdate;
        if (actionResult.isError){
            stateUpdate = {
                ...actionResult.state,
                captchaToken: {
                    ...actionResult.state.captchaToken,
                    data: ''
                }
            };

            window.smartCaptcha.reset();
        }
        else{
            stateUpdate = {
                ...this.state,
                isAccountCreated: true
            };
        }

        this.setState({
            ...stateUpdate,
            isFormLoading: false
        });
    }

    render() {
        if (this.props.accountToken || this.state.isAccountCreated){
            return <RegistrationSuccess />;
        }
        else{
            return(
                <InitFormsWrapper>
                    <ElementsBase>
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-floating mb-3">
                                <input id="accountEmail" type="email" className={`${validationFieldClass} form-control`} onChange={this.handleInputChange} disabled={this.state.isFormLoading}/>
                                <label htmlFor="accountEmail" className='form-label'>Электронная почта</label>
                                <div className="invalid-feedback" htmlFor="accountEmail">{this.state.accountEmail.error}</div>
                            </div>
                            <div className="form-floating mb-3">
                                <input id="accountName" type="text" className={`${validationFieldClass} form-control`} onChange={this.handleInputChange} disabled={this.state.isFormLoading}/>
                                <label htmlFor="accountName" className='form-label'>Никнейм</label>
                                <div className="invalid-feedback" htmlFor="accountName">{this.state.accountName.error}</div>
                            </div>
                            <div className='d-flex mb-3'>
                                <div className="form-floating flex-grow-1">                                    
                                    <input id="accountPassword" type="password" className={`${validationFieldClass} form-control`} onChange={this.handleInputChange} disabled={this.state.isFormLoading}/>
                                    <label htmlFor="accountPassword" className='form-label'>Пароль</label>
                                    <div className="invalid-feedback" htmlFor="accountPassword">{this.state.accountPassword.error}</div>
                                </div>
                                <div>
                                    <button className="btn text-light input-group-text h-100" type='button' onClick={this.togglePasswordVisibility}><i id='p-vis-icon' className="bi bi-eye"/></button>
                                </div>
                            </div>
                            <div className="form-floating mb-3">
                                <input id="accountPasswordVerification" type="password" className={`${validationFieldClass} form-control`} onChange={this.handleInputChange} disabled={this.state.isFormLoading}/>
                                <label htmlFor="accountPasswordVerification" className='form-label'>Подтверждение пароля</label>
                                <div className="invalid-feedback" htmlFor="accountPasswordVerification">{this.state.accountPasswordVerification.error}</div>
                            </div>
                            <div className="form-check mb-3">
                                <input id="termsOfUseState" className={`${validationFieldClass} form-check-input`} type="checkbox" value="" onChange={this.handleInputChange} checked={!!this.state.termsOfUseState.data}/>
                                <label className="form-check-label text-light" htmlFor="termsOfUseState">
                                    С условиями <Link className='text-light' to='/termsofuse' target="_blank" rel="noopener noreferrer" >пользовательского соглашения</Link> ознакомлен и согласен.
                                </label>
                                <div className="invalid-feedback" htmlFor="termsOfUseState">{this.state.termsOfUseState.error}</div>
                            </div>
                            <div className='mb-2' hidden={!this.state.termsOfUseState.data}>
                                <div id="captchaToken" className={`${validationFieldClass}`}/>
                                <div className="invalid-feedback" htmlFor="captchaToken">{this.state.captchaToken.error}</div>
                            </div>
                            <button className="btn btn-secondary" type="submit" disabled={this.state.isFormLoading}>
                                <span className="sr-only" hidden={this.state.isFormLoading}>Зарегистрироваться</span>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" hidden={!this.state.isFormLoading}/>
                            </button>
                        </form>
                    </ElementsBase>
                </InitFormsWrapper>
            )
        }
    }

    componentDidMount() {
        document.title = "Регистрация"; 
    }
}

const mapStateToProps = (state) => {
    return {
        accountToken: state.account.token,
        captchaKey: state.system.captchaKey
    }
}

export default connectInternal(mapStateToProps)(RegistrationForm);