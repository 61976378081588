import { getDateTimeFormatString } from "../../Helpers/FormHelpers";
import ElementBlock, { elementBlockTitleClass } from "./ElementBlock";
import InfoBlock from "./InfoBlock";
import { getSubscriptionStatusBadge } from '../../Helpers/OrgSubscriptionStatusHelper';

const OrganizationSubscriptionListElement = (props) => {
    const subscriptionData = props.value;

    const startDate = new Date(subscriptionData.startDate);
    const expirationDate = new Date(subscriptionData.expirationDate);

    return (
        <ElementBlock 
            directProps={{
                ...props
            }}>
            <div className={elementBlockTitleClass}>
                {getSubscriptionStatusBadge(subscriptionData)}
                <h5>{subscriptionData.name}</h5>
            </div>
            <h6>Посещений: <span className="badge text-dark bg-secondary">{`${subscriptionData.leftValue}/${subscriptionData.totalValue}`}</span></h6>
            <h6>Срок действия: <span className="badge text-dark bg-secondary">{`${getDateTimeFormatString(startDate)} - ${getDateTimeFormatString(expirationDate)}`}</span></h6>
            {!!subscriptionData.account
                ? <h6>Аккаунт: <span className="badge text-dark bg-secondary">{subscriptionData.account.name}</span></h6>
                : <></>}
            {!!subscriptionData.comment
                ? <InfoBlock
                    title='Комментарий'>
                    <p className="text-truncate m-0">{subscriptionData.comment}</p>
                </InfoBlock> 
                : <></>}
        </ElementBlock>
    );
}

export default OrganizationSubscriptionListElement;