import React, { Component } from "react";
import BeautyTextBlock from '../Elements/BeautyTextBlock';
import ElementsBase from '../Elements/ElementsBase';

class TermsOfUse extends Component {
    render(){
        return(
            <div className="container">
                <ElementsBase>
                    <BeautyTextBlock 
                        textData='
                        Пользовательское Соглашение
                        Настоящее Пользовательское Соглашение (далее Соглашение) регулирует отношения между владельцем "Сфера Событий" (sferasobytiy.ru) (далее Администрация) с одной стороны и Пользователем сайта с другой.
                        Сайт Сфера Событий (sferasobytiy.ru) не является средством массовой информации.
                        
                        Права и обязанности сторон:
                        Пользователь имеет право:
                        - осуществлять поиск информации на сайте
                        - получать информацию на сайте
                        - создавать информацию для сайта
                        - распространять информацию на сайте
                        - копировать информацию на другие сайты с указанием источника
                        - копировать информацию на другие сайты с разрешения правообладателя
                        - использовать информацию сайта в личных некоммерческих целях
                        
                        Администрация имеет право:
                        - по своему усмотрению и необходимости создавать, изменять, отменять правила
                        - ограничивать доступ к любой информации на сайте
                        - создавать, изменять, удалять информацию
                        - удалять учетные записи
                        - отказывать в регистрации без объяснения причин
                        - собирать и обрабатывать Пользовательские данные предоставляемые Пользователями* 
                        - обрабатывать и передавать собранные данные сторонним сервисам с целью обеспечения работоспособности функционала сервиса СфераСобытий

                        * - Собираются и обрабатываются данные не позволяющие достоверно идентифицировать Пользователя.
                        
                        Пользователь обязуется:
                        - обеспечить достоверность предоставляемой информации
                        - обеспечивать сохранность личных данных от доступа третьих лиц
                        - при копировании информации с других источников, включать в её состав информацию об авторе
                        - не распространять информацию, которая направлена на пропаганду войны, разжигание национальной, расовой или религиозной ненависти и вражды, а также иной информации, за распространение которой предусмотрена уголовная или административная ответственность
                        - не нарушать работоспособность сайта
                        - не создавать несколько учётных записей на Сайте, если фактически они принадлежат одному и тому же лицу
                        - не совершать действия, направленные на введение других Пользователей в заблуждение
                        - не передавать в пользование свою учетную запись и/или логин и пароль своей учетной записи третьим лицам
                        - не регистрировать учетную запись от имени или вместо другого лица за исключением случаев, предусмотренных законодательством РФ
                        - не размещать материалы рекламного, эротического, порнографического или оскорбительного характера, а также иную информацию, размещение которой запрещено или противоречит нормам действующего законодательства РФ
                        - не использовать скрипты (программы) для автоматизированного сбора информации и/или взаимодействия с Сайтом и его Сервисами
                        
                        Ответственность сторон:
                        - Пользователь лично несет полную ответственность за распространяемую им информацию
                        - Администрация не несет никакой ответственности за достоверность информации размещенной пользователями
                        - Администрация не несёт ответственность за несовпадение ожидаемых Пользователем и реально полученных услуг
                        - Администрация не несет никакой ответственности за услуги, предоставляемые третьими лицами
                        - Администрация не гарантирует сохранность информации, размещённой Пользователем, а также бесперебойную работу информационного ресурса
                        
                        Условия действия Соглашения:
                        Данное Соглашение вступает в силу при любом использовании данного сайта.
                        Соглашение перестает действовать при появлении его новой версии.
                        Администрация оставляет за собой право в одностороннем порядке изменять данное соглашение по своему усмотрению.
                        Администрация не оповещает пользователей об изменении в Соглашении.
                        
                        * Соглашение разработано на базе юридических документов сервиса Правилль
                        '/>
                </ElementsBase>
            </div>
        )
    }

    componentDidMount() {
        document.title = "Пользовательское соглашение"; 
    }
}

export default TermsOfUse;